import validate from 'validate.js';
import validid from 'validid';

import Locale from './Locale';

validate.validators.hkid = (value, options, key, attributes) => {
  if (!value || validid.hkid(value.toUpperCase())) {
    return null;
  }
  else {
    return Locale.text.notValidHKID;
  }
};

export default class Validation {
  constructor(attributes, constraints) {
    this.attributes = attributes || {};
    this.constraints = constraints || {};

    for (let prop in this.constraints) {
      if (Array.isArray(this.constraints[prop])) {
        const constraints = {};

        this.constraints[prop].forEach((constraint) => {
          Object.assign(constraints, constraint);
        });

        this.constraints[prop] = constraints;
      }
    }
  }

  add(prop, attribute, constraint) {
    this.attributes[prop] = attribute;

    if (arguments.length > 3) {
      const constraints = {};

      arguments.forEach((argument, index) => {
        if (index > 1) {
          Object.assign(constraints, argument);
        }
      });

      this.constraints[prop] = constraints;
    }
    else {
      this.constraints[prop] = constraint;
    }
  }

  validate() {
    return validate(this.attributes, this.constraints, {fullMessages: false});
  }

  static trimAndRemoveEmptyStrings(data) {
    for (let prop in data) {
      if (typeof data[prop] == 'string') {
        data[prop] = data[prop].trim();
      }
      if (data[prop] == '') {
        delete data[prop];
      }
    }
  }

  static getValidator(type, mandatory) {
    const validator = {};

    switch (type) {
      case 'text':
        validator.format = {
          pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
          message: () => Locale.text.needsToBeInEnglish,
        };
        break;
      case 'number':
        validator.numericality = {
          message: () => Locale.text.notANumber,
        };
        break;
      case 'email':
        validator.email = {
          message: () => Locale.text.notValidEmail,
        };
        break;
      case 'HKID':
        validator.hkid = {
          message: () => Locale.text.notValidHKID,
        };
        break;
    }

    if (mandatory) {
      validator.presence = {
        message: () => Locale.text.thisFieldCannotBeBlank,
      };
    }

    return validator;
  }

  static getProductConfirmationValidator() {
    const validator = {
      presence: {
        message: () => Locale.text.thisFieldCannotBeBlank,
      },
      format: {
        pattern: 'Y',
        message: () => Locale.text.theAboveIsRequiredToBeAgreed,
      },
    };

    return validator;
  }
}
