import React from 'react';
import {View} from 'react-native';

import Locale from './../../Locale';
import {YesNoQuestion} from './../../Form';

export default class CommissionsDisclosures extends React.Component {
  render() {
    const {agent, broker, promotionCode, value, onChange, message} = this.props;

    if (agent || broker || promotionCode) {

      var name = broker ? broker.nameEnUs || broker.name : agent.broker.nameEnUs
  
      return (
        <YesNoQuestion
          label={Locale.text.brokerDeclaration+ 
            name
            +Locale.text.brokerDeclarationII}
          value={value}
          onChange={onChange}
          message={message}
        />
      );
    }

    return <View />;
  }
}
