import React, {useEffect} from "react";
import {
  Text,
  View,
} from "react-native";
import { useState } from "react";
import { formStyles } from "../../config/styles";

import { languageStorage } from "../../lib/LanguageStorage";
import { enUS } from "../../screens/InstantEnrollment/IPA/en-US";
import { zhHK } from "../../screens/InstantEnrollment/IPA/zh-HK";

import DatePicker from "../../components/DatePicker/DatePicker";
import ModalSelector from "../../components/ModalSelector/ModalSelector";
import TextInput from "../../components/TextField/TextField";
import NameInput from "../../components/NameInput/NameInput";

// type='spouse'
// planType={this.state.planType}
// period={this.state.period}
// people={this.state.people}
// showMedicalGuarantee={showMedicalGuarantee}
// medicalGuarantee={this.state.insuredSpouse.medicalGuarantee}
// mainlandPermit={this.state.insuredSpouse.mainlandPermit}
// title={this.state.insuredSpouse.title}
// firstName={this.state.insuredSpouse.firstName}
// lastName={this.state.insuredSpouse.lastName}
// dob={this.state.insuredSpouse.dob}
// idType={this.state.insuredSpouse.idType}
// idNumber={this.state.insuredSpouse.idNumber}
// relationship={this.state.insuredSpouse.relationship}
// onChange={( =>
//   this._onInsuredChange(this.state.insuredSpouse, }
// messages={this.state.messages.insuredSpouse}

const OccupationQuestion = ({index, occupation, number, occupationList, onChange, message}) => {

  const [translation, setTranslation] = useState({});
  const [language, setLanguage] = useState("");
  const [type, setType] = useState("group")

  const getTranslations = (language) => {
  return language === "zhHK" ? zhHK : enUS;
  };

  useEffect(() => {
    languageStorage.getLanguage((language) => {
      const translations = getTranslations(language);
      setLanguage(language);
    setTranslation(translations);
    });
  }, [occupation, message]);


  const _getTitle = () => {
    let ordinalNumber;
    number += 1
    
    if (number == 1) {
      ordinalNumber = '1st ';
    }
    else if (number == 2) {
      ordinalNumber = '2nd ';
    }
    else if (number == 3) {
      ordinalNumber = '3rd ';
    }
    else {
      ordinalNumber = number + 'th ';
    }
    if (language == 'zhHK') {
      ordinalNumber = `第 ${number} 位`
    }
    if (number) {
      return ordinalNumber + (type == "children" ? translation.child : translation.insured);
    }
    return translation.insured;
  }

  return (

      <View style={formStyles.fieldset}>
        <Text style={styles.h4}>{_getTitle(index + 1)}</Text>
        <ModalSelector
          data={occupationList}
          initValue={occupation ? occupationList.find(obj => obj.key === occupation.key) : ""}
          onChange={(option) =>{
            occupation = {...option}
            onChange(index, occupation)
          }
            
          }
          cancelText={translation.cancel}
        />
        <Text style={formStyles.message}>{message ? message.key : ""}</Text>
        {/* {(this.state.insuredOccupation == "OC3" ||
          this.state.insuredOccupation == "OC4") && (
          <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        )} */}
      </View>
  );
};


const styles = {
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: "#C50000",
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: "center",
  },
  datepicker: {
    width: "100%",
    marginTop: 10,
  },
  // planTypes: {
  //   marginTop: 10,
  //   alignSelf: 'center',
  //   width: '40%',
  // },
  submitButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    marginBottom: 20,
    backgroundColor: "#C50000",
  },
  submitButtonText: {
    fontSize: 18,
    color: "white",
  },
  line: {
    justifyContent: "center",
    alignItems: "center",
  },
};

export default OccupationQuestion;
