import React from 'react';
import { StatusBar, Linking, Text, Image, Platform, Dimensions,
  View, TouchableOpacity } from 'react-native';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import Member from './../../Member';

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      })
    );

    memberStorage.getMember(
      (member, token) => this.setState({
        member: member,
        token: token,
      })
    );
  }

  render() {
    const backgroundColor = Member.getThemeColor();

    let content, backButton, brokerButton, homeButton = null;
    if (this.props.logo) {
      content = <Image style={styles.logo} source={{uri: Member.getTheme().logo}} />
    }
    if (this.props.title) {
      content = <Text style={styles.title}>{this.props.title}</Text>
    }
    if (this.props.backButton) {
      backButton =
        <TouchableOpacity
          style={styles.backButton}
          onPress={
            () => this.props.goBack ?
              this.props.history.push(this.props.goBack) :
              this.props.history.goBack()
          }
        >
          <Image style={styles.icon} source={require('./back.png')} />
        </TouchableOpacity>;
    }
    if (this.props.brokerButton && this.state.member && this.state.member.agent) {
      brokerButton =
        <TouchableOpacity
          style={styles.brokerButton}
          onPress={() => this.props.history.push('/broker')}
        >
          <Image style={styles.icon} source={require('./menu.png')} />
        </TouchableOpacity>;
    }
    if (this.props.homeButton == undefined || this.props.homeButton) {
      homeButton =
        <TouchableOpacity
          style={styles.homeButton}
          onPress={() => this.props.history.push('/')}
        >
          <Image style={styles.icon} source={require('./home.png')} />
        </TouchableOpacity>;
    }

    const logoutButton =
      <TouchableOpacity
        style={
          this.props.homeButton == false ?
            styles.homePageLogoutButton :
            styles.logoutButton
        }
        onPress={() => {
          if (this.state.member) {
            Member.logout();
            window.location = '/';
          }
          else {
            this.props.history.push('Login');
          }
        }}
      >
        <View style={{flexDirection: 'row'}}>
          <Image
            style={{width: 30, height: 30}}
            source={require('./lock.png')}
          />
          <Text style={{color: 'white', marginTop: 7}}>
            {this.state.member ? this.state.text.logout : this.state.text.login}
          </Text>
        </View>
      </TouchableOpacity>;

    return (
      <View style={[styles.navbar, {backgroundColor}]}>
        <StatusBar
          translucent={true}
          backgroundColor='#C50000'
        />
        <View style={styles.content}>
          { content }
        </View>
        { brokerButton }
        { backButton }
        { homeButton }
        { logoutButton }
      </View>
    );
  }
}

const deviceHeight = Dimensions.get('window').height;
const deviceWidth = Dimensions.get('window').width;
const platform = Platform.OS;
const isIphoneX = platform === 'ios' && deviceHeight >= 812 && deviceWidth >= 375;

const styles = {
  navbar: {
    height: (isIphoneX) ? 80 : 60,
    backgroundColor: '#C50000',
  },
  logo : {
    width: 250*0.7,
    height: 36*0.7
  },
  title: {
    color: 'white',
    fontSize: 16,
  },
  content: {
    marginBottom: 5,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    top: (isIphoneX) ? 45 : -10,
  },
  icon: {
    width: 25,
    height :25,
  },
  brokerButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 20,
    left: 7,
  },
  backButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 17,
    left: 7,
  },
  menuButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 15.5,
    right: 7,
  },
  homeButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 17,
    right: 7,
  },
  logoutButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 17,
    right: 47,
  },
  homePageLogoutButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 17,
    right: 17,
  },
  contactIconList: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: 8,
  },
  contactIcon: {
    color: 'white',
    marginLeft: 5,
    marginRight: 5,
  },
};

const drawerStyles = {
  drawer: {
    shadowColor: '#000000',
    shadowOpacity: 0.8,
    shadowRadius: 3,
  },
};
