import Request from './Request';

import {cms} from './../config/api';

export default class MemberAPI {
  /* 1.1 發送用戶驗證 SMS */
  static sendVerify(data, onSuccess, onFail) {
    Request.post('/member/verify/send', data, onSuccess, onFail);
  }

  /* 1.2 用戶註冊 */
  static register(data, onSuccess, onFail) {
    Request.post('/member/register', data, onSuccess, onFail);
  }

  /* 1.3 用戶登入 */
  static login(data, onSuccess, onFail) {
    Request.post('/member/login', data, onSuccess, onFail);
  }

  /* 1.4 取得保險對應財務顧問 */
  static checkAdvisor(data, onSuccess, onFail) {
    Request.post('/member/advisor/check', data, onSuccess, onFail);
  }

  /* 1.5 轉介保險財務顧問 */
  static referAdvisor(data, onSuccess, onFail) {
    Request.post('/member/advisor/refer', data, onSuccess, onFail);
  }

  /* 1.6 取得 eCash 記錄 */
  static checkEcash(data, onSuccess, onFail) {
    Request.post('/member/ecash/check', data, onSuccess, onFail);
  }

  /* 1.7 設定 Device Token */
  static saveDeviceToken(data, onSuccess, onFail) {
    Request.post('/member/deviceToken/save', data, onSuccess, onFail);
  }

  /* 1.8 取消保險財務顧問 */
  static disconnectAdvisor(data, onSuccess, onFail) {
    Request.post('/member/advisor/disconnect', data, onSuccess, onFail);
  }

  /* 1.9 檢查現金券 */
  static checkVoucher(data, onSuccess, onFail) {
    Request.post('/member/voucher/check', data, onSuccess, onFail);
  }

  /* 1.10 使用現金券 */
  static redeemVoucher(data, onSuccess, onFail) {
    Request.post('/member/voucher/redeem', data, onSuccess, onFail);
  }

  /* 1.11 檢查優惠碼 */
  static checkPromotionCode(data, onSuccess, onFail) {
    Request.post('/member/promotionCode/check', data, onSuccess, onFail);
  }

  /* 1.12 確認允許生物認證採集 */
  static biometricconsent(data, onSuccess, onFail) {
    Request.post('/member/biometric/consent', data, onSuccess, onFail);
  }

  /* 1.13 採集生物認證 */
  static biometricregister(data, onSuccess, onFail) {
    Request.postsecrect('/member/biometric/register', data, onSuccess, onFail);
  }

  /* 1.14 以生物認證登入 */
  static biometriclogin(data, onSuccess, onFail) {
    Request.postsecrect('/member/biometric/login', data, onSuccess, onFail);
  }

  /* 1.15 取消生物認證登入 */
  static biometricrevoke(data, onSuccess, onFail) {
    Request.post('/member/biometric/revoke', data, onSuccess, onFail);
  }

  /* 1.16 取得會員折扣 */
  static checkDiscount(data, onSuccess, onFail) {
    Request.post('/member/discount/check', data, onSuccess, onFail);
  }

  static postReferenceCode(data, onSuccess, onFail) {
    Request.post('/api/reference-code', data, onSuccess, onFail, {host: cms});
  }
}
