import React from 'react';
import {
  Text, Switch, Dimensions,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import {Helmet} from 'react-helmet';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import DatePicker from './../../../components/DatePicker/DatePicker';
import Calendar  from './../../../components/CalendarPicker/CalendarPicker';
import validate from 'validate.js';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import md5 from 'md5';

import { planTypes } from './planTypes';
import { options } from './options';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api26, busiSecKey } from './../../../config/api';
import PromotionCode from './../../../lib/PromotionCode';
import { memberStorage } from './../../../lib/MemberStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import { checkTyphoonMessage, decline, formatName, clearEmptyStrings,
   msgCodeDetail, removeSpaces } from './../../../lib/utils';
import HKID from './../../../lib/HKID';
import validid from 'validid';
import PromoteAPI from './../../../api/PromoteAPI';
import AgentAPI from './../../../api/AgentAPI';
import ProductAPI from './../../../api/ProductAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import NameInput from './../../../components/NameInput/NameInput';
import IdentityInput from './../../../components/IdentityInput/IdentityInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';

import Alert from './../../../components/Alert/Alert';
import TextInput from './../../../components/TextField/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { stateStorage } from './../../../lib/StateStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

import Member from './../../../Member';

export default class TravelInsurance3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    const travel = this.props.location.state.travel;
    const travellers = this.props.location.state.travellers;
    if (policy) {
      let planType;
      switch (policy.planKey.planCode[policy.planKey.planCode.length - 1]) {
        case '3':
          planType = 'premier';
          break;
        case '2':
          planType = 'classic';
          break;
        default:
          planType = 'standard';
      }

      let people;
      switch (travel.premiumPlan) {
        case 'IC':
          people = 'withChildren';
          break;
        case 'F':
          people = 'family';
          break;
        default:
          if (travellers.length > 1) {
            people = 'group';
          }
          else {
            people = 'individual';
          }
      }

      const a = policy.policyHolder;
      const b = travellers[0];
      const isTraveler =
        a.salutation == b.salutation &&
        a.firstName == b.firstName &&
        a.lastName == b.lastName &&
        a.dateOfBirth == b.dateOfBirth &&
        a.documentType == b.documentType &&
        a.documentNo == b.documentNo;

      const dob =
        moment(policy.policyHolder.dateOfBirth, 'YYYY-MM-DD hh:mm:ss');
      const age = moment().diff(dob, 'years');

      if (age >= 73) {
        delete policy.policyHolder.dateOfBirth;
      }

      this.state = {
        broker: this.props.location.state.broker,
        planType: planType,
        period: policy.planKey.planCode[6] == 'S' ? 'shortTermTrip' : 'annual',
        people: people,
        isCompany: !!policy.policyHolder.organization,
        policyHolderOrganization: policy.policyHolder.organization,
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderDOB: policy.policyHolder.dateOfBirth ?
          dob.format('MMMM D, YYYY') : undefined,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderAddress: policy.policyHolder.address1,
        policyholderEmail: policy.policyHolder.email,
        policyholderIdType: policy.policyHolder.documentType,
        policyholderIdNumber: policy.policyHolder.documentNo,
        isTraveler: isTraveler,
        isBuyagain: this.props.location.state.isBuyagain,
        policyholderMedicalGuarantee: travellers[0].includeChinaMedical ? 'Y' : 'N',
        insuredIndividuals: [],
        insuredSpouse: {medicalGuarantee: 'N', relationship: 'spouse'},
        insuredChildren: [],
        question: 'N',
        messages: {insuredIndividuals: [], insuredChildren: []},
        text: {},
      };
      if (this.state.period == 'shortTermTrip') {
        this.state.maxDate = moment(this.state.startDate, 'MMMM D, YYYY').add(181, 'days');
        this.state.paBenefits = travel.isUpgradePA ? 'Y' : 'N';
        this.state.cruiseBenefit = travel.includeCruise ? 'Y' : 'N';
      }
      else {
        let includeChinaMedical = false;
        travellers.forEach((traveller) => {
          if (traveller.includeChinaMedical) {
            includeChinaMedical = true;
            return;
          }
        });
        this.state.startDate = moment().add(1, 'days').format('MMMM D, YYYY');
        this.state.endDate = moment().add(6, 'days').format('MMMM D, YYYY');
        this.state.maxDate = moment().add(1 + periodMaxDays, 'days');
        this.state.paBenefits = travel.isUpgradePA ? 'Y' : 'N';
        this.state.medicalGuarantee = includeChinaMedical ? 'Y' : 'N';
      }

      let firstInsured = true, individualIndex = 0, childrenIndex = 0;

      travellers.forEach((traveller) => {
        const dob = moment(traveller.dateOfBirth, 'YYYY-MM-DD hh:mm:ss');
        const age = moment().diff(dob, 'years');

        if (age >= 73) {
          delete traveller.dateOfBirth;
        }

        if (firstInsured) {
          this.state.policyholderMainlandPermit = traveller.mainlandPermit;

          this.state.insuredIndividuals[individualIndex++] = {
            medicalGuarantee: traveller.includeChinaMedical ? 'Y' : 'N',
            mainlandPermit: traveller.mainlandPermit,
            title: traveller.salutation ? traveller.salutation.descEnUs.replace(/\./g, '') : undefined,
            firstName: traveller.firstName,
            lastName: traveller.lastName,
            dob: traveller.dateOfBirth ? dob.format('MMMM D, YYYY') : undefined,
            idType: traveller.documentType,
            idNumber: traveller.documentNo,
            relationship: traveller.relationship.relationshipKey,
          };
          firstInsured = false;
        }
        else {
          if (travel.premiumPlan == 'I') {
            this.state.insuredIndividuals[individualIndex++] = {
              medicalGuarantee: traveller.includeChinaMedical ? 'Y' : 'N',
              mainlandPermit: traveller.mainlandPermit,
              title: traveller.salutation ? traveller.salutation.descEnUs.replace(/\./g, '') : undefined,
              firstName: traveller.firstName,
              lastName: traveller.lastName,
              dob: traveller.dateOfBirth ? dob.format('MMMM D, YYYY') : undefined,
              idType: traveller.documentType,
              idNumber: traveller.documentNo,
              relationship: traveller.relationship.relationshipKey,
            };
          }
          else {
            if (traveller.relationship.relationshipKey == 'spouse') {
              this.state.insuredSpouse = {
                medicalGuarantee: traveller.includeChinaMedical ? 'Y' : 'N',
                mainlandPermit: traveller.mainlandPermit,
                title: traveller.salutation ? traveller.salutation.descEnUs.replace(/\./g, '') : undefined,
                firstName: traveller.firstName,
                lastName: traveller.lastName,
                dob: traveller.dateOfBirth ? dob.format('MMMM D, YYYY') : undefined,
                idType: traveller.documentType,
                idNumber: traveller.documentNo,
                relationship: 'spouse',
              };
            }
            else if (traveller.relationship.relationshipKey == 'child') {
              this.state.insuredChildren[childrenIndex++] = {
                medicalGuarantee: traveller.includeChinaMedical ? 'Y' : 'N',
                mainlandPermit: traveller.mainlandPermit,
                title: traveller.salutation ? traveller.salutation.descEnUs.replace(/\./g, '') : undefined,
                firstName: traveller.firstName,
                lastName: traveller.lastName,
                dob: traveller.dateOfBirth ? dob.format('MMMM D, YYYY') : undefined,
                idType: traveller.documentType,
                idNumber: traveller.documentNo,
                relationship: 'child',
              };
            }
            else {
              this.state.insuredIndividuals[individualIndex++] = {
                medicalGuarantee: traveller.includeChinaMedical ? 'Y' : 'N',
                mainlandPermit: traveller.mainlandPermit,
                title: traveller.salutation ? traveller.salutation.descEnUs.replace(/\./g, '') : undefined,
                firstName: traveller.firstName,
                lastName: traveller.lastName,
                dob: traveller.dateOfBirth ? dob.format('MMMM D, YYYY') : undefined,
                idType: traveller.documentType,
                idNumber: traveller.documentNo,
                relationship: traveller.relationship.relationshipKey,
              };
            }
          }
        }
      });
      this.state.numberOfIndividuals = individualIndex.toString();
      this.state.numberOfChildren = childrenIndex.toString();
    }
    else {
      this.state = {
        broker: this.props.location.state.broker,
        centaline: this.props.location.state.centaline,
        planType: this.props.location.state.planType,
        period: this.props.location.state.period,
        people: this.props.location.state.people,
        numberOfChildren: this.props.location.state.numberOfChildren,
        numberOfIndividuals: this.props.location.state.numberOfIndividuals,
        paBenefits: this.props.location.state.paBenefits,
        medicalGuarantee: this.props.location.state.medicalGuarantee,
        effectiveDateStart: this.props.location.state.effectiveDateStart,
        effectiveDateEnd: this.props.location.state.effectiveDateEnd,
        cruiseBenefit: this.props.location.state.cruiseBenefit,
        startDate: this.props.location.state.startDate,
        endDate: this.props.location.state.endDate,
        maxDate: moment(this.props.location.state.maxDate),
        shortTermTripOnly: this.props.location.state.shortTermTripOnly,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
        noticeAgree: this.props.location.state.noticeAgree,
        referral: this.props.location.state.referral,
        isTraveler: true,
        isBuyagain: this.props.location.state.isBuyagain,
        policyholderMedicalGuarantee: 'N',
        insuredIndividuals: [],
        insuredSpouse: {medicalGuarantee: 'N', relationship: 'spouse'},
        insuredChildren: [],
        messages: {insuredIndividuals: [], insuredChildren: []},
        text: {},
      };
    }

    for (let i = 0; i < 100; i++) {
      this.state.insuredIndividuals.push({
        medicalGuarantee: 'N',
        relationship: 'friend',
      });
    }
    for (let i = 0; i < 100; i++) {
      this.state.insuredChildren.push({
        medicalGuarantee: 'N',
        relationship: 'child',
      });
    }

    if (!this.state.startDate) {
      this.state.startDate = moment().add(1, 'days').format('MMMM D, YYYY');
    }
    if (!this.state.endDate) {
      this.state.endDate = moment().add(6, 'days').format('MMMM D, YYYY');
    }
    if (!this.state.maxDate) {
      this.state.maxDate = moment().add(1 + periodMaxDays, 'days');
    }
    this.state.days = moment(this.state.endDate, 'MMMM D, YYYY')
      .add(1, 'days').diff(moment(this.state.startDate, 'MMMM D, YYYY'), 'days');
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('gtravel3', (state) => {
      if (state) {
        state.maxDate = moment(state.maxDate);
        this.setState(state, () => {
          if (this.state.promotionCodes && this.state.businessEmailAddress) {
            this.setState({promotionCodes: undefined});
          }
        });
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        if (!this.state.noticeAgree) {
          checkTyphoonMessage(
            this.state.centaline ? 'CTL' : 'EAPP',
            'TRAVELS22',
            this.state.language,
            this.props.history,
            (alert) => this.setState({ alert: alert }),
            (noticeAgree) => this.setState({
              alert: undefined,
              noticeAgree: noticeAgree,
            })
          );
        }

        // init HKID validation
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value.toUpperCase())) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };

        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          if (this.state.broker) {
            // init broker-specific input field
            this.setState({
              insuranceAdvisorMobile:
                this.state.member && this.state.member.agent.isAdmin ?
                  undefined : this.state.broker.mobile
            });
          }
          else {
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                if (insuranceAdvisors.TRAVEL) {
                  if (this.state.member) {
                    this.setState({insuranceAdvisor: insuranceAdvisors.TRAVEL});
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              },
              (alert) => {
                this.setState({alert: alert})
              }
            );
          }

          if (!this.state.member && this.state.promotionCodes) {
            PromoteAPI.checkPromotionCode(
              {
                pn: 'EAPP',
                pl: this.state.period == 'shortTermTrip' ?
                  'TRAVELS22' : 'TRAVELA22',
                promotionCode: this.state.promotionCodes,
              },
              (msgDesc) => {
                this.setState({insuranceAdvisor: {agent: msgDesc}});
              }
            );
          }

          if (this.props.location.state.policy) {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                const insuranceAdvisor = insuranceAdvisors.TRAVEL;
                if (insuranceAdvisor) {
                  this.setState({insuranceAdvisor: insuranceAdvisor});

                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.TRAVEL.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      this.state.period == 'shortTermTrip' ?
                        'TRAVELS22' : 'TRAVELA22',
                      (promotionCodes) => {
                        this.setState({submitButtonDisabled: false});
                        if (!this.state.businessEmailAddress) {
                          this.setState({
                            promotionCodes:
                              this.state.promotionCodes || promotionCodes,
                            showBusinessEmailAddress: false,
                            businessEmailAddress: undefined,
                          });
                        }
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    promotionCodes: this.state.businessEmailAddress ?
                      undefined : this.state.promotionCodes,
                    submitButtonDisabled: false,
                  });
                }
              }
            );

            if (!this.state.member && this.state.promotionCodes) {
              PromoteAPI.checkPromotionCode(
                {
                  pn: 'EAPP',
                  pl: this.state.period == 'shortTermTrip' ?
                    'TRAVELS22' : 'TRAVELA22',
                  promotionCode: this.state.promotionCodes,
                },
                (msgDesc) => {
                  this.setState({insuranceAdvisor: {agent: msgDesc}});
                }
              );
            }


            
            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState('gtravel3', rawRequest);
    stateStorage.setState('gtravel4', undefined);
  }

  _onDateChange(startDate, endDate) {
    this.setState({
      startDate: startDate.format('MMMM D, YYYY'),
      endDate: endDate.format('MMMM D, YYYY'),
      days: endDate.add(1, 'days')
        .diff(moment(startDate, 'MMMM D, YYYY'), 'days'),
    });
  }

  _onInsuredChange(insured, props) {
    for (let prop in props) {
      insured[prop] = props[prop];
    }
    this.setState(this.state);
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      paBenefits: state.paBenefits,
      policyHolderOrganization: state.policyHolderOrganization,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderPhone: state.policyholderPhone,
      policyholderAddress: state.policyholderAddress,
      policyholderEmail: state.policyholderEmail,
      policyholderIdType: state.policyholderIdType,
      policyholderIdNumber: state.policyholderIdNumber,
      policyholderDOB: state.policyholderDOB,
      specialRequests: state.specialRequests,
      businessEmailAddress: state.businessEmailAddress,
      question: state.question,
    };
    if (state.period == 'annual') {
      attributes.effectiveDate = state.effectiveDateStart;
      attributes.medicalGuarantee = state.medicalGuarantee;
    };
    if (state.isTraveler) {
      attributes.policyholderDOB = state.policyholderDOB;
      attributes.policyholderFirstName = state.policyholderFirstName;
      attributes.policyholderLastName = state.policyholderLastName;
      attributes.policyholderIdType = state.policyholderIdType;
    };
    if (state.planType == 'premier') {
      attributes.cruiseBenefit = state.cruiseBenefit;
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    };

    let constraints = {
      paBenefits: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      policyholderPhone: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {message: this.state.text.notANumber},
      },
      policyholderAddress: {
        format: englishFormat,
      },
      policyholderEmail :{
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      specialRequests: {
        format:englishFormat,
      },
      businessEmailAddress: {
        format:englishFormat,
      },
      question: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
    }
    if (this.state.isCompany) {
      constraints.policyHolderOrganization = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format:englishFormat,
      };
    }
    else {
      constraints.policyholderDOB = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
      constraints.policyholderFirstName = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      };
      constraints.policyholderLastName = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      };
      constraints.policyholderIdType = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
      constraints.policyholderIdNumber = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: this.state.policyholderIdType == 'HKID',
        format: englishFormat,
      };
    }
    if (this.state.isTraveler) {
      constraints.policyholderDOB = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }
    if (this.state.period == 'annual') {
      constraints.effectiveDate = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
      constraints.medicalGuarantee = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }
    if (this.state.planType == 'preimer' && this.state.period == 'shortTermTrip') {
      constraints.cruiseBenefit = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }

    let messages = validate(attributes, constraints, {fullMessages: false});

    const validateInsured = (insured, isChild) => {
      insured = clearEmptyStrings(insured);

      let attributes = {
        firstName: insured.firstName,
        lastName: insured.lastName,
        dob: insured.dob,
        idType: insured.idType,
        idNumber: insured.idNumber,
      };

      const englishFormat = {
        pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
        message: this.state.text.needsToBeInEnglish,
      }

      let constraints = {
        firstName: {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
          format: englishFormat,
        },
        lastName: {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
          format: englishFormat,
        },
        dob: {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
        },
        idType: {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
        },
        idNumber: {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
          hkid: insured.idType == 'HKID',
          format: englishFormat,
        },
      };

      if (this.state.period == 'annual' && this.state.medicalGuarantee == 'Y') {
        attributes.medicalGuarantee = insured.medicalGuarantee;
        constraints.medicalGuarantee = {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
        };

        if (insured.medicalGuarantee == 'Y') {
          attributes.mainlandPermit = insured.mainlandPermit;
          constraints.mainlandPermit = {
            presence: {message: this.state.text.thisFieldCannotBeBlank},
          };
        }
      }

      let messages = validate(attributes, constraints, {fullMessages: false});

      if (!isChild && this.state.planType != 'standard') {
        if (
          moment().diff(moment(insured.dob, 'MMMM D, YYYY'), 'years') < 18 &&
          (this.props.period == 'annual' || this.props.planType != 'standard')
        ) {
          if (!messages) {
            messages = {};
          }

          if (this.state.period == 'shortTermTrip') {
            messages.dob = this.state.text.canOnlyChooseTheStandardPlan;
          }
          else {
            messages.dob = this.state.text.notAvailableForAgeBelow18;
          }
        }
      }

      return messages;
    };

    const insuredIndividualsMessages = [];
    let insuredSpouseMessages;
    const insuredChildrenMessages = [];
    let hasMedicalGuarantee = false;

    this.state.insuredIndividuals.forEach((individual, index) => {
      if (this.state.people == 'group') {
        if (index == 0 && this.state.isTraveler) {
          const messages = validateInsured({
            medicalGuarantee: state.policyholderMedicalGuarantee,
            mainlandPermit: state.policyholderMainlandPermit,
            firstName: state.policyholderFirstName,
            lastName: state.policyholderLastName,
            dob: state.policyholderDOB,
            idType: state.policyholderIdType,
            idNumber: state.policyholderIdNumber,
          });

          if (state.policyholderMedicalGuarantee == 'Y') {
            hasMedicalGuarantee = true;
          }

          if (messages) {
            insuredIndividualsMessages[index] = messages;
          }
        }
        else if (index < this.state.numberOfIndividuals) {
          const messages = validateInsured(individual);

          if (individual.medicalGuarantee == 'Y') {
            hasMedicalGuarantee = true;
          }

          if (messages) {
            insuredIndividualsMessages[index] = messages;
          }
        }
      }
      else if (index == 0) {
        if (this.state.isTraveler) {
          const messages = validateInsured({
            medicalGuarantee: state.policyholderMedicalGuarantee,
            mainlandPermit: state.policyholderMainlandPermit,
            firstName: state.policyholderFirstName,
            lastName: state.policyholderLastName,
            dob: state.policyholderDOB,
            idType: state.policyholderIdType,
            idNumber: state.policyholderIdNumber,
          });

          if (state.policyholderMedicalGuarantee == 'Y') {
            hasMedicalGuarantee = true;
          }

          if (messages) {
            insuredIndividualsMessages[index] = messages;
          }
        }
        else {
          const messages = validateInsured(individual);

          if (individual.medicalGuarantee == 'Y') {
            hasMedicalGuarantee = true;
          }

          if (messages) {
            insuredIndividualsMessages[index] = messages;
          }
        }
      }
    });

    if (this.state.people == 'withChildren' || this.state.people == 'family') {
      if (this.state.people == 'family') {
        const messages = validateInsured(this.state.insuredSpouse);

        if (state.insuredSpouse.medicalGuarantee == 'Y') {
          hasMedicalGuarantee = true;
        }

        if (messages) {
          insuredSpouseMessages = messages;
        }
      }

      this.state.insuredChildren.forEach((child, index) => {
        if (index < this.state.numberOfChildren) {
          const messages = validateInsured(child, true);

          if (child.medicalGuarantee == 'Y') {
            hasMedicalGuarantee = true;
          }

          if (messages) {
            insuredChildrenMessages[index] = messages;
          }
        }
      });
    }

    if (
      insuredIndividualsMessages.length > 0 ||
      insuredSpouseMessages ||
      insuredChildrenMessages.length > 0
    ) {
      messages = messages ? messages : {};
      messages.insuredIndividuals = insuredIndividualsMessages;
      messages.insuredSpouse = insuredSpouseMessages;
      messages.insuredChildren = insuredChildrenMessages;

      if (this.state.isTraveler && insuredIndividualsMessages[0]) {
        messages.policyholderMedicalGuarantee =
          insuredIndividualsMessages[0].medicalGuarantee;
        messages.policyholderMainlandPermit =
          insuredIndividualsMessages[0].mainlandPermit;

        delete insuredIndividualsMessages[0];
      }
    }
    else if (messages) {
      messages.insuredIndividuals = [];
      messages.insuredChildren = [];
    }

    // if (state.period == 'annual' &&
    //   hasMedicalGuarantee && state.medicalGuarantee == 'Y') {
    //   const addressMessages =
    //     validate(
    //       {policyholderAddress: state.policyholderAddress},
    //       {
    //         policyholderAddress: {
    //           presence: {message: this.state.text.thisFieldCannotBeBlank},
    //           format: englishFormat,
    //         },
    //       },
    //       {fullMessages: false}
    //     );

    //     if (addressMessages) {
    //       if (messages) {
    //         const {policyholderAddress} = addressMessages;

    //         messages.policyholderAddress = policyholderAddress;
    //       }
    //       else {
    //         messages = addressMessages;
    //         messages.insuredIndividuals = [];
    //         messages.insuredChildren = [];
    //       }
    //     }
    // }

    if (messages) {
      this.setState({
        alert: Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let pn = 'EAPP';
      if (this.state.centaline) {
        pn = 'CTL';
      }

      let pl;
      if (this.state.period == 'shortTermTrip') {
        switch (this.state.planType) {
          case 'standard':
            pl = 'TRAVELS21';
            break;
          case 'classic':
            pl = 'TRAVELS22';
            break;
          case 'premier':
            pl = 'TRAVELS23';
            break;
        }
      }
      else {
        switch (this.state.planType) {
          case 'standard':
            pl = 'TRAVELA21';
            break;
          case 'classic':
            pl = 'TRAVELA22';
            break;
          case 'premier':
            pl = 'TRAVELA23';
            break;
        }
      }

      let people = 'I';
      if (this.state.people == 'family') {
        people = 'F';
      }
      if (this.state.people == 'withChildren') {
        people = 'IC';
      }

      let travellers = [];
      if (this.state.isTraveler) {
        travellers.push({
          'salutation': this.state.policyholderTitle,
          'firstName': this.state.policyholderFirstName,
          'lastName': this.state.policyholderLastName,
          'documentType': this.state.policyholderIdType,
          'documentNo': this.state.policyholderIdNumber,
          'dateOfBirth': this._apiDateFormat(this.state.policyholderDOB),
          'relationship': 'insured',
          'includeChinaMedical': this.state.medicalGuarantee == 'Y' ?
            this.state.policyholderMedicalGuarantee : undefined,
          'mainlandPermit': this.state.medicalGuarantee == 'Y' &&
            this.state.policyholderMedicalGuarantee == 'Y' ?
            this.state.policyholderMainlandPermit : undefined,
        });
      }
      let insured = [];
      for (insured in this.state.insuredIndividuals) {
        const index = parseInt(insured);
        if (index > 0 && this.state.people != 'group') {
          break;
        }
        if (index == parseInt(this.state.numberOfIndividuals)) {
          break;
        }
        if (index == 0 && this.state.isTraveler) {
          continue;
        }
        if (this.state.insuredIndividuals[index].idNumber) {
          travellers.push({
            'salutation': this.state.insuredIndividuals[index].title,
            'firstName': this.state.insuredIndividuals[index].firstName,
            'lastName': this.state.insuredIndividuals[index].lastName,
            'documentType': this.state.insuredIndividuals[index].idType,
            'documentNo': this.state.insuredIndividuals[index].idNumber,
            'dateOfBirth': this._apiDateFormat(this.state.insuredIndividuals[index].dob),
            'relationship': this.state.insuredIndividuals[index].relationship,
            'includeChinaMedical': this.state.medicalGuarantee == 'Y' ?
              this.state.insuredIndividuals[index].medicalGuarantee : undefined,
            'mainlandPermit': this.state.medicalGuarantee == 'Y' &&
              this.state.insuredIndividuals[index].medicalGuarantee == 'Y' ?
              this.state.insuredIndividuals[index].mainlandPermit : undefined,
          });
        }
      }

      if (this.state.people == 'family') {
        travellers.push({
          'salutation': this.state.insuredSpouse.title,
          'firstName': this.state.insuredSpouse.firstName,
          'lastName': this.state.insuredSpouse.lastName,
          'documentType': this.state.insuredSpouse.idType,
          'documentNo': this.state.insuredSpouse.idNumber,
          'dateOfBirth': this._apiDateFormat(this.state.insuredSpouse.dob),
          'relationship': this.state.insuredSpouse.relationship,
          'includeChinaMedical': this.state.medicalGuarantee == 'Y' ?
            this.state.insuredSpouse.medicalGuarantee : undefined,
          'mainlandPermit': this.state.medicalGuarantee == 'Y' &&
            this.state.insuredSpouse.medicalGuarantee == 'Y' ?
            this.state.insuredSpouse.mainlandPermit : undefined,
        });
      }

      if (this.state.people == 'family' || this.state.people == 'withChildren') {
        let child = [];
        for (child in this.state.insuredChildren) {
          const index = parseInt(child);
          if (index == parseInt(this.state.numberOfChildren)) {
            break;
          }
          if (this.state.insuredChildren[index].idNumber) {
            travellers.push({
              'salutation': this.state.insuredChildren[index].title,
              'firstName': this.state.insuredChildren[index].firstName,
              'lastName': this.state.insuredChildren[index].lastName,
              'documentType': this.state.insuredChildren[index].idType,
              'documentNo': this.state.insuredChildren[index].idNumber,
              'dateOfBirth': this._apiDateFormat(this.state.insuredChildren[index].dob),
              'relationship': this.state.insuredChildren[index].relationship,
              'includeChinaMedical': this.state.medicalGuarantee == 'Y' ?
                this.state.insuredChildren[index].medicalGuarantee : undefined,
              'mainlandPermit': this.state.medicalGuarantee == 'Y' &&
                this.state.insuredChildren[index].medicalGuarantee == 'Y' ?
                this.state.insuredChildren[index].mainlandPermit : undefined,
            });
          }
        }
      }

      let numberOfMedicalGuarantee = 0;
      if (this.state.medicalGuarantee == 'Y') {
        let traveller;
        for (traveller in travellers) {
          if (travellers[traveller].includeChinaMedical == 'Y') {
            numberOfMedicalGuarantee += 1;
          }
        }
      }

      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;

      let fields;
      if (this.state.period == 'shortTermTrip') {
        fields = {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': pn,
          'pl': pl,
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'policy.effectiveDateStart': this._apiDateFormat(this.state.startDate),
          'policy.effectiveDateEnd': this._apiDateFormat(this.state.endDate),
          'travel.premiumPlan': people,
          'travel.isUpgradePA': this.state.paBenefits == 'Y' ? 'Y' : 'N',
          'travel.includeCruise': this.state.cruiseBenefit == 'Y' ? 'Y' : 'N',
          'numTravellers': travellers.length,
        };
      }
      else {
        fields = {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': pn,
          'pl': pl,
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
          'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'travel.premiumPlan': people,
          'travel.isUpgradePA': this.state.paBenefits == 'Y' ? 'Y' : 'N',
          'numTravellers': travellers.length,
          'numTravellersIncludeChinaMedical': numberOfMedicalGuarantee,
        };
      }
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }

      premium(
        fields,
        (msgDesc) => {
          this.setState({submitButtonDisabled: false}, () => {
            if (this.state.medicalGuarantee == 'N') {
              travellers.forEach((traveller) => {
                traveller.includeChinaMedical = 'N';
              });
              this.state.insuredIndividuals.forEach((insuredIndividual) => {
                insuredIndividual.medicalGuarantee = 'N';
              });
              this.state.insuredSpouse.medicalGuarantee = 'N';
              this.state.insuredChildren.forEach((insuredChild) => {
                insuredChild.medicalGuarantee = 'N';
              });
            }

            let rawRequest = Object.assign({}, state);
            rawRequest.maxDate = rawRequest.maxDate.format(),
            delete rawRequest.messages;
            delete rawRequest.alert;
            delete rawRequest.text;

            stateStorage.setState('gtravel3', rawRequest);
            stateStorage.setState('gtravel4', undefined);

            const premium = msgDesc.premium;
            const levy = msgDesc.levy;
            const total = premium.discount + levy;


         


            this.props.history.push('/gtravel4', {
              broker: this.state.broker,
              insuranceAdvisor: this.state.insuranceAdvisor,
              insuranceAdvisorMobile: this.state.insuranceAdvisorMobile,
              centaline: this.state.centaline,
              planType: this.state.planType,
              period: this.state.period,
              people: this.state.people,
              numberOfChildren: this.state.numberOfChildren,
              numberOfIndividuals: this.state.numberOfIndividuals,
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              days: this.state.days,
              effectiveDateStart: this.state.effectiveDateStart,
              effectiveDateEnd: this.state.effectiveDateEnd,
              businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
              specialRequests: this.state.specialRequests,
              promotionCodes: removeSpaces(this.state.promotionCodes),
              isCompany: this.state.isCompany,
              policyHolderOrganization: this.state.policyHolderOrganization,
              policyholderTitle: this.state.policyholderTitle,
              policyholderFirstName: this.state.policyholderFirstName,
              policyholderLastName: this.state.policyholderLastName,
              policyholderDOB: this.state.policyholderDOB,
              policyholderPhone: this.state.policyholderPhone,
              policyholderEmail: this.state.policyholderEmail,
              policyholderAddress: this.state.policyholderAddress,
              policyholderIdType: this.state.policyholderIdType,
              policyholderIdNumber: this.state.policyholderIdNumber,
              paBenefits: this.state.paBenefits,
              cruiseBenefit: this.state.cruiseBenefit,
              medicalGuarantee: this.state.medicalGuarantee,
              noticeAgree: this.state.noticeAgree,
              travellers: travellers,
              rawRequest: rawRequest,
              premium: premium,
              levy: levy,
              total: total,
            });
          });
        },
        (msgCode, msgDesc, handleDefault) => {
          if (msgCode == 40) {
            if (this.state.broker) {
              this.setState({
                alert: Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
                ),
                messages: {
                  promotionCodes: this.state.text['invalidPromotionCode']
                },
              });
            }
            else {
              this.setState({alert: Alert.alert(
                this.state.text.addInsuranceAdvisor,
                this.state.text.wouldYouLike
                + msgDesc.agent.brokerName
                + this.state.text.toBeYourInsuranceAdvisor,
                [
                  {
                    text: this.state.text.ok,
                    onPress: () => insuranceAdvisors.setInsuranceAdvisor(
                      {agentCode: msgDesc.agent.agentCode},
                      ['TRAVEL'],
                      this.state.promotionCodes,
                      this.state.language,
                      () => this.setState({nextScreen: true},
                        () => this._nextScreen())
                    )
                  },
                ],
                { cancelable: false }
              )});
            }
          }
          else if (msgCode == 56) {
            this.setState({
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
              ),
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
            });
          }
          else {
            handleDefault();
          }
          this.setState({submitButtonDisabled: false});
        },
      )
    }
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    let periodOption = [
      { key: 'shortTermTrip',
        label: options.period.shortTermTrip.label[this.state.language]
      },
    ];
    if (!this.state.shortTermTripOnly) {
      periodOption.push(
        { key: 'annual',
          label: options.period.annual.label[this.state.language]
        }
      );
    }

    let peopleOptions = [
      { key: 'individual',
        label: options.people.individual.label[this.state.language]
      },
      { key: 'family', label: options.people.family.label[this.state.language] },
    ];
    if (this.state.period == 'shortTermTrip') {
      peopleOptions.push(
        { key: 'withChildren',
          label: options.people.withChildren.label[this.state.language]
        }
      );
    }
    peopleOptions.push(
      { key: 'group', label: options.people.group.label[this.state.language] }
    );

    const showMedicalGuarantee =
      this.state.period == 'annual' && this.state.medicalGuarantee == 'Y';

    let insuredIndividuals = [];
    let insured;
    for (insured in this.state.insuredIndividuals) {
      const index = parseInt(insured);
      const numberOfIndividuals = parseInt(this.state.numberOfIndividuals);
      if (this.state.isTraveler && index == 0) {
        continue;
      }
      if (index > 0 && this.state.people != 'group') {
        break;
      }
      if (index == numberOfIndividuals) {
        break;
      }
      insuredIndividuals.push(
        <Insured
          key={index}
          type='individual'
          planType={this.state.planType}
          period={this.state.period}
          people={this.state.people}
          number={this.state.people == 'group' ? index + 1 : null}
          showMedicalGuarantee={showMedicalGuarantee}
          medicalGuarantee={this.state.insuredIndividuals[index].medicalGuarantee}
          mainlandPermit={this.state.insuredIndividuals[index].mainlandPermit}
          title={this.state.insuredIndividuals[index].title}
          firstName={this.state.insuredIndividuals[index].firstName}
          lastName={this.state.insuredIndividuals[index].lastName}
          dob={this.state.insuredIndividuals[index].dob}
          idType={this.state.insuredIndividuals[index].idType}
          idNumber={this.state.insuredIndividuals[index].idNumber}
          relationship={this.state.insuredIndividuals[index].relationship}
          onChange={(medicalGuarantee, title, firstName, lastName, dob, idType,
            idNumber, relationship) => this._onInsuredChange(
            this.state.insuredIndividuals[index], medicalGuarantee, title,
            firstName, lastName, dob, idType, idNumber, relationship)}
          language={this.state.language}
          text={this.state.text}
          messages={this.state.messages.insuredIndividuals[index]}
        />
      );
    }

    let insuredChildren = [];
    let child;
    if (this.state.people == 'family' || this.state.people == 'withChildren') {
      let maxInsured = 10;
      if (this.state.people == 'family') {
        maxInsured -= 2;
      }
      if (this.state.people == 'withChildren') {
        maxInsured -= 1;
      }
      for (child in this.state.insuredChildren) {
        const index = parseInt(child);
        const numberOfChildren = parseInt(this.state.numberOfChildren);
        if (index == numberOfChildren ||  index == maxInsured) {
          break;
        }
        insuredChildren.push(
          <Insured
            key={index}
            type='child'
            planType={this.state.planType}
            period={this.state.period}
            people={this.state.people}
            number={index + 1}
            showMedicalGuarantee={showMedicalGuarantee}
            medicalGuarantee={this.state.insuredChildren[index].medicalGuarantee}
            mainlandPermit={this.state.insuredChildren[index].mainlandPermit}
            title={this.state.insuredChildren[index].title}
            firstName={this.state.insuredChildren[index].firstName}
            lastName={this.state.insuredChildren[index].lastName}
            dob={this.state.insuredChildren[index].dob}
            idType={this.state.insuredChildren[index].idType}
            idNumber={this.state.insuredChildren[index].idNumber}
            relationship={this.state.insuredChildren[index].relationship}
            onChange=
            {(medicalGuarantee, title, firstName, lastName, dob, idType,
              idNumber, relationship) => this._onInsuredChange(
              this.state.insuredChildren[index], medicalGuarantee, title,
              firstName, lastName, dob, idType, idNumber, relationship)}
            language={this.state.language}
            text={this.state.text}
            messages={this.state.messages.insuredChildren[index]}
          />
        );
      }
    }

    return (
      <div className='App'>
        <Helmet>
          <title>Bravo Travel Protector | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getInsured}
          backButton
          history={this.props.history}
        />
        <ScrollView>
          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
              <Grid
                item xs
                style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}
              >
                <View style={[styles.greeting,{fontWeight:'bold',}]}>
                  <Text
                    style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}
                  >
                    {this.state.text.bravoTravelProtector}
                  </Text>
                  <p></p>
                  <Text style={[styles.greetingText,{fontSize: 20,}]}>
                    {this.state.text.adventure}
                  </Text>
                  <img
                  //  style={{width: '100%', height: '90%'}}
                  //  src={'./assets/images/travel.png'}
                  >
                  </img>
                </View>
              </Grid>
            </Hidden>

            <Grid item xs style={{marginTop:30,marginBottom:50}}>
              <View style={formStyles.form, styles.fie}>
              {this.state.alert}
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h2, {color}]}>
                    {
                      this.state.centaline ?
                      this.state.text.centalineTravelProtector :
                      this.state.text.bravoTravelProtector
                    }
                  </Text>
                </View>

                {
                  this.state.insuranceAdvisor || this.state.broker ?
                    <View style={formStyles.fieldset}>
                      <Text style={{textAlign: 'left'}}>
                        {this.state.text.theInsuranceIsIssued}
                        {
                          this.state.broker ?
                          this.state.broker.name :
                          this.state.insuranceAdvisor.agent.broker.nameEnUs
                        }
                        {this.state.text.professionalInsurance}
                      </Text>
                    </View> :
                    <View style={{textAlign: 'left'}}>
                      <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
                    </View>
                }

                <View style={formStyles.fieldset}>
                  <Text>{this.state.text.pleaseInputInEnglish}</Text>
                </View>

                {
                  this.state.broker &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                        value={this.state.insuranceAdvisorMobile}
                        editable={this.state.member && this.state.member.agent.isAdmin}
                        keyboardType='phone-pad'
                        maxLength={8}
                      />
                    </View>
                }

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.productType}</Text>
                  <ModalSelector
                    selectStyle={{borderColor: 'lightgrey'}}
                    initValueTextStyle={{color: 'black'}}
                    data={[
                      { key: 'premier', label: planTypes.premier.label[this.state.language] },
                      { key: 'classic', label: planTypes.classic.label[this.state.language] },
                      { key: 'standard', label: planTypes.standard.label[this.state.language] },
                    ]}
                    initValue={planTypes[this.state.planType].label[this.state.language]}
                    onChange={(option) => this.setState({planType: option.key})}
                    cancelText={this.state.text.cancel}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.shortTermTripAnnual}</Text>
                  <ModalSelector
                    selectStyle={{borderColor: 'lightgrey'}}
                    initValueTextStyle={{color: 'black'}}
                    data={periodOption}
                    initValue={
                      options.period[this.state.period] ?
                        options.period[this.state.period].label[this.state.language] :
                        this.state.text.pleaseChoose
                    }
                    onChange={(option) => {
                      if (option.key == 'annual') {
                        delete this.state.policyholderDOB;

                        this.state.insuredIndividuals.forEach((individual) => {
                          delete individual.dob;
                        });

                        delete this.state.insuredSpouse.dob;

                        this.state.insuredChildren.forEach((child) => {
                          delete child.dob;
                        });
                      }

                      this.setState({
                        period: option.key,
                        people: option.key == 'annual' &&
                          this.state.people == 'withChildren' ?
                            this.state.text.individual : this.state.people
                      });
                    }}
                    cancelText={this.state.text.cancel}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.individualOrWithOthers}</Text>
                  <ModalSelector
                    selectStyle={{borderColor: 'lightgrey'}}
                    initValueTextStyle={{color: 'black'}}
                    data={peopleOptions}
                    initValue={
                      options.people[this.state.people] ?
                        options.people[this.state.people].label[this.state.language] :
                        this.state.text.pleaseChoose
                    }
                    onChange={(option) => this.setState({people: option.key })}
                    cancelText={this.state.text.cancel}
                  />
                </View>
                {/* <View style={formStyles.fieldset}>
                  <YesNoQuestion
                    question={this.state.text.addtionalUpgradePersonalAccidentBenefit}
                    choice={this.state.paBenefits}
                    onChange={(choice) => this.setState({paBenefits: choice})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.paBenefits}</Text>
                </View> */}
                {
                  this.state.period == 'annual' &&
                    <View>
                      <View style={formStyles.fieldset}>
                        <YesNoQuestion
                          question={this.state.text.additionalChinaMedicalGuaranteeCard}
                          choice={this.state.medicalGuarantee}
                          onChange={(choice) => this.setState({medicalGuarantee: choice})}
                        />
                        <Text style={formStyles.message}>{this.state.messages.medicalGuarantee}</Text>
                      </View>
                    </View>
                }
                {
                  this.state.period == 'shortTermTrip' &&
                  <View>
                    {
                      this.state.planType == 'premier' &&
                        <View style={formStyles.fieldset}>
                          <YesNoQuestion
                            question={this.state.text.addtionalCruiseBenefit}
                            choice={this.state.cruiseBenefit}
                            onChange={(choice) => this.setState({cruiseBenefit: choice})}
                          />
                          <Text style={formStyles.message}>{this.state.messages.cruiseBenefit}</Text>
                        </View>
                    }
                    <View style={styles.fieldset}>
                      <Text style={styles.h4}>{this.state.text.periodOfTravel}</Text>
                      <TouchableOpacity style={formStyles.calendarPicker} onPress={() => this.setState({showCalendar: !this.state.showCalendar})}>
                        <Text style={formStyles.calendarPickerText}>
                          {
                            this.state.startDate && this.state.endDate ?
                              this.state.startDate + ' - ' +  this.state.endDate :
                              this.state.text.pleasePickADate
                          }
                        </Text>
                        {
                          this.state.days &&
                            <Text style={formStyles.calendarPickerText}>{this.state.days} {this.state.text.daysdays}</Text>
                        }
                      </TouchableOpacity>
                      {
                        this.state.showCalendar &&
                          <Calendar
                            width={Dimensions.get('window').width - 40}
                            minDate={moment()}
                            maxDate={this.state.maxDate}
                            twoStepChange={this.state.period == 'shortTermTrip'?false:true}
                            allowRangeSelection={false}
                            minRangeDuration={1}
                            selectedDayColor={color}
                            selectedDayTextColor='white'
                            selectedStartDate={this.state.startDate ? moment(this.state.startDate, 'MMMM D, YYYY') : null}
                            selectedEndDate={this.state.endDate ? moment(this.state.endDate, 'MMMM D, YYYY') : null}
                            onDateChange={(startDate, endDate) => {
                              if (this.state.period == 'shortTermTrip') {
                                this.state.maxDate = moment(startDate, 'MMMM D, YYYY').add(181, 'days');
                              }else{
                                this.state.maxDate = moment().add(1 + periodMaxDays, 'days');
                              }
                              this._onDateChange(startDate, endDate)
                            }}
                          />
                      }
                    </View>
                  </View>
                }
                {
                  this.state.period == 'annual' &&
                    <View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.effectiveDate}</Text>
                        <DatePicker
                          style={formStyles.datepicker}
                          date={this.state.effectiveDateStart}
                          mode='date'
                          placeholder={this.state.text.pleasePickADate}
                          format='MMMM D, YYYY'
                          minDate={moment().format('MMMM D, YYYY')}
                          maxDate={moment().add(6, 'months').format('MMMM D, YYYY')}
                          onDateChange={(date) => {this.setState({
                            effectiveDateStart: date,
                            effectiveDateEnd: moment(date, 'MMMM D, YYYY')
                              .add(1, 'years').subtract(1, 'days').format('MMMM D, YYYY'),
                          })}}
                          confirmBtnText={this.state.text.ok}
                          cancelBtnText={this.state.text.cancel}
                        />
                        <Text style={formStyles.message}>{this.state.messages.effectiveDate}</Text>
                      </View>
                      {
                        this.state.effectiveDateStart &&
                          <View style={formStyles.fieldset}>
                            <Text style={formStyles.h4}>{this.state.text.periodOfInsurance}</Text>
                            <View style={styles.periodOfInsurance}>
                              <Text>
                                {this.state.effectiveDateStart} - {this.state.effectiveDateEnd}
                              </Text>
                              <Text>{this.state.text.bothDatesInclusive}</Text>
                            </View>
                          </View>
                      }
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.destination}</Text>
                  <TextInput
                    value={this.state.text.worldwide}
                    editable={false}
                    multiline
                  />
                </View>
                {
                  !this.state.businessEmailAddress &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.promotionCodes}</Text>
                      <TextInput
                        style={formStyles.input}
                        placeholder={this.state.text.optional}
                        onChangeText={(text) => this.setState({promotionCodes: text})}
                        value={this.state.promotionCodes}
                      />
                      <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
                    </View>
                }
                {
                  !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.businessEmailAddress}</Text>
                      <TextInput
                        style={formStyles.input}
                        placeholder={this.state.text.optional}
                        keyboardType='email-address'
                        onChangeText={(text) => this.setState({businessEmailAddress: text})}
                        value={this.state.businessEmailAddress}
                      />
                      <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
                    </View>
                }
                <View style={formStyles.hr} />
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h3, {color}]}>{this.state.text.policyHolder}</Text>
                </View>

                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.isCompany}
                      onValueChange={(value) => this.setState({
                        isCompany: value,
                        isTraveler: value ? false : this.state.isTraveler,
                      })}
                    />
                  </View>
                  <View style={[formStyles.yesNoQuestionText,{marginBottom: 10}]}>
                    <Text>{this.state.text.policyholderIsACompany}</Text>
                  </View>
                </View>


                {
                  this.state.isCompany ?
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.companyName}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyHolderOrganization: text})}
                        value={this.state.policyHolderOrganization}
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyHolderOrganization}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyholderPhone: text})}
                        value={this.state.policyholderPhone}
                        keyboardType='numeric'
                        maxLength={8}
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.correspondenceAddress}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyholderAddress: text})}
                        value={this.state.policyholderAddress}
                        placeholder={
                          this.state.text.optional
                          }
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyholderEmail: text})}
                        value={this.state.policyholderEmail}
                        keyboardType='email-address'
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
                    </View>
                  </View>
                  :
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.name}</Text>
                      <NameInput
                        title={this.state.policyholderTitle}
                        firstName={this.state.policyholderFirstName}
                        lastName={this.state.policyholderLastName}
                        onChange={(title, firstName, lastName) => this.setState({
                          policyholderTitle: title,
                          policyholderFirstName: firstName,
                          policyholderLastName: lastName,
                        })}
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
                      <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
                    </View>
                    {
                      this.state.period == 'shortTermTrip' ?
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.policyholderDOB}
                        fullDate={true}
                        mode='date'
                        placeholder={this.state.text.pleasePickADate}
                        format='MMMM D, YYYY'
                        minDate={moment().subtract(80, 'years').add(1, 'days').format('MMMM D, YYYY')}
                        maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
                        onDateChange={(date) => {this.setState({policyholderDOB: moment(date)
                          .diff(moment().subtract(80,'years').add(1,'days')) < 0 ?
                          moment().subtract(80,'years').add(1,'days').format('MMMM D,YYYY') : date})}}
                        confirmBtnText={this.state.text.ok}
                        cancelBtnText={this.state.text.cancel}
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
                    </View> :
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.policyholderDOB}
                        mode='date'
                        fullDate={true}
                        placeholder={this.state.text.pleasePickADate}
                        format='MMMM D, YYYY'
                        minDate={moment().subtract(73, 'years').add(0, 'days').format('MMMM D, YYYY')}
                        maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
                        onDateChange={(date) => {this.setState({policyholderDOB: moment(date)
                          .diff(moment().subtract(73,'years').add(0,'days')) < -1 ?
                          moment().subtract(73,'years').add(0,'days').format('MMMM D,YYYY') : date})}}
                        confirmBtnText={this.state.text.ok}
                        cancelBtnText={this.state.text.cancel}
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
                    </View>
                    }
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyholderPhone: text})}
                        value={this.state.policyholderPhone}
                        keyboardType='numeric'
                        maxLength={8}
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.correspondenceAddress}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyholderAddress: text})}
                        value={this.state.policyholderAddress}
                        placeholder={this.state.text.optional}
                        placeholder={
                          this.state.text.optional
                          }
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({policyholderEmail: text})}
                        value={this.state.policyholderEmail}
                        keyboardType='email-address'
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.travelHkidOrPassportNumber}</Text>
                      <IdentityInput
                        type={this.state.policyholderIdType}
                        number={this.state.policyholderIdNumber}
                        onChange={(type, number) => this.setState({
                          policyholderIdType: type,
                          policyholderIdNumber: number ? validid.utils.normalize(number) : number,
                        })}
                        pp
                      />
                      <Text style={formStyles.message}>{this.state.messages.policyholderIdType}</Text>
                      <Text style={formStyles.message}>{this.state.messages.policyholderIdNumber}</Text>
                    </View>
                  </View>
                }

                {
                  !this.state.isCompany &&
                    <View style={formStyles.fieldset}>
                      <View style={formStyles.yesNoQuestion}>
                        <View style={formStyles.yesNoQuestionSwitch}>
                          <Switch
                            value={this.state.isTraveler}
                            onValueChange={(value) => this.setState({isTraveler: value})}
                          />
                        </View>
                        <View style={formStyles.yesNoQuestionText}>
                          <Text>
                            {this.state.text.travelPolicyholderIs}{
                              !this.state.people || this.state.people == 'individual' ?
                                this.state.text.theTraveler : this.state.text.travelOneOfTheTravelers
                            }
                          </Text>
                        </View>
                      </View>
                      {
                        (this.state.policyholderFirstName && !this.state.policyholderLastName && this.state.isTraveler) ?
                        <Text style={formStyles.message}>{this.state.text.theTravelerCannot}</Text> : undefined
                      }
                    </View>
                }

                {
                  (this.state.people == 'family' || this.state.people == 'withChildren') &&
                    <View>
                      <View style={formStyles.hr} />
                        <View style={formStyles.fieldset}>
                        <Text style={[formStyles.h3, {color}]}>{options.people[this.state.people].label[this.state.language]}</Text>
                      </View>
                      <View style={styles.fieldset}>
                        <Text style={styles.h4}>{this.state.text.travelNumberOfChildren}</Text>
                        <TextInput
                          style={styles.input}
                          onChangeText={(text) => this.setState({numberOfChildren: text})}
                          value={this.state.numberOfChildren}
                          keyboardType='numeric'
                        />
                      </View>
                    </View>
                }
                {
                  this.state.people == 'group' &&
                    <View>
                      <View style={formStyles.hr} />
                        <View style={formStyles.fieldset}>
                        <Text style={[formStyles.h3, {color}]}>{this.state.text.groupOfIndividuals}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.numberOfIndividuals}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({numberOfIndividuals: text})}
                          value={this.state.numberOfIndividuals}
                          keyboardType='numeric'
                        />
                        {
                          parseInt(this.state.numberOfIndividuals) > 10 &&
                            <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
                        }
                      </View>
                    </View>
                }
                {
                  this.state.isTraveler &&
                    <View>
                      <View style={formStyles.hr} />
                      <View style={formStyles.fieldset}>
                        <Text style={[formStyles.h3, {color}]}>
                          {this.state.people == 'group' && this.state.text.first}{this.state.text.insured}
                        </Text>
                      </View>
                      {
                        showMedicalGuarantee &&
                          <View style={formStyles.fieldset}>
                            <YesNoQuestion
                              question={this.state.text.chinaMedicalGuaranteeCard}
                              choice={this.state.policyholderMedicalGuarantee}
                              onChange={(choice) =>
                                this.setState({policyholderMedicalGuarantee: choice})}
                            />
                            <Text style={formStyles.message}>{this.state.messages.policyholderMedicalGuarantee}</Text>
                          </View>
                      }

                      {
                        showMedicalGuarantee && this.state.policyholderMedicalGuarantee == 'Y' &&
                          <View style={formStyles.fieldset}>
                            <Text style={formStyles.h4}>{this.state.text.mainlandPermit}</Text>
                            <TextInput
                              style={formStyles.input}
                              onChangeText={(text) => this.setState({policyholderMainlandPermit: text})}
                              value={this.state.policyholderMainlandPermit}
                            />
                            <Text style={formStyles.message}>{this.state.messages.policyholderMainlandPermit}</Text>
                          </View>
                      }

                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.name}</Text>
                        <TextInput
                          style={formStyles.input}
                          value={formatName(
                            this.state.policyholderTitle,
                            this.state.policyholderFirstName,
                            this.state.policyholderLastName
                          )}
                          editable={false}
                        />
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                        <DatePicker
                          style={formStyles.datepicker}
                          date={this.state.policyholderDOB}
                          fullDate={true}
                          mode='date'
                          placeholder={this.state.text.pleasePickADate}
                          format='MMMM D, YYYY'
                          disabled={true}
                          confirmBtnText={this.state.text.ok}
                          cancelBtnText={this.state.text.cancel}
                        />
                        <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.travelHkidOrPassportNumber}</Text>
                        <View style={styles.identityInput}>

                          <View style={styles.identityNumber}>
                            <TextInput
                              style={formStyles.input}
                              value={this.state.policyholderIdNumber}
                              placeholder={this.state.policyholderIdType == 'HKID' ? 'A1234567' : null }
                              editable={false}
                              maxLength={9}
                            />
                          </View>
                        </View>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.relationshipWithThePolicyholder}</Text>
                        <ModalSelector
                          selectStyle={{borderColor: 'lightgrey'}}
                          initValueTextStyle={{color: 'black'}}
                          data={[
                            { key: 'insured', label: this.state.text.policyHolder },
                          ]}
                          initValue={this.state.text.policyHolder}
                          disabled={true}
                          cancelText={this.state.text.cancel}
                        />
                      </View>
                    </View>
                }
                { insuredIndividuals }
                {
                  this.state.people == 'family' &&
                    <Insured
                      type='spouse'
                      planType={this.state.planType}
                      period={this.state.period}
                      people={this.state.people}
                      showMedicalGuarantee={showMedicalGuarantee}
                      medicalGuarantee={this.state.insuredSpouse.medicalGuarantee}
                      mainlandPermit={this.state.insuredSpouse.mainlandPermit}
                      title={this.state.insuredSpouse.title}
                      firstName={this.state.insuredSpouse.firstName}
                      lastName={this.state.insuredSpouse.lastName}
                      dob={this.state.insuredSpouse.dob}
                      idType={this.state.insuredSpouse.idType}
                      idNumber={this.state.insuredSpouse.idNumber}
                      relationship={this.state.insuredSpouse.relationship}
                      onChange={(props) =>
                        this._onInsuredChange(this.state.insuredSpouse, props)}
                      messages={this.state.messages.insuredSpouse}
                    />
                }
                { insuredChildren }

                <View style={formStyles.hr} />

                <View style={formStyles.fieldset}>
                  <YesNoQuestion
                    question={this.state.text.haveThisTripInvolvedAnyCompetition}
                    choice={this.state.question}
                    onChange={(choice) => this.setState({question: choice})}
                  />
                  {
                    this.state.question == 'Y' &&
                      decline(
                        this.state.text.sorryThatYourApplication,
                        this.state.language,
                        this.props.history,
                        () => this.setState({question: null})
                      )
                  }
                  <Text style={formStyles.message}>{this.state.messages.question}</Text>
                </View>

                <View style={formStyles.hr} />
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h3, {color}]}>{this.state.text.specialRequests}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({specialRequests: text})}
                    value={this.state.specialRequests}
                    placeholder={this.state.text.optional}
                  />
                  <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
                  {
                    !!this.state.specialRequests &&
                      <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
                  }
                </View>
                <View style={formStyles.fieldset}>
                    <Text style={[formStyles.h3, {color}]}>{this.state.text.referral}</Text>
                </View>
                <View style={formStyles.fieldset}>
                    <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({ referral: text })}
                        value={this.state.referral}
                        placeholder={this.state.text.optional}
                        placeholderTextColor='lightgrey'
                    />
                    <Text style={formStyles.message}>{this.state.messages.referral}</Text>
                </View>
                {
                  this.state.submitButtonDisabled ?
                    <View style={formStyles.submitButtonDisabled}>
                      <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                    </View> :
                    <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._nextScreen()}>
                      <Text style={formStyles.submitButtonText}>{this.state.text.buyNow}</Text>
                    </TouchableOpacity>
                }
              </View>
            </Grid>
            <Hidden xsDown>
              <div style={{marginRight: '5%'}} />
            </Hidden>
          </Grid>
        </ScrollView>
        <Footer/>
      </div>
      );
    }
  }

class Insured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };

    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      })
    );
  }

  _getMaximumAge() {
    if (this.props.type == 'child') {
      return 18;
    }
    if (this.props.period == 'shortTermTrip') {
      return 80;
    }
    if (this.props.period == 'annual') {
      return 73;
    }
  }


  _getMinimumAge() {
    if (this.props.type == 'child') {
      if (this.props.period == 'shortTermTrip') {
        return 0;
      }
      if (this.props.period == 'annual') {
        return 0;
      }
    }
    if (this.props.people == 'family' ||
        this.props.people == 'withChildren') {
      return 18;
    }
    return 0;
  }

  _getTitle() {
    const type = this.props.type;
    const number = this.props.number;
    let ordinalNumber;
    if (number == 1) {
      ordinalNumber = '1st ';
    }
    else if (number == 2) {
      ordinalNumber = '2nd ';
    }
    else if (number == 3) {
      ordinalNumber = '3rd ';
    }
    else {
      ordinalNumber = number + 'th ';
    }
    if (this.state.language == 'zhHK') {
      ordinalNumber = `第 ${number} 位`
    }
    if (type == 'individual') {
      if (number) {
        return ordinalNumber + this.state.text.insured;
      }
      return this.state.text.insured;
    }
    if (type == 'spouse') {
      return this.state.text.spouse;
    }
    if (type == 'child') {
      if (number) {
        return ordinalNumber + this.state.text.child;
      }
      return this.state.text.child;
    }
  }

  _getRelationshipLabel() {
    switch (this.props.relationship) {
      case 'insured':
        return this.state.text.policyHolder;
        break;
      case 'spouse':
        return this.state.text.spouse;
        break;
      case 'child':
        return this.state.text.children;
        break;
      case 'parents':
        return this.state.text.parents;
        break;
      case 'relative':
        return this.state.text.relatives;
        break;
      case 'friend':
        return this.state.text.friends;
        break;
      case 'others':
        return this.state.text.others;
    }
  }

  validate() {
    const props = clearEmptyStrings({
      medicalGuarantee: this.props.medicalGuarantee,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      dob: this.props.dob,
      idType: this.props.idType,
      idNumber: this.props.idNumber,
    });

    let attributes = {
      firstName: props.firstName,
      lastName: props.lastName,
      dob: props.dob,
      idType: props.idType,
      idNumber: props.idNumber,
    };
    if (this.props.showMedicalGuarantee) {
      attributes.medicalGuarantee = props.medicalGuarantee;
    }

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish,
    }

    let constraints = {
      firstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      lastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      dob: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      idType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      idNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: props.idType == 'HKID',
        format: englishFormat,
      },
    };
    if (this.props.showMedicalGuarantee) {
      attributes.medicalGuarantee = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }

    return validate(attributes, constraints, {fullMessages: false});
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    return (
      <View>
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h3, {color}]}>{this._getTitle()}</Text>
        </View>
        {
          this.props.showMedicalGuarantee &&
            <View style={formStyles.fieldset}>
              <YesNoQuestion
                question={this.state.text.chinaMedicalGuaranteeCard}
                choice={this.props.medicalGuarantee}
                onChange={(choice) =>
                  this.props.onChange({medicalGuarantee: choice})}
              />
            {
              this.props.messages &&
                <Text style={formStyles.message}>
                  {this.props.messages.medicalGuarantee}
                </Text>
            }
            </View>
        }

        {
          this.props.showMedicalGuarantee && this.props.medicalGuarantee == 'Y' ?
            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.mainlandPermit}</Text>
              <TextInput
                style={formStyles.input}
                value={this.props.mainlandPermit}
                onChangeText={(text) =>
                  this.props.onChange({mainlandPermit: text})}
              />
              {
                this.props.messages &&
                  <Text style={formStyles.message}>
                    {this.props.messages.mainlandPermit}
                  </Text>
              }
            </View> : undefined
        }
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.name}</Text>
          <NameInput
            title={this.props.title}
            firstName={this.props.firstName}
            lastName={this.props.lastName}
            onChange={(title, firstName, lastName) =>
              this.props.onChange({title, firstName, lastName})}
          />
          {
            this.props.messages &&
              <View>
                <Text style={formStyles.message}>
                  {this.props.messages.firstName}
                </Text>
                <Text style={formStyles.message}>
                  {this.props.messages.lastName}
                </Text>
              </View>
          }

        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.props.dob}
            fullDate={true}
            mode='date'
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={moment().subtract(this._getMaximumAge(), 'years').add(1, 'days').format('MMMM D, YYYY')}
            maxDate={moment().subtract(this._getMinimumAge(), 'years').format('MMMM D, YYYY')}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
            onDateChange={(date) => {
              if (
                moment().diff(moment(date, 'MMMM D, YYYY'), 'years') < 18 &&
                (this.props.period == 'annual' || this.props.planType != 'standard') &&
                this.props.type != 'child'
              ) {
                if (this.props.period == 'shortTermTrip') {
                  this.setState({dobMessage: this.state.text.canOnlyChooseTheStandardPlan});
                }
                else {
                  this.setState({dobMessage: this.state.text.notAvailableForAgeBelow18});
                }
              }
              else {
                let dob;
                if (moment(date).diff(moment().subtract(this._getMaximumAge(),'years').add(1,'days')) < 1) {
                  dob = moment().subtract(this._getMaximumAge(),'years').add(1,'days').format('MMMM D,YYYY');
                }
                else {
                  dob = date;
                }
                this.props.onChange({dob});
                this.setState({dobMessage: null});
              }
            }}
          />
          {
            this.state.dobMessage ?
              <Text style={formStyles.message}>
                {this.state.dobMessage}
              </Text> :
              <Text style={formStyles.message}>
                {this.props.messages && this.props.messages.dob}
              </Text>
          }
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.travelHkidOrPassportNumber}</Text>
          <IdentityInput
            type={this.props.idType}
            number={this.props.idNumber}
            onChange={(type, number) =>
              this.props.onChange({idType: type, idNumber: number})}
            pp
          />
          {
            this.props.messages &&
              <View>
                <Text style={formStyles.message}>
                  {this.props.messages.idType}
                </Text>
                <Text style={formStyles.message}>
                  {this.props.messages.idNumber}
                </Text>
              </View>
          }
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.relationshipWithThePolicyholder}</Text>
          <ModalSelector
            selectStyle={{borderColor: 'lightgrey'}}
            initValueTextStyle={{color: 'black'}}
            data={[
              { key: 'spouse', label: this.state.text.spouse },
              { key: 'child', label: this.state.text.children },
              { key: 'parents', label: this.state.text.parents },
              { key: 'relative', label: this.state.text.relatives },
              { key: 'friend', label: this.state.text.friends },
              { key: 'others', label: this.state.text.others },
            ]}
            initValue={this._getRelationshipLabel()}
            onChange={(option) =>
              this.props.onChange({relationship: option.key})}
            disabled={this.props.type == 'spouse' || this.props.type == 'child'}
            cancelText={this.state.text.cancel}
          />
        </View>
      </View>
    );
  }
}

const periodMaxDays = 181;

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  identityInput: {
    flex: 1,
    flexDirection: 'row',
  },
  identityType: {
    flex: 2,
  },
  identityNumber: {
    flex: 3,
    paddingLeft: 10,
  },
  addButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 40,
    marginRight: 40,
    backgroundColor: '#C50000',
  },
  addButtonText: {
    fontSize: 18,
    color: 'white',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white',
  },
};
