import React from 'react';
import {
  Text
} from 'react-native';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Alert extends React.Component {

  alert(title, message, buttons, options, type) {
    let buttons1 = [], buttons2 = [];
    buttons.forEach((button, index) => {
      const buttons = index < 2 ? buttons1 : buttons2;

      buttons.push(
        <Button onClick={button.onPress} color='primary'>
          {button.text}
        </Button>
      );
    });

    return(
      <Dialog open={true}>
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Text dangerouslySetInnerHTML={{__html: message}} style={{textAlign: 'left'}}></Text>
            {/* {message} */}
            {
              options && options.image &&
                <div style={{textAlign: 'center', marginTop: 20}}>
                  <a href={options.image.href}>
                    <img
                      style={{maxHeight: 200}}
                      src={options.image.source}
                    />
                  </a>
                </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons1}
        </DialogActions>
        {
          buttons2.length > 0 &&
            <DialogActions>
              {buttons2}
            </DialogActions>
        }
      </Dialog>
    );
  }

  //alertMulti(buttons,claimAlertText1, claimAlertText2,claimAlertText3, claimAlertText4, claimAlertText5, claimAlertText6,option1,option2,option3) {
  alertMulti(buttons,NeedToMakeClaim, followingLink,followingLinkText, claimContactText, claimContactEmail, option1,option2,option3) {
    let buttons1 = [], buttons2 = [];
    buttons.forEach((button, index) => {
      const buttons = index < 2 ? buttons1 : buttons2;

      buttons.push(
        <Button onClick={button.onPress} color='primary'>
          {button.text}
        </Button>
      );
    });

    return(
      <Dialog open={true}>
        {/* <DialogTitle id='alert-dialog-title'>{title}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {/* <h4><u>{claimAlertText1}</u></h4>
            {claimAlertText2}
            {
              option1 && option1.image &&
                <div style={{textAlign: 'center', marginTop: 20}}>
                  <a href={option1.image.href}>
                    <img
                      style={{maxHeight: 200}}
                      src={option1.image.source}
                    />
                  </a>
                </div>
            } */}
             {/* <h4><u>{claimAlertText3}</u></h4>
             {claimAlertText4}
            {
              option2 && option2.image &&
                <div style={{textAlign: 'center', marginTop: 20}}>
                  <a href={option2.image.href}>
                    <Text style={{color: 'C50000'}}>TPA Link</Text>
                  </a>
                </div>
            }
             <h4><u>{claimAlertText5}</u></h4> */}
             {/*claimAlertText6*/}
             {
              /*option3 && option3.image &&
                <div style={{textAlign: 'center', marginTop: 20}}>
                  <a href={option3.image.href}>
                    <img
                      style={{maxHeight: 200}}
                      src={option3.image.source}
                    />
                  </a>
                </div>*/
            }

            <Text style={[{fontWeight:'bold',fontSize:'18px'}]}>{NeedToMakeClaim}</Text>
            <p style={{marginBottom:'5px'}}>{followingLink}</p>
            <a href={followingLinkText} 
              style={{   
                display: 'inline-block',
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                verticalAlign: 'text-bottom'
              }}
             >{followingLinkText}</a>
            <p>{claimContactText}
              <a style={{   
                // display: 'inline-block',
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
                // verticalAlign: 'text-bottom'
              }}
              href= {"mailto:"+claimContactEmail}>{claimContactEmail}</a>
            </p>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons1}
        </DialogActions>
        {
          buttons2.length > 0 &&
            <DialogActions>
              {buttons2}
            </DialogActions>
        }
      </Dialog>
    );
  }
}

export default new Alert();
