import React from 'react';
import { Text, View } from 'react-native';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';

export default class ImportantNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    text: {},
    };
  }
  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  render() {
    let importantNotes = [];
    const notes =  this.props.importantNotes;
    for (let note in notes) {
      importantNotes.push(
        <View style={styles.importantNote} key={notes[note].number}>
            <li style={{fontSize: '.5em', marginTop: '1em'}}>
              <View style={styles.importantNoteText}>
                <Text style={{textAlign: 'left'}}>{notes[note].text}</Text>
              </View>
            </li>
        </View>
      );
    }

    return (
      <View style={{backgroundColor: this.props.backgroundColor}}>
        <View style={styles.ImportantNotes}>
          <Text>{this.state.text.importantNotes}</Text>
            <ol style={{marginTop: 5, marginBottom: 5}}>
              { importantNotes }
            </ol>
        </View>
      </View>
    );
  }
}

const styles = {
  ImportantNotes: {
    paddingTop: 20,
    paddingBottom: 20,

    paddingRight: 20,
  },
  importantNote: {
    textAlign: 'left',
  },
  importantNoteNumber: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  importantNoteText: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

  },
};
