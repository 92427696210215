import React from 'react';
import {
  StyleSheet, View, TouchableOpacity, Image, ScrollView, Text, FlatList,
} from 'react-native';

import Locale from './../Locale';
import Member from './../Member';
import Format from './../Format';
import HistoryAPI from './../api/HistoryAPI';
import AgentAPI from './../api/AgentAPI';
import {Input} from './../Form';
import Loading from './../components/Loading';
import Inbox, {InboxItem} from './../components/Inbox';

export default class PurchaseTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: !!Member.getMember(),
      searching: 0,
    };
  }

  componentDidMount() {
    if (Member.getMember()) {
      const policies = this.props.broker ?
        AgentAPI.policies : HistoryAPI.policies;

      policies(
        {
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'limit': 100,
        },
        ({policies}) => {
          this.setState({policies, loading: false});
        }
      );
    }
  }

  search(search) {
    if (Member.getMember()) {
      this.setState(
        {searching: this.state.searching + 1},
        () => {
          const policies = this.props.broker ?
            AgentAPI.policies : HistoryAPI.policies;

          policies(
            {
              'member.id': Member.getMemberId(),
              'member.token': Member.getMemberToken(),
              'search': search,
            },
            ({policies}) => {
              this.setState({
                policies,
                searching: this.state.searching - 1,
              });
            }
          );
        }
      );
    }
  }

  getProductName(planCode) {
    switch (planCode) {
      case 'ASAP':
        return Locale.text.annualStudyAbroadProtector;
      case 'LANDLORD':
        return Locale.text.centalineLandlordProtector;
      case 'DH':
        return Locale.text.domesticHelperInsurance;
        case 'DH2':
        return Locale.text.domesticHelperInsurance;
      case 'EPA':
        return Locale.text.executivePersonalAccidentProtector;
      case 'FIRE':
        return Locale.text.fireInsurance;
      case 'RPA':
        return Locale.text.bravoPassengerProtector;
    }

    if (planCode.startsWith('AHI')) {
      return Locale.text.ahiInsurance;
    }

    if (planCode.startsWith('HOME')) {
      return Locale.text.homePackageInsurance;
    }

    if (planCode.startsWith('IPA')) {
      return Locale.text.generaliPersonalAccidentInsurancePlan;
    }

    if (planCode.startsWith('MBDV')) {
      return Locale.text.bravoMobileDeviceProtector;
    }

    if (planCode.startsWith('STORAGE')) {
      return Locale.text.bravoStorageProtector;
    }

    if (planCode.startsWith('TRAVEL')) {
      if (planCode.endsWith('CTU')) {
        return Locale.text.cityU;
      }
      else if (planCode.endsWith('UOW')) {
        return Locale.text.uow;
      }
      else if (planCode.length == 9) {
        return Locale.text.bravoTravelProtector;
      }
      else {
        return Locale.text.gereraliTravelPackageInsurance;
      }
    }

    return planCode;
  }

  getContent(policy) {
    const {
      status,
      issueDate,
      effectiveDateStart,
      agent: {agentCode, nameEnUs},
      planKey: {planCode},
      policyHolder: {organization, salutation, firstName, lastName},
    } = policy;

    const fullName = organization ||
      Format.formatName(undefined, firstName, lastName);

    let content = fullName + Locale.text.of + this.getProductName(planCode);

    content += Locale.text.hasBeenAccepted;

    if (status == 10) {
      content += Format.formatDate(issueDate) +
        Locale.text.needsManualUnderwriting;
    }
    else {
      content += Format.formatDate(effectiveDateStart) +
        Locale.text.period;
    }

    if (this.props.broker && agentCode != 'TEST') {
      content += Locale.text.tr + nameEnUs + Locale.text.tr2;
    }

    return content;
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const {policies} = this.state;

    return (
      <View>
        <View style={styles.search}>
          <View style={styles.searchInput}>
            <Input onChange={(text) => this.search(text)} />
          </View>

          <TouchableOpacity>
            <Image
              style={styles.searchImage}
              source={require('./images/search.png')}
            />
          </TouchableOpacity>
        </View>

        {
          this.state.searching ?
            <Loading /> :
            <ScrollView style={styles.list}>
              <Text style={styles.label}>
                {
                  this.props.broker ?
                    Locale.text.historyOfPiliciesForBroker :
                    Locale.text.historyOfPilicies
                }
              </Text>

              {
                !policies || policies.length == 0 ?
                  <Text>
                    {Locale.text.noPurchaseHistoryYet}
                  </Text> :
                  <Inbox>
                    <FlatList
                      data={policies}
                      renderItem={({item}) =>
                        <InboxItem
                          label={this.getContent(item)}
                          onPress={
                            () => this.props.screen.push('PurchaseHistory', {
                              broker: this.props.broker,
                              content: this.getContent(item),
                              policyNo: item.policyNo,
                              policyHolder: item.policyHolder,
                            })
                          }
                        />
                      }
                      keyExtractor={(item, index) => index.toString()}
                    />
                  </Inbox>
              }
            </ScrollView>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  search: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchInput: {
    width: '80%',
  },
  searchImage: {
    width: 20,
    height: 20,
  },
  label: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  list: {
    marginLeft: 5,
    marginRight: 5,
  },
});
