import React from 'react';
import {
  Text, View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';


import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../../lib/LanguageStorage';
import { planTypes } from './planTypes';
import { instituteLocations } from './instituteLocations';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import PromotionCode from './../../../lib/PromotionCode';
import { host, api26, busiSecKey } from './../../../config/api';
import { memberStorage } from './../../../lib/MemberStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { clearEmptyStrings, hmacSha256, msgCodeDetail, removeSpaces } from './../../../lib/utils';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import HKID from './../../../lib/HKID';
import validid from 'validid';
import TextInput from './../../../components/TextField/TextField';
import Alert  from './../../../components/Alert/Alert';
import Footer from './../../../components/Footer/Footer';
import PromoteAPI from './../../../api/PromoteAPI';
import Navbar from './../../../components/Navbar/Navbar';
import DatePicker from './../../../components/DatePicker/DatePicker';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import NameInput from './../../../components/NameInput/NameInput';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';

import Member from './../../../Member';

export default class ASAP3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    const asap = this.props.location.state.asap;
    const insuredPeople = this.props.location.state.insuredPeople;
    const institution = this.props.location.state.institution;
    if (policy) {
      this.state = {
        effectiveDateStart: moment(policy.effectiveDateStart, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        effectiveDateEnd: moment(policy.effectiveDateEnd, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        policyholderTitle: policy.policyHolder.salutation.descEnUs.replace(/\./g, ''),
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderEmail: policy.policyHolder.email,
        policyholderAddress: policy.policyHolder.address1,
        policyholderHKID: policy.policyHolder.documentNo,
        policyholderDOB: moment(policy.policyHolder.dateOfBirth, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        planType: asap.includeGuardian == 'Y' ? 'with' : 'without',
        insuredTitle: insuredPeople[0].salutation ? insuredPeople[0].salutation.descEnUs.replace(/\./g, '') : undefined,
        insuredFirstName: insuredPeople[0].firstName,
        insuredLastName: insuredPeople[0].lastName,
        insuredHKID: insuredPeople[0].documentNo,
        // insuredDOB: moment(data['student.dateOfBirth'], 'YYYY-MM-DD').format('MMMM D, YYYY'),
        instituteName: institution.name,
        instituteAddress: institution.address,
        instituteLocation: institution.country.countryCode,
        specialRequests: policy.specialRequest,
        messages: {},
        text: {},
      };
    }
    else {
      this.state = {
        broker: this.props.location.state.broker,
        planType: this.props.location.state.planType,
        instituteLocation: this.props.location.state.instituteLocation,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
        referral: this.props.location.state.referral,
        messages: {},
        text: {},
      };
    }
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('asap3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        // init HKID validation
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value.toUpperCase())) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };

        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          if (this.state.broker) {
            // init broker-specific input field
            this.setState({
              insuranceAdvisorMobile:
                this.state.member && this.state.member.agent.isAdmin ?
                  undefined : this.state.broker.mobile
            });
          }
          else {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.navigation,
              (insuranceAdvisors) => {
                const insuranceAdvisor = insuranceAdvisors.ASAP;
                if (insuranceAdvisor) {
                  this.setState({insuranceAdvisor: insuranceAdvisor});

                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.ASAP.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'ASAP',
                      (promotionCodes) => {
                        this.setState({
                          showBusinessEmailAddress: false,
                          businessEmailAddress: undefined,
                          submitButtonDisabled: false,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              }
            );
            if (!this.state.member && this.state.promotionCodes) {
              PromoteAPI.checkPromotionCode(
                {
                  pn: 'EAPP',
                  pl: 'ASAP',
                  promotionCode: this.state.promotionCodes,
                },
                (msgDesc) => {
                  this.setState({insuranceAdvisor: {agent: msgDesc}});
                }
              );
            }

            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState('asap3', rawRequest);
    stateStorage.setState('asap4', undefined);
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      planType: state.planType,
      effectiveDate: state.effectiveDateStart,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderHKID: state.policyholderHKID,
      policyholderDOB: state.policyholderDOB,
      policyholderPhone: state.policyholderPhone,
      policyholderAddress: state.policyholderAddress,
      policyholderEmail: state.policyholderEmail,
      insuredFirstName: state.insuredFirstName,
      insuredLastName: state.insuredLastName,
      insuredHKID: state.insuredHKID,
      insuredDOB: state.insuredDOB,
      insuredEmail: state.insuredEmail,
      instituteName: state.instituteName,
      instituteLocation: state.instituteLocation,
      instituteAddress: state.instituteAddress,
      businessEmailAddress: state.businessEmailAddress,
      specialRequests: state.specialRequests,
      referral: state.referral,
    }
    if (state.planType == 'with') {
      attributes.optionalBenefit = state.optionalBenefit;
      attributes.optionalBenefitRelationship = state.optionalBenefitRelationship;
      if (state.optionalBenefit == 'other') {
        attributes.optionalBenefitFirstName = state.optionalBenefitFirstName;
        attributes.optionalBenefitLastName = state.optionalBenefitLastName;
        attributes.optionalBenefitHKID = state.optionalBenefitHKID;
        attributes.optionalBenefitDOB = state.optionalBenefitDOB;
      }
    }

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish,
    }

    let constraints = {
      planType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      effectiveDate: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      policyholderHKID: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: {message: this.state.text.notValidHKID},
        format: englishFormat,
      },
      policyholderPhone: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {message: this.state.text.notANumber},
      },
      policyholderDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      policyholderAddress: {
        format: englishFormat,
      },
      policyholderEmail: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      insuredFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredHKID: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: {message: this.state.text.notValidHKID},
        format: englishFormat,
      },
      insuredEmail: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      instituteName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      instituteLocation: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      instituteAddress:{
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      businessEmailAddress: {
        format: englishFormat,
      },
      specialRequests: {
        format: englishFormat,
      },
      referral: {
        format: englishFormat,
      },
    }
    if (this.state.planType == 'with') {
      constraints.optionalBenefit = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
      constraints.optionalBenefitRelationship = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
      if (this.state.optionalBenefit == 'other') {
        constraints.optionalBenefitFirstName = {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
          format: englishFormat,
        },
        constraints.optionalBenefitLastName = {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
          format: englishFormat,
        },
        constraints.optionalBenefitHKID = {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
          format: englishFormat,
        },
        constraints.optionalBenefitDOB = {
          presence: {message: this.state.text.thisFieldCannotBeBlank},
        };
      }
    }

    const messages = validate(attributes, constraints, {fullMessages: false});
    if (messages) {

      this.setState({
        alert:
        Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok,onPress: () => this.setState({alert: null}), style: 'cancel'}],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let fields = {
        'dt': moment().format('YYYYMMDDhhmmss'),
        'pn': 'EAPP',
        'pl': 'ASAP',
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.businessEmail': removeSpaces(this.state.businessEmailAddress),
        'asap.includeGuardian': this.state.planType == 'with' ? 'Y' : 'N',
        'institution.country': this.state.instituteLocation,
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api26, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0) {
          let rawRequest = Object.assign({}, state);
          delete rawRequest.messages;
          delete rawRequest.text;
          delete rawRequest.alert;

          const premium = responseJson.msgDesc.premium;
          const levy = responseJson.msgDesc.levy;
          const total = premium.discount + levy;

          stateStorage.setState('asap3', rawRequest);
          stateStorage.setState('asap4', undefined);

          this.props.history.push('/asap4', {
            broker: this.state.broker,
            insuranceAdvisor: state.insuranceAdvisor,
            insuranceAdvisorMobile: state.insuranceAdvisorMobile,
            product: state.product,
            effectiveDateStart: state.effectiveDateStart,
            effectiveDateEnd: state.effectiveDateEnd,
            promotionCodes: removeSpaces(state.promotionCodes),
            businessEmailAddress: removeSpaces(state.businessEmailAddress),
            specialRequests: state.specialRequests,
            referral: state.referral,
            policyholderTitle: state.policyholderTitle,
            policyholderFirstName: state.policyholderFirstName,
            policyholderLastName: state.policyholderLastName,
            policyholderPhone: state.policyholderPhone,
            policyholderAddress: state.policyholderAddress,
            policyholderEmail: state.policyholderEmail,
            policyholderHKID: state.policyholderHKID,
            policyholderDOB: state.policyholderDOB,
            planType: state.planType,
            insuredTitle: state.insuredTitle,
            insuredFirstName: state.insuredFirstName,
            insuredLastName: state.insuredLastName,
            insuredHKID: state.insuredHKID,
            insuredDOB: state.insuredDOB,
            insuredEmail: state.insuredEmail,
            instituteName: state.instituteName,
            instituteAddress: state.instituteAddress,
            instituteLocation: state.instituteLocation,
            optionalBenefit: state.optionalBenefit,
            optionalBenefitFirstName:state.optionalBenefitFirstName,
            optionalBenefitLastName:state.optionalBenefitLastName,
            optionalBenefitHKID: state.optionalBenefitHKID,
            optionalBenefitDOB: state.optionalBenefitDOB,
            optionalBenefitRelationship: state.optionalBenefitRelationship,
            optionalBenefitRelationshipother: state.optionalBenefitRelationshipother,
            rawRequest: rawRequest,
            premium: premium,
            levy: levy,
            total: total,
          });
    }
    else if (responseJson.msgCode == 40) {
      if (this.state.broker) {
        this.setState({
          messages: {
            promotionCodes: this.state.text['invalidPromotionCode']
          },
          alert: Alert.alert(
            this.state.text.alert,
            this.state.text.check,
            [{
              text: this.state.text.ok,
              onPress: () => this.setState({alert: null}),
              style: 'cancel',
            }],
          ),
        });
      }
      else {
        this.setState({alert:
        Alert.alert(
          this.state.text.addInsuranceAdvisor,
          this.state.text.wouldYouLike
          + responseJson.msgDesc.agent.brokerName
          + this.state.text.toBeYourInsuranceAdvisor,
          [
            {
              text: this.state.text.ok,
              onPress: () => {
                if (!this.state.bindingInsuranceAdvisor) {
                  this.setState({bindingInsuranceAdvisor: true}, () => {
                    insuranceAdvisors.setInsuranceAdvisor(
                      {agentCode: responseJson.msgDesc.agent.agentCode},
                      ['ASAP'],
                      this.state.promotionCodes,
                      this.state.language,
                      () => this.setState({bindingInsuranceAdvisor: false},
                        () => this._nextScreen())
                    );
                  });
                }
              }
            },
          ],
          { cancelable: false }
        )
        });
      }
    }
    else if (responseJson.msgCode == 56) {
      this.setState({
        messages: {
          promotionCodes: this.state.text['invalidPromotionCode']
        },
        alert: Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{
            text: this.state.text.ok,
            onPress: () => this.setState({alert: null}),
            style: 'cancel',
          }],
        ),
      });
    }
    else {
      alert(msgCodeDetail(responseJson.msgCode)
        [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
    }
    this.setState({submitButtonDisabled: false});
  })
  .catch((error) => {
    alert('Facing error when processing the quotation!');
    this.setState({submitButtonDisabled: false});
  });
}
}


  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    return (
      <div className='App'>

      <Helmet>
        <title>Annual Study Abroad Protector | Bravo Generali</title>
      </Helmet>

      <Navbar
        title={this.state.text.getInsured}
        backButton
        history={this.props.history}
      />

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
      <Hidden xsDown>
        <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.asap}</Text>
              <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.ensure}</Text>
            </View>

        </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,marginRight:'5%',backgroundColor: 'white',}}>
        <ScrollView style={formStyles.form, styles.fie}>

        {this.state.alert}
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h2, {color}]}>{this.state.text.annualStudyAbroadProtector}</Text>
        </View>
        {
          this.state.insuranceAdvisor || this.state.broker ?
            <View style={formStyles.fieldset}>
              <Text style={{textAlign: 'left'}}>
                {this.state.text.theInsuranceIsIssued}
                {
                  this.state.broker ?
                  this.state.broker.name :
                  this.state.insuranceAdvisor.agent.broker.nameEnUs
                }
                {this.state.text.professionalInsurance}
              </Text>
            </View> :
            <View style={{textAlign: 'left'}}>
              <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
            </View>
        }

        <Text>{this.state.text.pleaseInputInEnglish}</Text>

        {
          this.state.broker &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
              <TextInput
                style={formStyles.input}
                onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                value={this.state.insuranceAdvisorMobile}
                editable={this.state.member && this.state.member.agent.isAdmin}
                keyboardType='phone-pad'
                maxLength={8}
              />
            </View>
        }

        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.productType}</Text>
          <ModalSelector
            data={[
              { key: 'with', label: planTypes.with.label[this.state.language] },
              { key: 'without', label: planTypes.without.label[this.state.language] },
            ]}
            initValue={planTypes[this.state.planType].label[this.state.language]}
            onChange={(option) => this.setState({planType: option.key })}
            cancelText={this.state.text.cancel}
          />
            <Text style={formStyles.message}>{this.state.messages.planType}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.effectiveDate}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.effectiveDateStart && moment(this.state.effectiveDateStart).format('MMMM D, YYYY')}
            mode='date'
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={moment().add(1, 'days').format('MMMM D, YYYY')}
            maxDate={moment().add(1, 'days').add(6, 'months').format('MMMM D, YYYY')}
            onDateChange={(date) => {this.setState({
              effectiveDateStart: date,
              effectiveDateEnd: moment(date, 'MMMM D, YYYY')
                .add(1, 'years').subtract(1, 'days').format('MMMM D, YYYY'),
            })}}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
          />
          <Text style={formStyles.message}>{this.state.messages.effectiveDate}</Text>
        </View>
        {
          this.state.effectiveDateStart &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.periodOfInsurance}</Text>
              <View style={styles.periodOfInsurance}>
                <Text>
                  {this.state.effectiveDateStart} - {this.state.effectiveDateEnd}
                </Text>
                <Text>{this.state.text.bothDatesInclusive}</Text>
              </View>
            </View>
        }
        {
          !this.state.businessEmailAddress &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.promotionCodes}</Text>
              <TextInput
                style={formStyles.input}
                placeholder={this.state.text.optional}
                placeholderTextColor='lightgrey'
                onChangeText={(text) => this.setState({promotionCodes: text})}
                value={this.state.promotionCodes}
              />
                <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
            </View>
        }
        {
          !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.businessEmailAddress}</Text>
              <TextInput
                style={formStyles.input}
                placeholder={this.state.text.optional}
                placeholderTextColor='lightgrey'
                keyboardType='email-address'
                onChangeText={(text) => this.setState({businessEmailAddress: text})}
                value={this.state.businessEmailAddress}
              />
              <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
            </View>
        }
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h3, {color}]}>{this.state.text.policyHolder}</Text>
          <Text>{this.state.text.thePolicyHolderMustBeThe}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.name}</Text>
          <NameInput
            title={this.state.policyholderTitle}
            firstName={this.state.policyholderFirstName}
            lastName={this.state.policyholderLastName}
            onChange={(title, firstName, lastName) => this.setState({
              policyholderTitle: title,
              policyholderFirstName: firstName,
              policyholderLastName: lastName,
            })}
          />
          <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
          <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderHKID: text ? validid.utils.normalize(text) : text})}
            value={this.state.policyholderHKID}
            placeholder='A1234567'
            placeholderTextColor='lightgrey'
          />
          <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllCharacters}</Text>
          <Text style={formStyles.message}>{this.state.messages.policyholderHKID}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.policyholderDOB}
            fullDate={true}
            mode='date'
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={moment().subtract(76, 'years').add(1, 'days').format('MMMM D, YYYY')}
            maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
            onDateChange={(date) => {this.setState({policyholderDOB: date})}}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
          />
          <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>

          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderPhone: text})}
            value={this.state.policyholderPhone}
            keyboardType='phone-pad'
            maxLength={8}
          />

          <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.correspondenceAddress}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderAddress: text})}
            value={this.state.policyholderAddress}
            placeholder={this.state.text.optional}
            placeholderTextColor='lightgrey'
          />
          <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderEmail: text})}
            value={this.state.policyholderEmail}
            keyboardType='email-address'
          />
          <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
        </View>
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h3, {color}]}>{this.state.text.insuredStudent}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.name}</Text>
          <NameInput
            title={this.state.insuredTitle}
            firstName={this.state.insuredFirstName}
            lastName={this.state.insuredLastName}
            onChange={(title, firstName, lastName) => this.setState({
              insuredTitle: title,
              insuredFirstName: firstName,
              insuredLastName: lastName,
            })}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredFirstName}</Text>
          <Text style={formStyles.message}>{this.state.messages.insuredLastName}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({insuredHKID: text ? validid.utils.normalize(text) : text})}
            value={this.state.insuredHKID}
            placeholder='A1234567'
            placeholderTextColor='lightgrey'
            maxLength={8}
          />
        <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllCharacters}</Text>
            <Text style={formStyles.message}>{this.state.messages.insuredHKID}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.insuredDOB}
            fullDate={true}
            mode='date'
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={moment().subtract(31, 'years').add(1, 'days').format('MMMM D, YYYY')}
            maxDate={moment().subtract(12, 'years').format('MMMM D, YYYY')}
            onDateChange={(date) => {this.setState({insuredDOB: date})}}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredDOB}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({insuredEmail: text})}
            value={this.state.insuredEmail}
            keyboardType='email-address'
          />
            <Text style={formStyles.message}>{this.state.messages.insuredEmail}</Text>
        </View>
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h3, {color}]}>{this.state.text.overseasStudyingInstitution}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.location}</Text>
          <ModalSelector
            data={[
              { key: 'US_CA', label: instituteLocations.US_CA.label[this.state.language] },
              { key: 'OTHERS', label: instituteLocations.OTHERS.label[this.state.language] },
            ]}
            initValue={instituteLocations[this.state.instituteLocation].label[this.state.language]}
            onChange={(option) => this.setState({instituteLocation: option.key })}
            cancelText={this.state.text.cancel}
          />
          <Text style={formStyles.message}>{this.state.messages.instituteLocation}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.instituteName}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({instituteName: text})}
            value={this.state.instituteName}
          />
          <Text style={formStyles.message}>{this.state.messages.instituteName}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.state.text.address}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({instituteAddress: text})}
            value={this.state.instituteAddress}
          />
            <Text style={formStyles.message}>{this.state.messages.instituteAddress}</Text>
        </View>
        {
          this.state.planType == 'with' &&
            <View>
              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, {color}]}>{this.state.text.optionalBenefit}</Text>
                <Text>{this.state.text.thereIsAnOptional}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.personToBeInsured}</Text>
                <ModalSelector
                  data={[
                    { key: 'policyholder', label: this.state.text.sameAsThePolicyholder },
                    { key: 'other', label: this.state.text.otherPerson },
                  ]}
                  initValue={
                    this.state.optionalBenefit == 'policyholder' ?
                      this.state.text.sameAsThePolicyholder :
                      this.state.text.otherPerson
                  }
                  onChange={(option) => this.setState({
                    optionalBenefit: option.key,
                    optionalBenefitRelationship:
                      option.key == 'policyholder' ? 'Policyholder' : null,
                  })}
                  cancelText={this.state.text.cancel}
                />
                  <Text style={formStyles.message}>{this.state.messages.optionalBenefit}</Text>
              </View>
              {
                this.state.optionalBenefit == 'policyholder' &&
                  moment(this.state.policyholderDOB, 'MMMM D, YYYY').add(56, 'years').diff(moment()) < 0 &&
                  <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
              }
              {
                this.state.optionalBenefit == 'other' &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.name}</Text>
                      <NameInput
                        title={this.state.optionalBenefitTitle}
                        firstName={this.state.optionalBenefitFirstName}
                        lastName={this.state.optionalBenefitLastName}
                        onChange={(title, firstName, lastName) => this.setState({
                          optionalBenefitTitle: title,
                          optionalBenefitFirstName: firstName,
                          optionalBenefitLastName: lastName,
                        })}
                      />
                      <Text style={formStyles.message}>{this.state.messages.optionalBenefitFirstName}</Text>
                      <Text style={formStyles.message}>{this.state.messages.optionalBenefitLastName}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({optionalBenefitHKID: text})}
                        value={this.state.optionalBenefitHKID}
                        placeholder='A1234567'
                        placeholderTextColor='lightgrey'
                      />
                      <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllCharacters}</Text>
                      <Text style={formStyles.message}>{this.state.messages.optionalBenefitHKID}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.optionalBenefitDOB}
                        fullDate={true}
                        mode='date'
                        placeholder={this.state.text.pleasePickADate}
                        format='MMMM D, YYYY'
                        minDate={moment().subtract(56, 'years').add(1, 'days').format('MMMM D, YYYY')}
                        maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
                        onDateChange={(date) => {this.setState({optionalBenefitDOB: date})}}
                        confirmBtnText={this.state.text.ok}
                        cancelBtnText={this.state.text.cancel}
                      />
                      <Text style={formStyles.message}>{this.state.messages.optionalBenefitDOB}</Text>
                    </View>
                  </View>
              }
              {
                this.state.optionalBenefit &&
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.relationshipWithThePolicyholder}</Text>
                    {
                      this.state.optionalBenefit == 'policyholder' ?
                        <View>
                          <ModalSelector
                            data={[
                              { key: 'Policyholder', label: this.state.text.policyHolder },
                            ]}
                            initValue={this.state.text.policyHolder}
                            disabled={true}
                          />
                        </View> :
                        <View>
                          {
                            this.state.optionalBenefitOtherRelationship ?
                              <TextInput
                                style={formStyles.input}
                                onChangeText={(text) => this.setState({optionalBenefitRelationship: text})}
                                value={this.state.optionalBenefitRelationship}
                                placeholder={this.state.text.other}
                                placeholderTextColor='lightgrey'
                              /> :
                              <ModalSelector
                                data={[
                                  { key: 'Spouse', label: this.state.text.spouse },
                                  { key: 'other', label: this.state.text.other },
                                ]}
                                initValue={this.state.text.pleaseChoose}
                                onChange={(option) => this.setState({
                                  optionalBenefitRelationship: option.key == 'other' ? '' : option.key,
                                  optionalBenefitOtherRelationship: option.key == 'other',
                                })}
                                cancelText={this.state.text.cancel}
                              />
                          }
                        </View>
                    }
                    <Text style={formStyles.message}>{this.state.messages.optionalBenefitRelationship}</Text>
                  </View>
              }
            </View>
        }
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h3, {color}]}>{this.state.text.specialRequests}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({specialRequests: text})}
            value={this.state.specialRequests}
            placeholder={this.state.text.optional}
            placeholderTextColor='lightgrey'
          />
          <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
          {
            !!this.state.specialRequests &&
              <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
          }
        </View>
        <View style={formStyles.fieldset}>
          <Text style={[formStyles.h3, {color}]}>{this.state.text.referral}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({referral: text})}
            value={this.state.referral}
            placeholder={this.state.text.optional}
            placeholderTextColor='lightgrey'
          />
          <Text style={formStyles.message}>{this.state.messages.referral}</Text>
        </View>
        {
          this.state.submitButtonDisabled ?
            <View style={formStyles.submitButtonDisabled}>
              <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
            </View> :
            <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._nextScreen()}>
              <Text style={formStyles.submitButtonText}>{this.state.text.buyNow}</Text>
            </TouchableOpacity>
        }
        </ScrollView>
      </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
    </Grid>
      <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
