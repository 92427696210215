const config = {
  api: 'https://eapp.generali.com.hk/bravoapi21', 
  cms: 'https://eapp.generali.com.hk',  
  date: {
    dataFormat: 'YYYY-MM-DD',
    displayFormat: 'MMMM D, YYYY',
  },
  secretKey: '37bO1SVEa8qcyrtHvjrV'
};

export default config;
