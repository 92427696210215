export const zhHK = {
  insuranceAdvisor: '保險顧問',
  history: '記錄',
  instantEnrollment: '即時投保',
  instantClaims: '即時索償',
  referralLink: '推薦鏈接',
  beforeSendingTheLink: '在向我/我們的客戶發送推薦鏈接之前，我/我們已經獲得客戶同意。',
  shareYourReferralLink: '分享你的推薦鏈接',
  reminder: '請留意',
  youNeedToAgree: '在分享推薦鏈接之前，你需要先同意聲明。',
  chooseAgain: '重新選擇',
  home:'首頁',
  borkermes: '保險顧問功能',
  welcomeborker: '歡迎來到Bravo Generali！',
}
