import React from 'react';

import Locale from './../../Locale';
import {FormElement, Heading2, Input, Message} from './../../Form';

export default class ReferralCode extends React.Component {
  render() {
    const {value, onChange, messages} = this.props;

    return (
      <FormElement>
        <Heading2>{Locale.text.referralCode}</Heading2>

        <Input
          placeholder={Locale.text.optional}
          value={value}
          onChange={(referralCode) => onChange(referralCode)}
        />

        {messages && <Message>{messages.referralCode}</Message>}
      </FormElement>
    );
  }
}
