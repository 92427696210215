import React from 'react';
import {
  StyleSheet, View, FlatList, TouchableOpacity, Text,
} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import Member from './../../Member';
import {
  Heading2, NameInput, DocumentInput,
  DateInput, DropDownList, Divider, Button,
} from './../../Form';

export default class MembersForm extends React.Component {
  getHeading(index) {
    const {ahif} = this.props;

    if (ahif.premiumPlan == 'I') {
      return Locale.text.insured;
    }
    else {
      return Locale.getOrdinalNumber(index + 1) + Locale.text.insured;
    }
  }

  getMinDate(index) {
    const {relationship} = this.props.members[index];

    if (relationship == 'child') {
      return moment().subtract(18, 'years').add(1, 'day');
    }
    else {
      return moment().subtract(71, 'years').add(1, 'day');
    }
  }

  getMaxDate(index) {
    const {ahif, policyholderIsInsured} = this.props;
    const {relationship} = this.props.members[index];

    if (relationship == 'child') {
      return moment();
    }
    else if (ahif.premiumPlan == 'I' && !policyholderIsInsured) {
      return moment();
    }
    else {
      return moment().subtract(18, 'years');
    }
  }

  getRelationshipOptions(index) {
    if (index == 0) {
      const label = this.props.policyholderIsInsured ?
        Locale.text.policyHolder : Locale.text.insured;

      return [{value: 'insured', label}];
    }
    else {
      const {members} = this.props;
      const {relationship} = members[index];
      const options = [];
     
      let spouse;

      let childarray = []

      members.forEach((member) => {
        if (member.relationship == 'spouse') {
          spouse = true;
        }else if (member.relationship == 'child') {
          childarray.push('1');
        }
      });


      
      if(childarray.length<3 || relationship == 'child'){
        options.unshift({value: 'child', label: Locale.text.child});
      }
      if (!spouse || relationship == 'spouse') {
        options.unshift({value: 'spouse', label: Locale.text.spouse});
      }
  

      return options;
    }
  }

  onChange(index, data) {
    if (index == 0 && this.props.policyholderIsInsured) {
      return;
    }

    const {relationship} = this.props.members[index];

    if (data.relationship && relationship != data.relationship) {
      data.dateOfBirth = null;
    }

    this.props.onChange(index, data);
  }

  addInsured() {
    const {members, onChange} = this.props;

    onChange(members.length, {});
  }

  removeInsured(index) {
    const {members, onChange} = this.props;

    members.splice(index, 1);

    onChange(0, members[0]);
  }

  render() {
    const {policyholderIsInsured,
      policyHolder, ahif, members, messages} = this.props;
    const color = Member.getThemeColor();

    return (
      <View>
        <FlatList
          data={members}
          renderItem={({item, index}) =>
            <View>
              <View style={styles.row}>
                <Heading2>{this.getHeading(index)}</Heading2>

                {
                  index > 0 &&
                    <TouchableOpacity
                      onPress={() => this.removeInsured(index)}
                    >
                      <Text style={[styles.removeButtonText, {color}]}>
                        ×
                      </Text>
                    </TouchableOpacity>
                }
              </View>

              <NameInput
                label={Locale.text.name}
                disabled={index == 0 && policyholderIsInsured}
                salutation={item.salutation}
                firstName={item.firstName}
                lastName={item.lastName}
                onChange={(data) => this.onChange(index, data)}
                messages={messages && messages[index]}
              />

              <DocumentInput
                label={Locale.text.hkid}
                disabled={index == 0 && policyholderIsInsured}
                documentType={item.documentType}
                documentNo={item.documentNo}
                onChange={(data) => this.onChange(index, data)}
                messages={messages && messages[index]}
              />

              <DropDownList
                label={Locale.text.relationshipWithThePolicyholder}
                disabled={index == 0 && policyholderIsInsured}
                options={this.getRelationshipOptions(index)}
                value={item.relationship}
                onChange={(relationship) =>
                  this.onChange(index, {relationship})}
                message={messages && messages[index] &&
                  messages[index].relationship}
              />

              <DateInput
                label={Locale.text.dateOfBirth}
                disabled={index == 0 && policyholderIsInsured}
                fullDate={true}
                minDate={this.getMinDate(index)}
                maxDate={this.getMaxDate(index)}
                value={item.dateOfBirth}
                onChange={(dateOfBirth) => this.onChange(index, {dateOfBirth})}
                message={messages && messages[index] &&
                  messages[index].dateOfBirth}
              />

              <Divider />
            </View>
          }
          keyExtractor={(item, index) => index.toString()}
          extraData={JSON.stringify(this.props)}
        />

        {
          ahif.premiumPlan == 'F' && members.length < 5 &&
            <Button withMargin onPress={() => this.addInsured()}>
              {Locale.text.addInsured}
            </Button>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  removeButtonText: {
    fontSize: 20,
  },
});
