import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const options = {
  period: {
    shortTermTrip: {
      label: {
        enUS: enUS.shortTermTrip,
        zhHK: zhHK.shortTermTrip,
      },
    },
    annual: {
      label: {
        enUS: enUS.annual,
        zhHK: zhHK.annual,
      },
    },
  },
  people: {
    individual: {
      label: {
        enUS: enUS.individual,
        zhHK: zhHK.individual,
      },
    },
    family: {
      label: {
        enUS: enUS.family,
        zhHK: zhHK.family,
      },
    },
    withChildren: {
      label: {
        enUS: enUS.individualNChildren,
        zhHK: zhHK.individualNChildren,
      },
    },
    group: {
      label: {
        enUS: enUS.groupOfIndividuals,
        zhHK: zhHK.groupOfIndividuals,
      },
    },
  },
}
