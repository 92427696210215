import React from "react";
import { View } from "react-native";

import Locale from "./../../Locale";
import { Layout, Navbar } from "./../../Screen";
import { Form, Button } from "./../../Form";
import DHScreen from "./DHScreen";
import InformationForQuotation from "./../components/InformationForQuotation";
import Total from "./../components/Total";
import Alert from "./../../components/Alert/Alert";

export default class DHScreen2 extends DHScreen {
  buyNow() {
    if (this.state.broker) {
      this.askCustomerToLogin().then(() => this.push("domestic3"));
    } else {
      if (Locale.getPaymentStatus()) {
        this.askCustomerToLogin().then(() => this.push("domestic3"));
      } else {
        this.setState({
          alert: Alert.alert(
            Locale.text.paymentStopService1,
            Locale.text.paymentStopService2,
            [
              {
                text: Locale.text.yes,
                onPress: () => this.setState({ alert: null }),
              },
            ]
          ),
        });
      }
    }
  }

  render() {
    const { policy, domesticHelper, payment } = this.state.insuranceProduct;

    const data = [];

    if (payment.promotionCode) {
      data.push({
        label: Locale.text.promotionCodes,
        value: payment.promotionCode,
      });
    }

    if (policy.businessEmail) {
      data.push({
        label: Locale.text.businessEmailMembership,
        value: policy.businessEmail,
      });
    }

    return (
      <Layout alert={this.state.alert}>
        <Navbar screen={this} title={Locale.text.getAQuote} />

        {Locale.text.domesticHelperInsurance}
        {Locale.text.domesticHelperInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <InformationForQuotation
              planTypeOptions={this.includeOutpatientOptions}
              planType={domesticHelper.includeOutpatient}
              data={data}
            />

            <Total premium={this.getParam("premium")} />
          </Form>

          <Button withMargin onPress={() => this.buyNow()}>
            {Locale.text.buyNow}
          </Button>
        </View>
      </Layout>
    );
  }
}
