import React from 'react';
import {StyleSheet, ScrollView, Text, FlatList} from 'react-native';

import Locale from './../Locale';
import Member from './../Member';
import Format from './../Format';
import HistoryAPI from './../api/HistoryAPI';
import AgentAPI from './../api/AgentAPI';
import Loading from './../components/Loading';
import Inbox, {InboxItem} from './../components/Inbox';

export default class ClaimTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: !!Member.getMember(),
    };
  }

  componentDidMount() {
    if (Member.getMember()) {
      const claims = this.props.broker ?
        AgentAPI.claims : HistoryAPI.claims;

      claims(
        {
          'policyHolder.mobile': Member.getMember().mobile,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
        },
        ({claims}) => {
          this.setState({claims: claims.reverse(), loading: false});
        }
      );
    }
  }

  getContent(claim) {
    const {policyNo, claimDate} = claim;
    const date = Format.formatDate(claimDate);

    const content = Locale.text.yourClaimOn + policyNo +
      Locale.text.hasBeenSubmittedOn + date + Locale.text.periodII;

    return content;
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const {claims} = this.state;

    return (
      <ScrollView style={styles.list}>
        <Text style={styles.label}>
          {
            this.props.broker ?
              Locale.text.historyOfClaimForBroker :
              Locale.text.historyOfClaim
          }
        </Text>

        {
          claims && claims.length == 0 ?
            <Text>
              {Locale.text.noClaimHistoryYet}
            </Text> :
            <Inbox>
              <FlatList
                data={claims}
                renderItem={({item}) =>
                  <InboxItem label={this.getContent(item)} />
                }
                keyExtractor={(item, index) => index.toString()}
              />
            </Inbox>
        }
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  label: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
  list: {
    marginLeft: 5,
    marginRight: 5,
  },
});
