import React from 'react';
import { Text, Image, View, TouchableOpacity } from 'react-native';

import { languageStorage } from './../../lib/LanguageStorage';

export default class PlanTypePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'enUS',
    };
  }

  componentDidMount() {
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
      })
    );
  }

  render() {
    let planTypes = [];
    for (let planType in this.props.planTypes) {
      planTypes.push(
        <View key={planType} style={{minHeight: 60, backgroundColor: this.props.planTypes[planType].backgroundColor}}>
          <TouchableOpacity style={styles.planType} onPress={() => this.props.onSelect(planType)}>
            <View style={styles.planTypeRadioButton}>
              {
                this.props.selectedPlanType == planType ?
                  <Image style={styles.planTypeRadioButtonImage} source={radioButtonSelected} /> :
                  <Image style={styles.planTypeRadioButtonImage} source={radioButtonUnselected} />
              }
            </View>
            <View style={styles.planTypeText}>
              <Text style={{color: this.props.planTypes[planType].color}}>
                {this.props.planTypes[planType].label[this.state.language]}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    }

    return (
      <View>
        { planTypes }
      </View>
    );
  }
}

const radioButtonSelected = require('./radio_button_selected.png');
const radioButtonUnselected = require('./radio_button_unselected.png');

const styles = {
  planType: {
    flex: 1,
    flexDirection: 'row',
  },
  planTypeRadioButton: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  planTypeRadioButtonImage: {
    width: 20,
    height: 20,
  },
  planTypeText: {
    flex: 8,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
};
