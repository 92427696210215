import React from 'react';
import {
  Text, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { options } from './options';
import PromotionCode from './../../../lib/PromotionCode';
import { host, api23, api26, busiSecKey } from './../../../config/api';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { clearEmptyStrings, hmacSha256, msgCodeDetail, removeSpaces } from './../../../lib/utils';
import { stateStorage } from './../../../lib/StateStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import HKID from './../../../lib/HKID';
import TextInput from './../../../components/TextField/TextField';
import PromoteAPI from './../../../api/PromoteAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import DatePicker from './../../../components/DatePicker/DatePicker';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import NameInput from './../../../components/NameInput/NameInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import validid from 'validid';

export default class HomePackageInsurance3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    if (policy) {
      this.state = {
        effectiveDateStart: moment(policy.effectiveDateStart, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        effectiveDateEnd: moment(policy.effectiveDateEnd, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderEmail: policy.policyHolder.email,
        policyholderAddress: policy.policyHolder.address1,
        policyholderHKID: policy.policyHolder.documentNo,
        planType: 'without',
        propertySize: 1,
        // propertyAddress: data['insuredHome.fullAddress'],
        // propertySize: data['insuredHome.grossArea'],
        // propertyType: data['insuredHome.homeType'],
        // propertyUnoccupied: data['insuredHome.isUnoccupied'],
        // propertyAged: data['insuredHome.isOldBuilding'],
        // propertyLoss: data['insuredHome.isAnyLoss'],
        specialRequests: policy.specialRequest,
        messages: {},
        text: {},
      };
      if (this.state.centaline) {
        this.state.propertySize = 7;
      }
    }
    else {
      this.state = {
        broker: this.props.location.state.broker,
        grossAreas: this.props.location.state.grossAreas,
        //centaline: this.props.navigation.getParam('centaline'),
        planType: this.props.location.state.planType,
        propertySize: this.props.location.state.propertySize,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
        referral: this.props.location.state.referral,
        messages: {},
        text: {},
      };
    }
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('home3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        // init HKID validation
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value.toUpperCase())) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };

        // init options for property size
        if (this.state.grossAreas) {
          this.setState({
            defaultGrossAreaLabel:
              this._findGrossAreaById(this.state.propertySize).label,
          });
        }
        else {
          this._getGrossAreas();
        }

        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          if (this.state.broker) {
            // init broker-specific input field
            this.setState({
              insuranceAdvisorMobile:
                this.state.member && this.state.member.agent.isAdmin ?
                  undefined : this.state.broker.mobile
            });
          }
          else {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.navigation,
              (insuranceAdvisors) => {
                const insuranceAdvisor = insuranceAdvisors.HOME;
                if (insuranceAdvisor) {
                  this.setState({insuranceAdvisor: insuranceAdvisor});

                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.HOME.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'HOME',
                      (promotionCodes) => {
                        this.setState({
                          showBusinessEmailAddress: false,
                          businessEmailAddress: undefined,
                          submitButtonDisabled: false,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              }
            );
            if (!this.state.member && this.state.promotionCodes) {
              PromoteAPI.checkPromotionCode(
                {
                  pn: 'EAPP',
                  pl: 'HOME',
                  promotionCode: this.state.promotionCodes,
                },
                (msgDesc) => {
                  this.setState({insuranceAdvisor: {agent: msgDesc}});
                }
              );
            }

            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState('home3', rawRequest);
    stateStorage.setState('home4', undefined);
  }

  _findGrossAreaById(grossAreaId) {
    let match;
    this.state.grossAreas.forEach((grossArea) => {
      if (grossArea.key == grossAreaId) {
        match = grossArea;
      }
    });
    return match;
  }

  _getGrossAreas() {
    const data = JSON.stringify({
      'dt': moment().format('YYYYMMDDhhmmss'),
      'pn': this.state.centaline ? 'CTL' : 'EAPP',
      'timeStamp': Date.now(),
    });
    const hmac = hmacSha256(data);
    fetch(host + api23, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.msgCode == 0) {
        let grossAreas = [];
        let desc = 'descEnUs';
        if (this.state.language == 'zhHK') {
          desc = 'descZhHk';
        }
        responseJson.msgDesc.grossAreas.forEach((grossArea) => {
          grossAreas.push({
            key: grossArea.id,
            label: grossArea[desc],
          });
        });
        this.setState({
          grossAreas: grossAreas,
        }, () => {
          this.setState({
            defaultGrossAreaLabel:
              this._findGrossAreaById(this.state.propertySize).label,
          });
        });
      }
      else {
        alert(msgCodeDetail(responseJson.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
      }
    })
    .catch((error) => {
      alert('Facing error when processing the query!');
    });
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      policyholderDOB: state.policyholderDOB,
      effectiveDate: state.effectiveDateStart,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderPhone: state.policyholderPhone,
      policyholderAddress: state.policyholderAddress,
      policyholderEmail: state.policyholderEmail,
      policyholderHKID: state.policyholderHKID,
      propertyAddress: state.propertyAddress,
      propertySize: state.propertySize,
      propertyType: state.propertyType,
      propertyUnoccupied: state.propertyUnoccupied,
      propertyAged: state.propertyAged,
      propertyLoss: state.propertyLoss,
      propertyDeclined: state.propertyDeclined,
      specialRequests: state.specialRequests,
      referral: state.referral,
      businessEmailAddress: state.businessEmailAddress,
    };
    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      policyholderDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      effectiveDate: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      policyholderPhone: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {message: this.state.text.notANumber},
      },
      policyholderAddress: {
        format: englishFormat,
      },
      policyholderEmail: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      policyholderHKID: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: {message: this.state.text.notValidHKID},
        format: englishFormat,
      },
      propertyAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      propertySize: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      propertyType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      propertyUnoccupied: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      propertyAged: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      propertyLoss: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      propertyDeclined: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      specialRequests: {
        format: englishFormat,
      },
      referral: {
        format: englishFormat,
      },
      businessEmailAddress: {
        format: englishFormat,
      },
    }
    const messages = validate(attributes, constraints, {fullMessages: false});
    if (messages) {

      this.setState({
        alert:
        Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok, onPress: () => this.setState({alert: null}), style: 'cancel'}],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let fields = {
        'dt': moment().format('YYYYMMDDhhmmss'),
        'pn': 'EAPP',
        'pl': 'HOME',
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.businessEmail': this.state.centaline ?
          undefined : removeSpaces(this.state.businessEmailAddress),
        'insuredHome.grossArea': this.state.propertySize,
        'insuredHome.includeBuilding':
          this.state.planType == 'with' ? 'Y' : 'N',
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api26, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0 || this.state.nextScreen) {
          let rawRequest = Object.assign({}, state);
          delete rawRequest.messages;
          delete rawRequest.text;
          delete rawRequest.alert;

          const premium = responseJson.msgDesc.premium;
          const levy = responseJson.msgDesc.levy;
          const total = premium.discount + levy;

          stateStorage.setState('home3', rawRequest);
          stateStorage.setState('home4', undefined);

          this.props.history.push('/home4', {
            broker: state.broker,
            insuranceAdvisor: state.insuranceAdvisor,
            insuranceAdvisorMobile: state.insuranceAdvisorMobile,
            grossAreas: state.grossAreas,
            centaline: state.centaline,
            planType: state.planType,
            effectiveDateStart: state.effectiveDateStart,
            effectiveDateEnd: state.effectiveDateEnd,
            promotionCodes: removeSpaces(state.promotionCodes),
            businessEmailAddress: removeSpaces(state.businessEmailAddress),
            policyholderTitle: state.policyholderTitle,
            policyholderFirstName: state.policyholderFirstName,
            policyholderLastName: state.policyholderLastName,
            policyholderPhone: state.policyholderPhone,
            policyholderEmail: state.policyholderEmail,
            policyholderAddress: state.policyholderAddress,
            policyholderHKID: state.policyholderHKID,
            policyholderDOB: state.policyholderDOB,
            propertyAddress: state.propertyAddress,
            propertySize: state.propertySize,
            propertyType: state.propertyType,
            propertyUnoccupied: state.propertyUnoccupied,
            propertyAged: state.propertyAged,
            propertyLoss: state.propertyLoss,
            propertyDeclined: state.propertyDeclined,
            specialRequests: state.specialRequests,
            referral: state.referral,
            rawRequest: rawRequest,
            premium: premium,
            levy: levy,
            total: total,
          });
        }
        else if (responseJson.msgCode == 40) {
          if (this.state.broker) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                  style: 'cancel',
                }],
              ),
            });
          }
          else {
            this.setState({alert: Alert.alert(
              this.state.text.addInsuranceAdvisor,
              this.state.text.wouldYouLike
              + responseJson.msgDesc.agent.brokerName
              + this.state.text.toBeYourInsuranceAdvisor,
              [
                {
                  text: this.state.text.ok,
                  onPress: () => {
                    if (!this.state.bindingInsuranceAdvisor) {
                      this.setState({bindingInsuranceAdvisor: true}, () => {
                        insuranceAdvisors.setInsuranceAdvisor(
                          {agentCode: responseJson.msgDesc.agent.agentCode},
                          ['HOME'],
                          this.state.promotionCodes,
                          this.state.language,
                          () => this.setState({bindingInsuranceAdvisor: false},
                            () => this._nextScreen())
                        );
                      });
                    }
                  }
                },
              ],
              { cancelable: false }
            )});
          }
        }
        else if (responseJson.msgCode == 56) {
          this.setState({
            messages: {
              promotionCodes: this.state.text['invalidPromotionCode']
            },
            alert: Alert.alert(
              this.state.text.alert,
              this.state.text.check,
              [{
                text: this.state.text.ok,
                onPress: () => this.setState({alert: null}),
                style: 'cancel',
              }],
            ),
          });
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
        this.setState({submitButtonDisabled: false});
      })
      .catch((error) => {
        alert('Facing error when processing the quotation!');
        this.setState({submitButtonDisabled: false});
      });
    }
  }

  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Home Package Insurance | Bravo Generali</title>
        </Helmet>

          <Navbar
            title={this.state.text.getInsured}
            backButton
            history={this.props.history}
          />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
          <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
            <View style={[styles.greeting, {fontWeight: 'bold'}]}>
              <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.homepa}</Text>
                  <p></p>
              <Text style={[styles.greetingText,{fontSize: 20}]}>{this.state.text.valuable}</Text>
            </View>
          </Grid>
          </Hidden>
         <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>
            <ScrollView style={formStyles.form, styles.fie}>
            {this.state.alert}
            <View style={formStyles.fieldset}>
            <Text style={formStyles.h2}>
              {
                this.state.centaline ?
                this.state.text.centalineHomeProtector :
                this.state.text.homePackageInsurance
              }
            </Text>
          </View>

          {
            this.state.insuranceAdvisor || this.state.broker ?
              <View style={formStyles.fieldset}>
                <Text style={{textAlign: 'left'}}>
                  {this.state.text.theInsuranceIsIssued}
                  {
                    this.state.broker ?
                    this.state.broker.name :
                    this.state.insuranceAdvisor.agent.broker.nameEnUs
                  }
                  {this.state.text.professionalInsurance}
                </Text>
              </View> :
              <View style={{textAlign: 'left'}}>
                <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
              </View>
          }

          <Text>{this.state.text.pleaseInputInEnglish}</Text>

          {
            this.state.broker &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                  value={this.state.insuranceAdvisorMobile}
                  editable={this.state.member && this.state.member.agent.isAdmin}
                  keyboardType='phone-pad'
                  maxLength={8}
                />
              </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.productType}</Text>
            <ModalSelector
              data={[
                { key: 'without', label: planTypes.without.label[this.state.language] },
                { key: 'with', label: planTypes.with.label[this.state.language] },
              ]}
              initValue={planTypes[this.state.planType].label[this.state.language]}
              onChange={(option) => this.setState({planType: option.key })}
              cancelText={this.state.text.cancel}
            />
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.effectiveDate}</Text>
            <DatePicker
              style={formStyles.datepicker}
              date={this.state.effectiveDateStart}
              mode='date'
              placeholder={this.state.text.pleasePickADate}
              format='MMMM D, YYYY'
              minDate={moment().add(1, 'days').format('MMMM D, YYYY')}
              maxDate={moment().add(1, 'days').add(6, 'months').format('MMMM D, YYYY')}
              onDateChange={(date) => {this.setState({
                effectiveDateStart: date,
                effectiveDateEnd: moment(date, 'MMMM D, YYYY')
                  .add(1, 'years').subtract(1, 'days').format('MMMM D, YYYY'),
              })}}
              confirmBtnText={this.state.text.ok}
              cancelBtnText={this.state.text.cancel}
            />
            <Text style={formStyles.message}>{this.state.messages.effectiveDate}</Text>
          </View>
          {
            this.state.effectiveDateStart &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.periodOfInsurance}</Text>
                <View style={styles.periodOfInsurance}>
                  <Text>
                    {this.state.effectiveDateStart} - {this.state.effectiveDateEnd}
                  </Text>
                  <Text>{this.state.text.bothDatesInclusive}</Text>
                </View>
              </View>
          }
          {
            !this.state.businessEmailAddress &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.promotionCodes}</Text>
                <TextInput
                  style={formStyles.input}
                  placeholder={this.state.text.optional}
                  placeholderTextColor='lightgrey'
                  onChangeText={(text) => this.setState({promotionCodes: text})}
                  value={this.state.promotionCodes}
                />
                <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
              </View>
          }
          {
            !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.businessEmailAddress}</Text>
                <TextInput
                  style={formStyles.input}
                  placeholder={this.state.text.optional}
                  placeholderTextColor='lightgrey'
                  keyboardType='email-address'
                  onChangeText={(text) => this.setState({businessEmailAddress: text})}
                  value={this.state.businessEmailAddress}
                />
                <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
              </View>
          }
          <View style={formStyles.hr} />
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.policyholder}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.name}</Text>
            <NameInput
              title={this.state.policyholderTitle}
              firstName={this.state.policyholderFirstName}
              lastName={this.state.policyholderLastName}
              onChange={(title, firstName, lastName) => this.setState({
                policyholderTitle: title,
                policyholderFirstName: firstName,
                policyholderLastName: lastName,
              })}
            />
            <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
            <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
            <TextInput
              style={[formStyles.input,{height: 50,paddingLeft: 10,}]}
              onChangeText={(text) => this.setState({policyholderHKID: text})}
              value={this.state.policyholderHKID}
              placeholder='A1234567'
              placeholderTextColor='lightgrey'
              maxLength={8}
            />
            <View style={{textAlign: 'left'}}>
            <Text>{this.state.text.pleaseEnterAllHKID}</Text>
            </View>
            <Text style={formStyles.message}>{this.state.messages.policyholderHKID}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
            <DatePicker
              style={formStyles.datepicker}
              date={this.state.policyholderDOB}
              mode='date'
              placeholder={this.state.text.pleasePickADate}
              format='MMMM D, YYYY'
              minDate={'January 2, 1900'}
              maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
              onDateChange={(date) => {this.setState({policyholderDOB: date})}}
              confirmBtnText={this.state.text.ok}
              cancelBtnText={this.state.text.cancel}
            />
              <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({policyholderPhone: text})}
              value={this.state.policyholderPhone}
              keyboardType='numeric'
              maxLength={8}
            />
            <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.correspondenceAddress}</Text>
            <TextInput
              style={formStyles.input}
              placeholder={this.state.text.optional}
              placeholderTextColor='lightgrey'
              value={this.state.policyholderAddress}
              onChangeText={(text) =>
                this.setState({policyholderAddress: text})}
            />
            <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({policyholderEmail: text})}
              value={this.state.policyholderEmail}
              keyboardType='email-address'
            />
            <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
          </View>
          <View style={formStyles.hr} />
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.home}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.address}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({propertyAddress: text})}
              value={this.state.propertyAddress}
              placeholder={this.state.text.hongKongAddressOnly}
              placeholderTextColor='lightgrey'
            />
            <Text style={formStyles.message}>{this.state.messages.propertyAddress}</Text>
          </View>
          {
            this.state.centaline ?
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.size}</Text>
                <Text>{this.state.text.pleasChoose}</Text>
                <ModalSelector
                  data={this.state.grossAreas}
                  initValue={this.state.defaultGrossAreaLabel}
                  onChange={(option) => this.setState({propertySize: option.key})}
                  cancelText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>{this.state.messages.propertySize}</Text>
              </View> :
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.size}</Text>
                <Text>{this.state.text.pleasChoose}</Text>
                <ModalSelector
                  data={this.state.grossAreas}
                  initValue={this.state.defaultGrossAreaLabel}
                  onChange={(option) => this.setState({propertySize: option.key})}
                  cancelText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>{this.state.messages.propertySize}</Text>
              </View>
          }
          {
            this.state.propertySize == (this.state.centaline ? 12 : 6) &&
              <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
          }

          <View style={{textAlign: 'left'}}>
            <Text>{this.state.text.convertNetFloorArea}</Text>
            <Text>{this.state.text.forExample}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.houseOrApartment}</Text>
            <ModalSelector
              data={[
                { key: 'H', label: options.H.label[this.state.language] },
                { key: 'A', label: options.A.label[this.state.language] },
              ]}
              initValue={this.state.propertyType ?
                options[this.state.propertyType].label[this.state.language] :
                this.state.text.pleaseChoose}
              onChange={(option) => this.setState({propertyType: option.key})}
              cancelText={this.state.text.cancel}
            />
            {
              this.state.propertyType =='H' &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
            <Text style={formStyles.message}>{this.state.messages.propertyType}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.theHomeIsUnoccupiedForLonger30Days}
              choice={this.state.propertyUnoccupied}
              onChange={(choice) => this.setState({propertyUnoccupied: choice})}
            />
            {
              this.state.propertyUnoccupied =='Y' &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
            <Text style={formStyles.message}>{this.state.messages.propertyUnoccupied}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.anyLossDamageLiabilityOrAccident}
              choice={this.state.propertyLoss}
              onChange={(choice) => this.setState({propertyLoss: choice})}
            />
            {
              this.state.propertyLoss =='Y' &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
            <Text style={formStyles.message}>{this.state.messages.propertyLoss}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.theHomeBuildingIsMoreThan40years}
              choice={this.state.propertyAged}
              onChange={(choice) => this.setState({propertyAged: choice})}
            />
            {
              this.state.propertyAged =='Y' &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
            <Text style={formStyles.message}>{this.state.messages.propertyAged}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.anyInsuranceCompnayDeclined}
              choice={this.state.propertyDeclined}
              onChange={(choice) => this.setState({propertyDeclined: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.propertyDeclined}</Text>
            {
              this.state.propertyDeclined =='Y' &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
          </View>

          <View style={formStyles.hr} />
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({specialRequests: text})}
              value={this.state.specialRequests}
              placeholder={this.state.text.optional}
              placeholderTextColor='lightgrey'
            />
            <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
            {
              !!this.state.specialRequests &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.referral}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({referral: text})}
              value={this.state.referral}
              placeholder={this.state.text.optional}
              placeholderTextColor='lightgrey'
            />
            <Text style={formStyles.message}>{this.state.messages.referral}</Text>
          </View>
          {
            this.state.submitButtonDisabled ?
              <View style={formStyles.submitButtonDisabled}>
                <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
              </View> :
              <TouchableOpacity style={formStyles.submitButton} onPress={() => this._nextScreen()}>
                <Text style={formStyles.submitButtonText}>{this.state.text.buyNow}</Text>
              </TouchableOpacity>
          }
        </ScrollView>

        </Grid>
          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
       </Grid>

        <Footer/>
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
