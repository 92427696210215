import React from 'react';
import {
    Text, View, ScrollView, TouchableOpacity,
  } from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { options } from './options';
import { planTypes } from './planTypes';
import { formStyles } from './../../../config/styles';
import { addCommas, removeSpaces } from './../../../lib/utils';
import { stateStorage } from './../../../lib/StateStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import Alert  from './../../../components/Alert/Alert';
import Navbar from './../../../components/Navbar/Navbar';
import ProductDetailFolder from './../../../components/ProductDetailFolder/ProductDetailFolder';
import TotalCostSection from './../../../components/TotalCostSection/TotalCostSection';
import Footer from './../../../components/Footer/Footer';

export default class FireInsurance2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state.broker, // BROKER
      planType: this.props.location.state.planType,
      sumInsuredAmount: this.props.location.state.sumInsuredAmount,
      buildingAge: this.props.location.state.buildingAge,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
      premium: this.props.location.state.premium,
      referral: this.props.location.state.referral,
      text: {},
    };
  }


  _isLogin(){

    if (this.state.member) {
      this._nextScreen();
    }else{
      this.setState({
        alert:
          Alert.alert(
            this.state.text.forBetterCustomerExperiencelogin,
            this.state.text.forBetterCustomerExperience,
            [{
              text: this.state.text.forBetterCustomerExperiencelogin,
              onPress: () => this.props.history.push('Login'),
            },{
              text: this.state.text.forBetterCustomerExperiencregister,
              onPress: () => this.props.history.push('Register'),
            },
            {
              text: this.state.text.forBetterCustomerExperienceCon,
              onPress: () => this._nextScreen(),
            }],

          )
      });

  }
  }


  _nextScreen() {
    delete this.state.alert;
    stateStorage.setState('fire2', this.state);
    stateStorage.setState('fire3', undefined);

    this.props.history.push('/fire3', {
      broker: this.state.broker, // BROKER
      planType: this.state.planType,
      buildingAge: this.state.buildingAge,
      sumInsuredAmount: this.state.sumInsuredAmount,
      promotionCodes: this.state.promotionCodes,
      businessEmailAddress: this.state.businessEmailAddress,
      showBusinessEmailAddress: this.state.showBusinessEmailAddress,
      referral: this.state.referral,
    });
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('fire2', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }));

        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }))
  }

  render() {
    let planDetails = [
      {
        key: this.state.text.sumInsuredAmount,
        value: 'HK$' + addCommas(this.state.sumInsuredAmount),
      },
      {
        key: this.state.text.ageOfBuilding,
        value: options.buildingAge[this.state.buildingAge].label[this.state.language],
      },
    ];
    if (this.state.promotionCodes) {
      planDetails.push(
        {
          key: this.state.text.promotionCodes,
          value: this.state.promotionCodes,
        }
      );
    }
    if (this.state.businessEmailAddress) {
      planDetails.push(
        {
          key: this.state.text.businessEmailMembership,
          value: this.state.businessEmailAddress,
        }
      );
    }

    return (
      <div className='App'>
        <Helmet>
          <title>Fire Insurance | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />
        {this.state.alert}

          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
              <Hidden xsDown>
                  <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                      <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                          <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.fire}</Text>
                          <p></p>
                         <Text style={[styles.greetingText,{fontSize: 20}]}>{this.state.text.duetofire}</Text>
                      </View>

                  </Grid>
              </Hidden>
          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>


          <ScrollView style={styles.form,styles.fie}>
            <View style={styles.fieldset}>
          <Text style={styles.h4}>{this.state.text.IWouldLikeToBuy}</Text>
          <ProductDetailFolder
            label={planTypes[this.state.planType].label}
            color={planTypes[this.state.planType].color}
            backgroundColor={planTypes[this.state.planType].backgroundColor}
            planDetails={planDetails}
          />
          <TotalCostSection premium={this.state.premium} />
        <TouchableOpacity style={styles.submitButton} onPress={() => this._isLogin()}>
          <Text style={styles.submitButtonText}>{this.state.text.buyNow}</Text>
        </TouchableOpacity>
          </View>
          </ScrollView>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
         </Grid>

        <Footer/>

    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  h4: {
    marginTop: 20,
    marginBottom: 20,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
