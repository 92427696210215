import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { memberStorage } from './MemberStorage';
import { msgCodeDetail, handleExpiredLogin, hmacSha256 } from './utils';
import { host, api14, api15, api18, busiSecKey } from './../config/api';
import Alert from './../components/Alert/Alert';

export default class InsuranceAdvisors {
  getInsuranceAdvisors(language, navigation, callback, onExpiredLogin) {
    memberStorage.getMember((member, token) => {
      if (!member) {
        return;
      }
      let data = JSON.stringify({
        'member.id': member.id,
        'member.token': token.tokenStr,
        'timeStamp': Date.now(),
      });
      const hmac = hmacSha256(data);
      fetch(host + api14, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0) {
          callback && callback(responseJson.msgDesc.plans);
        }
        else if (responseJson.msgCode == 12) {
          onExpiredLogin &&
            onExpiredLogin(handleExpiredLogin(language, navigation));
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
      })
      .catch((error) => {
        alert('Facing error when getting your insurance advisors!');
      });
    });
  }

  setInsuranceAdvisor(
    insuranceAdvisor, products, language, promotionCodes, callback, dismissAlert) {
    memberStorage.getMember((member, token) => {
      if (!member) {
        return;
      }
      const data = JSON.stringify({
        'member.id': member.id,
        'member.token': token.tokenStr,
        'planTypes': products,
        'agentCode': insuranceAdvisor.agentCode,
        'agentMobile': insuranceAdvisor.agentMobile,
        'promotionCode': promotionCodes,
        'timeStamp': Date.now(),
      });
      const hmac = hmacSha256(data);
      fetch(host + api15, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.msgCode == 0) {
          callback && callback();
        }
        else if (responseJson.msgCode == 10 || responseJson.msgCode == 53) {
          callback(
            Alert.alert(
              language == 'enUS' ? 'Binding Failed' : '綁定失敗',
              language == 'enUS' ? 'Invalid mobile number.' : '無效的手機號碼。',
              [{
                text: language == 'enUS' ? 'OK' : '確認',
                onPress: () => dismissAlert && dismissAlert(),
              }]
            )
          );
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
      })
      .catch((error) => {
        alert('Facing error when setting your insurance advisor!');
      });
    });
  }

  removeInsuranceAdvisor(product, language, callback) {
    memberStorage.getMember((member, token) => {
      if (!member) {
        return;
      }
      let data = JSON.stringify({
        'member.id': member.id,
        'member.token': token.tokenStr,
        'planTypes': [product],
        'timeStamp': Date.now(),
      });
      const hmac = hmacSha256(data);
      fetch(host + api18, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.msgCode == 0) {
          callback && callback(true);
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [language == 'zhHK' ? 'desc_zh' : 'desc_en']);
        }
      })
      .catch((error) => {
        alert('Facing error when removing your insurance advisor!');
      });
    });
  }
}

export const insuranceAdvisors = new InsuranceAdvisors();
