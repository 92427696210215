export const enUS = {
  getAQuote: 'Get a Quote',
  annualStudyAbroadProtector: 'Annual Study Abroad Protector',
  secondarySchoolNUniversityIsATime: 'Secondary school and university is a time for exploration, and many young students will use that time to pursue the opportunity of studying overseas. With the Generali Annual Study Abroad Protector, you can make sure that your child’s time spent studying overseas is as stress-free as possible.',

  productEdges:'Product Edges',
  highMedicalExpenses: 'High Medical Expenses up to HK$1,500,000 (including the illness of COVID-19)',
  hospitaliztionBenefitsInHK: 'Hospitalization Benefits in Hong Kong (for newly diagnosed illness or injury) ',
  twelveMonthsConsecutiveCover: '12 months consecutive cover',
  optionalCoverForAccompanied: 'Optional Cover for Accompanied Parents',
  zeroExcess: 'Zero excess',

  forDetailsOfTheProduct:'For details of the product, exclusions and premium rates, please refer to Product Leaflets and Policy Provisions ',

  planType: 'Plan Type',
  withAccompanying: 'With accompanying father, mother or legal guardian',
  withoutAccompanying: 'Without accompanying father, mother or legal guardian',
  pleaseInputInEnglish: '* Please input in English *',

  locationOfTheOverseasStudyingInstitution:'Location of The Overseas Studying Institution',
  usaCanada: 'USA / Canada',

  promotionCodes: 'Promotion Codes',
  optional: 'Optional',
  businessEmailAddress: 'Business Email / Membership',
  businessEmailMembership: 'Business Email\n/ Membership',
  getAQuote: 'Get a Quote',

  iWouldLikeToBuy: 'I would like to buy',
  total: 'Total',
  levyIsNotIncluded: 'Levy is not included',
  buyNow: 'Buy Now',

  productType: 'Product Type',
  effectiveDate: 'Effective Date',
  pleasePickADate: 'Please pick a date',

  policyHolder: 'Policyholder',
  thePolicyHolderMustBeThe: 'The policyholder must be the student‘s father, mother or legal guardian.',
  name: 'Name',
  title: 'Title',
  mr: 'Mr.',
  ms: 'Ms.',
  mrs: 'Mrs.',
  miss: 'Miss',
  firstName: 'First name',
  lastName: 'Last name',

  hkid: 'HKID',
  pleaseEnterAllCharacters: 'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
  dateOfBirth: 'Date of Birth',
  mobileNumber: 'Mobile Number',
  correspondenceAddress: 'Correspondence Address',
  emailAddress: 'Email Address',

  insuredStudent: 'Insured Student',
  overseasStudyingInstitution: 'Oversea Studying Institution',
  location: 'Location',
  address: 'Address',
  specialRequests: 'Special Requests',
  referral: 'Referral Code',
  thisFieldCannotBeBlank: 'This field cannot be blank',

  importantNotes: 'Important Notes:',
  thePolicyHolderShallBe: 'The policyholder shall be the mother, father or legal guardian of the insured student.',
  thisPolicyExcludes: 'This Policy excludes to cover the Insured Person performing job duties of Hazardous Occupation (Please refer definition in our policy jacket).',
  theInsuredShallBe: 'The insured(s) shall be in good health and agree that any pre-existing conditions will not be covered under this insurance. Furthermore, obtaining medical treatment is not a purpose of this trip. All coverages are subject to the terms and conditions of the Policy.',
  assicurazioniGeneraliReservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  iWeCertifyGeneraliAsap: 'I/We certify that I/We have read and agreed to the Important Notes of GENERALI ASAP Insurance.',
  iWeCertifyDisclaimerNPersonalInformation: 'I/We certify that I/We have read and agreed to the Disclaimer and Personal Information Collection Statement.',
  iWeUnderstand: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay the authorized insurance broker commission and provide my/our personal data to the said broker during the continuance of the policy for arranging and managing the said policy.',
  levy: 'Levy',
  confirm: 'Confirm',
  payment: 'Payment',
  cardNumber: 'Card Number',
  expi: 'EXPI',
  mm: 'MM.',
  submit: 'Submit',

  youHave: 'You have ',
  eCashNow: 'Generali voucher now. Would you want to use it?',

  iWeDoNotConsentPDto:'I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentPDToCompanyMarketing:'I/ We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  brokerDeclaration: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay ',
  brokerDeclarationII:' commission and provide my/our personal data to the said broker/agent for arranging and managing the said policy.',

  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  periodOfInsurance: 'Period of Insurance',
  bothDatesInclusive: '(both dates inclusive)',
  optionalBenefit: 'Optional Benefit',
  thereIsAnOptional: 'There is an optional benefit for accompanied father, mother or legal guardian.',
  personToBeInsured: 'Person to be Insured',
  sameAsThePolicyholder: 'Same as the policyholder',
  otherPerson: 'Other person',
  pleaseChoose: 'Please choose',
  relationshipWithThePolicyholder: 'Relationship with the Policyholder',
  other: 'Other',
  spouse: 'Spouse',
  otherCountry: 'Other country',
  insuredName: 'Insured Name',
  insuredHKID: 'Insured HKID',
  insuredDateOfBirth: 'Insured Date of Birth',
  pleaseWait: 'Please Wait',
  manualUnderwritingIsRequired:'* Manual underwriting is required *',
  getInsured: 'Get Insured',
  invalidPromotionCode: 'Invalid promotion code',
  addInsuranceAdvisor: 'Add Insurance Advisor',
  wouldYouLike: 'Would you like ',
  toBeYourInsuranceAdvisor: ' to be your insurance advisor?',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  needsToBeInEnglish: 'Needs to be in English',
  thankYou: 'Thank You',
  youHaveSuccessfully: 'You have successfully submitted your application of this ',
  ccccc: '.',
  manualUnderwriting: ' Manual underwriting is needed and we will get back to you within two business days.',
  inCaseOfAnyQuestions: ' In case of any questions, you can chat with us in the app in section Roar Us.',
  instituteName: 'Name',
  check: 'Please check your inputs again.',
  cancel: 'cancel',
  theInsuranceIsIssuedByContent:'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',
  theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
  professionalInsurance: '.',

  yes:'Yes',
  no:'No',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  alert: 'Alert',
  ok: 'OK',
  submissionFailed: 'Submission Failed',
  invalidMobileNumber: 'Invalid insurance advisor mobile number.',
  tryAgain: 'Our system is temporarily not available, please try again later. ',

  congratulations: 'Congratulations',
  madeAPurchase: 'You have successfully made a purchase. ',
  weWillSend: 'We will send your policy schedule and policy provisions to you via email ',
  anyQuestions: '. If you have any questions, please contact us.\nThank you.',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  asap: 'Annual Study Abroad Protector',
  ensure: 'Ensure your child is safe as they embark on a new chapter of life.',
   forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
  forBetterCustomerExperiencelogin:'Log in',
  forBetterCustomerExperiencregister:'Register',
  forBetterCustomerExperienceCon:'Continue without log in',

  //special case
  paymentStopService1:'Dear Valued Clients,',
  paymentStopService2:'Please be advised that due to service maintenance, purchase attempt via BRAVO during 8 July 23:00 to 9 July 23:00 will be temporarily suspended. We apologize for any inconvenience this may have caused you.',

}
