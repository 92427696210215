import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { addCommas } from './../../lib/utils';
import { languageStorage } from './../../lib/LanguageStorage';

export default class PaymentBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        text: getText(language),
        language: language,
      }));
  }

  getDiscountPercentage(original, discount) {
    return Math.round((1000 - Math.round(discount / original * 1000)) / 10);
  }

  round(number) {
    return Math.round(number * 100) / 100;
  }

  render() {
    if (!this.props.manualUnderwriting && !this.props.premium) {
      return (
        <View />
      );
    }

    if (this.props.manualUnderwriting) {
      return (
        <View style={[styles.payment, {marginTop: 20}]}>
          <View style={styles.paymentItem}>
            <View style={styles.paymentLabelCentered}>
              <Text style={styles.paymentLabelText}>
                {this.state.text.manualUnderwriting}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    const original = this.props.premium.original;
    const discount = this.props.premium.discount;
    const redeemed = this.props.premium.redeemed;

    return (
      <View>
        {
          original > discount || redeemed ?
            <View style={styles.paymentItem}>
              <View style={styles.paymentNumber}>
                <Text style={styles.paymentNumberText}>
                  {
                    original > discount ?
                      this.getDiscountPercentage(original, discount) +
                        '% Off' + (this.props.groupDiscount ? ' *' : '') :
                      this.state.text.less + ' $' + (original - redeemed)
                  }
                </Text>
              </View>
            </View> :
            <View style={{marginTop: 20}} />
        }

        <View style={styles.payment}>
          <View style={styles.paymentItem}>
            <View style={styles.paymentLabel}>
              <Text style={styles.paymentLabelText}>
                {this.state.text.premium}
              </Text>
            </View>

            {
              (original > discount || redeemed) &&
                <View style={styles.premiumNumber}>
                  <Text style={styles.originalNumberText}>
                    ${addCommas(this.round(original))}
                  </Text>
                </View>
            }

            <View style={styles.premiumNumber}>
              <Text style={styles.paymentNumberText}>
                ${addCommas(this.round(
                  redeemed && !this.props.groupDiscount ? redeemed : discount))}
              </Text>
            </View>
          </View>

          {
            this.props.ecLevy &&
              <View style={styles.paymentItem}>
                <View style={styles.paymentLabel}>
                  <Text style={styles.paymentLabelText}>
                    {this.state.text.ecLevy}
                  </Text>
                </View>

                <View style={styles.paymentNumber}>
                  <Text style={styles.paymentNumberText}>
                    ${addCommas(this.round(this.props.ecLevy))}
                  </Text>
                </View>
              </View>
          }

          {
            this.props.terrorCharge &&
              <View style={styles.paymentItem}>
                <View style={styles.paymentLabel}>
                  <Text style={styles.paymentLabelText}>
                    {this.state.text.terrorCharge}
                  </Text>
                </View>

                <View style={styles.paymentNumber}>
                  <Text style={styles.paymentNumberText}>
                    ${addCommas(this.round(this.props.terrorCharge))}
                  </Text>
                </View>
              </View>
          }

          {
            this.props.groupDiscount && redeemed &&
              <View style={styles.paymentItem}>
                <View style={styles.paymentLabel}>
                  <Text style={styles.paymentLabelText}>
                    {this.state.text.flatDiscount}
                  </Text>
                </View>

                <View style={styles.paymentNumber}>
                  <Text style={styles.paymentNumberText}>
                    -${addCommas(this.round(discount - redeemed))}
                  </Text>
                </View>
              </View>
          }

          <View style={styles.paymentItem}>
            <View style={styles.paymentLabel}>
              <Text style={styles.paymentLabelText}>
                {this.state.text.levy}
              </Text>
            </View>

            <View style={styles.paymentNumber}>
              <Text style={styles.paymentNumberText}>
                ${addCommas(this.round(this.props.levy))}
              </Text>
            </View>
          </View>

          <View style={styles.paymentItem}>
            <View style={styles.paymentLabel}>
              <Text style={styles.paymentLabelText}>
                {this.state.text.total}
              </Text>
            </View>

            <View style={styles.paymentNumber}>
              <Text style={styles.paymentNumberText}>
                ${addCommas(this.round(
                  this.props.total - (redeemed ? discount - redeemed : 0)))}
              </Text>
            </View>
          </View>
        </View>

        {
          this.props.groupDiscount &&
            <View style={styles.groupDiscount}>
              <Text style={styles.paymentNumberText}>
                *{this.state.text.roundedToTheNearestPercentage}
              </Text>
            </View>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  payment: {
    flex: 1,
    marginBottom: 20,
    borderTopWidth: 1,
    borderTopColor: '#C50000',
    borderBottomWidth: 1,
    borderBottomColor: '#C50000',
    borderLeftWidth: 1,
    borderLeftColor: '#C50000',
    borderRightWidth: 1,
    borderRightColor: '#C50000',
  },
  paymentItem: {
    flexDirection: 'row',
    height: 40,
  },
  paymentLabel: {
    flex: 3,
    justifyContent: 'center',
    marginLeft: 20,
  },
  paymentLabelCentered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentLabelText: {
    color: '#C50000',
    fontSize: 16,
    textAlign: 'left',
  },
  paymentNumber: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  paymentNumberText: {
    color: '#C50000',
    fontSize: 16,
  },
  premiumNumber: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  originalNumberText: {
    color: '#C50000',
    fontSize: 16,
    textDecorationLine:  'line-through',
  },
  levy: {
    alignItems: 'flex-end',
    marginBottom: 10,
  },
  groupDiscount: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: -3,
  },
});
