import React from 'react';
import {
  Text, TextInput, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { getEcash, formatName, msgCodeDetail, removeSpaces, hmacSha256 } from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { host, api38, api310, busiSecKey } from './../../../config/api';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import Member from './../../../Member';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink.js';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import PaymentBreakdown from './../../../components/PaymentBreakdown/PaymentBreakdown';
import DirectMarketingTickBox from './../../../components/DirectMarketingTickBox/DirectMarketingTickBox';

export default class EPA4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state.broker,
      insuranceAdvisor: this.props.location.state.insuranceAdvisor,
      insuranceAdvisorMobile: this.props.location.state.insuranceAdvisorMobile,
      product: this.props.location.state.product,
      planType: this.props.location.state.planType,
      effectiveDateStart: this.props.location.state.effectiveDateStart,
      effectiveDateEnd: this.props.location.state.effectiveDateEnd,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      specialRequests: this.props.location.state.specialRequests,
      referral: this.props.location.state.referral,
      policyholderTitle: this.props.location.state.policyholderTitle,
      policyholderFirstName: this.props.location.state.policyholderFirstName,
      policyholderLastName: this.props.location.state.policyholderLastName,
      policyholderDOB: this.props.location.state.policyholderDOB,
      policyholderPhone: this.props.location.state.policyholderPhone,
      policyholderAddress: this.props.location.state.policyholderAddress,
      policyholderEmail: this.props.location.state.policyholderEmail,
      policyholderHKID: this.props.location.state.policyholderHKID,
      isInsured: this.props.location.state.isInsured,
      insuredTitle: this.props.location.state.insuredTitle,
      insuredName: this.props.location.state.insuredName,
      insuredDOB: this.props.location.state.insuredDOB,
      insuredHKID: this.props.location.state.insuredHKID,
      insuredOccupation: this.props.location.state.insuredOccupation,
      question1: this.props.location.state.question1,
      question1Insurer: this.props.location.state.question1Insurer,
      question1Benefit: this.props.location.state.question1Benefit,
      question1SumInsured: this.props.location.state.question1SumInsured,
      question1Reason: this.props.location.state.question1Reason,
      question1Condition: this.props.location.state.question1Condition,
      question2: this.props.location.state.question2,
      question2SufferedArea: this.props.location.state.question2SufferedArea,
      question2Diagnosis: this.props.location.state.question2Diagnosis,
      members: this.props.location.state.members,
      rawRequest: this.props.location.state.rawRequest,
      premium: this.props.location.state.premium,
      levy: this.props.location.state.levy,
      total: this.props.location.state.total,
      messages: {},
      text: {},
    };
    const firstInsuredDOB =
      moment(this.state.members[0].dateOfBirth, 'YYYY-MM-DD');
    this.state.manualUnderwriting =
      firstInsuredDOB.add(65, 'years').diff(moment()) < 0
      || this.state.members[0].occupation == 'NONCLERICAL'
      || (this.state.members[1] && this.state.members[1].occupation == 'NONCLERICAL')
      || (this.state.members[2] && this.state.members[2].fulltimeStudent == 'N')
      || (this.state.members[3] && this.state.members[3].fulltimeStudent == 'N')
      || this.state.question1 == 'Y'
      || this.state.question2 == 'Y'
      || this.state.specialRequests

    const details = this.state.broker ?
      AgentAPI.details : ProductAPI.details;

    details(
      {
        'pn': 'EAPP',
        'pl': 'EPA',
        'member.id': Member.getMemberId(),
        'member.token': Member.getMemberToken(),
        'payment.promotionCode': this.state.promotionCodes,
      },
      ({plan}) => {
        this.setState({planBroker: plan.planBroker});
      }
    );
  }


  handleLeavePage(e) {
    const confirmationMessage = 'Some message';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }
  
 
//   componentWillUnmount() {
//     window.removeEventListener("beforeunload", this.handleLeavePage);
//  }

  componentDidMount() {
    // window.addEventListener('beforeunload', this.handleLeavePage);
    // if (localStorage.getItem('preventGoBack') == 'true') {
    //   window.location = '/';
    //   return;
    // }

    stateStorage.getState('epa3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init member and token
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init ecash
        getEcash(
          this.state.language,
          this.props.navigation,
          (ecash) => this.setState({ecash: ecash})
        );
      })
    );
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _confirm() {
    if (this.state.submitButtonDisabled) {
      return;
    }
    this.setState({submitButtonDisabled: true});

    let attributes = {
      declaration2: this.state.declaration2,
      declaration3: this.state.declaration3,
      declaration4: this.state.declaration4,
    }

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    }

    let constraints = {
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
    }

    if (
      this.state.insuranceAdvisor ||
      this.state.broker ||
      this.state.promotionCodes
    ) {
      attributes.brokerDeclaration = this.state.brokerDeclaration;
      constraints.brokerDeclaration = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      };
    }

    if (
      !this.state.broker &&
      !this.state.manualUnderwriting &&
      this.state.ecash > 0
    ) {
      attributes.usingEcash = this.state.usingEcash;
      constraints.usingEcash = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
      return;
    }
    else {
      let rawRequest = this.state.rawRequest;
      rawRequest.declaration2 = this.state.declaration2;
      rawRequest.declaration3 = this.state.declaration3;
      rawRequest.declaration4 = this.state.declaration4;
      rawRequest.brokerDeclaration = this.state.brokerDeclaration;
      rawRequest.usingEcash = this.state.usingEcash;
      delete rawRequest.planBroker;

      const fields = {
        'pn': 'EAPP',
        'pl': 'EPA',
        'agent.mobile': this.state.insuranceAdvisorMobile,
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.effectiveDateEnd': this._apiDateFormat(this.state.effectiveDateEnd),
        'policy.allowPrivacyPromote3P': this.state.declaration3,
        'policy.allowPrivacyPromote': this.state.declaration4,
        'policy.businessEmail': removeSpaces(this.state.businessEmailAddress),
        'policy.specialRequest': this.state.specialRequests,
        'policyHolder.salutation': this.state.policyholderTitle,
        'policyHolder.firstName': this.state.policyholderFirstName,
        'policyHolder.lastName': this.state.policyholderLastName,
        'policyHolder.mobile': this.state.policyholderPhone,
        'policyHolder.email': this.state.policyholderEmail,
        'policyHolder.documentType': 'HKID',
        'policyHolder.documentNo': this.state.policyholderHKID,
        'policyHolder.occupation': this.state.insuredOccupation,
        'epa.premiumPlan': this.state.planType == 'individual' ? 'I' : 'F',
        'epa.insuranceDeclined': this.state.question1,
        'epa.declineRemarks': this.state.question1condition,
        'epa.hasImpairment': this.state.question2,
        'epa.diagnosis': this.state.question2diagnosis,
        'members': this.state.members,
        'rawRequest': JSON.stringify(rawRequest),
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      if (this.state.usingEcash == 'Y') {
        fields['payment.ecash'] =
          this.state.ecash > this.state.total ?
          this.state.total : this.state.ecash;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api38, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.msgCode == 0) {
          if (this.state.broker && !this.state.manualUnderwriting) {
            this.setState({alert:
              Alert.alert(
                this.state.text.thankYou,
                this.state.text.youHaveSuccessfully +
                this.state.text.executivePersonalAccidentProtector +
                this.state.text.ccccc,
                [{
                  text: this.state.text.ok,
                  style: 'cancel',
                  onPress: () => this.props.history.push('/'),
                }]
              )
            });
          }
          else if (this.state.manualUnderwriting) {
            this.setState({alert:
              Alert.alert(
                this.state.text.thankYou,
                this.state.text.youHaveSuccessfully +
                this.state.text.executivePersonalAccidentProtector +
                this.state.text.ccccc +
                this.state.text.manualUnderwriting +
                this.state.text.inCaseOfAnyQuestions,
                [{
                  text: this.state.text.ok,
                  style: 'cancel',
                  onPress: () => this.props.history.push('/'),
                }]
              )
            });
          }
          else if (
            this.state.usingEcash == 'Y' &&
            this.state.total - this.state.ecash <= 0
          ) {
            this.setState({alert:
              Alert.alert(
                this.state.text.congratulations,
                this.state.text.madeAPurchase +
                this.state.text.weWillSend +
                this.state.policyholderEmail +
                this.state.text.anyQuestions,
                [{
                    text: this.state.text.ok,
                    onPress: () => this.props.history.push('/'),
                }]
              )
            });
          }
          else {

            stateStorage.setState('epa4', this.state);

            this.props.history.push('Payment', {
              uri: host + api310 +
                '?txId=' + responseJson.msgDesc.txId + '&' +
                'locale=' + (this.state.language == 'zhHK' ? 'zh-HK' : 'en-US'),
              policyNumber: responseJson.msgDesc.policyNo,
              email: this.state.policyholderEmail,
              recaptcha: !this.state.member,
            });
          }
        }
        else if (this.state.broker && responseJson.msgCode == 53) {
          this.setState({alert:
          Alert.alert(this.state.text.submissionFailed,
            this.state.text.invalidMobileNumber,
            [{text: this.state.text.ok}]
          )
        });
        }
        else if(responseJson.msgCode==180){
          this.setState({alert: Alert.alert(this.state.text.submissionFailed,
            this.state.text.tryAgain,
            [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}]
          )});
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
        this.setState({submitButtonDisabled: false});
      })
      .catch((error) => {
        alert('Facing error when purchasing this Executive Personal Accident Protector! '
          + error);
        this.setState({submitButtonDisabled: false});
      });
    }
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    let insureds = [];
    this.state.members.forEach((member) => {
      let relationship;
      switch (member.relationship) {
        case 'insured':
          relationship = this.state.text.insured ;
          break;
        case 'spouse':
          relationship = this.state.text.spouse ;
          break;
        case 'child':
          relationship = this.state.text.child ;
          break;
      }
      insureds.push(
        <View key={member.documentNo}>
          <View style={formStyles.fieldset}>
            <Text style={[formStyles.h3, {color}]}>{relationship}</Text>
          </View>
          <View key={member.firstName}>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.name}</Text>
              <Text>
                {formatName(member.salutation, member.firstName, member.lastName)}
              </Text>
            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
              <Text>
                {moment(member.dateOfBirth, 'YYYY-MM-DD').format('MMMM D, YYYY')}
              </Text>
            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.hkid}</Text>
              <Text>{member.documentNo}</Text>
            </View>

            {
              member.occupation &&
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.occupationClericalAndNonManualWork}</Text>
                  <Text>{member.occupation == 'CLERICAL' ? this.state.text.yes : this.state.text.no }</Text>
                </View>
            }

            {
              member.fulltimeStudent &&
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.fullTimeStudentUnmarriedAndUnemployed}</Text>
                  <Text>{member.fulltimeStudent == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
                </View>
            }
          </View>
        </View>
      );
    });

    var name = '';
    if(this.state.planBroker)
       name = this.state.planBroker.nameEnUs

    return (
      <div className='App'>
        <Helmet>
          <title>Executive Personal Accident Protector | Bravo Generali</title>
        </Helmet>

            <Navbar
                title={this.state.text.getInsured}
                backButton
                history={this.props.history}
              />

              <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
              <Hidden xsDown>
                <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                    <View style={[styles.greeting,{fontWeight:'bold',}]}>
                      <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.epa}</Text>
                      <p></p>
                      {
                        this.state.language == 'zhHK' ?
                        <View>
                        <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                        </View>:
                        <View>
                        <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                        </View>

                      }

                    </View>

                </Grid>
                </Hidden>

                <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
               <ScrollView style={formStyles.form}>
               {this.state.alert}
               <View style={formStyles.fieldset}>
                 <Text style={[formStyles.h2, {color}]}>{this.state.text.executivePersonalAccidentProtector}</Text>
               </View>

               <View>
               <View style={styles.font}>
                 {
                   this.state.broker &&
                     <View style={formStyles.fieldset}>
                       <Text style={formStyles.bold}>{this.state.text.insuranceAdvisorMobile}</Text>
                       <Text>{this.state.insuranceAdvisorMobile}</Text>
                     </View>
                 }

                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.productType}</Text>
                   <Text>{this.state.planType == 'individual' ? this.state.text.individualPlan : this.state.text.familyPlan }</Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.effectiveDate}</Text>
                   <Text>{this.state.effectiveDateStart} - {this.state.effectiveDateEnd}</Text>
                 </View>

                 {
                   this.state.promotionCodes &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.promotionCodes}</Text>
                   <Text>{this.state.promotionCodes}</Text>
                 </View>
                 }
                 {
                   this.state.businessEmailAddress &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.businessEmailAddress}</Text>
                   <Text>{this.state.businessEmailAddress}</Text>
                 </View>
                 }

                 <View style={formStyles.fieldset}>
                   <Text style={[formStyles.h3, {color}]}>{this.state.text.policyholder}</Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.name}</Text>
                   <Text>
                   {formatName(
                     this.state.policyholderTitle,
                     this.state.policyholderFirstName,
                     this.state.policyholderLastName
                   )}
                   </Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
                   <Text>{this.state.policyholderDOB}</Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.hkid}</Text>
                   <Text>{this.state.policyholderHKID}</Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.mobileNumber}</Text>
                   <Text>{this.state.policyholderPhone}</Text>
                 </View>
                 {
                   this.state.policyholderAddress &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.correspondenceAddress}</Text>
                   <Text>{this.state.policyholderAddress}</Text>
                 </View>
                 }
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.emailAddress}</Text>
                   <Text>{this.state.policyholderEmail}</Text>
                 </View>

                 { insureds }

                 <View style={formStyles.fieldset}>
                   <Text style={[formStyles.h3, {color}]}>{this.state.text.questions}</Text>
                 </View>
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.bold}>{this.state.text.haveYourOrOtherCoveredMembersAPPOflife}</Text>
                   <Text>{this.state.question1 == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
                 </View>
               {
                 this.state.question1 == 'Y' &&
                  <View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.insurer}</Text>
                     <Text>{this.state.question1Insurer}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.benefit}</Text>
                     <Text>{this.state.question1Benefit}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.sumInsured}</Text>
                     <Text>{this.state.question1SumInsured}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.reason}</Text>
                     <Text>{this.state.question1Reason}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.condition}</Text>
                     <Text>{this.state.question1Condition}</Text>
                   </View>
                 </View>
               }
               <View style={formStyles.fieldset}>
                 <Text style={formStyles.bold}>{this.state.text.doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment}</Text>
                 <Text>{this.state.question2 == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
               </View>
               {
                 this.state.question2 == 'Y' &&
                  <View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.sufferedArea}</Text>
                     <Text>{this.state.question2SufferedArea}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.bold}>{this.state.text.diagnosis}</Text>
                     <Text>{this.state.question2Diagnosis}</Text>
                   </View>
                 </View>
               }
               {
                   this.state.specialRequests &&
                 <View style={formStyles.fieldset}>
                   <Text style={[formStyles.h3, {color}]}>{this.state.text.specialRequests}</Text>
                   <Text>{this.state.specialRequests}</Text>
                 </View>
               }
               {
                   false && this.state.referral &&
                 <View style={formStyles.fieldset}>
                   <Text style={[formStyles.h3, {color}]}>{this.state.text.referral}</Text>
                   <Text>{this.state.referral}</Text>
                 </View>
               }
               </View>

                 <View style={formStyles.fieldset}>
                   <ImportantNotes
                     color='#C50000'
                     backgroundColor='#F5F5F5'
                     importantNotes={[
                       {
                         number: 1,
                         text: this.state.text.beneficiaryWillBeTheLE,
                       },
                       {
                         number: 2,
                         text: this.state.text.assicurazioniGeneraliSpAReserves,
                       },
                      ]}
                   />
                 </View>

                 <View style={formStyles.fieldset, styles.fie}>

                 <View style={formStyles.fieldset}>
                   <LegalDocumentLink
                     product={this.state.text.executivePersonalAccidentProtector}
                     planBroker={this.state.planBroker}
                     history={this.props.history}
                     choice={this.state.declaration2}
                     onChange={(choice) => this.setState({declaration2: choice})}
                   />
                   <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <YesNoQuestion
                     question={this.state.text.iWeHerebyAuthorizedAnyLicensed}
                     choice={this.state.declaration3}
                     onChange={(choice) => this.setState({declaration3: choice})}
                   />
                   <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
                 </View>

                 <View style={formStyles.fieldset}>
                   <DirectMarketingTickBox
                     history={this.props.history}
                     choice={this.state.declaration4}
                     onChange={(choice) => this.setState({declaration4: choice})}
                   />
                   <Text style={formStyles.message}>{this.state.messages.declaration4}</Text>
                 </View>

                 {
             (this.state.insuranceAdvisor || this.state.broker || this.state.promotionCodes) &&
              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.brokerDeclaration+ 
                    name
                    +this.state.text.brokerDeclarationII}
                  choice={this.state.brokerDeclaration}
                  onChange={(choice) => this.setState({brokerDeclaration: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.brokerDeclaration}</Text>
              </View>
             }

                 {
                   this.state.manualUnderwriting ?
                     <PaymentBreakdown manualUnderwriting /> :
                     <View>
                       <View style={formStyles.fieldset}>
                         <Text style={[formStyles.h3, {color}]}>{this.state.text.payment}</Text>
                       </View>

                       {
                         !this.state.broker && this.state.ecash > 0 &&
                           <View style={formStyles.fieldset}>
                             <YesNoQuestion
                               question={this.state.text.youHave + this.state.text.eCashNow}
                               choice={this.state.usingEcash}
                               onChange={(choice) => this.setState({usingEcash: choice})}
                             />
                             <Text style={formStyles.message}>{this.state.messages.usingEcash}</Text>
                           </View>
                       }

                       <PaymentBreakdown
                         premium={this.state.premium}
                         levy={this.state.levy}
                         total={this.state.total}
                       />
                     </View>
                 }

                 {
                   this.state.submitButtonDisabled ?
                     <View style={formStyles.submitButtonDisabled}>
                       <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                     </View> :
                     <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._confirm()}>
                       <Text style={formStyles.submitButtonText}>{this.state.text.submit}</Text>
                     </TouchableOpacity>
                 }
                 </View>
               </View>
             </ScrollView>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
        <Footer/>
  </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
  },
  font:{
    margin: 'auto',
    minWidth: 300,
    maxWidth: 300,
    textAlign: 'left'
  },
};
