export const enUS = {
  title: 'Title',
  firstName: 'First Name',
  lastName: 'Last Name',
  mr: 'Mr.',
  mrs: 'Mrs.',
  miss: 'Miss',
  ms: 'Ms.',
  cancel: 'cancel',
}
