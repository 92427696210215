import { Platform } from 'react-native';

export const formStyles = {
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  h1: {
    fontSize: 30,
    marginTop: 10,
    color: '#C50000',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h3: {
    fontSize: 22,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    marginTop: 10,
    marginBottom: 10,
  },
  font: {
    fontSize: 16,
  },
  hr: {
    marginTop: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  fieldset: {
    fontSize: 28,
    marginTop: 5,
    marginBottom: 5,
  },
  fie: {
   margin: 'auto',
   minWidth: 300,
   maxWidth: 1200,
   paddingLeft: 20,
   paddingRight: 20,
  },
  input: {
    height: 50,
    borderBottomWidth: Platform.OS == 'android' ? 0 : 1,
    borderBottomColor: 'lightgrey',
    paddingLeft: 10,
    fontSize: 17.2,
    // fontWeight: 500,
  },
  attachment: {
    color: '#1F4E79',
    textDecorationLine: 'underline',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  calendarPicker: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#c7c8ca',
  },
  calendarPickerText: {
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  yesNoQuestion: {
    flex: 1,
    flexDirection: 'row',
  },
  yesNoQuestionSwitch: {
    flex: 1,
  },
  yesNoQuestionText: {
    flex: 5,
    textAlign: 'left',
  },
  addButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 40,
    marginRight: 40,
    backgroundColor: '#C50000',
  },
  addButtonText: {
    fontSize: 18,
    color: 'white',
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonDisabled: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'lightgrey',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
  message: {
    color: '#C50000',
  }
};
