import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

export default class IntroParagraph extends React.Component {
  render() {
    return (
      <View style={styles.introParagraph}>
        <Text style={styles.introParagraphText}>
          {this.props.children}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  introParagraph: {
    marginTop: 5,
    marginBottom: 5,
  },
  introParagraphText: {
    textAlign: 'justify',
  },
});
