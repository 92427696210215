import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import Locale from './../Locale';
import Member from './../Member';
import { Screen, Layout, Navbar } from './../Screen';
import { Form, Button } from './../Form';
import HistoryAPI from './../api/HistoryAPI';
import ScheduleAPI from './../api/ScheduleAPI';
import ProvisionAPI from './../api/ProvisionAPI';
import EcCardAPI from './../api/EcCardAPI';
import AgentAPI from './../api/AgentAPI';
import Loading from './../components/Loading';
import Attachment from './Attachment';

export default class PurchaseHistoryScreen extends Screen {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      broker: this.getParam("broker"),
      content: this.getParam("content"),
      includeChinaMedical: false,
    };
  }

  componentDidMount() {
    if (!Member.getMember()) {
      window.location = '/';
      return;
    }

    const policyNo = this.getParam('policyNo');
    const policyHolder = this.getParam('policyHolder');
    const { policy } = this.state.broker ? AgentAPI : HistoryAPI;

    policy(
      {
        'policyHolder.mobile': policyHolder.mobile,
        'member.id': Member.getMemberId(),
        'member.token': Member.getMemberToken(),
        'policy.policyNo': policyNo,
      },
      (msgDesc) => {
        this.setState(msgDesc);
        this.setState({ loading: false });
        console.log(msgDesc.travellers)
        if (msgDesc.travellers) {
          let isKeyPresent = msgDesc.travellers.some((el) => {
            if (el.hasOwnProperty("mainlandPermit")) return true;
          });
          this.setState({ includeChinaMedical: isKeyPresent });
        }
      },
      (msgCode, msgDesc, handleDefault) => {
        if (msgCode == 55) {
          this.alert(
            Locale.text.accessDenied,
            Locale.text.cannotBeAccessed,
            [{
              text: Locale.text.ok,
              onPress: () => this.goBack(),
            }],
          );
        }
        else {
          this.goBack();
          handleDefault();
        }
      }
    );
  }

  downloadPolicySchedule() {
    const { policy, policy: { policyHolder } } = this.state;

    ScheduleAPI.request(
      {
        'policy.policyNo': policy.policyNo,
        'policyHolder.documentType': policyHolder.documentType,
        'policyHolder.documentNo': policyHolder.documentNo,
      },
      ({ token }) => {
        window.open(ScheduleAPI.download(token));
      }
    );
  }

  downloadPolicyProvision() {
    const { policy: { policyNo } } = this.state;

    window.open(ProvisionAPI.download(policyNo));
  }

  downloadWEACard() {
    const { policy, policy: { policyHolder } } = this.state;

    ScheduleAPI.request(
      {
        'policy.policyNo': policy.policyNo,
        'policyHolder.documentType': policyHolder.documentType,
        'policyHolder.documentNo': policyHolder.documentNo,
      },
      ({ token }) => {
        window.open(EcCardAPI.download(token));
      }
    );
  }

  downloadCMCard() {
    const {
      policy,
      policy: { policyHolder },
    } = this.state;

    ScheduleAPI.requestcmc(
      {
        "policy.policyNo": policy.policyNo,
        "policyHolder.documentType": policyHolder.documentType,
        "policyHolder.documentNo": policyHolder.documentNo,
      },
      ({ token }) => {

        window.open(ScheduleAPI.downloadcmc(token));
   
      }
    );
  }

  buyAgain() {
    const { policy: { planKey: { planCode } } } = this.state;

    let routeName;

    if (/TRAVEL[S|A][1-3]/.test(planCode)) {
      routeName = 'gtravel3';
    }
    else if (/RPA/.test(planCode)) {
      routeName = 'Rpa3';
    }

    localStorage.setItem('preventGoBack', false);

    this.push(routeName, this.state);
  }

  submitClaims() {
    const { broker, policy, travellers } = this.state;
    const { planKey: { planCode } } = policy;

    const push = (routeName) =>
      this.push(routeName, { broker, policy, travellers });

    if (/TRAVEL|ASAP/.test(planCode)) {
      push('TravelInsurance');
    }
    else if (/DH|RPA|EPA|IPA/.test(planCode)) {
      push('HelperAndPersonalAccidentInsurance');
    }
    else if (/HOME|FIRE|LANDLORD|MBDV/.test(planCode)) {
      push('HomeInsurance');
    }
  }

  render() {
    const { policy, content } = this.state;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.historyDetail}
        />

        {Locale.text.history}
        {''}

        <View style={Layout.container}>
          {
            this.state.loading ?
              <Loading /> :
              <Form>
                <Text style={styles.content}>
                  {content}
                </Text>

                <Text>
                  {Locale.text.attachments}
                </Text>

                <Attachment onPress={() => this.downloadPolicySchedule()}>
                  {Locale.text.policySchedule}
                </Attachment>

                <Attachment onPress={() => this.downloadPolicyProvision()}>
                  {Locale.text.policyProvision}
                </Attachment>

                {
                  /TRAVEL[S|A][1-3]|ASAP/.test(policy.planKey.planCode) &&
                  <Attachment onPress={() => this.downloadWEACard()}>
                    {Locale.text.weaCard}
                  </Attachment>
                }

                {this.state.includeChinaMedical && (
                  <Attachment onPress={() => this.downloadCMCard()}>
                    {Locale.text.chinaMedicalGuaranteeCard}
                  </Attachment>
                )}

                {
                  /TRAVEL[S|A][1-3]/.test(policy.planKey.planCode) &&
                  <Button onPress={() => this.buyAgain()}>
                    {Locale.text.buyAgain}
                  </Button>
                }

                {
                  false &&
                  <Button onPress={() => this.submitClaims()}>
                    {Locale.text.submitClaims}
                  </Button>
                }
              </Form>
          }
        </View>
      </Layout>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    marginTop: 10,
    marginBottom: 40,
  },
});
