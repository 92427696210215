import React from 'react';

import Locale from './../../Locale';
import Format from './../../Format';
import Confirmation from './Confirmation';

export default class PolicyHolderConfirmation extends React.Component {
  render() {
    const {policyHolder} = this.props;
    const {formatName, formatDate} = Format;

    return (
      <Confirmation
        heading={Locale.text.policyHolder}
        data={[
          {
            label: this.props.isCompany ?
              Locale.text.companyName : Locale.text.name,
            value: this.props.isCompany ?
              policyHolder.organization :
              formatName(
                policyHolder.salutation,
                policyHolder.firstName,
                policyHolder.lastName
              ),
          },
          {
            label: policyHolder.documentType == 'HKID' ?
              Locale.text.hkid : Locale.text.passport,
            value: this.props.isCompany ?
              undefined : policyHolder.documentNo,
          },
          {
            label: Locale.text.dateOfBirth,
            value: this.props.isCompany || !policyHolder.dateOfBirth ?
              undefined : formatDate(policyHolder.dateOfBirth),
          },
          {
            label: Locale.text.mobileNumber,
            value: policyHolder.mobile,
          },
          {
            label: this.props.addressLabel || Locale.text.correspondenceAddress,
            value: policyHolder.address1,
          },
          {
            label: Locale.text.emailAddress,
            value: policyHolder.email,
          },
        ]}
      />
    );
  }
}
