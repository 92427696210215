import React from 'react';
import {
  Text, ImageBackground, Alert as NativeAlert,
  View, ScrollView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';
import CryptoJS from 'crypto-js';
import md5 from 'md5';
//Real Menu
import { enUS } from './en-US';
import { zhHK } from './zh-HK';
import { memberStorage } from './../../lib/MemberStorage';
import { hmacSha256, msgCodeDetail } from  './../../lib/utils';
import { stateStorage } from './../../lib/StateStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { host, api21, busiSecKey } from './../../config/api';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import ListMenu from './../../components/ListMenu/ListMenu';
import Alert from '../../components/Alert/Alert';

export default class InstantEnrollment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: {},
      text: {},
    };

    if (this.props.location.state) {
      this.state.broker = this.props.location.state.broker;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.setItem('preventGoBack', 'false');
    stateStorage.removeState();

    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }, () => {
      if (this.state.member) {
        const mobile = this.state.member.mobile;
        let showAffinityProducts = false;
        if (
          mobile == '68718681' ||
          mobile == '94529087' ||
          mobile == '93066870' ||
          mobile == '96208933' ||
          mobile == '96706709' ||
          mobile == '66821112' ||
          mobile == '51227331' ||
          mobile == '95104253' ||
          mobile == '97017940'
        ) {
          showAffinityProducts = true;
        }
        this.setState({showAffinityProducts: showAffinityProducts});

        if (this.state.broker) {
          const data = JSON.stringify({
            'member.id': this.state.member.id,
            'member.token': this.state.token.tokenStr,
            'timeStamp': Date.now(),
          });
          const hmac = hmacSha256(data);
          fetch(host + '/agent' + api21, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
          })
          .then((response) => response.json())
          .then((responseJson) => {
            if(responseJson.msgCode == 0) {
              let shortTermTripOnly = true;

              responseJson.msgDesc.plans.forEach((plan) => {
                const planCode = plan.planKey.planCode;

                if (planCode.startsWith('TRAVEL')) {
                  this.state.products.travel = true;
                }
                if (planCode.startsWith('DH')) {
                  this.state.products.dh = true;
                }
                if (planCode.startsWith('HOME')) {
                  this.state.products.home = true;
                }
                if (planCode.startsWith('ASAP')) {
                  this.state.products.asap = true;
                }
                if (planCode.startsWith('USSP')) {
                  this.state.products.ussp = true;
                }
                if (planCode.startsWith('EPA')) {
                  this.state.products.epa = true;
                }
                if (planCode.startsWith('IPA')) {
                  this.state.products.ipa = true;
                }
                if (planCode.startsWith('FIRE')) {
                  this.state.products.fire = true;
                }
                if (planCode.startsWith('TRAVELA')) {
                  shortTermTripOnly = false;
                }
              });
              this.setState({
                products: this.state.products,
                shortTermTripOnly: shortTermTripOnly,
              });
            }
            else {
              NativeAlert(msgCodeDetail(responseJson.msgCode)
                [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
            }
          })
          .catch((error) => {
            NativeAlert('Facing error when getting the product list!');
          });
        }
      }
    }));

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }



  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Quote and Buy | Bravo Generali</title>
        </Helmet>

      <Navbar
          title={this.state.text.instantEnrollment}
          backButton
          history={this.props.history}
        />
        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
          <Hidden xsDown>
           <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
           {
             this.state.broker ?
             <View style={[styles.greeting,{fontWeight:'bold',}]}>
               <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.borkermes}</Text>
               <p></p>
               <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.ieborker}</Text>
               <p></p>
               <Text style={styles.greetingText}>{this.state.text.iemessages}</Text>
             </View>:
             <View style={[styles.greeting,{fontWeight:'bold',}]}>
               <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.buy}</Text>
               <p></p>
               <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.just}</Text>
             </View>
           }

           </Grid>
          </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50}}>
          <View style={styles.container, styles.fieldset}>
          <View style={styles.jumbotron}>

            <ImageBackground source={require('./Instant-Enrollment.png')} style={styles.jumbotronImage}>
            </ImageBackground>

          </View>
          <View style={styles.menu}>
          <Text style={styles.jumbotronText}>
            {this.state.text.quotationandconfirmpurchase}
          </Text>
            <ScrollView>
              <ListMenu history={this.props.history} text ={this.state.text} items={
                [
                  {
                    hide: this.state.broker &&
                      !this.state.products.travel && !this.state.products.asap,
                    icon: require('./travel_insurance.png'),
                    text: this.state.text.travelInsurnace,
                    submenu: [
                      {
                        hide: this.state.broker && !this.state.products.travel,
                        text: this.state.text.bravoTravelProtector,
                        targetScreen: '/gtravel1',
                        navigationOptions: {
                          broker: this.state.broker,
                          shortTermTripOnly: this.state.shortTermTripOnly,
                        },
                      },
                      {
                        hide: this.state.broker && !this.state.products.asap,
                        text: this.state.text.annualStudyAbroadProtector,
                        targetScreen: '/asap1',
                        navigationOptions: {broker: this.state.broker},
                      },
                      // {
                      //   hide: this.state.broker && !this.state.products.ussp,
                      //   text: this.state.text.ussstudyplan,
                      //   alert:{
                      //     title: '',
                      //     message: this.state.text.ussstudyplanpopup,
                      //   },
                      //   targetScreen: '/ussp1',
                      //   navigationOptions: {broker: this.state.broker},
                      // },
                    ],
                  },
                  {
                    hide: this.state.broker &&
                      !this.state.products.home && !this.state.products.fire,
                    icon: require('./home_insurance.png'),
                    text: this.state.text.homeInsurnace,
                    submenu: [
                      {
                        hide: this.state.broker && !this.state.products.home,
                        text: this.state.text.homePackageInsurance,
                        targetScreen: '/home1',
                        navigationOptions: {broker: this.state.broker},
                      },
                      {
                        hide: this.state.broker && !this.state.products.fire,
                        text: this.state.text.fireInsurance,
                        targetScreen: '/fire1',
                        navigationOptions: {broker: this.state.broker},
                      },
                    ],
                  },
                  {
                    hide: this.state.broker && !this.state.products.dh,
                    icon: require('./domestic_helper_insurance.png'),
                    text: this.state.text.domesticHelperInsurance,
                    targetScreen: '/domestic1',
                    navigationOptions: {broker: this.state.broker},
                  },
                  {
                    hide: this.state.broker && !this.state.products.epa,
                    icon: require('./accident_insurance.png'),
                    text: this.state.text.accidentInsurance,
                    submenu: [
                       {
                        hide: this.state.broker && !this.state.products.ipa,
                         text: this.state.text.generaliPersonalAccidentInsurancePlan,
                         targetScreen: '/ipa1',
                         navigationOptions: {broker: this.state.broker},
                       },
                      {
                        hide: this.state.broker && !this.state.products.epa,
                        text: this.state.text.ahiInsurance,
                        targetScreen: '/ahi1',
                        navigationOptions: {broker: this.state.broker},
                      },
                      {
                        hide: this.state.broker && !this.state.products.epa,
                        text: this.state.text.bravoPassengerProtector,
                        targetScreen: '/rpa1',
                        navigationOptions: {broker: this.state.broker},
                      },
                      // {
                      //   hide: this.state.broker && !this.state.products.epa,
                      //   text: this.state.text.executivePersonalAccidentProtector,
                      //   targetScreen: '/epa1',
                      //   navigationOptions: {broker: this.state.broker},
                      // },
                      
                    ],
                  },
                  // {
                  //   hide: !this.state.showAffinityProducts,
                  //   icon: require('./affinity_products.png'),
                  //   text: this.state.text.affinityProduct,
                  //   submenu: [
                  //     {
                  //       text: this.state.text.cityU,
                  //       targetScreen: 'AffinityProducts2',
                  //       navigationOptions: {
                  //         broker: this.state.broker,
                  //         cityU: true,
                  //       },
                  //     },
                  //     {
                  //       text: this.state.text.uow,
                  //       targetScreen: 'AffinityProducts2',
                  //       navigationOptions: {
                  //         broker: this.state.broker,
                  //         uow: true,
                  //       },
                  //     },
                  //     {
                  //       text: this.state.text.centalineLandlordProtector,
                  //       targetScreen: 'CentalineLandlordProtector2',
                  //       navigationOptions: {
                  //         broker: this.state.broker,
                  //         centaline: true,
                  //       },
                  //     },
                  //     {
                  //       text: this.state.text.centalineHomeProtector,
                  //       targetScreen: 'HomePackageInsurance2',
                  //       navigationOptions: {
                  //         broker: this.state.broker,
                  //         centaline: true,
                  //         product: 'Centaline Home Protector',
                  //       },
                  //     },
                  //     {
                  //       text: this.state.text.centalineDomesticHelperProtector,
                  //       targetScreen: 'DomesticHelperInsurance2',
                  //       navigationOptions: {
                  //         broker: this.state.broker,
                  //         centaline: true,
                  //         product: 'Centaline Domestic Helper Protector',
                  //       },
                  //     },
                  //     {
                  //       text: this.state.text.centalineTravelProtector,
                  //       targetScreen: '/gtravel1',
                  //       navigationOptions: {
                  //         broker: this.state.broker,
                  //         centaline: true,
                  //       },
                  //     },
                  //   ],
                  // },
                ]
              }/>
            </ScrollView>
          </View>
        </View>
        </Grid>
          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
      </Grid>
        <Footer/>
      </div>
    );
  }
}


const styles = {
  flex: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  jumbotron: {
    flex: 4,

    alignItems: 'flex-end',
  },
  jumbotronImage: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
    height: 'auto',
  },
  jumbotronText: {
    marginLeft: 10,
    marginBottom: 10,
  },
  menu: {
    flex: 2,
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 695,
  },
};
