import React from 'react';
import {
  Text, View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import validate from 'validate.js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { clearEmptyStrings, hmacSha256, msgCodeDetail, removeSpaces } from './../../../lib/utils';
import { languageStorage } from './../../../lib/LanguageStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import { decline} from './../../../lib/utils';
import PromotionCode from './../../../lib/PromotionCode';
import { host, api26, busiSecKey } from './../../../config/api';
import HKID from './../../../lib/HKID';
import validid from 'validid';
import TextInput from './../../../components/TextField/TextField';
import PromoteAPI from './../../../api/PromoteAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import DatePicker from './../../../components/DatePicker/DatePicker';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import IdentityInput from './../../../components/IdentityInput/IdentityInput';
import NameInput from './../../../components/NameInput/NameInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';

export default class DomesticHelperInsurance3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    const domesticHelper = this.props.location.state.domesticHelper;
    if (policy) {
      this.state = {
        effectiveDateStart: moment(policy.effectiveDateStart, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        effectiveDateEnd: moment(policy.effectiveDateEnd, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderEmail: policy.policyHolder.email,
        policyholderAddress: policy.policyHolder.address1,
        policyholderHKID: policy.policyHolder.documentNo,
        // policyholderDOB: moment(data['policyHolder.dateOfBirth'], 'YYYY-MM-DD').format('MMMM D, YYYY'),
        insuredTitle: domesticHelper.salutation ? domesticHelper.salutation.descEnUs.replace(/\./g, '') : undefined,
        insuredFirstName: domesticHelper.firstName,
        insuredLastName: domesticHelper.lastName,
        insuredSex: domesticHelper.gender,
        // insuredDOB: moment(data['domesticHelper.dateOfBirth'], 'YYYY-MM-DD').format('MMMM D, YYYY'),
        insuredIdType: domesticHelper.documentType,
        insuredIdNumber: domesticHelper.documentNo,
        insuredCountry: domesticHelper.originCountry,
        planType: domesticHelper.includeOutpatient ? 'with' : 'without',
        insuredDeclaration1: 'Y',
        insuredDeclaration2: domesticHelper.isRefused ? 'Y' : 'N',
        insuredDeclaration3: domesticHelper.isMedAttn ? 'Y' : 'N',
        insuredDeclaration4: 'Y',
        specialRequests: policy.specialRequest,
        messages: {},
        text: {},
      };
    }
    else {
      this.state = {
        broker: this.props.location.state.broker,
        centaline: this.props.location.state.centaline,
        planType: this.props.location.state.planType,
        periodOfInsurance: this.props.location.state.periodOfInsurance,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
        referral: this.props.location.state.referral,
        messages: {},
        text: {},
      };
    }
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('domestic3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0)
    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        // init HKID validation
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value.toUpperCase())) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };

        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          if (this.state.broker) {
            // init broker-specific input field
            this.setState({
              insuranceAdvisorMobile:
                this.state.member && this.state.member.agent.isAdmin ?
                  undefined : this.state.broker.mobile
            });
          }
          else {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.navigation,
              (insuranceAdvisors) => {
                const insuranceAdvisor = insuranceAdvisors.DH;
                if (insuranceAdvisor) {
                  this.setState({insuranceAdvisor: insuranceAdvisor});

                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.DH.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'DH',
                      (promotionCodes) => {
                        this.setState({
                          showBusinessEmailAddress: false,
                          businessEmailAddress: undefined,
                          submitButtonDisabled: false,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              }
            );
            if (!this.state.member && this.state.promotionCodes) {
              PromoteAPI.checkPromotionCode(
                {
                  pn: 'EAPP',
                  pl: 'DH',
                  promotionCode: this.state.promotionCodes,
                },
                (msgDesc) => {
                  this.setState({insuranceAdvisor: {agent: msgDesc}});
                }
              );
            }

            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState('domestic3', rawRequest);
    stateStorage.setState('domestic4', undefined);
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      planType: state.planType,
      effectiveDate: state.effectiveDateStart,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderHKID: state.policyholderHKID,
      policyholderDOB: state.policyholderDOB,
      policyholderPhone: state.policyholderPhone,
      policyholderEmail: state.policyholderEmail,
      policyholderAddress: state.policyholderAddress,
      insuredFirstName: state.insuredFirstName,
      insuredLastName: state.insuredLastName,
      insuredIdType: state.insuredIdType,
      insuredIdNumber: state.insuredIdNumber,
      insuredDOB: state.insuredDOB,
      insuredCountry: state.insuredCountry,
      insuredDeclaration1: state.insuredDeclaration1,
      insuredDeclaration2: state.insuredDeclaration2,
      insuredDeclaration3: state.insuredDeclaration3,
      insuredDeclaration4: state.insuredDeclaration4,
      specialRequests: state.specialRequests,
      referral: state.referral,
      businessEmailAddress: state.businessEmailAddress,
    };
    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    };
    let constraints = {
      effectiveDate: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      policyholderHKID: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: {message: this.state.text.notValidHKID},
        format: englishFormat,
      },
      policyholderDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      policyholderPhone: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {message: this.state.text.notANumber},
      },
      policyholderEmail: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      policyholderAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredIdType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      insuredIdNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        hkid: this.state.insuredIdType == 'HKID',
      },
      insuredDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      insuredCountry: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      insuredDeclaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      insuredDeclaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      insuredDeclaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      insuredDeclaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      specialRequests: {
        format: englishFormat,
      },
      referral: {
        format: englishFormat,
      },
      businessEmailAddress: {
        format: englishFormat,
      },
    };
    const messages = validate(attributes, constraints, {fullMessages: false});
    if (messages) {

      this.setState({
        alert:
        Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{
            text: this.state.text.ok,
            onPress: () => this.setState({alert: null}),
            style: 'cancel',
          }],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let fields = {
        'dt': moment().format('YYYYMMDDhhmmss'),
        'pn': 'EAPP',
        'pl': 'DH',
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.businessEmail': this.state.centaline ?
          undefined : removeSpaces(this.state.businessEmailAddress),
        'domesticHelper.includeOutpatient': this.state.planType == 'with' ? 'Y' : 'N',
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api26, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0 || this.state.nextScreen) {
          let rawRequest = Object.assign({}, state);
          delete rawRequest.messages;
          delete rawRequest.text;
          delete rawRequest.alert;

          const premium = responseJson.msgDesc.premium;
          const ecLevy = responseJson.msgDesc.ecLevy;
          const terrorCharge = responseJson.msgDesc.terrorCharge;
          const levy = responseJson.msgDesc.levy;
          const total = premium.discount + ecLevy + terrorCharge + levy;

          stateStorage.setState('domestic3', rawRequest);
          stateStorage.setState('domestic4', undefined);

          this.props.history.push('/domestic4', {
            // broker: state.broker,
            insuranceAdvisor: state.insuranceAdvisor,
            insuranceAdvisorMobile: state.insuranceAdvisorMobile,
            centaline: state.centaline,
            effectiveDateStart: state.effectiveDateStart,
            effectiveDateEnd: state.effectiveDateEnd,
            periodOfInsurance: state.periodOfInsurance,
            promotionCodes: removeSpaces(state.promotionCodes),
            businessEmailAddress: removeSpaces(state.businessEmailAddress),
            specialRequests: state.specialRequests,
            referral: state.referral,
            policyholderTitle: state.policyholderTitle,
            policyholderFirstName: state.policyholderFirstName,
            policyholderLastName: state.policyholderLastName,
            policyholderPhone: state.policyholderPhone,
            policyholderEmail: state.policyholderEmail,
            policyholderAddress: state.policyholderAddress,
            policyholderHKID: state.policyholderHKID,
            policyholderDOB: state.policyholderDOB,
            insuredTitle: state.insuredTitle,
            insuredFirstName: state.insuredFirstName,
            insuredLastName: state.insuredLastName,
            insuredSex: state.insuredSex,
            insuredDOB: state.insuredDOB,
            insuredIdType: state.insuredIdType,
            insuredIdNumber: state.insuredIdNumber,
            insuredCountry: state.insuredCountry,
            planType: state.planType,
            insuredDeclaration2: state.insuredDeclaration2,
            insuredDeclaration3: state.insuredDeclaration3,
            insuredDeclaration4: state.insuredDeclaration4,
            rawRequest: rawRequest,
            premium: premium,
            ecLevy: ecLevy,
            terrorCharge: terrorCharge,
            levy: levy,
            total: total,
          });
        }
        else if (responseJson.msgCode == 40) {
          if (this.state.broker) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                  style: 'cancel',
                }],
              ),
            });
          }
          else {
            this.setState({
              alert: Alert.alert(
                this.state.text.addInsuranceAdvisor,
                this.state.text.wouldYouLike
                + responseJson.msgDesc.agent.brokerName
                + this.state.text.toBeYourInsuranceAdvisor,
                [
                  {
                    text: this.state.text.ok,
                    onPress: () => {
                      if (!this.state.bindingInsuranceAdvisor) {
                        this.setState({bindingInsuranceAdvisor: true}, () => {
                          insuranceAdvisors.setInsuranceAdvisor(
                            {agentCode: responseJson.msgDesc.agent.agentCode},
                            ['DH'],
                            this.state.promotionCodes,
                            this.state.language,
                            () => this.setState({bindingInsuranceAdvisor: false},
                              () => this._nextScreen())
                          );
                        });
                      }
                    }
                  },
                ],
                { cancelable: false }
              )
            });
          }
        }
        else if (responseJson.msgCode == 56) {
          this.setState({
            messages: {
              promotionCodes: this.state.text['invalidPromotionCode']
            },
            alert: Alert.alert(
              this.state.text.alert,
              this.state.text.check,
              [{
                text: this.state.text.ok,
                onPress: () => this.setState({alert: null}),
                style: 'cancel',
              }],
            ),
          });
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
        this.setState({submitButtonDisabled: false});
      })
      .catch((error) => {
        alert('Facing error when processing the quotation!');
        this.setState({submitButtonDisabled: false});
      });
    }
  }


  render() {
    return (
      <div className='App'>

      <Helmet>
        <title>Domestic Helper Insurance | Bravo Generali</title>
      </Helmet>

      <Navbar
        title={this.state.text.getInsured}
        backButton
        history={this.props.history}
      />

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
            <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                  <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.domestic}</Text>
                    <p></p>
                    {
                      this.state.language == 'zhHK' ?
                      <View>
                      <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.beingand}</Text>
                      </View>:
                      <View>
                      <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.beingand}</Text>
                      </View>

                    }
                </View>

            </Grid>
        </Hidden>
      <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>


      <ScrollView style={formStyles.form, styles.fie}>
      {this.state.alert}
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h2}>
            {
              this.state.centaline ?
              this.state.text.centalineDomesticHelperProtector :
              this.state.text.domesticHelperInsurance
            }
          </Text>
        </View>

        {
          this.state.insuranceAdvisor || this.state.broker ?
            <View style={formStyles.fieldset}>
              <Text style={{textAlign: 'left'}}>
                {this.state.text.theInsuranceIsIssued}
                {
                  this.state.broker ?
                  this.state.broker.name :
                  this.state.insuranceAdvisor.agent.broker.nameEnUs
                }
                {this.state.text.professionalInsurance}
              </Text>
            </View> :
            <View style={{textAlign: 'left'}}>
              <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
            </View>
        }


          <Text>{this.state.text.pleaseInputInEnglish}</Text>


        {
          this.state.broker &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.font}>{this.state.text.insuranceAdvisorMobile}</Text>
              <TextInput
                style={formStyles.input}
                onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                value={this.state.insuranceAdvisorMobile}
                editable={this.state.member && this.state.member.agent.isAdmin}
                keyboardType='phone-pad'
                maxLength={8}
              />
            </View>
        }

        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.productType}</Text>
          <ModalSelector
            data={[
              { key: 'with', label: planTypes.with.label[this.state.language] },
              { key: 'without', label: planTypes.without.label[this.state.language] },
            ]}
            initValue={planTypes[this.state.planType].label[this.state.language]}
            onChange={(option) => this.setState({planType: option.key })}
            cancelText={this.state.text.cancel}
          />
            <Text style={formStyles.message}>{this.state.messages.planType}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.effectiveDate}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.effectiveDateStart}
            mode='date'
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={moment().add(1, 'days').format('MMMM D, YYYY')}
            maxDate={moment().add(1, 'days').add(6, 'months').format('MMMM D, YYYY')}
            onDateChange={(date) => {this.setState({
              effectiveDateStart: date,
              effectiveDateEnd: moment(date, 'MMMM D, YYYY')
                .add(1, 'years').subtract(1, 'days').format('MMMM D, YYYY'),
            })}}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
          />
          <Text style={formStyles.message}>{this.state.messages.effectiveDate}</Text>
        </View>
        {
          this.state.effectiveDateStart &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.font}>{this.state.text.periodOfInsurance}</Text>
              <View style={styles.periodOfInsurance}>
                <Text>
                  {this.state.effectiveDateStart} - {this.state.effectiveDateEnd}
                </Text>
                <Text>{this.state.text.bothDatesInclusive}</Text>
              </View>
            </View>
        }
        {
          this.state.centaline &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.font}>{this.state.text.periodOfInsurance}</Text>
              <ModalSelector
                data={[
                  { key: 'oneYear', label: this.state.text.oneYear },
                  { key: 'twoYears', label: this.state.text.twoYears },
                ]}
                initValue={this.state.text[this.state.periodOfInsurance]}
                onChange={(option) => this.setState({periodOfInsurance: option.key })}
                cancelText={this.state.text.cancel}
              />
                <Text style={formStyles.message}>{this.state.messages.periodOfInsurance}</Text>
            </View>
        }
        {
          !this.state.businessEmailAddress &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.font}>{this.state.text.promotionCodes}</Text>
              <TextInput
                style={formStyles.input}
                placeholder={this.state.text.optional}
                placeholderTextColor='lightgrey'
                onChangeText={(text) => this.setState({promotionCodes: text})}
                value={this.state.promotionCodes}
              />
              <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
            </View>
        }
        {
          !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
            <View style={formStyles.fieldset}>
              <Text style={formStyles.font}>{this.state.text.businessEmailAddress}</Text>
              <TextInput
                style={formStyles.input}
                placeholder={this.state.text.optional}
                placeholderTextColor='lightgrey'
                keyboardType='email-address'
                onChangeText={(text) => this.setState({businessEmailAddress: text})}
                value={this.state.businessEmailAddress}
              />
              <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
            </View>
        }
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h3}>{this.state.text.policyholder}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.name}</Text>
          <NameInput
            title={this.state.policyholderTitle}
            firstName={this.state.policyholderFirstName}
            lastName={this.state.policyholderLastName}
            onChange={(title, firstName, lastName) => this.setState({
              policyholderTitle: title,
              policyholderFirstName: firstName,
              policyholderLastName: lastName,
            })}
          />
          <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
          <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.hkid}</Text>
          <TextInput
            style={[formStyles.input,{height: 50,paddingLeft: 10,}]}
            onChangeText={(text) => this.setState({policyholderHKID: text ? validid.utils.normalize(text) : text})}
            value={this.state.policyholderHKID}
            placeholder='A1234567'
            placeholderTextColor='lightgrey'
          />
          <View style={{textAlign: 'left'}}>
          <Text>{this.state.text.pleaseEnterAllHKID}</Text>
          </View>
            <Text style={formStyles.message}>{this.state.messages.policyholderHKID}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.dateOfBirth}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.policyholderDOB}
            mode='date'
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={'January 2, 1900'}
            maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
            onDateChange={(date) => {this.setState({policyholderDOB: date})}}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
          />
            <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.mobileNumber}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderPhone: text})}
            value={this.state.policyholderPhone}
            keyboardType='phone-pad'
            maxLength={8}
          />
            <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.emailAddress}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderEmail: text})}
            value={this.state.policyholderEmail}
            keyboardType='email-address'
          />
            <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.residentialAddress}</Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({policyholderAddress: text})}
            value={this.state.policyholderAddress}
          />
            <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
        </View>
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h3}>{this.state.text.domesticHelper}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.name}</Text>
          <NameInput
            title={this.state.insuredTitle}
            firstName={this.state.insuredFirstName}
            lastName={this.state.insuredLastName}
            onChange={(title, firstName, lastName) => this.setState({
              insuredTitle: title,
              insuredFirstName: firstName,
              insuredLastName: lastName,
            })}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredFirstName}</Text>
          <Text style={formStyles.message}>{this.state.messages.insuredLastName}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.hkidPassportNumber}</Text>
          <IdentityInput
            type={this.state.insuredIdType}
            number={this.state.insuredIdNumber}
            onChange={(type, number) => this.setState({
              insuredIdType: type,
              insuredIdNumber: number,
            })}
            pp
          />
          <Text style={formStyles.message}>{this.state.messages.insuredIdType}</Text>
          <Text style={formStyles.message}>{this.state.messages.insuredIdNumber}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.dateOfBirth}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.insuredDOB}
            mode='date'
            placeholder={this.state.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate={moment().subtract(61, 'years').add(1, 'days').format('MMMM D, YYYY')}
            maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
            onDateChange={(date) => {this.setState({insuredDOB: date})}}
            confirmBtnText={this.state.text.ok}
            cancelBtnText={this.state.text.cancel}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredDOB}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.sex}</Text>
          <ModalSelector
            data={[
              { key: 'M', label: this.state.text.male },
              { key: 'F', label: this.state.text.female },
            ]}
            initValue={this.state.insuredSex ?
              this.state.insuredSex == 'M' ? this.state.text.male : this.state.text.female
              : this.state.text.pleasechoose}
            onChange={(option) => this.setState({insuredSex: option.key })}
            cancelText={this.state.text.cancel}
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.countryOfOrigin}</Text>
            {
              this.state.otherCountry ?
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({insuredCountry: text})}
                  value={this.state.insuredCountry}
                /> :
                <ModalSelector
                  data={[
                    { key: 'sriLanka', label: this.state.text.sriLanka },
                    { key: 'indonesia', label: this.state.text.indonesia },
                    { key: 'philippines', label: this.state.text.philippines },
                    { key: 'singapore', label: this.state.text.singapore },
                    { key: 'malaysia', label: this.state.text.malaysia },
                    { key: 'thailand', label: this.state.text.thailand },
                    { key: 'nepal', label: this.state.text.nepal },
                    { key: 'bangladesh', label: this.state.text.bangladesh },
                    { key: 'others', label: this.state.text.others },
                  ]}
                  initValue={this.state.insuredCountry ?
                    this.state.text[this.state.insuredCountry] :
                    this.state.text.pleasechoose}
                  onChange={(option) => this.setState({
                    insuredCountry: option.key == 'others' ? '' : option.key,
                    otherCountry: option.key == 'others'
                  })}
                  cancelText={this.state.text.cancel}
                />
            }
            <Text style={formStyles.message}>{this.state.messages.insuredCountry}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.font}>{this.state.text.workingLocationInHK}</Text>
          <TextInput
            style={formStyles.input}
            value={this.state.policyholderAddress}
            editable={false}
            placeholder={this.state.text.sameAsTheResidentialAddressOfPolicyholder}
            placeholderTextColor='lightgrey'
          />
            <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <YesNoQuestion
            question={this.state.text.thePlaceOfEmploymentIsInHK}
            choice={this.state.insuredDeclaration1}
            onChange={(choice) => this.setState({insuredDeclaration1: choice})}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredDeclaration1}</Text>
          {
            this.state.insuredDeclaration1 == 'N' &&
            decline(
              this.state.text.sorryThatYourApplicationIsDeclinedAsOnlyHKPlace,//提示文字
              this.state.language,
              this.props.navigation, //不動
              () => this.setState({insuredDeclaration1: null})  //更改參數
             )
           }
        </View>

        <View style={formStyles.fieldset}>
          <YesNoQuestion
            question={this.state.text.theHelperHasEverBeenRufusedForEnrollment}
            choice={this.state.insuredDeclaration2}
            onChange={(choice) => this.setState({insuredDeclaration2: choice})}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredDeclaration2}</Text>
        </View>
        {
          this.state.insuredDeclaration2 =='Y' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
        <View style={formStyles.fieldset}>
          <YesNoQuestion
            question={this.state.text.theHelperIsReceivingOrContemplatingAnyMdeical}
            choice={this.state.insuredDeclaration3}
            onChange={(choice) => this.setState({insuredDeclaration3: choice})}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredDeclaration3}</Text>
        </View>
        {
          this.state.insuredDeclaration3 =='Y' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
        <View style={formStyles.fieldset}>
          <YesNoQuestion
            question={this.state.text.theHelperIsFullTimeOverseasHelper}
            choice={this.state.insuredDeclaration4}
            onChange={(choice) => this.setState({insuredDeclaration4: choice})}
          />
          <Text style={formStyles.message}>{this.state.messages.insuredDeclaration4}</Text>
          {
            this.state.insuredDeclaration4 == 'N' &&
            decline(
              this.state.text.sorryThatYourApplicationIsDeclinedAsOnlyFullTimeOverseas,//提示文字
              this.state.language,
              this.props.navigation, //不動
              () => this.setState({insuredDeclaration4: null})  //更改參數
             )
           }
        </View>
        <View style={formStyles.hr} />
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({specialRequests: text})}
            value={this.state.specialRequests}
            placeholder={this.state.text.optional}
            placeholderTextColor='lightgrey'
          />
          <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
        </View>
        {
          !!this.state.specialRequests &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h3}>{this.state.text.referral}</Text>
        </View>
        <View style={formStyles.fieldset}>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.setState({referral: text})}
            value={this.state.referral}
            placeholder={this.state.text.optional}
            placeholderTextColor='lightgrey'
          />
          <Text style={formStyles.message}>{this.state.messages.referral}</Text>
        </View>
        {
          this.state.submitButtonDisabled ?
            <View style={formStyles.submitButtonDisabled}>
              <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
            </View> :
            <TouchableOpacity style={formStyles.submitButton} onPress={() => this._nextScreen()}>
              <Text style={formStyles.submitButtonText}>{this.state.text.buyNow}</Text>
            </TouchableOpacity>
        }
      </ScrollView>

      </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
     </Grid>

      <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fie: {
      margin: 'auto',
      minWidth: 300,
      maxWidth: 600,
      minHeight: 580,
      paddingLeft: 20,
   paddingRight: 20,
    },
};
