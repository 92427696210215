export const zhHK = {
  disclaimer: '免責聲明',
  pic: '收集個人資料聲明',
  agreed: '我 / 我們已閱讀並同意',
  comma: '的重要聲明',
  space: '，',
  and: '和',
  yes:'是',
  no:'否',
  disclaimer: '免責聲明',
  personal: '收集個人資料聲明',
  dot: '。',
}
