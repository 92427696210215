import React from 'react';
import { Text, Image, View, ScrollView, TouchableOpacity
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { getEcash, addCommas, hmacSha256 } from './../../lib/utils';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { formStyles } from './../../config/styles';
import { host, cms, busiSecKey } from './../../config/api';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

import Member from './../../Member';

export default class PrivilegedClubDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      text: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init member
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          // init expiry date
          const data = JSON.stringify({ 'member.id': this.state.member.id, 'timeStamp': Date.now(), });
          const hmac = hmacSha256(data);
          fetch(cms + '/api/voucher/latestTransactionDate', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
          })
          .then((response) => response.json())
          .then((responseJson) => {
            // init ecash
            getEcash(
              this.state.language,
              this.props.history,
              (ecash) => this.setState({
                latestTransactionDate:
                  responseJson.msgDesc.latestTransactionDate,
                ecash: ecash,
                loading: false,
              })
            );
          })
          .catch((error) => {
            alert('Facing error when getting the last transaction date!' + error);
          });
        }));
      })
    );
  }

  render() {
    const color = Member.getThemeColor();

    return (
    <div className='App'>
      <Helmet>
        <title>Privilege Club | Bravo Generali</title>
      </Helmet>

       <Navbar
          title={this.state.text.privilegedClub}
          backButton
          history={this.props.history}
        />
        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%',}}>

              <View style={[styles.greeting,{fontWeight:'bold',}]}>
                <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.privilegedClub}</Text>
                <p></p>
                <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.tuned}</Text>

              </View>

          </Grid>
          </Hidden>

          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <View style={styles.container}>

            <ScrollView style={formStyles.form, styles.fieldset}>
            {
              this.state.language == 'enUS' &&
                <View style={styles.content}>
                  {
                    this.state.loading ?
                      <Text style={styles.p}>Loading...</Text> :
                      <View>
                        <Text style={styles.p}>Generali Voucher Balance = HK${addCommas(this.state.ecash)}</Text>
                        <Text style={styles.p}>Valid until {this.state.latestTransactionDate && moment(this.state.latestTransactionDate, 'YYYY-MM-DD hh:mm:ss').add(1, 'years').format('MMMM D, YYYY')}</Text>
                      </View>
                  }
                  <Text style={[styles.h2, {color}]}>Terms and Conditions</Text>
                  <Text style={styles.p}>1. The Generali voucher is only available on the "Bravo Generali" App for premium payment. It has a validity period of 1 year.</Text>
                  <Text style={styles.p}>2. The Generali voucher is not transferable and cannot be exchanged for cash or other goods. If the insurance is withdrawn or refunded, the voucher amount will not be refunded.</Text>
                  <Text style={styles.p}>3. The Company has the absolute right to interpret these terms and conditions and to change any related arrangements. In case of any dispute, The Company reserves the right of final decision without further notice</Text>
                </View>
            }
            {
              this.state.language == 'zhHK' &&
                <View style={styles.content}>
                  {
                    this.state.loading ?
                      <Text style={styles.p}>載入中……</Text> :
                      <View>
                        <Text style={styles.p}>Generali 現金券餘額 = 港幣${addCommas(this.state.ecash)}</Text>
                        <Text style={styles.p}>有效至 {this.state.latestTransactionDate && moment(this.state.latestTransactionDate, 'YYYY-MM-DD hh:mm:ss').add(1, 'years').format('MMMM D, YYYY')}</Text>
                      </View>
                  }
                  <Text style={[styles.h2, {color}]}>條款及細則</Text>
                  <Text style={styles.p}>1. Generali 現金劵僅限於「Bravo Generali」平台購物，透過手機應用程式付款時使用。自領取之日起1年內有效，逾期未使用自動失效。</Text>
                  <Text style={styles.p}>2. Generali 現金劵，不可轉讓，亦不可兌換現金或其他貨品。如退保或者退款，獎賞所對應的金額不作為退款款項。</Text>
                  <Text style={styles.p}>3. 本公司對本條款及細則擁有絕對解釋權及保留一切更改的絕對權利。如 有任何爭議，忠意保留最終決定權而毋須另行通知。</Text>
                </View>
            }
          </ScrollView>
        </View>
       </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
     </Grid>
     <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  content: {
    marginTop: 10,
    marginBottom: 40,
  },
  h2: {
    fontSize: 22,
    marginTop: 10,
    marginBottom: 20,
    color: '#C50000',
    textAlign: 'center',
  },
  h3: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
    color: '#C50000',
    textAlign: 'center',
  },
  p: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
  },
  attachment: {
    color: '#1F4E79',
    textDecorationLine: 'underline',
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },
};
