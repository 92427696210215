import React from "react";
import {
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import { Helmet } from "react-helmet";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ModalSelector from "./../../../components/ModalSelector/ModalSelector";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import validate from "validate.js";
import moment from "moment";
import md5 from "md5";

import { enUS } from "./en-US.js";
import { zhHK } from "./zh-HK.js";
import { planTypes } from "./planTypes";
import { options } from "./options";
import { platform } from "./../../../config/platform";
import { formStyles } from "./../../../config/styles";
import { msgCodeDetail } from "./../../../lib/utils";
import { addCommas, removeCommas, removeSpaces } from "./../../../lib/utils";
import { memberStorage } from "./../../../lib/MemberStorage";
import { insuranceAdvisors } from "./../../../lib/InsuranceAdvisors";
import {
  host,
  api26,
  api36,
  api37,
  api310,
  busiSecKey,
} from "./../../../config/api";
import { clearEmptyStrings, handleExpiredLogin } from "./../../../lib/utils";
import { languageStorage } from "./../../../lib/LanguageStorage";
import { stateStorage } from "./../../../lib/StateStorage";
import ProductAPI from "./../../../api/ProductAPI";
import AgentAPI from "./../../../api/AgentAPI";
import PromotionCode from "./../../../lib/PromotionCode";
import TextInput from "./../../../components/TextField/TextField";
import Navbar from "./../../../components/Navbar/Navbar";
import Footer from "./../../../components/Footer/Footer";
import Alert from "./../../../components/Alert/Alert";
import ProductEdges from "./../../../components/ProductEdges/ProductEdges";
import AttachmentLink from "./../../../components/AttachmentLink/AttachmentLink";
import PlanTypePicker from "./../../../components/PlanTypePicker/PlanTypePicker";
import YesNoQuestion from "./../../../components/YesNoQuestion/YesNoQuestion";
import OccupationQuestion from "../../../insurance-product/ipa/OccupationQuestion";


import Hidden from "@material-ui/core/Hidden";
export default class IPA1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state
        ? this.props.location.state.broker
        : undefined,
      planType: "IPA1",
      people: "individual",
      numberOfChildren: '2',
      numberOfIndividuals: '4',
      insuredOccupation: "",
      spouseOccupation: "",
      insuredOccupationLabel: {},
      spouseOccupationLabel: "",
      insuredOccupations: [], //Handle occupations for group 
      occupations: [],
      coverageD: "N",
      messages: {},
      text: {},
    };

    if (!this.state.broker && localStorage.getItem('promotionCodes')) {
      this.state.promotionCodes = localStorage.getItem('promotionCodes');
    }

    if (!this.state.broker && localStorage.getItem('businessEmail')) {
      this.state.businessEmailAddress = localStorage.getItem('businessEmail');
    }

    const searchParams = new URLSearchParams(this.props.location.search);

    const lang = searchParams.get('lang');
    if (lang) {
      switch (lang) {
        case 'en':
          languageStorage.setLanguage('enUS');
          break;
        case 'zh':
          languageStorage.setLanguage('zhHK');
          break;
      }
    }

    const businessEmail = searchParams.get('businessEmail');
    if (businessEmail) {
      this.state.promotionCodes = undefined;
      localStorage.removeItem('promotionCodes');

      this.state.businessEmailAddress = businessEmail;
      localStorage.setItem('businessEmail', businessEmail);
    }

    const promotionCode = searchParams.get("promotionCode");
    if (promotionCode) {
      this.state.businessEmailAddress = undefined;
      localStorage.removeItem('businessEmail');

      this.state.promotionCodes = promotionCode;
      localStorage.setItem('promotionCodes', promotionCode);
    }

    const referral = searchParams.get("refcode");
    if (referral) {
      this.state.referral = referral;
    }
    localStorage.setItem('preventGoBack', 'false');
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == "zhHK") {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState(
        {
          language: language,
          text: getText(language),
        },
        () => {
          // init member and token
          memberStorage.getMember((member, token) =>
            this.setState(
              {
                member: member,
                token: token,
              },
              () => {
                ProductAPI.getOccupations(
                  {
                    planType: "IPA",
                  },
                  (msgDesc) => {
                    for (let i = 0; i < msgDesc.occupations.length; i++) {
                      this.state.occupations.push({
                        key: msgDesc.occupations[i].id,
                        label: language == "zhHK" ? msgDesc.occupations[i].occupationNameZhHk:msgDesc.occupations[i].occupationNameEnUs,
                      });
                    }
                    this.setState({ occupations: this.state.occupations });
                  }
                );
                if (!this.state.broker) {
                  // init bound insurance advisor
                  insuranceAdvisors.getInsuranceAdvisors(
                    this.state.language,
                    this.props.history,
                    (insuranceAdvisors) => {
                      if (!insuranceAdvisors.IPA) {
                        this.setState({ showBusinessEmailAddress: true });
                      }
                    }
                  );
                  if (!this.state.member) {
                    this.setState({ showBusinessEmailAddress: true });
                  }
                }
                
              }
            )
          );
        }
      )
    );
  }

  _getTitle = (number) => {
    let ordinalNumber;
    number += 1;

    if (number == 1) {
      ordinalNumber = '1st ';
    }
    else if (number == 2) {
      ordinalNumber = '2nd ';
    }
    else if (number == 3) {
      ordinalNumber = '3rd ';
    }
    else {
      ordinalNumber = number + 'th ';
    }
    if (this.state.language == 'zhHK') {
      ordinalNumber = `第 ${number} 位`
    }
    if (number) {
      return ordinalNumber + this.state.text.insured;
    }
    return "";
  }

  _handleOccupationChange = (index, occupation) => {
    let mergedOccupations = ''
    let newDict = this.state.insuredOccupations
    newDict[index] = occupation
    this.setState({
      insuredOccupations: newDict,
    }, () => {
      if(this.state.people == 'group'){
        this.state.insuredOccupations.forEach((occupation, i) => {
          if(i == 0){
            mergedOccupations += occupation ? occupation.key : undefined
          } else {
            mergedOccupations += (";" + (occupation ? occupation.key : undefined))
          }
        })
      }
      this.setState({
        insuredOccupation: mergedOccupations
      })
    })



  }

  _nextScreen() {
    this.setState({ submitButtonDisabled: true });
    const state = clearEmptyStrings(this.state);
    let attributes = {
      businessEmailAddress: state.businessEmailAddress,
      insuredOccupation: state.insuredOccupation,
      numberOfIndividuals: state.numberOfIndividuals, 
    };

    if (state.people == "withSpouse" || state.people == "withChildren") {
      attributes.numberOfChildren = state.numberOfChildren;
    }

    if(this.state.people == 'withSpouse'){
      attributes["spouseOccupation"] = state.spouseOccupation
    }

    const englishFormat = {
      pattern: "[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+",
      message: this.state.text.needsToBeInEnglish,
    };
    let constraints = {
      businessEmailAddress: {
        format: englishFormat,
      },
      insuredOccupation: {
        presence: {message: this.state.text.thisFieldCannotBeBlank, }
      },
      numberOfIndividuals: {
        presence: {message: this.state.text.thisFieldCannotBeBlank, }
      }
    };

    if(this.state.people == 'withSpouse' || this.state.people == 'withChildren' ) {
      constraints.numberOfChildren = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      };
    }

    if(this.state.people == 'withSpouse'){
      constraints["spouseOccupation"] = {
        presence: {message: this.state.text.thisFieldCannotBeBlank, }
      }
    }

    let messages = validate(attributes, constraints, { fullMessages: false });


    let occupationConstraints = {
      "key": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      }
    }

    if(this.state.people == 'group'){
    //Group of Individual Constraints
    let occupationMessages = {}
    this.state.insuredOccupations.forEach((occupation, index) => {
      let occupationMessage = validate(clearEmptyStrings(occupation), occupationConstraints, {fullMessages: false})
      if(occupationMessage != undefined){
        occupationMessages[index] = occupationMessage
      }
    })
    
    if(Object.keys(occupationMessages).length != 0){
      if(!messages){
        messages = {}
      }
      messages.insuredOccupations = Object.keys(occupationMessages).length != 0 ? occupationMessages : undefined
    }
    
    }


    if (messages) {
      this.setState({
        alert: Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });

    } else {
      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;
      
      let noOfChildren = '0';

      let people = 'I';
      if (this.state.people == 'withSpouse') {
        people = 'F';
        noOfChildren = this.state.numberOfChildren
      }
      if (this.state.people == 'withChildren') {
        people = 'IC';
        noOfChildren = this.state.numberOfChildren
      }


      premium(
        {
          dt: moment().format("YYYYMMDDhhmmss"),
          pn: "EAPP", // Partner Code (For Bravo Webapp, always "EAPP")
          pl: this.state.planType, // Plan Code (Plan 1 = IPA1, Plan 2 = IPA2, Plan 3 = IPA3)
          insuredOccupation: this.state.insuredOccupation, // This is the insured Occupation fields, if there is multiple of individuals, separate it by ";" e.g. "47;39;20;20"
          spouseOccupation: this.state.spouseOccupation, // Only provide when choosing "Family / Indivdual and Children"
          // numberOfIndividuals: this.state.numberOfIndividuals,
          noOfChildren: noOfChildren, // Number of Children (Only provide when choosing "Indivdual and Children")
          premiumPlan: people, // F = Family Plan, I = Indivdual / Group of Indivduals, IC = Indivdual and Children
          "member.id": this.state.member ? this.state.member.id : undefined,
          "member.token": this.state.token ? this.state.token.tokenStr : undefined,
          "payment.promotionCode": removeSpaces(this.state.promotionCodes),
          "policy.businessEmail": removeSpaces(this.state.businessEmailAddress),
          "ipa.sumInsuredA1": null,
          "ipa.sumInsuredA2": null,
          "ipa.sumInsuredB": null,
          "ipa.sumInsuredC": null,
          "ipa.includeD": null,
        },
        (msgDesc) => {
          this.setState({ submitButtonDisabled: false }, () => {

            let rawRequest = Object.assign({}, state);
            delete rawRequest.messages;
            delete rawRequest.text;
  
            stateStorage.setState("ipa1", rawRequest);
            stateStorage.setState("ipa2", undefined);
            this.props.history.push("/ipa2", {
              broker: this.state.broker,
              planType: this.state.planType,
              people: this.state.people,
              numberOfChildren: this.state.numberOfChildren,
              numberOfIndividuals: this.state.numberOfIndividuals,
              occupations: this.state.occupations,
              insuredOccupation: this.state.insuredOccupation,
              insuredOccupations: this.state.insuredOccupations,
              spouseOccupation: this.state.spouseOccupation,
              insuredOccupationLabel: this.state.insuredOccupationLabel,
              spouseOccupationLabel: this.state.spouseOccupationLabel,
              coverageA1: this.state.coverageA1,
              coverageA2: this.state.coverageA2,
              coverageB:
                this.state.insuredOccupation == "OC3" ||
                this.state.insuredOccupation == "OC4"
                  ? undefined
                  : this.state.coverageB,
              coverageC: this.state.coverageC,
              coverageD: this.state.coverageD,
              premium: msgDesc.premium,
              promotionCodes: removeSpaces(this.state.promotionCodes),
              businessEmailAddress: removeSpaces(
                this.state.businessEmailAddress
              ),
              showBusinessEmailAddress: this.state.showBusinessEmailAddress,
              referral: this.state.referral,
            });
          });
        },
        (msgCode, msgDesc, handleDefault) => {
          if (msgCode == 40) {
            if (this.state.broker) {
              Alert.alert(this.state.text.alert, this.state.text.check, [
                { text: this.state.text.ok, style: "cancel" },
              ]);
              this.setState({
                messages: {
                  promotionCodes: this.state.text["invalidPromotionCode"],
                },
              });
            } else {
              Alert.alert(
                this.state.text.addInsuranceAdvisor,
                this.state.text.wouldYouLike +
                  msgDesc.agent.brokerName +
                  this.state.text.toBeYourInsuranceAdvisor,
                [
                  {
                    text: this.state.text.ok,
                    onPress: () =>
                      insuranceAdvisors.setInsuranceAdvisor(
                        { agentCode: msgDesc.agent.agentCode },
                        ["IPA"],
                        this.state.promotionCodes,
                        this.state.language,
                        () =>
                          this.setState({ nextScreen: true }, () =>
                            this._nextScreen()
                          )
                      ),
                  },
                ],
                { cancelable: false }
              );
            }
          } else if (msgCode == 56) {
            Alert.alert(this.state.text.alert, this.state.text.check, [
              { text: this.state.text.ok, style: "cancel" },
            ]);
            this.setState({
              messages: {
                promotionCodes: this.state.text["invalidPromotionCode"],
              },
            });
          } else {
            handleDefault();
          }
          this.setState({ submitButtonDisabled: false });
        }
      );
    }
  }

  _handleGroupOccupations = () => {

    //slice if too many; add if too little
    let occupationsDict = this.state.insuredOccupations
    if(occupationsDict.length == 0){
      for(let i = 0; i < this.state.numberOfIndividuals; i++){
        occupationsDict.push(undefined)
      }
      this.setState({
        insuredOccupations: occupationsDict
      })
    } else if(this.state.numberOfIndividuals < this.state.insuredOccupations.length){
      this.setState({
        insuredOccupations: this.state.insuredOccupations.slice(0, parseInt(this.state.numberOfIndividuals))
      })
    } else if (this.state.numberOfIndividuals > this.state.insuredOccupations.length){
      for(let i = 0; i < (this.state.numberOfIndividuals - this.state.insuredOccupations.length); i++){
        occupationsDict.push(undefined)
      }
      this.setState({
        insuredOccupations: occupationsDict
      })
    }
    
  }

  render() {
    const leaflet =
      "https://sitecontent-prd.s3.ap-east-1.amazonaws.com/bravo/Product_leaflet/Product_leaflet_IPA.pdf";
    const provision =
      "https://sitecontent-prd.s3.ap-east-1.amazonaws.com/bravo/Product_leaflet/Product_Provision_Generali_Personal_Accident_Insurance_Policy.pdf";


    let listOfOccupations = []
    for (let i = 0; i < this.state.numberOfIndividuals; i++) {
      listOfOccupations.push(
        <OccupationQuestion 
        index={i} 
        number={i} 
        occupation={this.state.insuredOccupations[i]} 
        occupationList={this.state.occupations}
        onChange={this._handleOccupationChange}
        message={this.state.messages.insuredOccupations ? this.state.messages.insuredOccupations[i] : undefined}
        />
      )
    
    }
    return (
      <div className="App">
        <Helmet>
          <title>IPA</title>
        </Helmet>

        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{ backgroundColor: "#f2f2f2" }}>
          <Hidden xsDown>
            <Grid
              item
              xs
              style={{ marginBottom: 13, marginLeft: "5%", marginRight: "5%" }}
            >
              <View style={[styles.greeting, { fontWeight: "bold" }]}>
                <Text
                  style={[
                    styles.greetingText,
                    { marginTop: "10%", fontSize: 35 },
                  ]}
                >
                  {this.state.text.ipa}
                </Text>
                <p></p>
                {this.state.language == "zhHK" ? (
                  <View>
                    <Text style={{ fontSize: 20, textAlign: "center" }}>
                      {this.state.text.enjoy}
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text style={{ fontSize: 20, textAlign: "center" }}>
                      {this.state.text.enjoy}
                    </Text>
                  </View>
                )}
              </View>
            </Grid>
          </Hidden>
          {this.state.alert}
          <Grid
            item
            xs
            style={{
              marginTop: 30,
              marginBottom: 50,
              backgroundColor: "white",
            }}
          >
            <ScrollView style={(styles.form, styles.fie)}>
              <View style={(formStyles.form, styles.fie)}>
                <View style={styles.fieldset}>
                  <Text style={styles.h2}>
                    {this.state.text.generaliPersonalAccidentInsurancePlan}
                  </Text>
                </View>
                <Text>
                  {this.state.text.sometimesEvenMinorAccidentsLongContent}
                </Text>
                <ProductEdges
                  productEdges={[
                    this.state.text.highMedicalExpenses,
                    this.state.text.hospitaliztionBenefitsInHK,
                    this.state.text.twelveMonthsConsecutiveCover,
                    // this.state.text.optionalCoverForAccompanied,
                  ]}
                />

                <View style={styles.fieldset}>
                  <AttachmentLink
                    leaflet={leaflet}
                    provision={provision}
                    history={this.props.history}
                  />

                  <Text style={styles.h4}>{this.state.text.planType}</Text>
                  <View style={styles.planTypes}>
                    <PlanTypePicker
                      selectedPlanType={this.state.planType}
                      onSelect={(selectedPlanType) =>
                        this.setState({ planType: selectedPlanType })
                      }
                      planTypes={planTypes}
                    />
                  </View>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={styles.h4}>
                    {this.state.text.coverType}
                  </Text>
                  <ModalSelector
                    data={[
                      {
                        key: "individual",
                        label:
                          options.people.individual.label[this.state.language],
                      },
                      {
                        key: "withSpouse",
                        label:
                          options.people.withSpouse.label[this.state.language],
                      },
                      {
                        key: "group",
                        label:
                          options.people.group.label[this.state.language],
                      },
                      {
                        key: "withChildren",
                        label:
                          options.people.withChildren.label[this.state.language],
                      },
                    ]}
                    initValue={
                      options.people[this.state.people].label[
                        this.state.language
                      ]
                    }
                    onChange={
                      (option) => {
                        this.setState({ people: option.key })
                        this._handleGroupOccupations()
                      }
                    }
                    cancelText={this.state.text.cancel}
                  />
                </View>

                {
                  (this.state.people == 'withSpouse' || this.state.people == 'withChildren') &&
                    <View style={styles.fieldset}>
                      <Text style={styles.h4}>{this.state.text.numberOfChildren}</Text>
                      <TextInput
                        style={styles.input}
                        onChangeText={(text) => {
                          if(parseInt(text) > 10 || parseInt(text) < 0){
                            this.setState({alert: Alert.alert(
                              this.state.text.alert,
                              this.state.text.limitedTo10,
                              [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
                            )})
                            return;                          
                          }
                          this.setState({numberOfChildren: text})
                        }}
                        value={this.state.numberOfChildren}
                        keyboardType='numeric'
                      />
                      <Text style={formStyles.message}>{this.state.messages.numberOfChildren}</Text>
                    </View>
                }

                {
                  this.state.people == 'group' &&
                    <View style={styles.fieldset}>
                      <Text style={styles.h4}>{this.state.text.numberOfIndividuals}</Text>
                      <TextInput
                        style={styles.input}
                        onChangeText={(text) => {
                          if(parseInt(text) > 10 || parseInt(text) < 0){
                            this.setState({alert: Alert.alert(
                              this.state.text.alert,
                              this.state.text.limitedTo10,
                              [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
                            )})
                            return;                          
                          }

                          this.setState({ 
                            numberOfIndividuals: text,
                          }, () => {
                            this._handleGroupOccupations()
                          })

                        }}
                        value={this.state.numberOfIndividuals}
                        keyboardType='numeric'
                      />
                      {
                        parseInt(this.state.numberOfIndividuals) > 10 &&
                          <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
                      }
                      <Text style={formStyles.message}>{this.state.messages.numberOfIndividuals}</Text>
                    </View>
                    
                }

                { this.state.people == "group" ? 
                  listOfOccupations
                :
                <View style={formStyles.fieldset}>
                  <Text style={styles.h4}>{this.state.text.insuredOccupation}</Text>
                  <ModalSelector
                    data={this.state.occupations}
                    // initValue={options.occupation[option.key]}
                    onChange={(option) =>{
                      this.setState({ 
                        insuredOccupation: option.key,
                        insuredOccupationLabel: this.state.occupations.find(obj => obj.key === option.key)  })
                    }
                      
                    }
                    cancelText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.insuredOccupation}</Text>
                  {/* {(this.state.insuredOccupation == "OC3" ||
                    this.state.insuredOccupation == "OC4") && (
                    <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                  )} */}
                </View>

                }

                



                {this.state.people == "withSpouse" && (
                  <View style={formStyles.fieldset}>
                    <Text style={styles.h4}>{this.state.text.spouseOccupation}</Text>
                    <ModalSelector
                      data={this.state.occupations}
                     // initValue={options.occupation[this.state.spouseOccupation].label[this.state.language] + ' ' + options.occupation[this.state.spouseOccupation].description[this.state.language]}
                      onChange={(option) =>
                        this.setState({ 
                          spouseOccupation: option.key,
                          spouseOccupationLabel: this.state.occupations.find(obj => obj.key === option.key) })
                      }
                      cancelText={this.state.text.cancel}
                    />
                  <Text style={formStyles.message}>{this.state.messages.spouseOccupation}</Text>
                    {(this.state.spouseOccupation == "OC3" ||
                      this.state.spouseOccupation == "OC4") && (
                      <Text>
                        {this.state.text.manualUnderwritingsIsRequired}
                      </Text>
                    )}
                  </View>
                )}

                {this.state.planType == "IPAT" && (
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={styles.h4}>
                        {options.coverage.coverageA1.label[this.state.language]}
                      </Text>
                      <Text>
                        {
                          options.coverage.coverageA1.description[
                            this.state.language
                          ][this.state.language]
                        }
                      </Text>
                      
                      <TextInput
                        style={styles.input}
                        placeholder={this.state.text.sumInsured}
                        //value={addCommas(this.state.coverageA1)}
                        value={addCommas(Math.max(1, Math.min(100000, Number(this.state.coverageA1))))}
                        onChangeText={(text) =>
                          this.setState({ coverageA1: removeCommas(text) })
                        }
                        keyboardType="numeric"
                      />
                      <Text style={formStyles.message}>
                        {this.state.messages.coverageA1}
                      </Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={styles.h4}>
                        {options.coverage.coverageA2.label[this.state.language]}
                      </Text>
                      <Text>
                        {
                          options.coverage.coverageA2.description[
                            this.state.language
                          ]
                        }
                      </Text>
                      <TextInput
                        style={styles.input}
                        placeholder={this.state.text.sumInsured}
                        //value={addCommas(this.state.coverageA2)}
                        value={addCommas(Math.max(1, Math.min(100, Number(this.state.coverageA2))))}
                        onChangeText={(text) =>
                          this.setState({ coverageA2: removeCommas(text) })
                        }
                        keyboardType="numeric"
                      />
                      <Text style={formStyles.message}>
                        {this.state.messages.coverageA2}
                      </Text>
                    </View>
                    {!(
                      this.state.people == "OC3" ||
                      this.state.insuredOccupation == "OC4"
                    ) && (
                      <View style={formStyles.fieldset}>
                        <Text style={styles.h4}>
                          {
                            options.coverage.coverageB.label[
                              this.state.language
                            ]
                          }
                        </Text>
                        <Text>
                          {
                            options.coverage.coverageB.description[
                              this.state.language
                            ]
                          }
                        </Text>
                        <TextInput
                          style={styles.input}
                          placeholder={this.state.text.sumInsured}
                          //value={addCommas(this.state.coverageB)}
                          value={addCommas(Math.max(1, Math.min(100, Number(this.state.coverageB))))}
                          onChangeText={(text) =>
                            this.setState({ coverageB: removeCommas(text) })
                          }
                          keyboardType="numeric"
                        />
                        <Text style={formStyles.message}>
                          {this.state.messages.coverageB}
                        </Text>
                      </View>
                    )}
                    <View style={formStyles.fieldset}>
                      <Text style={styles.h4}>
                        {options.coverage.coverageC.label[this.state.language]}
                      </Text>
                      <Text>
                        {
                          options.coverage.coverageC.description[
                            this.state.language
                          ]
                        }
                      </Text>
                      <TextInput
                        style={styles.input}
                        placeholder={this.state.text.sumInsured}
                        //value={addCommas(this.state.coverageC)}
                        value={addCommas(Math.max(1, Math.min(100, Number(this.state.coverageC))))}
                        onChangeText={(text) =>
                          this.setState({ coverageC: removeCommas(text) })
                        }
                        keyboardType="numeric"
                      />
                      <Text style={formStyles.message}>
                        {this.state.messages.coverageC}
                      </Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={styles.h4}>
                        {options.coverage.coverageD.label[this.state.language]}
                      </Text>
                      <Text>
                        {
                          options.coverage.coverageD.description[
                            this.state.language
                          ]
                        }
                      </Text>
                      <YesNoQuestion
                        choice={this.state.coverageD}
                        onChange={(choice) =>
                          this.setState({ coverageD: choice })
                        }
                      />
                    </View>
                  </View>
                )}

                {!this.state.businessEmailAddress && (
                  <View style={styles.fieldset}>
                    <Text style={styles.h4}>
                      {this.state.text.promotionCodes}
                    </Text>
                    <TextInput
                      style={styles.input}
                      placeholder={this.state.text.optional}
                      value={this.state.promotionCodes}
                      onChangeText={(text) =>
                        this.setState({ promotionCodes: text })
                      }
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.promotionCodes}
                    </Text>
                  </View>
                )}

                {!this.state.promotionCodes &&
                  this.state.showBusinessEmailAddress && (
                    <View style={styles.fieldset}>
                      <Text style={styles.h4}>
                        {this.state.text.businessEmailAddress}
                      </Text>
                      <TextInput
                        style={styles.input}
                        placeholder={this.state.text.optional}
                        keyboardType="email-address"
                        value={this.state.businessEmailAddress}
                        onChangeText={(text) =>
                          this.setState({ businessEmailAddress: text })
                        }
                      />
                      <Text style={formStyles.message}>
                        {this.state.messages.businessEmailAddress}
                      </Text>
                    </View>
                  )}

                {this.state.submitButtonDisabled ? (
                  <View style={formStyles.submitButtonDisabled}>
                    <Text style={formStyles.submitButtonText}>
                      {this.state.text.pleaseWait}
                    </Text>
                  </View>
                ) : (
                  <TouchableOpacity
                    style={formStyles.submitButton}
                    onPress={() => this._nextScreen()}
                  >
                    <Text style={formStyles.submitButtonText}>
                      {this.state.text.getAQuote}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </ScrollView>
          </Grid>
          <Hidden xsDown>
            <div style={{ marginRight: "5%" }} />
          </Hidden>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: "#C50000",
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: "center",
  },
  datepicker: {
    width: "100%",
    marginTop: 10,
  },
  // planTypes: {
  //   marginTop: 10,
  //   alignSelf: 'center',
  //   width: '40%',
  // },
  submitButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    marginBottom: 20,
    backgroundColor: "#C50000",
  },
  submitButtonText: {
    fontSize: 18,
    color: "white",
  },
  line: {
    justifyContent: "center",
    alignItems: "center",
  },
};
