import React from 'react';
import {View} from 'react-native';

import Locale from './../Locale';
import Member from './../Member';
import {Screen, Layout, Navbar} from './../Screen';
import Jumbotron from './../components/Jumbotron';
import TabView from './TabView';
import NoticeTab from './NoticeTab';
import PurchaseTab from './PurchaseTab';
import ClaimTab from './ClaimTab';

export default class HistoryScreen extends Screen {
  constructor(props) {
    super(props);

    this.state = {
      broker: this.getParam('broker'),
      tab: 1,
    };
  }

  render() {
    const {broker, tab} = this.state;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.historyList}
        />

        {Locale.text.history}
        {''}

        <View style={Layout.container}>
          {
            !this.state.broker &&
              <Jumbotron source={require('./images/History.png')} />
          }

          <TabView
            tabs={[
              {
                label: Locale.text.notice,
                content: <NoticeTab />,
              },
              {
                label: Locale.text.purchase,
                content: <PurchaseTab screen={this} broker={broker} />,
              },
              {
                label: Locale.text.claim,
                content: <ClaimTab broker={broker} />,
              },
            ]}
            value={this.state.tab}
            onChange={(tab) => this.setState({tab})}
          />
        </View>
      </Layout>
    );
  }
}
