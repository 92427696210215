import React from 'react';
import {View, Text} from 'react-native';

import Locale from './../../Locale';
import {Heading2, TextInput, DropDownList, YesNoQuestion} from './../../Form';
import SizeOfTheProperty from './SizeOfTheProperty';
import HengFaChuenChecking from './../HengFaChuenChecking';

export default class InsuredHomeForm extends React.Component {
  checkHengFaChuen() {
    const {fullAddress} = this.props.insuredHome;

    if (fullAddress && fullAddress.length) {
      return HengFaChuenChecking.check(fullAddress);
    }
  }

  render() {
    const {insuredHome, onChange, messages} = this.props;

    return (
      <View>
        <Heading2>{Locale.text.home}</Heading2>

        <TextInput
          label={Locale.text.address}
          placeholder={Locale.text.hongKongAddressOnly}
          value={insuredHome.fullAddress}
          onChange={(fullAddress) => onChange({fullAddress})}
          message={messages && messages.fullAddress}
        />

        {
          this.checkHengFaChuen() &&
            <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        }

        <SizeOfTheProperty
          label={Locale.text.size}
          value={insuredHome.grossArea}
          onChange={(grossArea) => onChange({grossArea})}
          message={messages && messages.grossArea}
        />

        <DropDownList
          label={Locale.text.houseOrApartment}
          options={[
            {value: 'H', label: Locale.text.house},
            {value: 'A', label: Locale.text.apartment},
          ]}
          value={insuredHome.homeType}
          onChange={(homeType) => onChange({homeType})}
          message={messages && messages.homeType}
        />

        {
          insuredHome.homeType == 'H' &&
          <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        }

        {
          insuredHome.includeBuilding == 'Y' &&
            <View>
              <YesNoQuestion
                label={Locale.text.postCopyToMortgagee}
                value={insuredHome.isPostPolicy}
                onChange={(isPostPolicy) => onChange({isPostPolicy})}
                message={messages && messages.isPostPolicy}
              />

              {
                insuredHome.isPostPolicy == 'Y' &&
                  <TextInput
                    label={Locale.text.nameOfMortgagee}
                    value={insuredHome.mortgageeName}
                    onChange={(mortgageeName) => onChange({mortgageeName})}
                    message={messages && messages.mortgageeName}
                  />
              }

              {
                insuredHome.isPostPolicy == 'Y' &&
                  <TextInput
                    label={Locale.text.addressOfMortgagee}
                    value={insuredHome.mortgageeAddress}
                    onChange={(mortgageeAddress) =>
                      onChange({mortgageeAddress})}
                    message={messages && messages.mortgageeAddress}
                  />
              }
            </View>
        }

        <YesNoQuestion
          label={Locale.text.theHomeIsUnoccupiedForLonger30Days}
          value={insuredHome.isUnoccupied}
          onChange={(isUnoccupied) => onChange({isUnoccupied})}
          manualUnderwriting={insuredHome.isUnoccupied == 'Y'}
          message={messages && messages.isUnoccupied}
        />

        <YesNoQuestion
          label={Locale.text.anyLossDamageLiabilityOrAccident}
          value={insuredHome.isAnyLoss}
          onChange={(isAnyLoss) => onChange({isAnyLoss})}
          manualUnderwriting={insuredHome.isAnyLoss == 'Y'}
          message={messages && messages.isAnyLoss}
        />

        <YesNoQuestion
          label={Locale.text.homePackageTheHomeBuildingIsMoreThan40years}
          value={insuredHome.isOldBuilding}
          onChange={(isOldBuilding) => onChange({isOldBuilding})}
          manualUnderwriting={insuredHome.isOldBuilding == 'Y'}
          message={messages && messages.isOldBuilding}
        />

        <YesNoQuestion
          label={Locale.text.anyInsuranceCompnayDeclined}
          value={insuredHome.isProposalDeclined}
          onChange={(isProposalDeclined) => onChange({isProposalDeclined})}
          manualUnderwriting={insuredHome.isProposalDeclined == 'Y'}
          message={messages && messages.isProposalDeclined}
        />
      </View>
    );
  }
}
