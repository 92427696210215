import React from 'react';
import {Text, View, FlatList} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import Format from './../../Format';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, Heading2,
  Divider, Button, YesNoQuestion} from './../../Form';
import DHScreen from './DHScreen';
import Confirmation from './../components/Confirmation';
import PolicyHolderConfirmation from './../components/PolicyHolderConfirmation';
import SpecialRequestConfirmation
  from './../components/SpecialRequestConfirmation';
import ImportantNotes from './../components/ImportantNotes';
import DisclaimerAndPIC from './../components/DisclaimerAndPIC';
import DirectMarketing from './../components/DirectMarketing';
import CommissionsDisclosures from './../components/CommissionsDisclosures';
import Payment from './../components/Payment';

export default class DHScreen4 extends DHScreen {
  constructor(props) {
    super(props);

    this.readPlanBroker().then((planBroker) => this.setState({planBroker}));
  }

  render() {
    const {
      insuranceProduct:
        {pl, agent, policy, policyHolder, domesticHelper, payment},
      messages,
    } = this.state;
    const {formatName, formatDate, getYesNoLabel, getGenderLabel} = Format;
    const manualUnderwriting =
      domesticHelper.isRefused == 'Y' ||
      domesticHelper.isMedAttn == 'Y' ||
      !!policy.specialRequest;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getInsured}
        />

        {Locale.text.domesticHelperInsurance}
        {Locale.text.domesticHelperInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.domesticHelperInsurance}
            </Heading1>

            <Confirmation
              data={[
                {
                  label: Locale.text.productType,
                  value: this.getIncludeGuardianLabel(),
                },
                {
                  label: Locale.text.effectiveDate,
                  value: formatDate(policy.effectiveDateStart) +
                    ' - ' + formatDate(policy.effectiveDateEnd),
                },
                {
                  label: Locale.text.promotionCodes,
                  value: payment.promotionCode,
                },
                {
                  label: Locale.text.businessEmailMembership,
                  value: policy.businessEmail,
                },
              ]}
            />

            <PolicyHolderConfirmation
              pl={pl}
              policyHolder={policyHolder}
              addressLabel={Locale.text.residentialAddress}
            />

            <Confirmation
              heading={Locale.text.domesticHelper}
              data={[
                {
                  label: Locale.text.name,
                  value: formatName(
                    domesticHelper.salutation,
                    domesticHelper.firstName,
                    domesticHelper.lastName
                  ),
                },
                {
                  label: domesticHelper.documentType == 'HKID' ?
                    Locale.text.hkid : Locale.text.passport,
                  value: domesticHelper.documentNo,
                },
                {
                  label: Locale.text.dateOfBirth,
                  value: formatDate(domesticHelper.dateOfBirth),
                },
                {
                  label: Locale.text.sex,
                  value: getGenderLabel(domesticHelper.insuredSex),
                },
                {
                  label: Locale.text.countryOfOrigin,
                  value: this.getInsuredCountryLabel(),
                },
                {
                  label: Locale.text.thePlaceOfEmploymentIsInHK,
                  value: Locale.text.yes,
                },
                {
                  label: Locale.text.theHelperHasEverBeenRufusedForEnrollment,
                  value: getYesNoLabel(domesticHelper.isRefused),
                },
                {
                  label:
                    Locale.text.theHelperIsReceivingOrContemplatingAnyMdeical,
                  value: getYesNoLabel(domesticHelper.isMedAttn),
                },
                {
                  label: Locale.text.theHelperIsFullTimeOverseasHelper,
                  value: Locale.text.yes,
                },
              ]}
            />

            <SpecialRequestConfirmation policy={policy} />

            <ImportantNotes>
              {Locale.text.thisInsuranceIsAvailableForFullTimeOverseas}
              {Locale.text.assicurazioniGeneraliSpAReserves}
              {Locale.text.policyholderMustBeHongKongResident}
            </ImportantNotes>

            <YesNoQuestion
              screen={this}
              label={Locale.text.
                iHerebyDeclareThatTheBasicBenefitPayableUnderSection2}
              value={policy.declaration}
              onChange={(declaration) => this.updatePolicy({declaration})}
              message={messages && messages.declaration}
            />

            <DisclaimerAndPIC
              screen={this}
              product={Locale.text.domesticHelperInsurance}
              planBroker={this.state.planBroker}
              value={policy.disclaimerAndPIC}
              onChange={(disclaimerAndPIC) =>
                this.updatePolicy({disclaimerAndPIC})}
              message={messages && messages.disclaimerAndPIC}
            />

            <DirectMarketing
              screen={this}
              value={policy.allowPrivacyPromote}
              onChange={(allowPrivacyPromote) =>
                this.updatePolicy({
                  allowPrivacyPromote,
                  allowPrivacyPromote3P: allowPrivacyPromote,
                })}
              message={messages && messages.allowPrivacyPromote}
            />

            <CommissionsDisclosures
              agent={this.state.agent}
              broker={this.state.broker}
              promotionCode={payment.promotionCode}
              value={policy.commissionsDisclosures}
              onChange={(commissionsDisclosures) =>
                this.updatePolicy({commissionsDisclosures})}
              message={messages.commissionsDisclosures}
            />

            <Payment
              manualUnderwriting={manualUnderwriting}
              broker={this.state.broker}
              payment={payment}
              onChange={(ecash) => this.updatePayment({ecash})}
              premium={this.getParam('premium')}
              ecLevy={this.getParam('ecLevy')}
              terrorCharge={this.getParam('terrorCharge')}
              levy={this.getParam('levy')}
              total={this.getParam('total')}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.confirm()}
            >
              {Locale.text.submit}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
