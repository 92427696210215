export const enUS = {
  currency: 'Currency',
  pleaseFill: 'Please fill in the amount',
  others: 'Others',
  aud: 'AUD',
  eur: 'EUR',
  gbp: 'GBP',
  hkd: 'HKD',
  jpy: 'JPY',
  rmb: 'RMB',
  usd: 'USD',
  cancel: 'cancel',
  }
