import React from 'react';
import {
  Text, View, ScrollView, TouchableOpacity,
} from 'react-native';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US';
import { zhHK } from './zh-HK';
import { planTypes } from './planTypes';
import { formStyles } from './../../../config/styles';
import { platform } from './../../../config/platform';
import { msgCodeDetail } from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import { clearEmptyStrings, removeSpaces, handleExpiredLogin } from './../../../lib/utils';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import PromotionCode from './../../../lib/PromotionCode';
import { stateStorage } from './../../../lib/StateStorage';
import { host, api26, busiSecKey } from './../../../config/api';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import ProductEdges from './../../../components/ProductEdges/ProductEdges';
import AttachmentLink from './../../../components/AttachmentLink/AttachmentLink';
import PlanTypePicker from './../../../components/PlanTypePicker/PlanTypePicker';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';

export default class DomesticHelperInsurance1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state ?
        this.props.location.state.broker : undefined,
      planType: 'with',
      periodOfInsurance: 'oneYear',
      messages: {},
      text: {},
    };

    if (!this.state.broker && localStorage.getItem('promotionCodes')) {
      this.state.promotionCodes = localStorage.getItem('promotionCodes');
    }

    if (!this.state.broker && localStorage.getItem('businessEmail')) {
      this.state.businessEmailAddress = localStorage.getItem('businessEmail');
    }

    try{
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        this.state.businessEmailAddress = undefined;
        localStorage.removeItem('businessEmail');

        this.state.promotionCodes = promotionCode;
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        this.state.promotionCodes = undefined;
        localStorage.removeItem('promotionCodes');

        this.state.businessEmailAddress = businessEmail;
        localStorage.setItem('businessEmail', businessEmail);
      }

      const referral = searchParams.get('refcode');
      if (referral) {
        this.state.referral = referral;
      }

    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
    localStorage.setItem('preventGoBack', 'false');
  }


  componentDidMount() {
      stateStorage.getState('domestic1', (state) => {
        if (state && !this.state.promotionCodes && !this.state.businessEmailAddress) {
          if (state.promotionCodes) {
            delete this.state.businessEmailAddress;
          }
          if (state.businessEmailAddress) {
            delete this.state.promotionCodes;
          }
          this.setState(state);
        }
      });

      window.scrollTo(0, 0)
    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          submitButtonDisabled: member && !this.state.broker,
          member: member,
          token: token,
        }, () => {
          if (!this.state.broker) {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                if (insuranceAdvisors.DH) {
                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.DH.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'DH',
                      (promotionCodes) => {
                        this.setState({
                          submitButtonDisabled: false,
                          businessEmailAddress: undefined,
                          promotionCodes: promotionCodes ?
                            promotionCodes : this.state.promotionCodes,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              },
              (alert) => {
                this.setState({alert: alert})
              }
            );
            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      businessEmailAddress: state.businessEmailAddress,
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      businessEmailAddress: {
        format: englishFormat,
      },
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;

      premium(
        {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': 'EAPP',
          'pl': 'DH',
          'member.id' : this.state.member ? this.state.member.id : undefined,
          'member.token' : this.state.token ? this.state.token.tokenStr : undefined,
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'domesticHelper.includeOutpatient': this.state.planType == 'with' ? 'Y' : 'N',
        },
        (msgDesc) =>  {
          this.setState({submitButtonDisabled: false, alert: undefined}, () => {
            stateStorage.setState('domestic1', this.state);
            stateStorage.setState('domestic2', undefined);

            this.props.history.push('/domestic2', {
              broker: this.state.broker,
              centaline: this.state.centaline,
              planType: this.state.planType,
              periodOfInsurance: this.state.periodOfInsurance,
              promotionCodes: removeSpaces(this.state.promotionCodes),
              businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
              showBusinessEmailAddress: this.state.showBusinessEmailAddress,
              premium: msgDesc.premium,
              referral: this.state.referral,
            });
          });
        },
        (msgCode, msgDesc, handleDefault) =>  {
          if (msgCode == 40) {
            if (this.state.broker) {
              this.setState({
                messages: {
                  promotionCodes: this.state.text['invalidPromotionCode']
                },
                alert: Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [{
                    text: this.state.text.ok,
                    onPress: () => this.setState({alert: null}),
                    style: 'cancel',
                  }],
                ),
              });
            }
            else {
              this.setState({
                alert: Alert.alert(
                  this.state.text.addInsuranceAdvisor,
                  this.state.text.wouldYouLike
                  + msgDesc.agent.brokerName
                  + this.state.text.toBeYourInsuranceAdvisor,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => {
                        if (!this.state.bindingInsuranceAdvisor) {
                          this.setState({bindingInsuranceAdvisor: true}, () => {
                            insuranceAdvisors.setInsuranceAdvisor(
                              {agentCode: msgDesc.agent.agentCode},
                              ['DH'],
                              this.state.promotionCodes,
                              this.state.language,
                              () => this.setState({bindingInsuranceAdvisor: false},
                                () => this._nextScreen())
                            );
                          });
                        }
                      }
                    },
                  ],
                  { cancelable: false }
                )
              });
            }
          }
          else if (msgCode == 56) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                  style: 'cancel',
                }],
              ),
            });
          }
          else {
            handleDefault();
          }
          this.setState({submitButtonDisabled: false});
        },
      )
    }
  }



  render() {
    let leaflet, provision;
      if (this.state.centaline) {
        leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Centaline_Helper_eng_.pdf';
        provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Centaline%20Domestic%20Helper%20Protector%20Policy.pdf';
      }
      else {
        leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Domestic%20Helper.pdf';
        provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Domestic%20Helper%20Insurance%20Policy.pdf';
      }

    return (
      <div className='App'>

      <Helmet>
        <title>Domestic Helper Insurance | Bravo Generali</title>
      </Helmet>

      <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
            <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                  <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.domestic}</Text>
                    <p></p>
                    {
                      this.state.language == 'zhHK' ?
                      <View>
                      <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.beingand}</Text>
                      </View>:
                      <View>
                      <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.beingand}</Text>
                      </View>

                    }
                </View>

            </Grid>
        </Hidden>
      <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>


      {this.state.alert}
      <ScrollView style={styles.form, styles.fie}>
      <View style={styles.fieldset}>
        <Text style={styles.h2}>
          {
            this.state.centaline ?
            this.state.text.centalineDomesticHelperProtector :
            this.state.text.domesticHelperInsurance
          }
        </Text>
      </View>

      {
        this.state.centaline ?
          <View>
            <Text>{this.state.text.centalineProductDescription}</Text>
            <ProductEdges
              productEdges={[
                this.state.text.centalineProductEdge1,
                this.state.text.centalineProductEdge2,
                this.state.text.centalineProductEdge3,
                this.state.text.centalineProductEdge4,
                this.state.text.centalineProductEdge5,
              ]}
            />
          </View> :
          <View>
          <View style={{textAlign: 'left'}}>
            <Text>{this.state.text.asAResponsibleEmployer}</Text>
            </View>
            <ProductEdges
              productEdges={[

                this.state.text.coverHelpersDeath,
                this.state.text.coverHospitalization,
                this.state.text.optionalOutpatientExenses,
                this.state.text.comprehensiveCoverageFromBasicEmployee,
              ]}
            />
        </View>
      }

      <AttachmentLink
        leaflet={leaflet}
        provision={provision}
        history={this.props.history}
      />

      <View style={styles.fieldset}>
        <Text style={styles.h4}>{this.state.text.planType}</Text>
        <View style={styles.planTypes}>
          <PlanTypePicker
            selectedPlanType={this.state.planType}
            onSelect={
              (selectedPlanType) =>
                this.setState({planType: selectedPlanType})
            }
            planTypes={planTypes}
          />
        </View>
      </View>

      {
        this.state.centaline &&
          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.periodOfInsurance}</Text>
            <ModalSelector
              data={[
                { key: 'oneYear', label: this.state.text.oneYear },
                { key: 'twoYears', label: this.state.text.twoYears },
              ]}
              initValue={this.state.text[this.state.periodOfInsurance]}
              onChange={(option) => this.setState({periodOfInsurance: option.key })}
              cancelText={this.state.text.cancel}
            />
              <Text style={formStyles.message}>{this.state.messages.periodOfInsurance}</Text>
          </View>
      }

      {
        !this.state.businessEmailAddress &&
          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.promotionCodes}</Text>
            <TextInput
              style={styles.input}
              placeholder={this.state.text.optional}
              placeholderTextColor='lightgrey'
              value={this.state.promotionCodes}
              onChangeText={(text) => this.setState({promotionCodes: text})}
            />
            <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
          </View>
      }

      {
        !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.businessEmailAddress}</Text>
            <TextInput
              style={styles.input}
              placeholder={this.state.text.optional}
              placeholderTextColor='lightgrey'
              keyboardType='email-address'
              value={this.state.businessEmailAddress}
              onChangeText={(text) => this.setState({businessEmailAddress: text})}
            />
            <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
          </View>
      }

      {
        this.state.submitButtonDisabled ?
          <View style={formStyles.submitButtonDisabled}>
            <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
          </View> :
          <TouchableOpacity style={formStyles.submitButton} onPress={() => this._nextScreen()}>
            <Text style={formStyles.submitButtonText}>{this.state.text.getAQuote}</Text>
          </TouchableOpacity>
      }

    </ScrollView>

    </Grid>
      <Hidden xsDown>
       <div style={{marginRight: '5%'}} />
      </Hidden>
   </Grid>

      <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
      margin: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      minWidth: 300,
      maxWidth: 600,
      minHeight: 580,
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: 'center',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  // planTypes: {
  //   marginTop: 10,
  //   alignSelf: 'center',
  //    width: '75%',
  // },
  importantNotes: {
    marginTop: 10,
    marginBottom: 40,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
  buton: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },

};
