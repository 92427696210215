import React from 'react';
import {Text, View} from 'react-native';

import Locale from './../../Locale';
import Format from './../../Format';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, YesNoQuestion, Button} from './../../Form';
import AHIScreen from './AHIScreen';
import Confirmation from './../components/Confirmation';
import PolicyHolderConfirmation from './../components/PolicyHolderConfirmation';
import MembersConfirmation from './MembersConfirmation';
import SpecialRequestConfirmation
  from './../components/SpecialRequestConfirmation';
import ImportantNotes from './../components/ImportantNotes';
import DisclaimerAndPIC from './../components/DisclaimerAndPIC';
import DirectMarketing from './../components/DirectMarketing';
import CommissionsDisclosures from './../components/CommissionsDisclosures';
import Payment from './../components/Payment';

export default class AHIScreen4 extends AHIScreen {
  constructor(props) {
    super(props);

    this.readPlanBroker().then((planBroker) => this.setState({planBroker}));
  }

  render() {
    const {
      insuranceProduct: {agent, policy, policyHolder, ahi, members, payment},
      messages,
    } = this.state;
    const {formatDate} = Format;
    const manualUnderwriting = !!policy.specialRequest;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getInsured}
        />

        {Locale.text.ahiInsurance}
        {''}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.ahiInsurance}
            </Heading1>

            <Confirmation
              data={[
                {
                  label: Locale.text.insuranceAdvisorMobile,
                  value: agent.mobile,
                },
                {
                  label: Locale.text.productType,
                  value: this.getPremiumPlanLabel(),
                },
                {
                  label: Locale.text.effectiveDate,
                  value: formatDate(policy.effectiveDateStart) +
                    ' - ' + formatDate(policy.effectiveDateEnd),
                },
                {
                  label: Locale.text.promotionCodes,
                  value: payment.promotionCode,
                },
                {
                  label: Locale.text.businessEmailAddress,
                  value: policy.businessEmail,
                },
              ]}
            />

            <PolicyHolderConfirmation policyHolder={policyHolder} />

            <MembersConfirmation members={members} />

            <SpecialRequestConfirmation policy={policy} />

            <ImportantNotes>
              {Locale.text.assicurazioniGeneraliReservesTheRight}
            </ImportantNotes>

            <DisclaimerAndPIC
              screen={this}
              product={Locale.text.ahiInsurance}
              planBroker={this.state.planBroker}
              value={policy.disclaimerAndPIC}
              onChange={(disclaimerAndPIC) =>
                this.updatePolicy({disclaimerAndPIC})}
              message={messages && messages.disclaimerAndPIC}
            />

            <YesNoQuestion
              label={Locale.text.IWeHereByAuthorizedAnyLicensedSuchInformation}
              value={policy.declaration}
              onChange={(declaration) => this.updatePolicy({declaration})}
              message={messages.declaration}
            />

            <DirectMarketing
              screen={this}
              value={policy.allowPrivacyPromote}
              onChange={(allowPrivacyPromote) =>
                this.updatePolicy({
                  allowPrivacyPromote,
                  allowPrivacyPromote3P: allowPrivacyPromote,
                })}
              message={messages.allowPrivacyPromote}
            />

            <CommissionsDisclosures
              agent={this.state.agent}
              broker={this.state.broker}
              promotionCode={payment.promotionCode}
              value={policy.commissionsDisclosures}
              onChange={(commissionsDisclosures) =>
                this.updatePolicy({commissionsDisclosures})}
              message={messages.commissionsDisclosures}
            />

            <Payment
              manualUnderwriting={manualUnderwriting}
              broker={this.state.broker}
              payment={payment}
              onChange={(ecash) => this.updatePayment({ecash})}
              premium={this.getParam('premium')}
              levy={this.getParam('levy')}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.confirm()}
            >
              {Locale.text.confirm}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
