import React from 'react';
import { Text } from 'react-native';

import Locale from './../../Locale';
import Member from './../../Member';
import { YesNoQuestion } from './../../Form';


import { languageStorage } from './../../lib/LanguageStorage';

export default class DisclaimerAndPIC extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      choice: this.props.choice ? this.props.choice : null,
      text: {},
    };
  }
 

  componentDidMount() {
    languageStorage.getLanguage(
      (language) => this.setState({language: language}));
  }

  showDisclaimer() {
    this.props.screen.push('29C');
  }

  showPIC() {
    this.props.screen.push('29E');
  }

  showTOBA() {
    window.open(this.props.planBroker.TOBA);
  }

  render() {
    const {
      product, noDisclaimer, planBroker, value, onChange, message,
    } = this.props;
    const color = Member.getThemeColor();

    let provisionE =  'https://www.app-generali.com/share_files/Product_29doc/29E_en.pdf';
    let provisionC =  'https://www.app-generali.com/share_files/Product_29doc/29C_en.pdf';
    if(this.state.language == 'enUS')
    {
       provisionE = 'https://www.app-generali.com/share_files/Product_29doc/29E_en.pdf';
       provisionC =  'https://www.app-generali.com/share_files/Product_29doc/29C_en.pdf';
      }
      else
      {
        provisionE = 'https://www.app-generali.com/share_files/Product_29doc/29E_zh.pdf';
        provisionC =  'https://www.app-generali.com/share_files/Product_29doc/29C_zh.pdf';
      }

    return (
      <YesNoQuestion
        label={
          <Text>
            <Text>{Locale.text.agreed}</Text>

            {product && <Text>{product + Locale.text.comma}</Text>}

            {
              !noDisclaimer &&
                <Text>
                  <Text style={{color}} onPress={() =>{}}>
           
                    <a href={provisionC} target="_blank">{Locale.text.disclaimer}</a>
                  </Text>

                  {planBroker && planBroker.TOBA ?
                    Locale.text.disclaimerComma : Locale.text.and}
                </Text>
            }

            <Text style={{color}} onPress={() =>{}}>
            <a href={provisionE} target="_blank">{Locale.text.pic}</a>
            </Text>

            {
              planBroker && planBroker.TOBA &&
                <Text>
                  {Locale.text.andThe}
                  <Text style={{color}} onPress={() => this.showTOBA()}>
                    {Locale.text.termsOfBusinessAgreementWith}
                    {planBroker.nameEnUs}
                    {Locale.text.termsOfBusinessAgreementWith2}
                  </Text>
                </Text>
            }

            {Locale.text.legalDocumentLinkPeriod}
          </Text>
        }
        value={value}
        onChange={onChange}
        message={message}
      />
    );
  }
}
