import { TrendingUpTwoTone } from '@material-ui/icons';
import React from 'react';
import { Text, Image, View, ScrollView, TouchableOpacity, } from 'react-native';

import Member from './../../Member';
import Alert from '../Alert/Alert';


export default class ListMenu extends React.Component {
  render() {
    return (
      <View style={styles.menu}>
        {this.props.items.map((item, key) =>
          !item.hide &&
            <ListMenuItem
              key={key}
              icon={item.icon}
              text={item.text}
              submenu={item.submenu}
              action={item.action}
              targetScreen={item.targetScreen}
              navigationOptions={item.navigationOptions}
              history={this.props.history}
              fullText={this.props.text}
            />
        )}
      </View>
    );
  }
}

class ListMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenuOpen: false,

    };
  }



  _onPress() {
    if (this.props.action) {
      this.props.action();
    }
    if (this.props.targetScreen) {
      this.props.history.push(
        this.props.targetScreen, this.props.navigationOptions);
    }
    if (this.props.submenu) {
      this.setState({submenuOpen: !this.state.submenuOpen});
    }
  }

  _renderSubmenu() {
    return (
      <View style={styles.submenu}>
        {this.props.submenu.map((item, key) =>
          <TouchableOpacity
            key={key}
            style={styles.submenuItem}
            onPress={() => {
              console.log(this.props)
              if(item.targetScreen && item.alert){
                this.setState({
                  alert: Alert.alert(
                    item.alert.title,
                    item.alert.message,
                    [
                      {
                        text: this.props.fullText.yes,
                        onPress: () => this.props.history.push(
                          item.targetScreen, item.navigationOptions),
                        style: 'cancel',
                      },
                      {
                        text: this.props.fullText.no,
                        onPress: () => this.setState({alert: null}),
                        style: 'cancel',
                      },
                    ]
                  ),
                })
              }else if(item.targetScreen){
                this.props.history.push(
                  item.targetScreen, item.navigationOptions)
              }else{null}
            }}
          >
            <View style={styles.submenuItemText}>
              <Text style={styles.greyText}>{item.text}</Text>
            </View>
            <View style={styles.menuItemArrow}>
              <Image style={styles.menuItemArrowImage} source={greyArrowRight} />
            </View>
          </TouchableOpacity>
        )}
      </View>
    );
  }

 

  render() {
    const color = Member.getThemeColor();

    return (
      <View>
        <TouchableOpacity style={styles.menuItem} onPress={() => this._onPress()}>
          <View style={styles.menuItemIcon}>
            <Image style={styles.menuItemIconImage} source={this.props.icon} />
          </View>
          <View style={styles.menuItemText}>
            <Text style={[styles.redText,{textAlign: 'left', color}]}>{this.props.text}</Text>
          </View>
          <View style={styles.menuItemArrow}>
            { this.state.submenuOpen ?
              <Image style={styles.menuItemArrowImage} source={redArrowDown} /> :
              <Image style={styles.menuItemArrowImage} source={redArrowRight} />
            }
          </View>
        </TouchableOpacity>
        { this.state.submenuOpen ? this._renderSubmenu() : null }
        {this.state.alert}
      </View>
    );
  }
}

const redArrowDown = require('./red_arrow_down.png');
const redArrowRight = require('./red_arrow_right.png');
const greyArrowRight = require('./grey_arrow_right.png');

const styles = {
  redText: {
    color: '#C50000',

  },
  greyText: {
    color: 'grey',
  },
  menu: {
    flex: 1,
    alignItems: 'stretch',
  },
  menuItem: {
    height: 50,
    flexDirection: 'row',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  menuItemIcon: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemIconImage: {
    width: 50,
    height: 50,
  },
  menuItemText: {
    flex: 6,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  menuItemArrow: {
    flex: 2,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  menuItemArrowImage: {
    width: 25,
    height: 25,
  },
  submenu: {
    backgroundColor: 'white',
  },
  submenuItem: {
    height: 50,
    marginLeft: '10%',
    flexDirection: 'row',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  submenuItemText: {
    flex: 8,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
};
