import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const options = {
  people: {
    individual: {
      label: {
        enUS: enUS.individual,
        zhHK: zhHK.individual,
      },
    },
    // refers to Cover Type: Family
    withSpouse: {
      label: {
        enUS: enUS.withSpouse,
        zhHK: zhHK.withSpouse,
      },
    },
    withChildren: {
      label: {
        enUS: enUS.individualNChildren,
        zhHK: zhHK.individualNChildren,
      },
    },
    group: {
      label: {
        enUS: enUS.groupOfIndividuals,
        zhHK: zhHK.groupOfIndividuals,
      },
    },
  },
  occupation: {
    OC1: {
      label: {
        enUS: enUS.class1,
        zhHK: zhHK.class1,
      },
      description: {
        enUS: enUS.nvolveMainlyIndoorWork,
        zhHK: zhHK.nvolveMainlyIndoorWork,
      },
    },
    OC2: {
      label: {
        enUS: enUS.class2,
        zhHK: zhHK.class2,
      },
      description: {
       enUS: enUS.requiringOutdoorWork,
       zhHK: zhHK.requiringOutdoorWork,
      },
    },
    OC3: {
      label: {
        enUS: enUS.class3,
        zhHK: zhHK.class3,
      },
      description: {
        enUS: enUS.ofLightManualWorks,
        zhHK: zhHK.ofLightManualWorks,
      },
    },
    OC4: {
      label: {
        enUS: enUS.class4,
        zhHK: zhHK.class4,
        },
      description: {
        enUS: enUS.ofExtraHazardousNature,
        zhHK: zhHK.ofExtraHazardousNature,
      },
    },
  },
  coverage: {
    coverageA1: {
      label: {
        enUS: enUS.coverageA1,
        zhHK: zhHK.coverageA1,
      },
      description: {
        enUS: enUS.accidentalDeathNPermanent,
        zhHK: zhHK.accidentalDeathNPermanent,
      },
    },
    coverageA2: {
      label: {
        enUS: enUS.coverageA2,
        zhHK: zhHK.coverageA2,
      },
      description: {
        zhHK: zhHK.accidentalMedicalExpenses,
        zhHK: zhHK.accidentalMedicalExpenses,
      },
  },
    coverageB: {
      label: {
        enUS: enUS.coverageB,
        zhHK: zhHK.coverageB,
      },
      description: {
        zhHK: zhHK.temporaryTotalDisablement,
        zhHK: zhHK.temporaryTotalDisablement,
      },
    },
    coverageC: {
      label: {
        enUS: enUS.coverageC,
        zhHK: zhHK.coverageC,
      },
      description: {
        zhHK: zhHK.doubleIndemnity,
        zhHK: zhHK.doubleIndemnity,
      },
    },
    coverageD: {
      label: {
        enUS: enUS.coverageD,
        zhHK: zhHK.coverageD,
      },
      description: {
        zhHK: zhHK.brokenBonesAndBurns,
        zhHK: zhHK.brokenBonesAndBurns,
      },
    },
  },
  relationship:{
    insured: {
        enUS: enUS.insured,
        zhHK: zhHK.insured,
    },
    spouse: {
      enUS: enUS.spouse,
      zhHK: zhHK.spouse,
    },
    child: {
      enUS: enUS.children,
      zhHK: zhHK.children,
    },
    parents: {
      enUS: enUS.parent,
      zhHK: zhHK.parent,
    },
    parInLaw: {
      enUS: enUS.parentInLaw,
      zhHK: zhHK.parentInLaw,
    },
    employee: {
      enUS: enUS.employee,
      zhHK: zhHK.employee,
    },
  }
}
