import Request from './Request';
import { host } from './../config/api';

export default class ScheduleAPI {
  /* 4.1a 下載保單 (請求) */
  static request(data, onSuccess, onFail) {
    Request.post('/schedule/request', data, onSuccess, onFail);
  }

  static requestcmc(data, onSuccess, onFail) {
    Request.post('/cmCard/request', data, onSuccess, onFail);
  }

  /* 4.1b(get) 下載保單 (下載) */
  static download(token) {
    return host + '/schedule/download?token=' + token;
  }

  /* 4.2b(get) 下載CMC (下載) */
  static downloadcmc(token) {
    return host + '/cmCard/download?token=' + token;
  }
}