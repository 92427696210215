export const enUS = {
  total: 'Total',
  total2: 'Total',
  flatDiscount: 'Flat Discount',
  finalTotal: 'Final Total',
  ecLevy: 'EC Levy',
  terrorCharge: 'Government Terrorism Facility Charge',
  levy: 'Levy',
  premium: 'Premium',
  manualUnderwriting: 'Manual underwriting is needed',
  roundedToTheNearestPercentage: 'Rounded to the nearest percentage',
  less: 'Less',
}
