import React from 'react';
import {
  Dimensions, StyleSheet, Text, Switch, View, ScrollView, TouchableOpacity,
} from 'react-native';
import TextField from '@material-ui/core/TextField';
// import CalendarPicker from 'react-native-calendar-picker';
import MaterialSelect from '@material-ui/core/Select';
import MaterialButton from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers';
import moment from 'moment';
import validid from 'validid';

import config from './config';
import Locale from './Locale';
import Member from './Member';
import Format from './Format';

export class Form extends React.Component {
  render() {
    return (
      <ScrollView style={styles.form}>
        {this.props.children}
      </ScrollView>
    );
  };
}

export class Heading1 extends React.Component {
  render() {
    const color = Member.getThemeColor();

    return (
      <Text style={[styles.heading1, {color}]}>
        {this.props.children}
      </Text>
    );
  }
}

export class Heading2 extends React.Component {
  render() {
    const color = Member.getThemeColor();

    return (
      <Text style={[styles.heading2, {color}]}>
        {this.props.children}
      </Text>
    );
  }
}

export class FormElement extends React.Component {
  render() {
    const style =
      this.props.cozy ? styles.cozyFormElement : styles.formElement;

    return (
      <View style={style}>
        {this.props.children}
      </View>
    );
  }
}

export class Label extends React.Component {
  render() {
    const style = this.props.cozy ? styles.cozyLabel : styles.label;

    return (
      <Text style={style}>
        {this.props.children}
      </Text>
    );
  }
}

export class Message extends React.Component {
  render() {
    return (
      <Text style={styles.message}>
        {this.props.children}
      </Text>
    );
  }
}

export class Input extends React.Component {
  render() {
    const {cozy, keyboardType, maxLength,
      placeholder, value, disabled, multiline, onChange} = this.props;
    const style = cozy ? styles.cozyInput : styles.input;

    return (
      <TextField
        keyboardType={keyboardType}
        placeholder={placeholder}
        style={style}
        value={value}
        labelHeight={16}
        tintColor={Member.getThemeColor()}
        label=''
        disabled={disabled}
        multiline={multiline}
        onChange={(event) => event.target.value.length > maxLength ||
          onChange(event.target.value)}
      />
    );
  }
}

export class Select extends React.Component {
  getLabel(value) {
    let label;

    this.props.options.forEach((option) => {
      if (option.value == value) {
        label = option.label;
      }
    });

    return label;
  }

  render() {
    const {options, disabled, onChange, value} = this.props;

    let initValue;

    if (value) {
      const label = this.getLabel(value);

      initValue = label || value;
    }
    else {
      initValue = Locale.text.pleaseChoose;
    }

    return (
      <MaterialSelect
        disabled={disabled}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((option) =>
          <MenuItem value={option.value}>{option.label}</MenuItem>
        )}
      </MaterialSelect>
    );
  }
}

export class DropDownList extends React.Component {
  render() {
    const {cozy, label, disabled,
      options, value, onChange, message} = this.props;

    return (
      <FormElement cozy={cozy}>
        <Label cozy={cozy}>{label}</Label>

        <Select
          disabled={disabled}
          options={options}
          value={value}
          onChange={(option) => onChange(option)}
        />

        {message && <Message>{message}</Message>}
      </FormElement>
    );
  }
}

export class TextInput extends React.Component {
  render() {
    const {cozy, label, keyboardType, maxLength, placeholder,
      disabled, multiline, value, onChange, message} = this.props;

    return (
      <FormElement>
        <Label cozy={cozy}>{label}</Label>

        <Input
          cozy={cozy}
          keyboardType={keyboardType}
          maxLength={maxLength}
          placeholder={placeholder}
          disabled={disabled}
          multiline={multiline}
          value={value}
          onChange={(text) => onChange(text)}
        />

        {message && <Message>{message}</Message>}
      </FormElement>
    );
  }
}

export class NumberInput extends React.Component {
  onChange(number) {
    const {thousandSeparator, dollarSign, percentage, onChange} = this.props;

    if (percentage && number.includes('%')) {
      number = number.replace('%', '');
    }

    if (dollarSign && number.length > 1) {
      number = number.slice(1);
    }

    if (thousandSeparator) {
      number = Format.parseNumber(number);
    }

    onChange(number && number.toString());
  }

  render() {
    const {
      cozy, label, maxLength, placeholder, disabled, multiline,
      thousandSeparator, dollarSign, percentage, value, onChange, message,
    } = this.props;

    let displayValue;

    if (value) {
      displayValue = value.toString();

      if (thousandSeparator) {
        displayValue = Format.formatNumber(displayValue);
      }

      if (dollarSign) {
        displayValue = '$' + displayValue;
      }

      if (percentage) {
        displayValue = displayValue + '%';
      }
    }

    return (
      <TextInput
        cozy={cozy}
        label={label}
        keyboardType='numeric'
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        multiline={multiline}
        value={displayValue}
        onChange={(number) => this.onChange(number)}
        message={message}
      />
    );
  }
}

export class EmailInput extends React.Component {
  render() {
    const {cozy, label, maxLength, placeholder,
      disabled, multiline, value, onChange, message} = this.props;

    return (
      <TextInput
        cozy={cozy}
        label={label}
        keyboardType='email-address'
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        multiline={multiline}
        value={value}
        onChange={(number) => onChange(number)}
        message={message}
      />
    );
  }
}

export class NameInput extends React.Component {
  render() {
    const {cozy, label, placeholder, disabled, salutation,
      firstName, lastName, onChange, messages} = this.props;
    const fullName = Format.formatName(salutation, firstName, lastName);

    return (
      <FormElement cozy={cozy}>
        <Label cozy={cozy}>{label}</Label>

        <Text>{this.props.placeholder}</Text>

        <View style={styles.nameInput}>
          <View style={styles.nameInputSalutation}>
            <Select
              disabled={disabled}
              options={[
                {value: 'Mr', label: Locale.text.mr},
                {value: 'Ms', label: Locale.text.ms},
                {value: 'Mrs', label: Locale.text.mrs},
                {value: 'Miss', label: Locale.text.miss},
              ]}
              value={salutation || Locale.text.title}
              onChange={(salutation) => onChange({salutation})}
            />
          </View>

          <View style={styles.nameInputName}>
            <Input
              placeholder={Locale.text.firstName}
              disabled={disabled}
              value={firstName}
              onChange={(firstName) => onChange({firstName})}
            />
          </View>

          <View style={styles.nameInputName}>
            <Input
              placeholder={Locale.text.lastName}
              disabled={disabled}
              value={lastName}
              onChange={(lastName) => onChange({lastName})}
            />
          </View>
        </View>

        {fullName.length > 0 &&
          <Text style={styles.nameInputFullName}>{fullName}</Text>}

        {
          messages &&
            <View>
              {messages.salutation &&<Message>{messages.salutation}</Message>}
              {messages.firstName &&<Message>{messages.firstName}</Message>}
              {messages.lastName &&<Message>{messages.lastName}</Message>}
            </View>
        }
      </FormElement>
    );
  }
}

export class DocumentInput extends React.Component {
  componentDidMount() {
    this.props.onChange({documentType: 'HKID'});
  }

  render() {
    const {cozy, label, disabled, passport,
      documentType, documentNo, onChange, messages} = this.props;

    return (
      <FormElement>
        <Label cozy={cozy}>{label}</Label>

        <View style={styles.documentInput}>
          {
            passport &&
              <View style={styles.documentInputType}>
                <Select
                  disabled={disabled}
                  options={[
                    {value: 'HKID', label: Locale.text.hkid},
                    {value: 'PP', label: Locale.text.passport},
                  ]}
                  value={documentType}
                  onChange={(documentType) => onChange({documentType})}
                />
              </View>
          }

          <View style={styles.documentInputNumber}>
            <Input
              placeholder={documentType == 'HKID' ? 'A1234567' : undefined}
              disabled={disabled}
              maxLength={documentType == 'HKID' ? 9 : undefined}
              value={documentNo}
              onChange={(documentNo) => onChange({documentNo: documentType == 'HKID' ? documentNo ? validid.utils.normalize(documentNo) : documentNo : documentNo})}
            />
          </View>
        </View>

        {
          documentType == 'HKID' &&
            <Text>{Locale.text.pleaseEnterAllChars}</Text>
        }

        {
          messages &&
            <View>
              {messages.documentType &&
                <Message>{messages.documentType}</Message>}
              {messages.documentNo &&
                <Message>{messages.documentNo}</Message>}
            </View>
        }
      </FormElement>
    );
  }
}

export class AmountInput extends React.Component {
  constructor(props) {
    super(props);

    this.defaultOption = {value: 'USD', label: Locale.text.usd};

    this.props.onChange({currency: this.defaultOption.value});
  }

  render() {
    const {cozy, label, disabled, thousandSeparator, currencyOptions,
      currency, amount, onChange, message} = this.props;
    const {formatNumber, parseNumber} = Format;

    const options = [this.defaultOption];

    if (currencyOptions) {
      currencyOptions.forEach((currencyOption) => {
        switch (currencyOption) {
          case 'HKD':
            options.push({value: 'HKD', label: Locale.text.hkd});
        }
      });
    }

    return (
      <FormElement>
        <Label cozy={cozy}>{label}</Label>

        <View style={styles.amountInput}>
          <View style={styles.amountInputType}>
            <Select
              disabled={disabled}
              options={options}
              value={currency}
              onChange={(currency) => onChange({currency})}
            />
          </View>

          <View style={styles.amountInputNumber}>
            <Input
              keyboardType='numeric'
              disabled={disabled}
              value={thousandSeparator ? formatNumber(amount) : amount}
              onChange={(amount) => onChange({
                amount: thousandSeparator ? parseNumber(amount) : amount})}
            />
          </View>
        </View>

        {message && <Message>{message}</Message>}
      </FormElement>
    );
  }
}

export class DateInput extends React.Component {
  onChange(date) {
    const {dataFormat, displayFormat} = config.date;
    const {minDate, maxDate,fulldate, onChange} = this.props;

    date = moment(date, displayFormat);

    if (date.diff(minDate) < 0) {
      onChange(minDate.format(dataFormat));
    }
    else if (date.diff(maxDate) > 0) {
      onChange(maxDate.format(dataFormat));
    }
    else {
      onChange(date.format(dataFormat));
    }
  }

  render() {
    const {cozy, label, disabled, value,
      minDate, maxDate,fullDate, onChange, message} = this.props;
    const {displayFormat} = config.date;
    const {formatDate} = Format;

    return (
      <FormElement cozy={cozy}>
        <Label cozy={cozy}>{label}</Label>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          {
            fullDate?
            <DatePicker
            style={styles.dateInput}
            openTo={"year"}
            views={["year", "month", "day"]}
            disabled={disabled}
            value={value ? formatDate(value) : null}
            mode='date'
            placeholder={Locale.text.pleasePickADate}
            format={displayFormat}
            minDate={formatDate(minDate)}
            maxDate={formatDate(maxDate)}
            confirmBtnText={Locale.text.ok}
            cancelBtnText={Locale.text.cancel}
            onChange={(date) => this.onChange(date)}
          />
            :
            <DatePicker
            style={styles.dateInput}
            disabled={disabled}
            value={value ? formatDate(value) : null}
            mode='date'
            placeholder={Locale.text.pleasePickADate}
            format={displayFormat}
            minDate={formatDate(minDate)}
            maxDate={formatDate(maxDate)}
            confirmBtnText={Locale.text.ok}
            cancelBtnText={Locale.text.cancel}
            onChange={(date) => this.onChange(date)}
          />
          }
          
        </MuiPickersUtilsProvider>

        {message && <Message>{message}</Message>}
      </FormElement>
    );
  }
}

export class YesNoOptions extends React.Component {
  render() {
    const {value, onChange} = this.props;

    return (
      <View style={styles.yesNoOptions}>
        <View style={styles.yesNoOption}>
          <MaterialButton
            variant='contained'
            style={{marginRight: 20}}
            color={value == 'Y' ? 'secondary' : null}
            onClick={(value) => onChange(value ? 'Y' : null)}
          >
            {Locale.text.yes}
          </MaterialButton>
        </View>

        <View style={styles.yesNoOption}>
          <MaterialButton
            variant='contained'
            style={{marginRight: 20}}
            color={value == 'N' ? 'secondary' : null}
            onClick={(value) => onChange(value ? 'N' : null)}
          >
            {Locale.text.no}
          </MaterialButton>
        </View>
      </View>
    );
  }
}

export class YesNoQuestion extends React.Component {
  render() {
    const {cozy, label, value, onChange,
      manualUnderwriting, message} = this.props;

    return (
      <FormElement cozy={cozy}>
        <Label cozy={cozy}>{this.props.label}</Label>

        <YesNoOptions
          value={value}
          onChange={onChange}
        />

        {
          manualUnderwriting &&
            <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        }

        {message && <Message>{message}</Message>}
      </FormElement>
    );
  }
}

export class Checkbox extends React.Component {
  render() {
    const {cozy, disabled, value, onChange, label} = this.props;

    return (
      <FormElement cozy={cozy}>
        <View style={styles.checkbox}>
          <View style={styles.checkboxSwitch}>
            <Switch
              disabled={disabled}
              value={value == 'Y'}
              onValueChange={(value) => onChange(value ? 'Y' : 'N')}
            />
          </View>

          <View style={styles.checkboxLabel}>
            <Label>{label}</Label>
          </View>
        </View>
      </FormElement>
    );
  }
}

// export class DateRangePicker extends React.Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       folded: true,
//     };
//   }
//
//   onDateChange(date, type) {
//     const {startDate, onChange} = this.props;
//     const {dataFormat} = config.date;
//
//     switch (type) {
//       case 'START_DATE':
//         onChange({
//           startDate: date.format(dataFormat),
//           endDate: null,
//         });
//         break;
//       case 'END_DATE':
//         onChange({
//           startDate: startDate,
//           endDate: date.format(dataFormat),
//         });
//         break;
//     }
//   }
//
//   render() {
//     const {cozy, label, startDate, endDate, minDate, maxDate} = this.props;
//     const {formatDate} = Format;
//
//     let days;
//     if (startDate && endDate) {
//       days = moment(endDate).add(1, 'day').diff(moment(startDate), 'days');
//     }
//
//     return (
//       <FormElement cozy={cozy}>
//         <Label cozy={cozy}>{label}</Label>
//
//         <TouchableOpacity
//           style={styles.dateRangePicker}
//           onPress={() => this.setState({folded: !this.state.folded})}
//         >
//           <Text style={styles.dateRangePickerText}>
//             {
//               startDate && endDate ?
//                 formatDate(startDate) + ' - ' +  formatDate(endDate) :
//                 Locale.text.pleasePickADate
//             }
//           </Text>
//
//           {
//             days &&
//               <Text style={styles.dateRangePickerText}>
//                 {days} {Locale.text.daysdays}
//               </Text>
//           }
//         </TouchableOpacity>
//
//         {
//           !this.state.folded &&
//             <CalendarPicker
//               width={Dimensions.get('window').width - 40}
//               minDate={minDate}
//               maxDate={maxDate}
//               allowRangeSelection={true}
//               minRangeDuration={1}
//               selectedDayColor={Member.getThemeColor()}
//               selectedDayTextColor='white'
//               selectedStartDate={startDate && moment(startDate)}
//               selectedEndDate={endDate && moment(endDate)}
//               onDateChange={(date, type) => this.onDateChange(date, type)}
//             />
//         }
//       </FormElement>
//     );
//   }
// }

export class Divider extends React.Component {
  render() {
    return (
      <View style={styles.divider} />
    );
  }
}

export class Button extends React.Component {
  render() {
    const style =
      this.props.withMargin ? styles.buttonWithMargin : styles.button;

    if (this.props.disabled) {
      const backgroundColor = 'lightgrey';

      return (
        <View style={[style, {backgroundColor}]}>
          <Text style={styles.buttonText}>
            {Locale.text.pleaseWait}
          </Text>
        </View>
      );
    }
    else {
      const backgroundColor = Member.getThemeColor();

      return (
        <TouchableOpacity
          style={[style, {backgroundColor}]}
          onPress={() => this.props.onPress()}
        >
          <Text style={styles.buttonText}>
            {this.props.children}
          </Text>
        </TouchableOpacity>
      );
    }
  }
}

const styles = {
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  heading1: {
    fontSize: 26,
    marginTop: 15,
    marginBottom: 5,
  },
  heading2: {
    fontSize: 22,
    marginTop: 10,
  },
  formElement: {
    marginTop: 10,
    marginBottom: 10,
  },
  cozyFormElement: {
    marginTop: 10,
    marginBottom: 10,
  },
  label: {
    marginTop: 5,
    marginBottom: 5,
  },
  cozyLabel: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  input: {
    fontSize: 14,
  },
  cozyInput: {
    fontSize: 18,
    textAlign: 'center',
  },
  nameInput: {
    flex: 1,
    flexDirection: 'row',
  },
  nameInputSalutation: {
    flex: 1,
  },
  nameInputName: {
    flex: 2,
    paddingLeft: 10,
  },
  nameInputFullName: {
    marginTop: 10,
  },
  documentInput: {
    flex: 1,
    flexDirection: 'row',
  },
  documentInputType: {
    flex: 2,
    marginRight: 10,
  },
  documentInputNumber: {
    flex: 3,
  },
  amountInput: {
    flex: 1,
    flexDirection: 'row',
  },
  amountInputType: {
    flex: 1,
    marginRight: 10,
  },
  amountInputNumber: {
    flex: 3,
  },
  dateInput: {
    width: '100%',
    marginTop: 10,
  },
  yesNoOptions: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  yesNoOption: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  yesNoOptionSwitch: {
    flex: 1,
    alignItems: 'flex-end',
  },
  yesNoOptionText: {
    flex: 1,
    alignItems: 'flex-start',
  },
  checkbox: {
    flex: 1,
    flexDirection: 'row',
  },
  checkboxSwitch: {
    flex: 1,
  },
  checkboxLabel: {
    flex: 5,
  },
  dateRangePicker: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#c7c8ca',
  },
  dateRangePickerText: {
    fontSize: 16,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
  },
  buttonWithMargin: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    margin: 20,
  },
  buttonText: {
    fontSize: 18,
    color: 'white',
  },
  message: {
    color: '#C50000',
  },
};
