export const zhHK = {
  instantClaim: '即時索償',
  helperNPersonalAccidentInsurance: '僱傭與人身意外保險',
  insuredDetails: '受保人詳情',
  policyNumber: '保單編號',
  policyholderName: '保單持有人姓名',
  insuredName: '受保人姓名',
  insuredOccupation: '受保人職業',
  hkidNumberPassNumber:'香港身份證號碼 / 護照號碼',
  hkid: '香港身份證',
  passport: '護照',
  cancel: '取消',
  mobileNumber: '手提電話號碼',
  emailAddress: '電郵',

  claimDetails: '索賠詳情',
  dateOfAccident: '意外日期',
  pleasePickADate: '請選擇日期',
  timeOfAccident: '意外時間',
  pleasePickTime: '請選擇時間',
  placeOfAccident: '意外地點',
  howWasItHappened: '意外經過?',
  pleaseDescribe: '請述受傷部位及傷勢，如：骨折、刀傷、瘀腫等。',
  wasTheAccidentReported: '上述意外有否交由警方調查？',
  yes: '有',
  nameOfThePoliceStation: '警署地點',
  caseReferenceNumber: '報案編號',
  no: '沒有',
  nameOfDoctorOrHospital: '醫生/ 醫院名稱',
  dateOfFirstConsultation: '初診日期',
  documentsAttached: '附加文件',
  otherInsurance: '其他保險公司',
  doYouHaveOtherInsurance: '請問除本公司外，有否投保於其他保險公司？',
  nameOfInsurer: '保險公司名稱',
  typeOfCoverage: '投保種類',
  policyEffectiveDate: '保單生效日期',
  submit: '提交',
  pleaseWait: '請稍候',

  needstobeInEnglish: '需以英文填寫',
  thisFieldCannotBeBlank: '必須填寫空格',

  congratulations: '恭喜',
  youHaveSuccessfullySubmitted: '您已成功提交您的索償！',
  yourClaimApplication: '您的索償申請已收到。',
  failedOnSubmittingThisClaim: '提交索償失敗！',
  messageCode: '訊息碼：',
  facingErrorWhenSubmitting: '提交索償遇到錯誤！',
  errorMessage: '錯誤訊息：',
  isNotAValidHKID: '無效香港身分證',
  typeOfClaim: '索償類別',
  employeesCompensation: '僱員補償',
  personalAccident: '個人意外 / 住院 / 門診醫療費',
  medicalExpenses: '意外醫療費用 / 永久完全或部分傷殘 / 意外住院現金',
  others: '其他',

  notValidEmail:'這不是正確的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是正確的香港身份證號碼',
  theClaimantIs: '索賠者是18歲以下',
  descriptionOfTheAccident: '事件詳細經過',
  claimedAmount: '索償金額',
  aCopyOfForm: '表格2 / 2A / 2B 副本',
  originalMedicalReceipts: '住院醫療 / 醫藥費收據，如適用',
  theEmploymentContract: '受傷傭工之僱傭合約',
  completeDocumentationShowing: '完整文件證明顯示，最近受保人已經給付受傷傭工之定期付款與醫療費用金額',
  theCertificateOfCompensationAssessment: '補償評估証明書(表格5) 及評估証明書 (表格7) ，如適用',
  hospitalMedicalBillsReceiptReport: '住院醫療 / 醫藥費收據',
  policeMemo: '有關意外的警方備忘 / 口供供詞 / 報告，如適用',
  attendingDoctorStatement: '主診醫生報告',
  accidentalDeath: '意外死亡',
  nameOfDoctorHospital: '醫生 / 醫院名稱',

  pleaseBeRemindedToMail: '有關住院索償，如果索償金額高於港幣5,000及其等值，請寄回相關醫療賬單正本到本公司。我們會先處理你提交之電子索償申請，在收到醫療賬單的正本後，我們方可發放索償金額。如你成功提交索賠申請後，將收到索賠參考編號。請將醫療賬單的正本寄回香港皇后大道東8號忠意保險大廈5樓，並在信封上註明索賠參考編號以作跟進。',
  proofOfRelationship: '關係保證（18歲以下索償者需提供）',
  deathCertificate: '死亡證明',

  fieldsMissing: '仍有空白欄位',
  someBlankFields: '目前仍有些欄位未填寫。您還要送出索償嗎？',
  resumeFilling: '返回填寫',

  thankYou: '謝謝您',
  submitted: '您的索償已成功送出。',
  claimReferenceNumber: '索償索引編號：',

  specialRequests: '其他 / 特別要求',

  declaration: '聲明',
  declaration1: '本人/吾等作出聲明，就本人/吾等所知及所信，上述資料均屬真確及並無遺漏。',
  declaration2: '本人/吾等授權任何醫院、醫生、診所、其他人士、有關官方或機構，向忠意保險有限公司(香港分行)（「忠意」）或其受權之代表提供所有有關本人之損失、受傷、症病、病歷、醫療診斷及藥方、警方報告、口供、所有醫院或醫療報告之副本。',
  declaration3: '本人同意所有文件及收據予忠意將不獲退還。',
  declaration5: '我/我們不同意提供個人資料被第三方作為行銷用途使用',
  declaration6: '我/我們不同意提供個人資料給貴公司作為行銷用途',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成申請',
  reservesTheRight: '忠意保險有限公司 (香港分行) 保留權利向索賠人要求提交索償證明文件的正本。請提交索償後，保留正本至少3個月。',

  ok: '確認',
}
