import React from 'react';
import {
  Text, ImageBackground,
  View, ScrollView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { platform } from './../../config/platform';
import ListMenu from './../../components/ListMenu/ListMenu';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

import Member from './../../Member';

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    memberStorage.getMember((member, token) => {
      this.setState({
        member: member,
        token: token,
      });
    });

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      })
    );
  }

  _logout(navigation) {
    Member.logout();
    window.location = '/';
  }

  render() {
    let items = [];

    if (this.state.member) {
      items.push({
        text: this.state.text.logout,
        action: () => this._logout(this.props.history),
      });
    }
    else {
      items.push({
        text: this.state.text.register,
        targetScreen: 'Register',
      });
      items.push({
        text: this.state.text.login,
        targetScreen: 'Login',
      });
    }
    items.push({
      text: this.state.text.languages,
      targetScreen: 'Languages',
    });
    items.push({
      text: this.state.text.insuranceAdvisor,
      targetScreen: 'InsuranceAdvisor',
    });
    // const developers = ['97190268', '95104253', '65671171', '97017940'];
    // developers.forEach((developer) => {
    //   if (this.state.member && this.state.member.mobile == developer) {
    //     items.push({
    //       text: this.state.text.developerChecker,
    //       targetScreen: 'Developer',
    //     });
    //   }
    // });

    return (
      <div className='App'>
        <Helmet>
          <title>Settings | Bravo Generali</title>
        </Helmet>

      <Navbar
        title={this.state.text.settings}
        backButton
        history={this.props.history}
      />
      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
      <Hidden xsDown>
        <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.seting}</Text>
              <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.setingme}</Text>

            </View>

        </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,}}>
          <View style={styles.container, styles.fieldset}>

            <View style={styles.jumbotron}>
              <ImageBackground source={require('./Settings.png')} style={styles.jumbotronImage}>
              </ImageBackground>
            </View>
            <View style={styles.menu}>
              <ScrollView>
                <ListMenu history={this.props.history} items={items}/>
              </ScrollView>
            </View>
            <View style={styles.versionMark}>
              <Text style={styles.versionMarkText}>v.{platform.version}</Text>
            </View>
          </View>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>

      <Footer/>
   </div>
    );
  }
}

const styles = {
  flex: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  jumbotron: {
    flex: 3,
  },
  jumbotronImage: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
    height: 'auto',
  },
  jumbotronText: {
    color: '#C50000',
    marginLeft: 10,
    marginBottom: 10,
  },
  menu: {
    flex: 1,
  },
  versionMark: {
    alignItems: 'flex-end',
    marginBottom: 5,
    marginRight: 5,
  },
  versionMarkText: {
    fontSize: 10,
    color: 'lightgrey',
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
    backgroundColor: 'white',
  },
};
