import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { addCommas } from './../../lib/utils';
import { languageStorage } from './../../lib/LanguageStorage';

export default class TotalCostSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  getDiscountPercentage(original, discount) {
    return Math.round((1000 - Math.round(discount / original * 1000)) / 10);
  }

  round(number) {
    return Math.round(number * 100) / 100;
  }

  render() {
    const original = this.props.premium.original;
    const discount = this.props.premium.discount;
    const redeemed = this.props.premium.redeemed;

    return (
      <View>
        {
          !original ?
            <View style={[styles.total, {marginTop: 20}]}>
              <View style={styles.totalItem}>
                <View style={styles.totalLabelCentered}>
                  <Text style={styles.totalLabelText}>
                    {this.state.text.manualUnderwriting}
                  </Text>
                </View>
              </View>
            </View> :
            <View>
              {
                original > discount || redeemed ?
                  <View style={styles.totalItem}>
                    <View style={styles.totalSalePrice02}>
                      <Text style={styles.totalSalePriceText}>
                        {
                          original > discount ?
                            this.getDiscountPercentage(original, discount) +
                              '% OFF' + (this.props.groupDiscount ? ' *' : '') :
                            this.state.text.less + this.state.text.currency + (original - redeemed)
                        }
                      </Text>
                    </View>
                  </View> :
                  <View style={{marginTop: 20}} />
              }

              <View style={styles.total}>
                <View style={styles.totalItem}>
                  <View style={styles.totalLabel}>
                    <Text style={styles.totalLabelText}>
                      {
                        this.props.groupDiscount && redeemed ?
                          this.state.text.total2 : this.state.text.total
                      }
                    </Text>
                  </View>

                  {
                    (original > discount || redeemed) &&
                      <View style={styles.totalOriginalPrice}>
                        <Text style={styles.totalOriginalPriceText}>
                          ${addCommas(this.round(original))}
                        </Text>
                      </View>
                  }

                  <View style={styles.totalSalePrice}>
                    <Text style={styles.totalSalePriceText}>
                      {
                        discount ?
                          this.state.text.currency + addCommas(this.round(redeemed && !this.props.
                            groupDiscount ? redeemed : discount)) :
                          this.state.text.pleaseWait
                      }
                    </Text>
                  </View>
                </View>

                {
                  this.props.groupDiscount && redeemed &&
                    <View>
                      <View style={styles.totalItem}>
                        <View style={styles.totalLabel}>
                          <Text style={styles.totalLabelText}>
                            {this.state.text.flatDiscount}
                          </Text>
                        </View>

                        <View style={styles.totalSalePrice}>
                          <Text style={styles.totalSalePriceText}>
                            -${addCommas(this.round(discount - redeemed))}
                          </Text>
                        </View>
                      </View>

                      <View style={styles.totalItem}>
                        <View style={styles.totalLabel}>
                          <Text style={styles.totalLabelText}>
                            {this.state.text.finalTotal}
                          </Text>
                        </View>

                        <View style={styles.totalSalePrice}>
                          <Text style={styles.totalSalePriceText}>
                            ${addCommas(this.round(redeemed))}
                          </Text>
                        </View>
                      </View>
                    </View>
                }
              </View>

              <View style={styles.levy}>
                <Text style={styles.levyText}>
                  {this.state.text.levyIsnotincluded}
                </Text>
                {
                  this.props.groupDiscount &&
                    <Text style={{marginTop: 10, color: '#C50000'}}>
                      {this.state.text.roundedToTheNearestPercentage}
                    </Text>
                }
              </View>
            </View>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  total: {
    flex: 1,
    marginBottom: 10,
    borderTopWidth: 1,
    borderTopColor: '#C50000',
    borderBottomWidth: 1,
    borderBottomColor: '#C50000',
    borderLeftWidth: 1,
    borderLeftColor: '#C50000',
    borderRightWidth: 1,
    borderRightColor: '#C50000',
  },
  totalItem: {
    flexDirection: 'row',
    height: 55,
  },
  totalLabel: {
    flex: 4,
    justifyContent: 'center',
    marginLeft: 20,
  },
  totalLabelCentered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalLabelText: {
    color: '#C50000',
    fontSize: 18,
    textAlign: 'left',
  },
  totalOriginalPrice: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  totalOriginalPriceText: {
    color: '#C50000',
    fontSize: 18,
    textDecorationLine:  'line-through',
  },
  totalSalePrice: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  totalSalePrice02: {
    flex: 3,
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  totalSalePriceText: {
    color: '#C50000',
    fontSize: 18,
  },
  levy: {
    alignItems: 'flex-end',
    marginBottom: 10,
  },
  levyText: {
    color: '#C50000',
  },
});
