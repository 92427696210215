import React from 'react';
import { Text, View } from 'react-native';
import ModalSelector from './../../components/ModalSelector/ModalSelector'
import TextInput from './../../components/TextField/TextField';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { formStyles } from './../../config/styles';
import { addCommas, removeCommas } from './../../lib/utils';
import { languageStorage } from './../../lib/LanguageStorage';

export default class AmountInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  render() {
    const currencies = [
      { key: 'aud', label: this.state.text.aud },
      { key: 'eur', label: this.state.text.eur },
      { key: 'gbp', label: this.state.text.gbp },
      { key: 'hkd', label: this.state.text.hkd },
      { key: 'jpy', label: this.state.text.jpy },
      { key: 'rmb', label: this.state.text.rmb },
      { key: 'usd', label: this.state.text.usd },
      { key: 'others', label: this.state.text.others },
    ];

    return (
      <View style={styles.amountInput}>
        <View style={styles.amountCurrency}>
          {
            this.state.customCurrency ?
              <TextInput
                style={formStyles.input}
                textAlign='center'
                onChangeText={(text) =>
                  this.props.onChange(text, this.props.amount)}
                value={this.state.currency}
                placeholder={this.state.text.currency}
              /> :
              <ModalSelector
                data={currencies}
                initValue={this.state.text.currency}
                onChange={(option) => {
                  this.props.onChange(option.key, this.props.number);
                  if (option.key == 'others') {
                    this.setState({customCurrency: true});
                  }
                }}
                cancelText={this.state.text.cancel}
              />
          }
        </View>
        <View style={styles.amountNumber}>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) =>
              this.props.onChange(this.props.currency, removeCommas(text))}
            value={addCommas(this.props.number)}
            keyboardType='numeric'
            placeholder={this.state.text.pleaseFill}
          />
        </View>
      </View>
    );
  }
}

const styles = {
  amountInput: {
    flex: 1,
    flexDirection: 'row',
  },
  amountCurrency: {
    flex: 3,
  },
  amountNumber: {
    flex: 7,
    paddingLeft: 10,
  },
};
