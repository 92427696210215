import React from 'react';
import {StyleSheet, View, Text, FlatList} from 'react-native';

import Locale from './../../Locale';

export default class ProductEdges extends React.Component {
  render() {
    return (
      <View style={styles.productEdges}>
        <View style={styles.productEdgesTitle}>
          <Text style={styles.productEdgesTitleText}>
            {Locale.text.productEdges}
          </Text>
        </View>

        <FlatList
          data={this.props.children}
          renderItem={({item, index}) =>
            <View style={styles.productEdge} key={index}>
              <View style={styles.productEdgeBullet}>
                <Text>•</Text>
              </View>

              <View style={styles.productEdgeLabel}>
                <Text>{item}</Text>
              </View>
            </View>
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  productEdges: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  productEdgesTitle: {
    marginTop: 10,
  },
  productEdgesTitleText: {
    fontSize: 18,
    textAlign: 'center',
  },
  productEdge: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
  },
  productEdgeBullet: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  productEdgeLabel: {
    flex: 19,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});
