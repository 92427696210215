import React from 'react';
import { AsyncStorage } from 'react-native';
import CryptoJS from 'crypto-js';

export default class HistoryStorage {
  addEnrollmentHistory(policyNumber, content, buyAgain, callback) {
    AsyncStorage.getItem('HISTORY', (err, result) => {
      let histories;
      if (result) {
        histories = JSON.parse(
          CryptoJS.AES.decrypt(result, '37bO1SVEa8qcyrtHvjrV').toString(CryptoJS.enc.Utf8));
      }
      else {
        histories = [];
      }
      histories.push({
        type: 'Purchase',
        policyNumber: policyNumber,
        content: content,
        buyAgain: buyAgain,
      });
      AsyncStorage.setItem('HISTORY',
        CryptoJS.AES.encrypt(JSON.stringify(histories), '37bO1SVEa8qcyrtHvjrV').toString(),
        () => {
        callback(true);
      });
    });
  }

  addClaimHistory(content, callback) {
    AsyncStorage.getItem('HISTORY', (err, result) => {
      let histories;
      if (result) {
        histories = JSON.parse(
          CryptoJS.AES.decrypt(result, '37bO1SVEa8qcyrtHvjrV').toString(CryptoJS.enc.Utf8));
      }
      else {
        histories = [];
      }
      histories.push({
        type: 'Claims',
        content: content,
      });
      AsyncStorage.setItem('HISTORY',
        CryptoJS.AES.encrypt(JSON.stringify(histories), '37bO1SVEa8qcyrtHvjrV').toString(),
        () => {
        callback(true);
      });
    });
  }

  async getHistories() {
    const histories = await AsyncStorage.getItem('HISTORY');
    if (histories) {
      return JSON.parse(
        CryptoJS.AES.decrypt(histories, '37bO1SVEa8qcyrtHvjrV').toString(CryptoJS.enc.Utf8));
    }
  }
}

export const historyStorage = new HistoryStorage();
