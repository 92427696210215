import React from 'react';
import { AsyncStorage } from 'react-native';
import CryptoJS from 'crypto-js';

export default class StateStorage {
  setState(route, state) {
    AsyncStorage.getItem('STATE', (error, result) => {
      let states;
      if (result) {
        states = JSON.parse(CryptoJS.AES.decrypt(result, '37bO1SVEa8qcyrtHvjrV')
          .toString(CryptoJS.enc.Utf8));
      }
      else {
        states = {};
      }
      states[route] = state;

      AsyncStorage.setItem(
        'STATE',
        CryptoJS.AES.encrypt(
          JSON.stringify(states), '37bO1SVEa8qcyrtHvjrV').toString()
      );
    });
  }

  getState(route, callback) {
    AsyncStorage.getItem('STATE', (error, result) => {
      if (!error && result) {
        callback(
          JSON.parse(CryptoJS.AES.decrypt(result, '37bO1SVEa8qcyrtHvjrV')
            .toString(CryptoJS.enc.Utf8))[route]
        );
      }
      else {
        callback();
      }
    });
  }

  removeState(callback) {
    AsyncStorage.removeItem('STATE', () => callback && callback());
  };
}

export const stateStorage = new StateStorage();
