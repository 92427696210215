import React from 'react';
import { AsyncStorage } from 'react-native';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import MemberAPI from './../api/MemberAPI';
import AgentAPI from './../api/AgentAPI';
import { handleExpiredLogin } from './utils';
import { host, api111, api84, busiSecKey } from './../config/api';

export default class PromotionCode {
  static validatePromotionCodes(
    promotionCodes, memberId, token, pn, pl, callback) {

    MemberAPI.checkPromotionCode(
      {
        'member.id': memberId,
        'member.token': token,
        'pn': pn,
        'pl': pl,
        'promotionCode': promotionCodes,
      },
      (msgDesc) => {
        callback(promotionCodes);
      },
      (msgCode, handleDefault) => {
        callback();
      },
    )
  }

  static checkPromotionCodes(
    promotionCodes, memberId, token, callback, onLoginExpired) {

    AgentAPI.checkPromotionCode(
      {
        'member.id': memberId,
        'member.token': token,
        'promotionCode': promotionCodes,
      },
      (msgDesc) => {
        onLoginExpired();
      },
      (msgCode, msgDesc, handleDefault) => {
        callback();
      },
    )
  }
}
