import React from "react";
import {
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TextInput,
} from "react-native";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import moment from "moment";
import md5 from "md5";

import { enUS } from "./en-US.js";
import { zhHK } from "./zh-HK.js";
import { host, api26, busiSecKey } from "./../../../config/api";
import { msgCodeDetail } from "./../../../lib/utils";
import { planTypes } from "./planTypes";
import { options } from "./options";
import { formStyles } from "./../../../config/styles";
import { removeSpaces } from "./../../../lib/utils";
import { stateStorage } from "./../../../lib/StateStorage";
import { memberStorage } from "./../../../lib/MemberStorage";
import Alert from "./../../../components/Alert/Alert";
import { languageStorage } from "./../../../lib/LanguageStorage";
import Navbar from "./../../../components/Navbar/Navbar";
import Footer from "./../../../components/Footer/Footer";
import ProductDetailFolder from "./../../../components/ProductDetailFolder/ProductDetailFolder";
import TotalCostSection from "./../../../components/TotalCostSection/TotalCostSection";

import Member from "./../../../Member";

export default class TravelInsurance2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state.broker,
      //centaline: this.props.location.state.centaline'),
      planType: this.props.location.state.planType,
      period: this.props.location.state.period,
      people: this.props.location.state.people,
      numberOfChildren: this.props.location.state.numberOfChildren,
      numberOfIndividuals: this.props.location.state.numberOfIndividuals,
      paBenefits: this.props.location.state.paBenefits,
      medicalGuarantee: this.props.location.state.medicalGuarantee,
      numberOfMedicalGuarantee:
        this.props.location.state.numberOfMedicalGuarantee,
      cruiseBenefit: this.props.location.state.cruiseBenefit,
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      maxDate: this.props.location.state.maxDate,
      days: this.props.location.state.days,
      shortTermTripOnly: this.props.location.state.shortTermTripOnly,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      showBusinessEmailAddress:
        this.props.location.state.showBusinessEmailAddress,
      noticeAgree: this.props.location.state.noticeAgree,
      numTravellers: this.props.location.state.numTravellers,
      premium: this.props.location.state.premium,
      referral: this.props.location.state.referral,
      text: {},
    };

    if (this.state.promotionCodes && this.state.businessEmailAddress) {
      this.state.promotionCodes == undefined;
    }
  }

  componentDidMount() {
    if (localStorage.getItem("preventGoBack") == "true") {
      window.location = "/";
      return;
    }

    stateStorage.getState("gtravel2", (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    const getText = (language) => {
      let text = enUS;
      if (language == "zhHK") {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState({
        language: language,
        text: getText(language),
      })
    );

    memberStorage.getMember((member, token) =>
      this.setState({
        member: member,
        token: token,
      })
    );
  }

  _apiDateFormat(date) {
    return moment(date, "MMMM D, YYYY").format("YYYY-MM-DD");
  }

  _isLogin() {
    if (this.state.member) {
      this._nextScreen();
    } else {
      this._nextScreen();
      // this.setState({
      //   alert:
      //     Alert.alert(
      //       this.state.text.forBetterCustomerExperiencelogin,
      //       this.state.text.forBetterCustomerExperience,
      //       [{
      //         text: this.state.text.forBetterCustomerExperiencelogin,
      //         onPress: () => this.props.history.push('Login'),
      //       },{
      //         text: this.state.text.forBetterCustomerExperiencregister,
      //         onPress: () => this.props.history.push('Register'),
      //       },
      //       {
      //         text: this.state.text.forBetterCustomerExperienceCon,
      //         onPress: () => this._nextScreen(),
      //       }],

      //     )
      // });
    }
  }

  _nextScreen() {
    delete this.state.alert;

    if (this.state.broker) {
      stateStorage.setState("gtravel2", this.state);
      stateStorage.setState("gtravel3", undefined);

      this.props.history.push("/gtravel3", {
        broker: this.state.broker,
        // centaline: this.state.centaline,
        planType: this.state.planType,
        period: this.state.period,
        people: this.state.people,
        numberOfChildren: this.state.numberOfChildren,
        numberOfIndividuals: this.state.numberOfIndividuals,
        paBenefits: this.state.paBenefits,
        medicalGuarantee: this.state.medicalGuarantee,
        cruiseBenefit: this.state.cruiseBenefit,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        maxDate: this.state.maxDate,
        days: this.state.days,
        shortTermTripOnly: this.state.shortTermTripOnly,
        promotionCodes: removeSpaces(this.state.promotionCodes),
        businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
        showBusinessEmailAddress: this.state.showBusinessEmailAddress,
        noticeAgree: this.state.noticeAgree,
        referral: this.state.referral,
      });
    } else {
      var getToday = new Date();
      var startTime = moment(getToday, "DD-MM-YYYY hh:mm:ss");
      //var startTime = moment("11-03-2022 23:31:00", "DD-MM-YYYY hh:mm:ss"); //start 1380m
      var endTime = moment("09-07-2022 23:00:00", "DD-MM-YYYY hh:mm:ss");
      var minutesDiff = endTime.diff(startTime, "minutes");
      console.log("Minutes:" + minutesDiff);
      if (minutesDiff > 1440 || minutesDiff < 1) {
        stateStorage.setState("gtravel2", this.state);
        stateStorage.setState("gtravel3", undefined);
        this.props.history.push("/gtravel3", {
          broker: this.state.broker,
          // centaline: this.state.centaline,
          planType: this.state.planType,
          period: this.state.period,
          people: this.state.people,
          numberOfChildren: this.state.numberOfChildren,
          numberOfIndividuals: this.state.numberOfIndividuals,
          paBenefits: this.state.paBenefits,
          medicalGuarantee: this.state.medicalGuarantee,
          cruiseBenefit: this.state.cruiseBenefit,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          maxDate: this.state.maxDate,
          days: this.state.days,
          shortTermTripOnly: this.state.shortTermTripOnly,
          promotionCodes: removeSpaces(this.state.promotionCodes),
          businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
          showBusinessEmailAddress: this.state.showBusinessEmailAddress,
          noticeAgree: this.state.noticeAgree,
          referral: this.state.referral,
        });
      } else {
        this.setState({
          alert: Alert.alert(
            this.state.text.paymentStopService1,
            this.state.text.paymentStopService2,
            [
              {
                text: this.state.text.ok,
                onPress: () => {
                  this.setState({ alert: null });
                },
              },
            ]
          ),
        });
      }
    }
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    let planDetails = [];
    planDetails.push({
      key: this.state.text.shortTermTripAnnual,
      value: options.period[this.state.period].label[this.state.language],
    });
    planDetails.push({
      key: this.state.text.individualOrWithOthers,
      value: options.people[this.state.people].label[this.state.language],
    });
    if (this.state.period == "shortTermTrip") {
      planDetails.push({
        key: this.state.text.periodOfTravel,
        value:
          moment(this.state.startDate, "MMMM D, YYYY").format("MMM D, YY") +
          " - " +
          moment(this.state.endDate, "MMMM D, YYYY").format("MMM D, YY"),
      });
    }
    if (this.state.paBenefits == "Y") {
      planDetails.push({
        key: this.state.text.addtionalUpgradePersonalAccidentBenefit,
        value: this.state.text.yes,
      });
    }
    if (this.state.cruiseBenefit == "Y") {
      planDetails.push({
        key: this.state.text.addtionalCruiseBenefit,
        value: this.state.text.yes,
      });
    }
    if (this.state.medicalGuarantee == "Y") {
      planDetails.push({
        key: this.state.text.additionalChinaMedicalGuaranteeCard,
        value: this.state.text.yes,
      });
      planDetails.push({
        key: this.state.text.numberOfChinaMedicalGuaranteeCards,
        value: this.state.numberOfMedicalGuarantee.toString(),
      });
    }
    if (this.state.promotionCodes) {
      planDetails.push({
        key: this.state.text.promotionCodes,
        value: this.state.promotionCodes,
      });
    }
    if (this.state.businessEmailAddress) {
      planDetails.push({
        key: this.state.text.businessEmailMembership,
        value: this.state.businessEmailAddress,
      });
    }

    return (
      <div className="App">
        <Helmet>
          <title>Bravo Travel Protector | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />
        {this.state.alert}
        <ScrollView>
          <Grid container spacing={20} style={{ backgroundColor: "#f2f2f2" }}>
            <Hidden xsDown>
              <Grid
                item
                xs
                style={{
                  marginBottom: 13,
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <View style={[styles.greeting, { fontWeight: "bold" }]}>
                  <Text
                    style={[
                      styles.greetingText,
                      { marginTop: "10%", fontSize: 35 },
                    ]}
                  >
                    {this.state.text.bravoTravelProtector}
                  </Text>
                  <p></p>
                  <Text style={[styles.greetingText, { fontSize: 20 }]}>
                    {this.state.text.adventure}
                  </Text>
                  <img
                  //  style={{width: '100%', height: '90%'}}
                  //  src={'./assets/images/travel.png'}
                  ></img>
                </View>
              </Grid>
            </Hidden>

            <Grid item xs style={{ marginTop: 30, marginBottom: 50 }}>
              <View style={(styles.container, styles.fie)}>
                <View style={styles.form}>
                  <Text style={styles.h4}>
                    {this.state.text.iWouldLikeToBuy}
                  </Text>

                  <ProductDetailFolder
                    label={planTypes[this.state.planType].label}
                    color={planTypes[this.state.planType].color}
                    backgroundColor={
                      planTypes[this.state.planType].backgroundColor
                    }
                    planDetails={planDetails}
                  />

                  <TotalCostSection
                    premium={this.state.premium}
                    groupDiscount={
                      this.state.period == "shortTermTrip" &&
                      this.state.people == "group" &&
                      this.state.numTravellers >= 7
                    }
                  />
                </View>

                <TouchableOpacity
                  style={[styles.submitButton, { backgroundColor }]}
                  onPress={() => this._isLogin()}
                >
                  <Text style={styles.submitButtonText}>
                    {this.state.text.buyNow}
                  </Text>
                </TouchableOpacity>
              </View>
            </Grid>
            <Hidden xsDown>
              <div style={{ marginRight: "5%" }} />
            </Hidden>
          </Grid>
        </ScrollView>
        <Footer />
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  h4: {
    marginTop: 20,
    marginBottom: 20,
  },
  submitButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    backgroundColor: "#C50000",
  },
  submitButtonText: {
    fontSize: 18,
    color: "white",
  },

  fie: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
    backgroundColor: "white",
  },
};
