import React from 'react';

import Locale from './../../Locale';
import Member from './../../Member';
import Validation from './../../Validation';
import InsuranceProductScreen from './../InsuranceProductScreen';
import ApplyAPI from './../../api/ApplyAPI';
import AgentAPI from './../../api/AgentAPI';

export default class FireScreen extends InsuranceProductScreen {
  constructor(props) {
    super(props, 'FIRE');

    this.fireOption = [
      {value: 'FIRE', label: Locale.text.fireInsurance},
    ];

    this.buildingAgeOptions = [
      {value: 40, label: Locale.text.ageOfBuilding40},
      {value: 45, label: Locale.text.ageOfBuilding45},
      {value: 50, label: Locale.text.ageOfBuilding50},
      {value: 51, label: Locale.text.ageOfBuildingAbove},
    ];

    if (!this.state.insuranceProduct.pl) {
      this.state.insuranceProduct.pl = 'FIRE';
      this.state.insuranceProduct.insuredHome = {
        buildingAge: 40,
        sumInsured: 5000000,
      };
    }
  }
  handleLeavePage(e) {
    const confirmationMessage = 'Some message';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }
  
  componentDidMount() {
    //window.addEventListener('beforeunload', this.handleLeavePage);

  }
  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleLeavePage);
 }

  componentDidUpdate() {
    const {insuredHome} = this.state.insuranceProduct;

    if (insuredHome.isOwnedByHolder == 'N') {
      this.updateInsuredHome({isOwnedByHolder: null});
      this.decline(Locale.text.sorryThatYourApplication);
      return;
    }
    if (insuredHome.residentialOrCommercial == 'commercial') {
      this.updateInsuredHome({residentialOrCommercial: null});
      this.decline(Locale.text.sorryThatYour);
      return;
    }
    if (insuredHome.isPostPolicy == 'N') {
      if (insuredHome.mortgageeName || insuredHome.mortgageeAddress) {
        this.updateInsuredHome({
          mortgageeName: '',
          mortgageeAddress: '',
        });
      }
    }
  }

  getBuildingAgeLabel() {
    const {insuredHome} = this.state.insuranceProduct;

    switch (insuredHome.buildingAge) {
      case 40: return Locale.text.ageOfBuilding40;
      case 45: return Locale.text.ageOfBuilding45;
      case 50: return Locale.text.ageOfBuilding50;
      case 51: return Locale.text.ageOfBuildingAbove;
    }
  }

  getResidentialLabel() {
    const {insuredHome} = this.state.insuranceProduct;

    switch (insuredHome.residentialOrCommercial) {
      case 'residential': return Locale.text.residential;
      case 'commercial': return Locale.text.commercial;
    }
  }

  getBungalowLabel() {
    const {insuredHome} = this.state.insuranceProduct;

    switch (insuredHome.homeType) {
      case 'H': return Locale.text.house;
      case 'A': return Locale.text.apartment;
    }
  }

  updateInsuredHome(data) {
    this.updateInsuranceProduct('insuredHome', data);
  }

  getQuote(nextScreen) {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        insuredHome, payment} = this.state.insuranceProduct;

      const {trimAndRemoveEmptyStrings, getValidator} = Validation;

      [agent, policy, policyHolder, insuredHome, payment].forEach((data) => {
        trimAndRemoveEmptyStrings(data);
      });

      const messages = {};

      const discountValidation = new Validation(
        {
          businessEmail: policy.businessEmail,
          promotionCode: payment.promotionCode,
        },
        {
          businessEmail: getValidator('text'),
          promotionCode: getValidator('text'),
        }
      );

      messages.discount = discountValidation.validate();

      if (nextScreen == 'Fire2') {
        const insuredHomeValidation = new Validation(
          insuredHome,
          {
            sumInsured: {
              presence: {message: Locale.text.thisFieldCannotBeBlank},
              numericality: {
                greaterThanOrEqualTo: 1000000,
                notValid: Locale.text.notANumber,
                notGreaterThanOrEqualTo: Locale.text.lessThanMinimum,
              },
            },
          }
        );

        messages.insuredHome = insuredHomeValidation.validate();
      }

      if (nextScreen == 'Fire4') {
        const agentValidation = new Validation(
          agent,
          {mobile: getValidator('number')}
        );

        messages.agent = agentValidation.validate();

        const policyValidation = new Validation(
          policy,
          {
            effectiveDateStart: getValidator('text', true),
            specialRequest: getValidator('text'),
          }
        );

        messages.policy = policyValidation.validate();

        const policyHolderValidation = new Validation(
          policyHolder,
          {
            firstName: getValidator('text', true),
            lastName: getValidator('text', true),
            mobile: getValidator('number', true),
            email: getValidator('email', true),
            address1: getValidator('text'),
          }
        );

        messages.policyHolder = policyHolderValidation.validate();

        const insuredHomeValidation = new Validation(
          insuredHome,
          {
            sumInsured: {
              presence: {message: Locale.text.thisFieldCannotBeBlank},
              numericality: {
                greaterThanOrEqualTo: 1000000,
                notValid: Locale.text.notANumber,
                notGreaterThanOrEqualTo: Locale.text.lessThanMinimum,
              },
            },
            buildingAge: getValidator('number', true),
            isOwnedByHolder: getValidator('text', true),
            fullAddress: getValidator('text', true),
            residentialOrCommercial: getValidator('text', true),
            homeType: getValidator('text', true),
            isPostPolicy: getValidator('text', true),
            mortgageeName: insuredHome.isPostPolicy == 'Y' ?
              getValidator('text', true) : undefined,
            mortgageeAddress: insuredHome.isPostPolicy == 'Y' ?
              getValidator('text', true) : undefined,
            isUnoccupied: getValidator('text', true),
            isAnyLoss: getValidator('text', true),
            isOwnedByHolder: getValidator('text', true),
            isProposalDeclined: getValidator('text', true),
          }
        );

        messages.insuredHome = insuredHomeValidation.validate();
      }

      const invalid = JSON.stringify(messages) != '{}';

      if (invalid) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.readPremium({
          'pn': pn,
          'pl': pl,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'payment.promotionCode': payment.promotionCode,
          'policy.effectiveDateStart':policy.effectiveDateStart,
          'policy.businessEmail': policy.businessEmail,
          'insuredHome.buildingAge': insuredHome.buildingAge,
          'insuredHome.sumInsured': insuredHome.sumInsured,
        })
        .then(({premium, levy}) => {
          this.push(nextScreen, {premium, levy});
        });
      }
    });
  }

  confirm() {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        insuredHome, payment} = this.state.insuranceProduct;

      const {getValidator, getProductConfirmationValidator} = Validation;

      const confirmationValidation = new Validation(
        policy,
        {
          disclaimerAndPIC: getProductConfirmationValidator(),
          allowPrivacyPromote: getValidator('text', true),
          commissionsDisclosures:
            (this.state.agent || this.state.broker || payment.promotionCode) &&
              getProductConfirmationValidator(),
        }
      );

      const messages = confirmationValidation.validate();

      if (messages) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.apply(
          Locale.text.fireInsurance,
          this.state.broker ? AgentAPI.fire : ApplyAPI.fire,
          {
            'pn': pn,
            'pl': pl,
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'agent.mobile': agent.mobile,
            'policy.effectiveDateStart': policy.effectiveDateStart,
            'policy.effectiveDateEnd': policy.effectiveDateEnd,
            'policy.allowPrivacyPromote3P': policy.allowPrivacyPromote3P,
            'policy.allowPrivacyPromote': policy.allowPrivacyPromote,
            'policy.businessEmail': policy.businessEmail,
            'policy.specialRequest': policy.specialRequest,
            'policyHolder.salutation': policyHolder.salutation,
            'policyHolder.firstName': policyHolder.firstName,
            'policyHolder.lastName': policyHolder.lastName,
            'policyHolder.mobile': policyHolder.mobile,
            'policyHolder.email': policyHolder.email,
            'policyHolder.address1':policyHolder.address1,
            'policyHolder.documentType': policyHolder.documentType,
            'policyHolder.documentNo': policyHolder.documentNo,
            'policyHolder.dateOfBirth': policyHolder.dateOfBirth,
            'insuredHome.fullAddress': insuredHome.fullAddress,
            'insuredHome.area': 'HK',
            'insuredHome.buildingAge': insuredHome.buildingAge,
            'insuredHome.sumInsured': insuredHome.sumInsured,
            'insuredHome.homeType': insuredHome.homeType,
            'insuredHome.isUnoccupied': insuredHome.isUnoccupied,
            'insuredHome.isOldBuilding':
              insuredHome.buildingAge > 50 ? 'Y' : 'N',
            'insuredHome.isAnyLoss': insuredHome.isAnyLoss,
            'insuredHome.isOwnedByHolder': insuredHome.isOwnedByHolder,
            'insuredHome.isProposalDeclined': insuredHome.isProposalDeclined,
            'insuredHome.isPostPolicy': insuredHome.isPostPolicy,
            'insuredHome.mortgageeName': insuredHome.mortgageeName,
            'insuredHome.mortgageeAddress': insuredHome.mortgageeAddress,
            'payment.ecash': payment.ecash,
            'payment.promotionCode': payment.promotionCode,
            'rawRequest': JSON.stringify(this.state.insuranceProduct),
          }
        );
      }
    });
  }

}
