import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const planTypes = {
  IPA1: {
    label: {
      enUS: enUS.plan1,
      zhHK: zhHK.plan1,
    },
    color: 'white',
    backgroundColor: '#8E1230',
  },
  IPA2: {
    label: {
      enUS: enUS.plan2,
      zhHK: zhHK.plan2,
    },
    color: 'white',
    backgroundColor: '#E9573D',
  },
  IPA3: {
    label: {
      enUS: enUS.plan3,
      zhHK: zhHK.plan3,
    },
    color: 'white',
    backgroundColor: '#F09273',
  },
  // ITREQ-3818: remove Tailor Made Plan
  // IPAT: {
  //   label: {
  //     enUS: enUS.tailorMadePlan,
  //     zhHK: zhHK.tailorMadePlan,
  //   },
  //   color: 'white',
  //   backgroundColor: '#B0B2B3',
  // },
};
