import React from 'react';
import {View, FlatList} from 'react-native';
import Locale from './../../Locale';
import Format from './../../Format';
import Confirmation from './../components/Confirmation';

export default class MembersConfirmation extends React.Component {
  getHeading(index) {
    const {members} = this.props;

    if (members.length > 1) {
      return Locale.getOrdinalNumber(index + 1) + Locale.text.insured;
    }
    else {
      return Locale.text.insured;
    }
  }

  render() {
    const {members} = this.props;
    const {formatName, formatDate} = Format;

    return (
      <FlatList
        data={members}
        renderItem={({item, index}) =>
          <Confirmation
            heading={this.getHeading(index)}
            data={[
              {
                label: Locale.text.name,
                value: formatName(
                  item.salutation,
                  item.firstName,
                  item.lastName
                ),
              },
              {
                label: Locale.text.hkid,
                value: item.documentNo,
              },
              {
                label: Locale.text.dateOfBirth,
                value: formatDate(item.dateOfBirth),
              },
            ]}
          />
        }
        keyExtractor={(item, index) => index.toString()}
      />
    );
  }
}
