import React from 'react';
import {StyleSheet, View, FlatList, TouchableOpacity, Text} from 'react-native';

import Member from './../Member';

export default class TabView extends React.Component {
  render() {
    const {tabs, value, onChange} = this.props;
    const color = Member.getThemeColor();

    return (
      <View style={styles.tabView}>
        <FlatList
          horizontal
          style={{maxHeight: 40}}
          contentContainerStyle={styles.tabs}
          data={tabs}
          renderItem={({item, index}) =>
            <TouchableOpacity
              style={styles.tab}
              onPress={() => onChange(index)}
            >
              <Text style={value == index && [styles.selected, {color}]}>
                {item.label}
              </Text>
            </TouchableOpacity>
          }
          keyExtractor={(item, index) => index.toString()}
        />

        <View style={styles.content}>
          {tabs[value].content}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tabView: {
    flex: 2,
  },
  tabs: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
  tab: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  selected: {
    fontSize: 18,
  },
  content: {
    flex: 1,
  },
});
