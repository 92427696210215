import React from 'react';
import ReactDOM from 'react-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

export default class ModalSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let data = [];
    let value;
    if (this.props.data) {
      this.props.data.forEach((datum, index) => {
        data.push(
          <MenuItem value={datum.key}>{datum.label}</MenuItem>
        );

        if (this.state.value) {
          value = this.state.value;
        }
        else if (this.props.initValue == datum.label) {
          value = datum.key;
        }
      });
    }

    return (
      <FormControl disabled={this.props.disabled}>
        <Select
          value={value}
          onChange={(event) => this.setState(
            { value: event.target.value },
            () => this.props.onChange({ key: event.target.value })
          )}
        >
          { data }
        </Select>
      </FormControl>
    );
  }
}
