export const enUS = {
  settings: 'Settings',
  register: 'Register',
  or:'OR',
  createAccount: 'Create Account',
  name: 'Name',
  mobileNumber: 'Mobile Number',
  emailAddress: 'Email Address',
  getConfirmationCode: 'Get Confirmation Code',
  confirmationCodeSent: 'Confirmation Code Sent',
  confirmationCodeSentTo: 'Confirmation code has been sent to: ',
  enterCodeBelow: 'Please enter the six-digit code below',
  pleaseWait: 'Please Wait',
  login: 'Login',
  loginWithMobilePhone: 'Login with my Mobile Phone',
  welcome: 'Welcome',
  loggedIn: 'You have successfully logged in!\nTo better protect your personal data, you are encouraged to log out everytime.',
  ok: 'OK',
  accountCreated: 'You have successfully created an account!',
  youHaveRegistered: 'You have registered',
  alreadyRegistered: 'This account is already registered. You can use this phone number to login.',
  logout: 'Logout',
  languages: 'Languages',
  preferredLanguage: 'Preferred Langauge',
  alert: 'Alert',
  pleaseRegisterFirst: 'Please register first.',
  referenceCode: 'Reference Code',
  codeBySMS: 'Resend six-digit code by SMS',
  codeByEmail: 'Resend six-digit code by email',
  
  // Insurance Advisor Screen
  insuranceAdvisor: 'Insurance Advisor',
  insuranceAdvisorIs: 'Insurance advisor: ',
  notSpecifiedYet: 'Not specified yet',
  change: 'CHANGE',
  changeInsuranceAdvisor: 'Change insurance advisor?',
  wouldYouLike: 'Would you like ',
  comma: ', ',
  toBeYourInsuranceAdvisor: ' to be your insurance advisor?',
  yes: 'Yes',
  no: 'No',
  requiredToLogin: 'Required to Login',
  pleaseLogin: 'Please login before you can manage your insurance advisors!',
  login: 'Login',
  register: 'Register',
  product: 'Product: ',
  pleaseInputTheMobileNumber: 'Please input the mobile number of the insurance advisor',
  confirm: 'Confirm',
  removeInsuranceAdvisor: 'Remove the Insurance Advisor',
  doYouWantToRemove: 'Do you want to remove your insurance advisor for ',
  questionMark: '?',
  thanksAndWeWillInform: 'Thanks and we will inform ',
  accordingly: ' accordingly.',
  pleaseCheckTheProductList: ' Please check the insurance advisor list and click confirm.',

  thisFieldCannotBeBlank: 'This field cannot be blank',
  invalidMobileNumber: 'This is not a valid mobile number',
  notValidEmail:'It is not a valid email address',
  needsToBeInEnglish: 'Needs to be in English',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the registration',

  // Developer Screen
  developerChecker: 'Developer Checker',
  host: 'Host',
  token: 'Token',
  version: 'Version',

  // product names
  travelInsurnace: 'Travel Insurance',
  gereraliTravelPackageInsurance: 'Bravo Travel Protector',
  annualStudyAbroadProtector: 'Annual Study Abroad Protector',
  ussstudyplan: 'US Study Plan',
  homeInsurnace: 'Home Insurance',
  homePackageInsurance: 'Home Package Insurance',
  fireInsurance: 'Fire Insurance',
  domesticHelperInsurance: 'Domestic Helper Insurance',
  accidentInsurance: 'Accident Insurance',
  generaliPersonalAccidentInsurancePlan: 'Generali Personal Accident Insurance Plan',
  executivePersonalAccidentProtector: 'Accident Insurance',
  affinityProduct: 'Affinity Product',
  cityU: 'Travel Program (City University of HK)',
  uow: 'Travel Program (UOW College HK)',
  centalineLandlordProtector: 'Centaline Landlord Protector',
  centalineHomeProtector: 'Centaline Home Protector',
  centalineTravelProtector: 'Centaline Travel Protector',
  centalineDomesticHelperProtector: 'Centaline Domestic Helper Protector',

  iCerifyThatIHaveReadAndAgreedDisclaimerAndPersonal:'I/We certify that I/we have read and agreed to the Disclaimer and Personal Information Collection Statement',
  iWeDoNotConsentPDto3PartyMarketing:'I/We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentPDToCompanyMarketing:'I/ We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  insurance: 'Insurance Advisors',
  advisors: 'Manage your insurance advisors here.',
  languageSettings: 'Language Settings',
  choose: 'Choose your preferred language.',
  registration: 'Registration',
  bravo: 'Welcome to Bravo Generali!',
  back: 'Welcome back!',
  seting: 'Settings',
  setingme: 'Manage your preferences here.',
  referenceCode: 'Reference Code',
  optional: 'Optional',
}
