import { AsyncStorage } from 'react-native';
import CryptoJS from 'crypto-js';

import MemberAPI from './api/MemberAPI';
import config from './config';

// the base route to serve static images for themes
const staticServer =
  'https://www.app-generali.com/settings/theme/generali/image';

// the default theme used when the member is not logged in
const defaultTheme = {
  color: 'C50000',
  logo: staticServer + '/logo.png',
  imageHome: staticServer + '/banner.jpg',
  imageInsuranceProducts: staticServer + '/product.png',
  imageClaims: staticServer + '/claims.png',
  imageRoarUs: staticServer + '/roarus.png',
  imagePrivilegeClub: staticServer + '/club.png',
  imageHistory: staticServer + '/history.png',
  imageSettings: staticServer + '/settings.png',
};

export default class Member {
  constructor(member, theme, token) {
    this.member = member;
    this.theme = theme;
    this.token = token;
  }

  static onReady(event) {
    Member._onReady = event;
  }

  static getMember() {
    if (Member._member && Member._member.member) {
      return Member._member.member;
    }
  }

  static getMemberId() {
    if (Member._member && Member._member.member) {
      return Member._member.member.id;
    }
  }

  static getMemberToken() {
    if (Member._member && Member._member.token) {
      return Member._member.token.tokenStr;
    }
  }

  static getTheme() {
    if (Member._member && Member._member.theme) {
      return Member._member.theme;
    }
    else {
      return defaultTheme;
    }
  }

  static getThemeColor() {
    if (Member._member && Member._member.theme) {
      return '#' + Member._member.theme.color;
    }
    else {
      return '#' + defaultTheme.color;
    }
  }

  static setTheme(theme) {
    if (Member._member) {
      Member._member.theme = theme;
    }
  }

  static save(member) {
    const encrypt = (data) =>
      CryptoJS.AES.encrypt(JSON.stringify(data), config.secretKey).toString();

    return new Promise((resolve, reject) => {
      try {
        AsyncStorage.setItem('MEMBER', encrypt(member), () => resolve());
      }
      catch (error) {
        reject(error);
      }
    });
  }

  static load() {
    AsyncStorage.removeItem('log')

    const decrypt = (data) => JSON.parse(
      CryptoJS.AES.decrypt(data, config.secretKey).toString(CryptoJS.enc.Utf8));

    return new Promise((resolve, reject) => {
      AsyncStorage.getItem('MEMBER', (error, result) => {
        if (error) {
          reject(error);
        }
        else if (!result) {
          resolve();
        }
        else {
          const member = decrypt(result);

          let token;
          AsyncStorage.getItem('TOKEN', (error, result) => {
            token = decrypt(result);
          });

          resolve(new Member(member, member.theme, token));
        }
      });
    });
  }

  static register(
    username, firstName, lastName,
    mobile, email, verifyCode,
    onSuccess, onFail,
  ) {
    MemberAPI.register(
      {
        username: username,
        first_name: firstName,
        last_name: lastName,
        mobile: mobile,
        email: email,
        verify_code: verifyCode,
      },
      (msgDesc) => {
        Member._member = new Member(
          msgDesc.member, msgDesc.theme, msgDesc.token);

        Member.save(Member._member)
          .then(() => onSuccess(msgDesc))
          .catch((error) => alert(error.message));
      },
      onFail
    );
  }

  static login(username, verifyCode, onSuccess, onFail) {
    MemberAPI.login(
      {
        username: username,
        verify_code: verifyCode,
      },
      (msgDesc) => {
        Member._member = new Member(
          msgDesc.member, msgDesc.theme, msgDesc.token);

        Member.save(Member._member)
          .then(() => onSuccess(msgDesc))
          .catch((error) => alert(error.message));
      },
      onFail
    );
  }

  static Biologin(signature, onSuccess, onFail) {
    MemberAPI.biometriclogin(
      {
        'biometric.key': signature,
      },
      (msgDesc) => {
        Member._member = new Member(
          msgDesc.member, msgDesc.theme, msgDesc.token);

        Member.save(Member._member)
          .then(() => onSuccess(msgDesc))
          .catch((error) => alert(error.message));
      },
      onFail
    );
  }

  static logout() {
    delete Member._member;

    AsyncStorage.removeItem('MEMBER');
  }
}

// initialize the app with the locally stored data
Member.load()
  .then((member) => {
    Member._member = member;
    Member._onReady && Member._onReady();
  })
  .catch((error) => alert(error.message));
