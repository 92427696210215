export const enUS = {
  disclaimer: 'Disclaimer',
  pic: 'Personal Information Collection Statement',
  agreed: 'I/We have read and agreed to the Important Notes of ',
  comma: ' ',
  space: ' ',
  and: ' and ',
  yes:'Yes',
  no:'No',
  disclaimer: 'Disclaimer',
  personal: 'Personal Information Collection Statement',
  dot: '.',
  }
