export const enUS = {
  centalineLandlordProtector : 'Centaline Landlord Protector',
  comprehesiveProtectionOfYourRentalPropertyContent: 'Comprehensive protection of your rental property for financial loss of rent, liability and damage of home content is what every landlord needs. To secure your property investments, Generali offers the Centaline Landlord Protector to insure the risks associated with renting your property. It also provides professional referral service by Centaline Group for rent recovery and repossession.',

  productEdges: 'Product Edges',
  productEdge1: 'Up to HK$500,000 contents damage protection',
  productEdge2: 'Up to HK$10,000,000 owner’s liability protection',
  productEdge3: 'Up to HK$200,000 rental loss protection or 6 months’ rent, whichever is lower',
  productEdge4: 'Optional building insurance',
  productEdge5: 'Professional referral services by Centaline Group to recover rent and property',

  forDetails: 'For details of the product, exculsions and premium rates, please refer to Product Leaflets and Policy Provisions.',
  planType: 'Plan Type',
  withHomeBuilding: 'With home building',
  withoutHomeBuilding: 'Without home building',

  sizeOfTheProperty: 'Size of the Property',
  pleaseChooseTheNetSaleable: 'Please choose the net saleable area of the property',

  effectiveDate: 'Effective Date',
  pleasePickADate: 'Please pick a date',
  periodOfInsurance: 'Period of Insurance',
  promotionCodes: 'Promotion Codes',
  businessEmailAddress: 'Business Email Address',
  optional: 'Optional',
  getAQuote: 'Get a Quote',

  iWouldLikeToBuy: 'I would like to buy',
  levyIsNotIncluded: 'Levy is not included',
  buyNow: 'Buy Now',

  executivePersonalAccidentProtector: 'Centaline Landlord Protector',
  theInsuranceIsIssuedByContent:'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',
  theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
  professionalInsurance: '.',
  pleaseInputInEnglish: '*Please input in English*',
  productType: 'Product Type',
  thisFieldCannotBeBlank:'This field cannot be blank',

  promotionCodes: 'Promotion Codes',
  policyholder: 'Policyholder',
  name: 'Name',
  title:'Title',
  firstName:'First Name',
  lastName: 'Last Name',
  dateOfBirth:'Date of Birth',
  hkid:'HKID',
  pleaseEnterAllHKID:'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
  mobileNumber:'Mobile Number',
  home:'Home',
  address:'Address',
  HKAdressOnly:'Hong Kond Address only',
  size:'Size',
  houseOrApartment:'House / Apartment',
  house:'House',
  apartment:'Apartment',
  pleaseChoose:'Please Choose',
  theHomeBuildingIsMoreThan40years:'The home building is more than 40 years',
  yes:'Yes',
  no:'No',
  manualUnderwritingsIsRequired:'* Manual underwriting is required *',
  theHomeIsUnoccupiedForLonger30Days:'The home is unoccupied for longer than 30 consective days',
  anyLossDamageLiabilityOrAccident:'Any loss, damage, liability or accident occurred during the past 12 months',
  anyLegalActionAgainstTenantToRent:'Any legal actions taken against tenant to recover rent for regain possession of the insured home',
  anyDefeatOfRentalByTenantDuring12Months:'Any defeat of rental by tentant of the insured home during the past 12 months',
  anyInsuranceCompnayDeclined:'Any insurance company declined your proposal, cancelled or refused to renew your policy or imposed special terms and conditions for the insurance?',
  specialRequests:'Special Requests',
  referral: 'Referral',

  importantNotes:'Important Notes',
  thisInsuranceDoesNotCoverCstOrGD:'This insurance does not cover the cost of gradual deterioration.',
  thisInsuranceIsNotMaintenanceContract:'This insurance is not a maintenance contract.',
  validCOverProperyGoodOrder:'Valid cover is conditional on keeping the property in good order and taking reasonable steps to avoid accident.',
  theInsuredHomeResidentalInHKBuliding:'The insured home is only applicable to residential building situated in Hong Kong and solely occupied for dwelling purpose only.',
  theInsuredHomebrickStones:'The insured home is built of bricks, stones and concrete and roofed with concrete.',
  allstructuresOfInsuredHomeBeLegal:'All structures of the insured home must be legally constructed.',
  proposerMustBeHKCitizen:'Proposer must be a Hong Kong citizen or resident in Hong Kong holding a valid  HKID card.',
  thisInsurISNotPublicHousingEstate:'This insurance is not applicable to public housing estate, and subdivided flat.',
  tenancyAgreement:'Tenancy Agreement – It must be a duly stamped Tenancy Agreement with tenant',
  section3RentProtection:'Section 3 – Rent Protection : Insured must pursue recovery of rent in arrears of first outstanding monthly rent by taking legal actions to demand payment from tenant immediately within 14 days on which the outstanding rent exceed 1 month, and inform Assicurazioni Generali S.p.A. by writing immediately.',
  sectione4buildingMax:'Section 4 – Building : Maximum Limit of Indemnity is up to the sum insured amount or the rebuilding cost of Building, whichever is the less',
  allCoveragesAreSubject:'All coverages are subject to the terms and conditions of the Policy.',
  assicurazioniGenerali:'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application',

  iWeDoNotConsentPDto:'I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentPDToCompanyMarketing:'I/ We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  brokerDeclaration: 'I understand, acknowledge and agree on, the personal data passed to me will only be used during the continuance of the policy for arranging and managing the said policy.',

  technicalRepresentativeMobileNumber: 'Technical Representative Mobile Number',
  bothDatesInclusive: '(both dates inclusive)',
  occupation: 'Occupation',
  emailAddress: 'Email Address',
  anyLegalAction: 'Any legal action taken against tenant to recover rent or regain possession of the insured home',
  anyDefaultOfRental: 'Any default of rental by tenant of the insured home during the past 12 months',
  invalidPromotionCode: 'Invalid promotion code',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  needsToBeInEnglish: 'Needs to be in English',
  congratulations: 'Thank You',
  youHaveSuccessfully: 'You have successfully submitted your application of this ',
  ccccc: '.',
  manualUnderwriting: ' Manual underwriting is needed and we will get back to you within two business days.',
  inCaseOfAnyQuestions: ' In case of any questions, you can chat with us in the app in section Roar Us.',
  check: 'Please check your inputs again.',
  cancel: 'cancel',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  tryAgain: 'Our system is temporarily not available, please try again later. ',
  alert: 'Alert',
  ok: 'OK',
  getInsured: 'Get Insured',
  iCertify: 'I certify that I have read and agreed to the Important Notes of Generali Landlord Protector Insurance',
  forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
  forBetterCustomerExperiencelogin:'Log in',
  forBetterCustomerExperiencregister:'Register',
  forBetterCustomerExperienceCon:'Continue without log in',
}
