import React from 'react';
import {
  StyleSheet, View, FlatList, TouchableOpacity, Text,
} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import Member from './../../Member';
import {
  Heading2, NameInput, DocumentInput,
  DateInput, DropDownList, Divider, Button,
} from './../../Form';

export default class InsuredPeopleForm extends React.Component {
  render() {
    const {policyholderIsInsured, policyHolder,
      cyber, insuredPeople, onChange, messages} = this.props;
    const color = Member.getThemeColor();

    return (
      <View>
        <FlatList
          data={insuredPeople}
          renderItem={({item, index}) =>
            <View>
              <View style={styles.row}>
                <Heading2>{Locale.text.insured}</Heading2>
              </View>

              <NameInput
                label={Locale.text.name}
                disabled={index == 0 && policyholderIsInsured}
                salutation={item.salutation}
                firstName={item.firstName}
                lastName={item.lastName}
                onChange={(data) => onChange(index, data)}
                messages={messages && messages[index]}
              />

              <DocumentInput
                label={Locale.text.hkid}
                documentType={item.documentType}
                documentNo={item.documentNo}
                onChange={(data) => onChange(index, data)}
                messages={messages && messages[index]}
              />

              <DateInput
                label={Locale.text.dateOfBirth}
                minDate={moment().subtract(71, 'years').add(1, 'day')}
                maxDate={moment()}
                value={item.dateOfBirth}
                onChange={(dateOfBirth) => onChange(index, {dateOfBirth})}
                message={messages && messages[index] &&
                  messages[index].dateOfBirth}
              />

              <Divider />
            </View>
          }
          keyExtractor={(item, index) => index.toString()}
          extraData={JSON.stringify(this.props)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  removeButtonText: {
    fontSize: 20,
  },
});
