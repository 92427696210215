import React from 'react';
import {
  Text, TextInput, Switch, 
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';
import { stateStorage } from './../../../lib/StateStorage';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { options } from './options';
import { getEcash, formatName, addCommas, msgCodeDetail} from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { historyStorage } from './../../../lib/HistoryStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { host, api26, api39, api310, busiSecKey } from './../../../config/api';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink';
import PaymentBreakdown from './../../../components/PaymentBreakdown/PaymentBreakdown';
import ProductAPI from "./../../../api/ProductAPI";
import AgentAPI from "./../../../api/AgentAPI";
import ApplyAPI from '../../../api/ApplyAPI.js';

export default class IPA4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state ? this.props.location.state.broker : undefined,
      insuranceAdvisor: this.props.location.state.insuranceAdvisor,
      technicalRepresentativeMobileNumber: this.props.location.state.technicalRepresentativeMobileNumber,
      product: this.props.location.state.product,
      planType: this.props.location.state.planType,
      people: this.props.location.state.people,
      occupations: this.props.location.state.occupations,
      effectiveDateStart: this.props.location.state.effectiveDateStart,
      effectiveDateEnd: this.props.location.state.effectiveDateEnd,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      specialRequests: this.props.location.state.specialRequests,
      referral: this.props.location.state.referral,
      policyholderTitle: this.props.location.state.policyholderTitle,
      policyholderFirstName: this.props.location.state.policyholderFirstName,
      policyholderLastName: this.props.location.state.policyholderLastName,
      policyholderPhone: this.props.location.state.policyholderPhone,
      policyholderEmail: this.props.location.state.policyholderEmail,
      policyholderAddress: this.props.location.state.policyholderAddress,
      policyholderHKID: this.props.location.state.policyholderHKID,
      policyholderDOB: this.props.location.state.policyholderDOB,
      insuredOccupation: this.props.location.state.insuredOccupation,
      spouseOccupation: this.props.location.state.spouseOccupation,
      otherspouseOccupation: this.props.location.state.otherspouseOccupation,
      spouseOccupationLabel: this.props.location.state.spouseOccupationLabel,
      child1OccupationLabel: this.props.location.state.child1OccupationLabel,
      child2OccupationLabel: this.props.location.state.child2OccupationLabel,
      otherinsuredOccupation: this.props.location.state.otherinsuredOccupation,
      insuredOccupationLabel: this.props.location.state.insuredOccupationLabel,
      insuredRightHanded: this.props.location.state.insuredRightHanded,
      question1: this.props.location.state.question1,
      question1Insurer: this.props.location.state.question1Insurer,
      question1Benefit: this.props.location.state.question1Benefit,
      question1SumInsured: this.props.location.state.question1SumInsured,
      question1Reason: this.props.location.state.question1Reason,
      question1Condition: this.props.location.state.question1Condition,
      question2: this.props.location.state.question2,
      question2SufferedArea: this.props.location.state.question2SufferedArea,
      question2Diagnosis: this.props.location.state.question2Diagnosis,
      coverageA1: this.props.location.state.coverageA1,
      coverageA2: this.props.location.state.coverageA2,
      coverageB: this.props.location.state.coverageB,
      coverageC: this.props.location.state.coverageC,
      coverageD: this.props.location.state.coverageD,
      members: this.props.location.state.members,
      rawRequest: this.props.location.state.rawRequest,
      messages: {},
      text: {},
      premium: this.props.location.state.premium,
      levy: this.props.location.state.levy,
      total: this.props.location.state.total,
    };
    this.state.manualUnderwriting =
      this.state.insuredOccupation == 'OC3'
      || this.state.insuredOccupation == 'OC4'
      || this.state.spouseOccupation == 'OC3'
      || this.state.spouseOccupation == 'OC4'
      || this.state.question1 == 'Y'
      || this.state.question2 == 'Y'
      || this.state.specialRequests
  }

  componentDidMount() {
    //if (localStorage.getItem('preventGoBack') == 'true') {
   //   window.location = '/';
   //   return;
   // }

    stateStorage.getState('ipa3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init member and token
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    // init ecash
    getEcash((ecash) => this.setState({ecash: ecash}));

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      })
    );
  }



  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _confirm() {
    if (this.state.submitButtonDisabled) {
      return;
    }
    this.setState({submitButtonDisabled: true});

    let attributes = {
      declaration1: this.state.declaration1,
      declaration2: this.state.declaration2,
      declaration3: this.state.declaration3,
      declaration4: this.state.declaration4,
      declaration5: this.state.declaration5,
    }
    if (!this.state.manualUnderwriting && this.state.ecash > 0) {
      attributes.usingEcash = this.state.usingEcash;
    }

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    }

    let constraints = {
      declaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      declaration5: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
    }

    if (
      this.state.insuranceAdvisor ||
      this.state.broker
    ) {
      attributes.brokerDeclaration = this.state.brokerDeclaration;
      constraints.brokerDeclaration = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      };
    }

    if (
      !this.state.broker &&
      !this.state.manualUnderwriting &&
      this.state.ecash > 0
    ) {
      attributes.usingEcash = this.state.usingEcash;
      constraints.usingEcash = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
      return;
    }
    else {
      let rawRequest = this.state.rawRequest;
      rawRequest.declaration1 = this.state.declaration1;
      rawRequest.declaration2 = this.state.declaration2;
      rawRequest.declaration3 = this.state.declaration3;
      rawRequest.declaration4 = this.state.declaration4;
      rawRequest.declaration5 = this.state.declaration5;
      rawRequest.brokerDeclaration = this.state.brokerDeclaration;
      rawRequest.usingEcash = this.state.usingEcash;

      let people = 'I';
      if (this.state.people == 'withSpouse') {
        people = 'F';
      }
      if (this.state.people == 'withChildren') {
        people = 'IC';
      }

      const fields = {
        'pn': 'EAPP', // Partner Code (For Bravo Webapp, always "EAPP")
        'pl': this.state.planType, // Plan Code (Plan 1 = IPA1, Plan 2 = IPA2, Plan 3 = IPA3)
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.effectiveDateEnd': this._apiDateFormat(this.state.effectiveDateEnd),
        'policy.allowPrivacyPromote3P': this.state.declaration4,
        'policy.allowPrivacyPromote': this.state.declaration5,
        'policy.businessEmail': this.state.businessEmailAddress,
        'policy.specialRequest': this.state.specialRequests,
        'policyHolder.salutation': this.state.policyholderTitle,
        'policyHolder.firstName': this.state.policyholderFirstName,
        'policyHolder.lastName': this.state.policyholderLastName,
        'policyHolder.mobile': this.state.policyholderPhone,
        'policyHolder.email': this.state.policyholderEmail,
        'policyHolder.address1': this.state.policyholderAddress,
        'policyHolder.documentType': 'HKID',
        'policyHolder.documentNo': this.state.policyholderHKID,
        'policyHolder.dateOfBirth': this._apiDateFormat(this.state.policyholderDOB),
        'ipa.premiumPlan': people, // F = Family Plan, I = Indivdual / Group of Indivduals, IC = Indivdual and Children
        'ipa.insuranceDeclined': this.state.question1,
        'ipa.declineRemarks': this.state.question1 == 'Y' ? this.state.question1Reason : null,
        'ipa.hasImpairment': this.state.question2,
        'ipa.diagnosis': 'N', //Always N
        'ipa.businessTraveller': 'N', //Always N
        'ipa.selfEmployed': 'N', //Always N
        'ipa.sumInsuredA1': null, // keep as null as there is no tailer plan anymore
        'ipa.sumInsuredA2': null, // keep as null as there is no tailer plan anymore
        'ipa.sumInsuredB': null, // keep as null as there is no tailer plan anymore
        'ipa.sumInsuredC': null, // keep as null as there is no tailer plan anymore
        'ipa.includeD': null, // keep as null as there is no tailer plan anymore
        'members': this.state.members,
        'rawRequest': JSON.stringify(rawRequest),
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      if (this.state.usingEcash == 'Y') {
        fields['payment.ecash'] = this.state.ecash;
      }
      if (this.state.promotionCodes) {
        fields['payment.promotionCode'] = this.state.promotionCodes;
      }

      const ipaPa = this.state.broker ? AgentAPI.ipaPa: ApplyAPI.ipaPa;
      ipaPa(
        fields,
        (msgDesc) => {
          if (this.state.broker) {
            this.setState({alert:
            Alert.alert(
              this.state.text.thankYou,
              this.state.text.youHaveSuccessfully +
              this.state.text.generaliPersonalAccidentInsurancePlan +
              this.state.text.ccccc,
              [{
                text: this.state.text.ok,
                style: 'cancel',
                onPress: () => this.props.history.push('/'),
              }]
            )
          });
          } else if (msgDesc.isManualWriting || this.state.manualUnderwriting) {
  
            this.setState({alert:
              Alert.alert(
                this.state.text.thankYou,
                this.state.text.youHaveSuccessfully +
                this.state.text.generaliPersonalAccidentInsurancePlan +
                this.state.text.ccccc +
                this.state.text.manualUnderwriting +
                this.state.text.inCaseOfAnyQuestions,
                [{
                  text: this.state.text.ok,
                  style: 'cancel',
                  onPress: () => this.props.history.push('/'),
                }]
              )
            });
          
          } else {
            stateStorage.setState('ipa4', this.state);
            this.props.history.push('Payment', {
              uri: host + api310 +
                '?txId=' + msgDesc.txId + '&' +
                'locale=' + (this.state.language == 'zhHK' ? 'zh-HK' : 'en-US'),
              policyNumber: msgDesc.policyNo,
              email: this.state.policyholderEmail,
              recaptcha: !this.state.member,
            });
          }          
          this.setState({submitButtonDisabled: false});
        },
        (msgCode, msgDesc, handleDefault) => {
          if(msgCode==180){
            this.setState({alert: Alert.alert(this.state.text.submissionFailed,
              this.state.text.tryAgain,
              [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}]
            )});
          } else {
            handleDefault()
          }
          this.setState({submitButtonDisabled: false});
        }
      )
    }
  }

  render() {
    let insureds = [];
    let relationshipList = [
      {
        key: "insured",
        label: options.relationship.insured[this.state.language]
      },
      {
        key: "spouse",
        label: options.relationship.spouse[this.state.language]
      },
      {
        key: "child",
        label: options.relationship.child[this.state.language]
      },
      {
        key: "parents",
        label: options.relationship.parents[this.state.language]
      },
      {
        key: "parInLaw",
        label: options.relationship.parInLaw[this.state.language]
      },            
      {
        key: "employee",
        label: options.relationship.employee[this.state.language]
      },
    ]

    this.state.members.forEach((member, i) => {
      
      let relationship;
      let occupation;
      switch (member.relationship) {
        case 'insured':
          relationship = this.state.text.insured
          occupation = this.state.occupations.find((obj) => obj.key == member.occupation).label ;
          break;
        case 'spouse':
          relationship = this.state.text.spouse ;
          occupation = this.state.occupations.find((obj) => obj.key == member.occupation).label;
          break;
        case 'child':
          relationship = this.state.text.child ;
          occupation = "N/A";
          break;
        default: 
          relationship = relationshipList.find((obj) => obj.key == member.relationship).label
          occupation = this.state.occupations.find((obj) => obj.key == member.occupation).label;
          break;
      }
      insureds.push(
        <View key={member.documentNo}>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{relationship}</Text>
          </View>
          <View key={member.firstName}>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.name}</Text>
              <Text>
                {formatName(member.salutation, member.firstName, member.lastName)}
              </Text>
            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.hkid}</Text>
              <Text>{member.documentNo}</Text>
            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
              <Text>
                {moment(member.dateOfBirth, 'YYYY-MM-DD').format('MMMM D, YYYY')}
              </Text>
            </View>

            {
              member.gender &&
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.sex}</Text>
                  <Text>{member.gender == 'M' ?
                    this.state.text.male : this.state.text.female}</Text>
                </View>
            }

            {
              member.occupation &&
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.occupation}</Text>
                  <Text>{occupation}</Text>
                </View>
            }

            {
              member.rightHanded &&
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.rightHanded}</Text>
                  <Text>{member.rightHanded == 'Y' ? this.state.text.yes : this.state.text.no}</Text>
                </View>
            }
          </View>
        </View>
      );
    });
    return (
      <div className='App'>
      <Helmet>
        <title>Executive Personal Accident Protector | Bravo Generali</title>
      </Helmet>

          <Navbar
              title={this.state.text.getInsured}
              backButton
              history={this.props.history}
            />

            <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
              <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                  <View style={[styles.greeting,{fontWeight:'bold',}]}>
                    <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.epa}</Text>
                    <p></p>
                    {
                      this.state.language == 'zhHK' ?
                      <View>
                      <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                      </View>:
                      <View>
                      <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                      </View>

                    }

                  </View>

              </Grid>
              </Hidden>

              <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
             <ScrollView style={formStyles.form}>
             {this.state.alert}
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h2}>{this.state.text.generaliPersonalAccidentInsurancePlan}</Text>
          </View>

          {
            this.state.broker &&
              <View style={formStyles.fieldset}>
                <Text>{this.state.text.technicalRepresentativeMobileNumber}</Text>
                <Text>{this.state.technicalRepresentativeMobileNumber}</Text>
              </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.productType}</Text>
            <Text>{planTypes[this.state.planType].label[this.state.language]}</Text>
          </View>

          {
            this.state.planType == 'IPAT' &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.coverageA1}</Text>
                  <Text style={formStyles.bold}>{options.coverage.coverageA1.description[this.state.language]}</Text>
                  <Text>{addCommas(this.state.coverageA1)}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.coverageA2}</Text>
                  <Text style={formStyles.bold}>{options.coverage.coverageA2.description[this.state.language]}</Text>
                  <Text>{addCommas(this.state.coverageA2)}</Text>
                </View>

                {
                  this.state.coverageB &&
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.bold}>{this.state.text.coverageB}</Text>
                    <Text style={formStyles.bold}>{options.coverage.coverageB.description[this.state.language]}</Text>
                    <Text>{addCommas(this.state.coverageB)}</Text>
                  </View>
                }

                {
                  this.state.coverageC &&
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.bold}>{this.state.text.coverageC}</Text>
                    <Text style={formStyles.bold}>{options.coverage.coverageC.description[this.state.language]}</Text>
                    <Text>{addCommas(this.state.coverageC)}</Text>
                  </View>
                }

                {
                  this.state.coverageD &&
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.bold}>{this.state.text.coverageD}</Text>
                    <Text style={formStyles.bold}>{options.coverage.coverageD.description[this.state.language]}</Text>
                    <Text>{this.state.coverageD == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
                  </View>
                }
              </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.coverType}</Text>
            <Text>{options.people[this.state.people] ? options.people[this.state.people].label[this.state.language] : ""}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.effectiveDate}</Text>
            <Text>{this.state.effectiveDateStart} - {this.state.effectiveDateEnd}</Text>
          </View>

          {
            this.state.promotionCodes &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.promotionCodes}</Text>
            <Text>{this.state.promotionCodes}</Text>
          </View>
          }
          {
            this.state.businessEmailAddress &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.businessEmailAddress}</Text>
            <Text>{this.state.businessEmailAddress}</Text>
          </View>
          }
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.policyholder}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.name}</Text>
            <Text>
            {formatName(
                this.state.policyholderTitle,
                this.state.policyholderFirstName,
                this.state.policyholderLastName
              )}
              </Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.phone}</Text>
            <Text>{this.state.policyholderPhone}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.email}</Text>
            <Text>{this.state.policyholderEmail}</Text>
          </View>

          {
            this.state.policyholderAddress &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.correspondenceAddress}</Text>
            <Text>{this.state.policyholderAddress}</Text>
          </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.hkid}</Text>
            <Text>{this.state.policyholderHKID}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
            <Text>{this.state.policyholderDOB}</Text>
          </View>

          { insureds }

        {
          this.state.question1 == 'Y' &&
           <View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.haveYourOrOtherCoveredMembersAPPOflife}</Text>
              <Text>{this.state.question1 == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
            </View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.insurer}</Text>
              <Text>{this.state.question1Insurer}</Text>
            </View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.benefit}</Text>
              <Text>{this.state.question1Benefit}</Text>
            </View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.reason}</Text>
              <Text>{this.state.question1Reason}</Text>
            </View>
          </View>
        }
        {
          this.state.question2 == 'Y' &&
           <View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment}</Text>
              <Text>{this.state.question2 == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
            </View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.diagnosis}</Text>
              <Text>{this.state.question2Diagnosis}</Text>
            </View>
          </View>
        }

{
          this.state.question3 == 'Y' &&
           <View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.busniesstravellerIPA4}</Text>
              <Text>{this.state.question3 == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
            </View>
          </View>
        }

{
          this.state.question4 == 'Y' &&
           <View>
            <View style={formStyles.fieldset}>
              <Text style={formStyles.bold}>{this.state.text.isselfemployed}</Text>
              <Text>{this.state.question4 == 'Y' ? this.state.text.yes : this.state.text.no }</Text>
            </View>
          </View>
        }

        {
            this.state.specialRequests &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
            <Text>{this.state.specialRequests}</Text>
          </View>
        }
        {
            this.state.referral &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.referral}</Text>
            <Text>{this.state.referral}</Text>
          </View>
        }

          <View style={formStyles.fieldset}>
            <ImportantNotes
              color='#C50000'
              backgroundColor='#F5F5F5'
              importantNotes={[
                {
                  number: 1,
                  text: this.state.text.policyholderAndInsuredsHaveValidHKID
                },
                {
                  number: 2,
                  text: this.state.text.premiumChargedWillBeBasedOnTheInsured
                },
                {
                  number: 3,
                  text: this.state.text.ifACoupleAppliesTogether
                },
                {
                  number: 4,
                  text: this.state.text.beneficiaryWillbeTheLegalEstateOfTheInsured
                },
                {
                  number: 5,
                  text: this.state.text.assicurazioniGeneraliSpAReserves
                },
              ]}
            />
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.iWECerrifyNotesOfIPAPI}
              choice={this.state.declaration1}
              onChange={(choice) => this.setState({declaration1: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration1}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <LegalDocumentLink
              navigation={this.props.navigation}
              choice={this.state.declaration2}
              onChange={(choice) => this.setState({declaration2: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.iWeHerebyAuthorizedAnyLicensed}
              choice={this.state.declaration3}
              onChange={(choice) => this.setState({declaration3: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
          </View>


          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.iWeDoNotConsentPDto}
              choice={this.state.declaration4}
              onChange={(choice) => this.setState({declaration4: choice})}
              
            />
            <Text style={formStyles.message}>{this.state.messages.declaration4}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.iWeDoNotConsentPDToCompanyMarketing}
              choice={this.state.declaration5}
              onChange={(choice) => this.setState({declaration5: choice})}
              
            />
            <Text style={formStyles.message}>{this.state.messages.declaration5}</Text>
          </View>

          {
            (this.state.insuranceAdvisor || this.state.broker) &&
              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.brokerDeclaration}
                  choice={this.state.brokerDeclaration}
                  onChange={(choice) => this.setState({brokerDeclaration: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.brokerDeclaration}</Text>
              </View>
          }

          {
            !this.state.broker &&
              <View>
                {
                  this.state.manualUnderwriting ?
                  <PaymentBreakdown manualUnderwriting /> :
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.payment}</Text>
                    </View>

                    {
                      this.state.ecash > 0 && this.state.total > 40 &&
                        <View style={formStyles.fieldset}>
                          <YesNoQuestion
                            question={this.state.text.youHave + this.state.ecash + this.state.text.eCashNow}
                            choice={this.state.usingEcash}
                            onChange={(choice) => this.setState({usingEcash: choice})}
                          />
                          <Text style={formStyles.message}>{this.state.messages.usingEcash}</Text>
                        </View>
                    }

                    <PaymentBreakdown
                      premium={this.state.premium}
                      levy={this.state.levy}
                      total={this.state.total}
                    />
                  </View>
                }
              </View>
          }

          {
            this.state.submitButtonDisabled ?
              <View style={formStyles.submitButtonDisabled}>
                <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
              </View> :
              <TouchableOpacity style={formStyles.submitButton} onPress={() => this._confirm()}>
                <Text style={formStyles.submitButtonText}>{this.state.text.submit}</Text>
              </TouchableOpacity>
          }
        </ScrollView>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
        <Footer/>
  </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
  },
  font:{
    margin: 'auto',
    minWidth: 300,
    maxWidth: 300,
    textAlign: 'left'
  },
};
