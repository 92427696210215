import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const planTypes = {
  basic: {
    label: {
      enUS: enUS.withAccompanying,
      zhHK: zhHK.withAccompanying,
    },
    color: 'white',
    backgroundColor: '#8E1230',
  },
  plus: {
    label: {
      enUS: enUS.withoutAccompanying,
      zhHK: zhHK.withoutAccompanying,
    },
    color: 'white',
    backgroundColor: '#E9573D',
  },
};
