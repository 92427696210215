export const zhHK = {
  getAQuote: '索取報價',
  ussstudyplan: '美國學習計劃',
  ussp: '美國學習計劃',
  secondarySchoolNUniversityIsATime: '美國一直是每個學生及家長海外留學的心儀選擇。有見及此，忠意保險誠意為貴子女設計了一份全新又全面的升學醫療保障。此計劃既能滿足美國學院對醫療保險的要求，且提供更相宜的保費選擇。貴子女定能更安心專注學習。',
  productEdges:'產品特點',
  highMedicalExpenses: '醫療保障額高達HK$1,500,000 (包括 COVID-19)',
  hospitaliztionBenefitsInHK: '在香港的住院保障（新診斷的疾病或受傷）',
  twelveMonthsConsecutiveCover: '連續12個月無間斷保障',
  optionalCoverForAccompanied: '可自選附加保障陪同留學之父/母',
  zeroExcess: '所有保障均無自負金額',

  
  point1:"美國最大的醫療保健網絡 (PPO)",
  point2:"出院無需結帳（共付額除外）",
  point3:"醫療保障 - 賠償額不設上限 (指定計劃)  / 保障全面",
  point4:"緊急醫療援助",
  point5:"美國設立24小時熱線中心",
  point6:"保障恐怖襲擊",
  point7:"保障所有經學校安排的旅程（包括外地行程）",
  point8:"連續12個月無間斷保障",
  point9:"節省保費",
  point10:"如果向美國大學申請保險豁免失敗，我們將全額退還保費。",
  

  forDetailsOfTheProduct:'有關產品細節，不保事項及保費率，請參閱產品小冊子及保單條款',

  planType: '產品類別',
  withAccompanying: '標準',
  withoutAccompanying: '優越',
  studentage:'年紀',

  locationOfTheOverseasStudyingInstitution:'緊急聯絡',
  usaCanada: '美國/加拿大',
  pleaseInputInEnglish: '* 請輸入英文 *',
  needsToBeInEnglish: '需以英文填寫',
  emergencyPhone:'緊急聯絡人電話',
  emergencyLastName:'緊急聯絡人姓',
  emergencyFirstName:'急聯絡人名',

  promotionCodes: '優惠代碼',
  optional: '非必填',
  businessEmailAddress: '商業電郵 / 會員',
  businessEmailMembership: '商業電郵 / 會員',
  getAQuote: '索取報價',

  iWouldLikeToBuy: '我想投保',
  total: '合共',
  levyIsNotIncluded: '不包括保費徵費及有關費用',
  buyNow: '立即購買',

  productType: '產品類別',
  effectiveDate: '生效日期',
  pleasePickADate: '請選擇日期',

  policyHolder: '保單持有人',
  thePolicyHolderMustBeThe: '投保人必須是受保學生。',
  name: '姓名',
  title: '稱謂',
  mr: '先生',
  ms: '女士',
  mrs: '太太',
  miss: '小姐',
  firstName: '名',
  lastName: '姓',

  hkid: '香港身份證',
  pleaseEnterAllCharacters: '請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456（7），請輸入A1234567。',
  dateOfBirth: '出生日期',
  mobileNumber: '手機號碼',
  correspondenceAddress: '通訊地址 ',
  emailAddress: '電郵地址',

  deductible:'每年免賠額',
  insuredStudent: '受保學生',
  overseasStudyingInstitution: '海外留學學校',
  location: '地區',
  address: '地址',
  specialRequests: '特別要求',
  referral: '介紹碼',
  thisFieldCannotBeBlank: '必須填寫空格',

  importantNotes: '重要聲明:',
  thePolicyHolderShallBe: '投保人應為受保學生的母親，父親或法定監護人。',
  // thisPolicyExcludes: '本保險不包括承保危險職業工作職責的受保人（請參閱我們的條款中的定義）。',
  theInsuredShallBe: '受保人的身體狀況良好，並同意本保險不包括任何已存在的疾病。所有承保範圍均受條款和條件的約束。',
  assicurazioniGeneraliReservesTheRight: '忠意保險有限公司　(香港分行)　保留接受或拒絕任何申請的權利。',
  // iWeCertifyGeneraliAsap: '我 / 我們證明我/我們已閱讀並同意全年海外留學保障的重要聲明。',
  // iWeCertifyDisclaimerNPersonalInformation: '我 / 我們證明我/我們已閱讀並同意免責聲明和收集個人資料聲明。',
  // iWeUnderstand: '我/我們理解、承認並同意，購買忠意保險有限公司(香港分行)所發行的保險，會給付佣金與傳遞我/我們的個人數據給官方保險經紀人，並且只會在安排和管理保單的持續期間使用。',
  levy: '保費徵費',
  confirm: '確認',
  payment: '付款',
  cardNumber: '信用卡號碼',
  expi: '到期日',
  mm: '月',
  submit: '提交',

  youHave: '您有 ',
  eCashNow: 'Generali 現金券，是否要使用它？',

  iWeDoNotConsentPDto:'我/我們不同意提供個人資料被第三方作為行銷用途使用',
  iWeDoNotConsentPDToCompanyMarketing:'我/我們不同意提供個人資料給貴公司作為行銷用途',
  brokerDeclaration: '我/我們明白，確知及同意，忠意保險有限公司（香港分行） 會就本人的申請而簽發的保單，向安排保單事宜的獲授權保險經紀或保險代理',
  brokerDeclarationII:'支付佣金，並向其提供閣下之個人資料，作處理及管理該保單之用途 。',

  insuranceAdvisorMobile: '保險顧問電話號碼',
  periodOfInsurance: '保障期限',
  bothDatesInclusive: '(包含開始日及結束日)',
  optionalBenefit: '自選保障',
  thereIsAnOptional: '自選保障予同行父親、母親或法定監護人。',
  personToBeInsured: '受保人',
  sameAsThePolicyholder: '保單持有人',
  otherPerson: '其他人',
  pleaseChoose: '請選擇',
  relationshipWithThePolicyholder: '與保單持有人的關係',
  other: '其他',
  spouse: '配偶',
  child: '子女',
  guardian:'監護人',
  children: '子女',
  parents: '父母親',
  relatives: '親戚',
  friends: '朋友',
  others:'其他',
  otherCountry: '其他國家',
  insuredName: '受保人姓名',
  insuredHKID: '受保人香港身分證',
  insuredAddress: '受保人地址',
  insuredDateOfBirth: '受保人出生日期',
  pleaseWait: '請稍後',
  manualUnderwritingIsRequired:'* 您的申請需要核保 *',
  getInsured: '立即投保',
  invalidPromotionCode: '無效的優惠代碼',
  addInsuranceAdvisor: '新增保險顧問',
  wouldYouLike: '您願意 ',
  toBeYourInsuranceAdvisor: ' 成為您的保險顧問嗎？',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成購買',
  thankYou: '謝謝！',
  youHaveSuccessfully: '您已成功提交',
  ccccc: '申請。',
  stateCode:'州份代號',
  universityCode:'大學代號',
  hasMedicalCare:'是否曾經接受專業醫護人員的跟進診治或醫療護理',
  takeMedications:'是否曾被醫生建議定期服用為期超過一個月的處方藥物',
  isPregnant:'現時是否懷孕',
  visaStatus:'受保學生簽證狀況',
  manualUnderwriting: '您的申請需要核保，我們會在兩個工作天內回覆您。',
  youCanAlsoFindYour: '您可以在訂單歷史區域找到您的申請資訊。',
  inCaseOfAnyQuestions: '假如有任何疑問，您可透過此 APP 的「聯絡我們」功能與我們聯絡。',
  instituteName: '名稱',
  check: '請檢查您的輸入一遍！',
  cancel: '取消',
  theInsuranceIsIssuedByContent: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發。',
  theInsuranceIsIssued: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 ',
  professionalInsurance: ' 安排。',

  yes:'是',
  no:'否',

  notValidEmail:'這不是有效的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是有效身份証號碼',

  alert: '請注意',
  ok: '確認',
  submissionFailed: '提交失敗',
  invalidMobileNumber: '無效的保險顧問電話號碼。',
  tryAgain: '我們的系統暫時不可用，請稍後再試',

  congratulations: '恭喜',
  madeAPurchase: '你已成功購買這項產品\n',
  weWillSend: '接下來，我們將會寄一封附有保單及保單條款的電郵到你的電子郵箱',
  anyQuestions: '\n如有任何疑問，歡迎隨時與我們聯絡，謝謝',
  login: '登入',
  logout: '登出',
  register: '登記',
  home: '首頁',
  page: '上一頁',
  asap: '全年海外留學保障',
  ensure: '讓您的孩子安全地展開人生的新篇章。',
  forBetterCustomerExperience: '為了獲得更好的客戶體驗，您想先登入嗎？',
  forBetterCustomerExperiencelogin:'會員登入',
  forBetterCustomerExperiencregister:'註冊新會員',
  forBetterCustomerExperienceCon:'不登入繼續',

  healthdeclarationTitle:'病史',
  healthdeclarationquestions1:'在過去 5 年中，您是否曾經或被建議定期或持續（例如每月、每 2 個月、每半年、每年）就任何疾病或醫療狀況與精神科醫生進行任何後續諮詢或醫療護理？',
  healthdeclarationquestions2:'在過去 5 年中，您的醫生是否曾建議您連續服用任何藥物（例如每天服用/每週一次/按醫生指示服用）超過 1 個月？',
  healthdeclarationquestions3:'你現在懷孕了嗎？',
  healthdeclarationquestions4:'我己符台以下其中一個身分：\na)本科生 - 註冊並全日制上課；或 \nb）研究生；或 \nc)受教育機構邀請的學者或研究人員',

  declarationTitle:'聲明',


  //special case
  paymentStopService1:'尊貴的客戶，',
  paymentStopService2:'由於我們現正進行系統更新，在7月8日晚上11:00至翌日7月9號晚上11:00，服務將會臨時暫停。不便之處，敬請原諒。',

}
