import React from 'react';
import { WebView, View, StyleSheet, Image, Text, ScrollView } from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {Helmet} from 'react-helmet';

import { cms } from './../../config/api';
import { platform } from './../../config/platform';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';
import { formStyles } from './../../config/styles';
export default class Promotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        text: {},
        url: 'https://www.app-generali.com/settings/financial-advisors/promotion_en.html',
    };
  }

  componentDidMount() {
    // init language
    const getText = (language) => {
        let text = enUS;

      if (language == 'zhHK') {
          text = zhHK;
          this.setState({ url: 'https://www.app-generali.com/settings/financial-advisors/promotion_zh.html' });
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState({language: language, text: getText(language)}));
  }

  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Promotion | Bravo Generali</title>
        </Helmet>

      <Navbar
          title={this.state.text.promotion}
          backButton
          history={this.props.history}
        />

      <Grid container spacing={20} style={{ backgroundColor: 'white'}}>
        <Hidden xsDown>
            <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

              <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.promotion}</Text>

              </View>

            </Grid>
        </Hidden>

        {this.state.alert}
        <Hidden xsDown>
       <Grid item xs style={{ marginTop: 30, marginBottom: 50, backgroundColor: 'white' }}>
        <div
      className="promotion"
      style={{
        position: "relative",
        paddingBottom: "100%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={this.state.url}
        frameBorder="0"
      />
        </div>
         </Grid>
        </Hidden>

        <Hidden smUp>
                    <Grid item xs style={{ marginTop:0, marginBottom:0, backgroundColor: 'white' }}>
                        <View style={formStyles.form, styles.fie}>

                            <iframe
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%"
                                }}
                                src={this.state.url}
                                frameBorder="0"
                            />

                        </View>
                    </Grid>
         </Hidden>

      <Hidden xsDown>
       <div style={{marginRight: '5%'}} />
      </Hidden>
      </Grid>

      <Footer/>
    </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fie: {
      margin: 'auto',
      minWidth: 300,
      maxWidth: 600,
      minHeight: 580,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: 'white',
  },
});
