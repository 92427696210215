import React from 'react';
import {
  AsyncStorage, Dimensions, Text, Image,
  View, ScrollView, TouchableOpacity, Switch, TextInput, Linking,
} from 'react-native';
import CryptoJS from 'crypto-js';
import { Container, Row, Col } from 'react-grid-system';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { formStyles } from './../../config/styles';
import { platform } from './../../config/platform';
import { checkVersion } from './../../lib/utils';
import { stateStorage } from './../../lib/StateStorage';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { host } from './../../config/api';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import Alert  from './../../components/Alert/Alert';
import ModalSelector from './../../components/ModalSelector/ModalSelector';

import Member from './../../Member';





export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      alert:null,
      alertMobile:null
    };

    Member.onReady(() => this.setState(this.state));

    AsyncStorage.getItem('CONFIRMED', (err, result) => {
      if (result) {
        this.setState({confirmed: JSON.parse(result)});
      }
      else {
        this.setState({confirmed: false, subconfirmed: false});
      }
    });

    try {
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        localStorage.removeItem('businessEmail');
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        localStorage.removeItem('promotionCodes');
        localStorage.setItem('businessEmail', businessEmail);
      }
    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
  }



  componentDidMount() {
    
    stateStorage.removeState();
    localStorage.removeItem('state');
    localStorage.setItem('preventGoBack', 'true');

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };

    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        checkVersion(this.state.language);
      })
    );

    memberStorage.getMember((member) => this.setState({member: member}));
  }

	_subconfirm() {
  	if (this.state.PA && this.state.LI) {
  		this.setState({subconfirmed: true});
  	}
    else {
      Alert.alert(
        this.state.text.alert,
        this.state.text.pleaseAgree,
        [
          {text: this.state.text.ok},
        ]
      );
    }
  }

  _confirm() {
    AsyncStorage.setItem('CONFIRMED', JSON.stringify(true), () => {
      this.setState({confirmed: true, subconfirmed: false});
    });
  }

  _register() {
  	this._confirm();
    this._history('Register');
  }

  _login() {
    this._confirm();
  	this._history('Login');
  }

  _history(screen) {
    this.props.history.push(screen);
  }

  _push(screen) {
    this.props.history.push(screen);
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    return (
      <div className='App' >
        <Helmet>
          <title>Bravo Generali</title>
        </Helmet>

        <View style={styles.container}>
          <Navbar
            logo brokerButton homeButton={false}
            history={this.props.history}
          />

          {/* ///////////// */}
          {/* / ∇ 電腦版 ∇ / */}
          {/* ///////////// */}

        <Hidden xsDown>
        <ScrollView>
          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2', minHeight: window.innerHeight - 190}}>
            <Grid item xs style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 20, paddingBottom: '4%'}}>
                <View style={[styles.greeting, {marginTop: '3%'}]}>
                  <Text style={[styles.greetingText,{fontWeight: 'bold',fontSize: 30}]}>{this.state.text.welcome}</Text>
                </View>
                {
                  !this.state.member &&
                    <View style={[styles.greeting, {marginTop: '1%'}]}>
                      <Text
                        style={[
                          styles.greetingText,
                          {marginBottom:'2%',fontSize: 20,fontWeight: 'bold'}]}
                      >
                        {this.state.text.hellohello}
                      </Text>
                    </View>
               }
               {
                 this.state.member &&
                   <View style={[styles.greeting, {marginTop: '1%'}]}>
                     <Text
                       style={[
                         styles.greetingText,
                         {marginBottom:'2%',fontSize: 20,fontWeight: 'bold'}]}
                     >
                      {this.state.text.hellohello} {this.state.member.firstName}
                     </Text>
                   </View>
               }

               <View style={{flex:1,justifyContent: 'center',alignItems: 'center',}}>
                 <img
                   style={{width: '60%',}}
                   src={Member.getTheme().imageHome}
                 />
                 <View style={{ justifyContent: 'flex-end', alignItems: 'center',marginBottom: 10,}}>
                   <View style={{padding: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, }}>
                     <Text style={{color, textAlign: 'center',fontSize: 27,fontWeight:'bold'}}>
                     {this.state.text.quickLinks}
                     </Text>
                   </View>
                 </View>
                 {
                   this.state.member ?
                     <View style={{flexDirection: 'row'}}>
                       <Grid item xs={3}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/buyagain')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./buyAgain-white.png')}
                           />
                           
                      
                           <View>
                             <Text style={{color}}>
                               {this.state.text.buyAgain}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>
                       <Grid item xs={3}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/promotion')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./horn-white.png')}
                           />
                           <View>
                             <Text style={{color}}>
                               {this.state.text.promotion}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>
                       <Grid item xs={3}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/gtravel1')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./airplane-white.png')}
                           />
                           <View>
                             <Text style={{color}}>
                               {this.state.text.travel}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>
                       <Grid item xs={3}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/home1')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./home-white.png')}
                           />
                           <View>
                             <Text style={{color}}>
                               {this.state.text.homeScreen}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>

                     </View>
                     :
                     <View style={{flexDirection: 'row'}}>

                       <Grid item xs={4}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/promotion')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./horn-white.png')}
                           />
                           <View>
                             <Text style={{color}}>
                               {this.state.text.promotion}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>
                       <Grid item xs={4}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/gtravel1')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./airplane-white.png')}
                           />
                           <View>
                             <Text style={{color}}>
                               {this.state.text.travel}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>
                       <Grid item xs={4}>
                         <TouchableOpacity
                           style={styles.shorcutItem}
                           onClick={() => this._push('/home1')}
                         >
                           <Image
                            style={[styles.menubarImage,{tintColor:Member.getThemeColor()}]}
                             source={require('./home-white.png')}
                           />
                           <View>
                             <Text style={{color}}>
                               {this.state.text.homeScreen}
                             </Text>
                           </View>
                         </TouchableOpacity>
                       </Grid>
                     </View>
                 }
               </View>

            </Grid>

      

            <Grid item xs style={{marginTop: 'auto', marginBottom: 'auto', marginLeft:'5%'}}>
               <View style={styles.menu}>
                 <View style={styles.menuRow}>
                   <View style={styles.menuColumn}>
                     <TouchableOpacity style={[styles.menuItemLeft, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onPress={() => this._push('/instant-enrollment')}>
                     <Image
                     style={[styles.menuImage,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageInsuranceProducts}}
                    />
                       <View style={styles.menuDescription}>
                         <Text style={[styles.menuText, {color}]}>{this.state.text.instantEnrollment}</Text>
                       </View>
                     </TouchableOpacity>
                   </View>

                   <View style={styles.menuColumn}>
                     <TouchableOpacity style={[styles.menuItemRight, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onPress={() => this._push('/privilegedClubList')}>
                     <Image
                     style={[styles.menuImage,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imagePrivilegeClub}}
                    />
                       <View style={styles.menuDescription}>
                         <Text style={[styles.menuText, {color}]}>{this.state.text.privilegedClub}</Text>
                       </View>
                     </TouchableOpacity>
                   </View>

                 </View>

                 <View style={styles.menuRow}>
                   <View style={styles.menuColumn}>
                    {/* <TouchableOpacity style={[styles.menuItemLeft, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onPress={() => this._push('/instant-claimsScreens')}>*/}
                    <TouchableOpacity 
                      style={[styles.menuItemLeft, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} 
                      onPress={() => {
                        this.setState({
                          alert:Alert.alertMulti(
                          [{
                            text: this.state.text.ok,
                            onPress: () => this.setState({alert:null}),
                          }],
                          //this.state.text.claimAlertText1,
                          //this.state.text.claimAlertText2,
                          //this.state.text.claimAlertText3,
                          //this.state.text.claimAlertText4,
                          //this.state.text.claimAlertText5,
                          //this.state.text.claimAlertText6,
                          this.state.text.NeedToMakeClaim,
                          this.state.text.followingLink,
                          this.state.text.followingLinkText,
                          this.state.text.claimContactText,
                          this.state.text.claimContactEmail,
                          {
                         
                          },
                          {
                           
                          },
                          {
                         
                          }
                        )
                      })
                      }}
                    >
                     {this.state.alert}
                     <Image
                      style={[styles.menuImage,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageClaims}}
                    />
                       <View style={styles.menuDescription}>
                         <Text style={[styles.menuText, {color}]}>{this.state.text.instantClaims}</Text>
                       </View>
                     </TouchableOpacity>
                   </View>

                   <View style={styles.menuColumn}>
                     <TouchableOpacity style={[styles.menuItemRight, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onPress={() => this._push('/history')}>
                     <Image
                     style={[styles.menuImage,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageHistory}}
                    />
                       <View style={styles.menuDescription}>
                         <Text style={[styles.menuText, {color}]}>{this.state.text.history}</Text>
                       </View>
                     </TouchableOpacity>
                   </View>


               </View>



               <View style={styles.menuRow}>
                 <View style={styles.menuColumn}>
                     <TouchableOpacity style={[styles.menuItemLeft, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onPress={() => this._push('/roarUs')}>
                     <Image
                     style={[styles.menuImage,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageRoarUs}}
                    />
                       <View style={styles.menuDescription}>
                         <Text style={[styles.menuText, {color}]}>{this.state.text.roarUs}</Text>
                       </View>
                     </TouchableOpacity>
                   </View>

                   <View style={styles.menuColumn}>
                    <TouchableOpacity style={[styles.menuItemRight, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onPress={() => this._push('/settings')}>
                   
                       <Image
                     style={[styles.menuImage,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageSettings}}
                    />
                      <View style={styles.menuDescription}>
                        <Text style={[styles.menuText, {color}]}>{this.state.text.settings}</Text>
                      </View>
                     </TouchableOpacity>
                   </View>
                 </View>
                </View>

            </Grid>

               <div style={{marginRight: '2%'}} />
            </Grid>
          </ScrollView>
          </Hidden>

            {/* ///////////// */}
            {/* / ∇ 手機版 ∇ / */}
            {/* ///////////// */}

          <Hidden smUp>
          <ScrollView>

          <Grid container>
            <Grid item xs={12}>
            <View style={{flex:1}}>
              <img
                style={{width: '100%'}}
                src={Member.getTheme().imageHome}
              />
              <View style={{ justifyContent: 'flex-end', alignItems: 'center', marginTop: -28,marginBottom: 10,}}>
                <View style={{backgroundColor, padding: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5,}}>
                  <Text style={{color: 'white', textAlign: 'center',}}>{this.state.text.quickLinks}</Text>
                </View>
              </View>
            </View>

              {
                this.state.member ?
                  <View style={{flexDirection: 'row', backgroundColor, marginTop: -10}}>
                    <Grid item xs={3}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/buyagain')}
                      >
                        <img
                          style={{width: '75%'}}
                          src={'./assets/images/buyAgain002.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.buyAgain}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>
                    <Grid item xs={3}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/promotion')}
                      >
                        <img
                          style={{width: '75%'}}
                          src={'./assets/images/horn01.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.promotion}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>
                    <Grid item xs={3}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/gtravel1')}
                      >
                        <img
                          style={{width: '75%'}}
                          src={'./assets/images/airplane-01.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.travel}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>
                    <Grid item xs={3}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/home1')}
                      >
                        <img
                          style={{width: '75%'}}
                          src={'./assets/images/home002.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.homeScreen}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>

                  </View>
                  :
                  <View style={{flexDirection: 'row', backgroundColor, marginTop: -10,}}>

                    <Grid item xs={4}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/promotion')}
                      >
                        <img
                          style={{width: '60%'}}
                          src={'./assets/images/horn01.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.promotion}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>
                    <Grid item xs={4}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/gtravel1')}
                      >
                        <img
                          style={{width: '60%'}}
                          src={'./assets/images/airplane-01.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.travel}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>
                    <Grid item xs={4}>
                      <TouchableOpacity
                        style={styles.shorcutItem}
                        onClick={() => this._push('/home1')}
                      >
                        <img
                          style={{width: '60%'}}
                          src={'./assets/images/home002.png'}
                        />
                        <View>
                          <Text style={{color:'#FFF'}}>
                            {this.state.text.homeScreen}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Grid>
                  </View>
              }
            </Grid>

              <Grid item xs={4}>
                <View style={styles.menuPhone}>

                    <TouchableOpacity style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onClick={() => this._push('/instant-enrollment')}>
          

                    <Image
                     style={[styles.menuImagemob,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageInsuranceProducts}}
                    />

                      <View style={styles.menuDescription}>
                        <Text style={[styles.menuText, {color}]}>{this.state.text.instantEnrollment}</Text>
                      </View>
                    </TouchableOpacity>

                </View>
              </Grid>

              <Grid item xs={4}>
                <View style={styles.menuPhone}>

                  {/*<TouchableOpacity style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onClick={() => this._push('/instant-claimsScreens')}>*/}
                  <TouchableOpacity 
                    style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} 
                    onPress={() => {
                      this.setState({
                        alertMobile:Alert.alertMulti(
                        [{
                          text: this.state.text.ok,
                          onPress: () => this.setState({alertMobile:null}),
                        }],
                        //this.state.text.claimAlertText1,
                        //this.state.text.claimAlertText2,
                        //this.state.text.claimAlertText3,
                        //this.state.text.claimAlertText4,
                        //this.state.text.claimAlertText5,
                        //this.state.text.claimAlertText6,
                        this.state.text.NeedToMakeClaim,
                        this.state.text.followingLink,
                        this.state.text.followingLinkText,
                        this.state.text.claimContactText,
                        this.state.text.claimContactEmail,
                        {
                       
                        },
                        {
                         
                        },
                        {
                       
                        }
                      )
                    })
                    }}
                    >
                    {this.state.alertMobile}
                    <Image
                     style={[styles.menuImagemob,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageClaims}}
                    />
                    <View style={styles.menuDescription}>
                      <Text style={[styles.menuText, {color}]}>{this.state.text.instantClaims}</Text>
                    </View>
                  </TouchableOpacity>

                </View>
              </Grid>

                <Grid item xs={4}>
                  <View style={styles.menuPhone}>

                    <TouchableOpacity style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onClick={() => this._push('/roarUs')}>
                    <Image
                     style={[styles.menuImagemob,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageRoarUs}}
                    />
                      <View style={styles.menuDescription}>
                        <Text style={[styles.menuText, {color}]}>{this.state.text.roarUs}</Text>
                      </View>
                    </TouchableOpacity>

                  </View>
                </Grid>

                <Grid item xs={4}>
                  <View style={styles.menuPhone}>

                    <TouchableOpacity style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onClick={() => this._push('/privilegedClubList')}>
                    <Image
                     style={[styles.menuImagemob,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imagePrivilegeClub}}
                    />
                      <View style={styles.menuDescription}>
                        <Text style={[styles.menuText, {color}]}>{this.state.text.privilegedClub}</Text>
                      </View>
                    </TouchableOpacity>

                  </View>
                </Grid>

                <Grid item xs={4}>
                  <View style={styles.menuPhone}>

                      <TouchableOpacity style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onClick={() => this._push('/history')}>
                      <Image
                     style={[styles.menuImagemob,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageHistory}}
                    />
                      <View style={styles.menuDescription}>
                        <Text style={[styles.menuText, {color}]}>{this.state.text.history}</Text>
                      </View>
                      </TouchableOpacity>

                  </View>
                </Grid>


                <Grid item xs={4}>
                  <View style={styles.menuPhone}>

                    <TouchableOpacity style={[styles.menuItemPhone, {borderTopColor: color, borderBottomColor: color, borderLeftColor: color, borderRightColor: color}]} onClick={() => this._push('/settings')}>
                    <Image
                     style={[styles.menuImagemob,{tintColor:Member.getThemeColor()}]}
                      source={{uri: Member.getTheme().imageSettings}}
                    />
                      <View style={styles.menuDescription}>
                        <Text style={[styles.menuText, {color}]}>{this.state.text.settings}</Text>
                      </View>
                    </TouchableOpacity>

                  </View>
                </Grid>

          </Grid>
        </ScrollView>
        </Hidden>

          <Footer />
        </View>
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  confirmation: {
    marginRight: 15,
  },
  greeting: {
    marginTop: 5,
    alignItems: 'center',
  },
  greetingText: {
    color: 'black',
  },
  carousel: {
    flex: 6,
    backgroundColor: 'black',
  },
  menu: {
    flex: 4,
    alignItems: 'center',
    marginTop: 100,
    marginRight: '44%',
    marginBottom: '5%',
  },
  menuPhone: {
    flex: 1,
    alignItems: 'center',
    margin: 5,
  },

  menuRow: {
    flex: 1,
    flexDirection: 'row',
  },
  menuColumn: {
    flex: 1,
    flexDirection: 'column',
  },
  menuItemLeft: {
  
    flex: 1,
    marginLeft: 100,
    alignItems: 'center',
    backgroundColor:'#f2f2f2',
    borderTopColor: '#C50000',
    borderBottomColor: '#C50000',
    borderLeftColor: '#C50000',
    borderRightColor: '#C50000',
  },
  menuItemRight: {

    flex: 1,
    marginLeft: 100,
    alignItems: 'center',
    backgroundColor:'#f2f2f2',
    borderTopColor: '#C50000',
    borderBottomColor: '#C50000',
    borderLeftColor: '#C50000',
    borderRightColor: '#C50000',
  },
  menuItemPhone: {
    flex: 1,
    alignItems: 'center',
    backgroundColor:'white',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopColor: '#C50000',
    borderBottomColor: '#C50000',
    borderLeftColor: '#C50000',
    borderRightColor: '#C50000',
  },
  menubarImage: {
    width: 120, 
    height: 120
  },
  menuImage: {
    width: 150, 
    height: 150
  },
  menuImagemob: {
    width: 80, 
    height: 80
  },
  menuDescription: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 40,
  },
  menuText: {
    color: '#C50000',
    textAlign: 'center',
    width: 100, 
  },
  Grid:{
    justifyContent:'center',
    alignItems: 'center',
  },
  shorcutItem: {
    flex: 1,
    margin: 5,
    alignItems: 'center',
  },
};
