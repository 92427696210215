import React from 'react';
import {View} from 'react-native';

import Locale from './../../Locale';
import InsuranceProductScreen from './../InsuranceProductScreen';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, Button,DropDownList} from './../../Form';
import DHScreen from './DHScreen';
import IntroParagraph from './../components/IntroParagraph';
import ProductEdges from './../components/ProductEdges';
import LeafletAndProvision from './../components/LeafletAndProvision';
import PlanType from './../components/PlanType';
import DiscountInput from './../components/DiscountInput';
import moment from 'moment';

export default class DHScreen1 extends DHScreen {
  componentDidMount() {
    this.initialize();
  }

  render() {
    const {pl, policy, domesticHelper, payment} = this.state.insuranceProduct;
   

    if (localStorage.hasOwnProperty("outpatient")) {
        const outpatient = localStorage.getItem('outpatient');
        if(outpatient.length > 0){
        domesticHelper.includeOutpatient = outpatient
        localStorage.setItem('outpatient', '');
        }
    }

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getAQuote}
        />

        {Locale.text.domesticHelperInsurance}
        {Locale.text.domesticHelperInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.domesticHelperInsurance}
            </Heading1>

            <IntroParagraph>
              {Locale.text.asAResponsibleEmployer}
            </IntroParagraph>

            <ProductEdges>
              {Locale.text.coverHelpersDeath}
              {Locale.text.coverHospitalization}
              {Locale.text.optionalOutpatientExenses}
              {Locale.text.comprehensiveCoverageFromBasicEmployee}
            </ProductEdges>

            <LeafletAndProvision
              screen={this}
              leaflet={leaflet}
              provision={provision}
            />

  


            <PlanType
              options={this.includeOutpatientOptions}
              value={domesticHelper.includeOutpatient}
              onChange={(includeOutpatient) => this.updateDomesticHelper({includeOutpatient})}
            />

              <DropDownList
              cozy
              label={Locale.text.periodOfInsurance}
              options={this.DHPlanType}
              value={pl}
              onChange={(pl) => {
                this.updatePlanCode(pl);

                if (policy && policy.effectiveDateStart) {
                  var year = (pl === 'DH') ? 1 : 2;

                  this.updatePolicy({
                    effectiveDateStart: policy.effectiveDateStart,
                    effectiveDateEnd: moment(policy.effectiveDateStart)
                      .add(year, "years")
                      .subtract(1, "days")
                      .format("YYYY-MM-DD"),
                  })
                }
              }}
            />

         


            <DiscountInput
              cozy
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={this.state.initialized &&
                !this.state.agent && !this.state.broker}
              onChange={({promotionCode, businessEmail}) => {
                this.updatePayment({promotionCode});
                this.updatePolicy({businessEmail});
              }}
              messages={this.state.messages.discount}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote('domestic2')}
            >
              {Locale.text.getAQuote}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}

const leaflet = 'Product_leaflet_Domestic%20Helper.pdf';
const provision = 'Product%20Provision_Domestic%20Helper%20Insurance%20Policy.pdf';
