import React from 'react';
import { Text, Image, View, TouchableOpacity } from 'react-native';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';

export default class DocumentAttached extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocuments: [],
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  // async _pickDocument() {
  //   ImagePicker.showImagePicker({
  //     mediaType: 'photo',
  //     width: 1000,
  //     maxHeight: 1000,
  //     title: this.state.text.title,
  //     cancelButtonTitle: this.state.text.cancel,
  //     takePhotoButtonTitle: this.state.text.takePhoto,
  //     chooseFromLibraryButtonTitle: this.state.text.chooseFromLibrary,
  //     storageOptions: {
  //       skipBackup: true,
  //       path: 'images',
  //     },
  //   });
  // }

  render() {
    return (
      <View style={styles.documentAttached}>
        <Text style={{textAlign: 'left'}}>{this.props.text}</Text>

        <input
          type='file'
          accept='image/png, image/jpeg'
          onchange={() =>
            this.files.forEach((file) => {
              this.props.onSelect({uri: file.webkitRelativePath})
            })
          }
        />
      </View>
    );
  }
}

const styles = {
  documentAttached: {
    marginTop: 10,
    marginBottom: 10,
  },
  openPhotoText: {
    color: '#C50000',
    textDecorationLine: 'underline',
  },
  photoSelectedText: {
    color: '#4BB543',
  },
  message: {
    color: '#C50000',
  },
};
