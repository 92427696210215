import React from 'react';
import {Text, View} from 'react-native';

import Locale from './../../Locale';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, Button, TextInput, DropDownList} from './../../Form';
import FireScreen from './FireScreen';
import SumInsuredAmount from './SumInsuredAmount';
import IntroParagraph from './../components/IntroParagraph';
import ProductEdges from './../components/ProductEdges';
import LeafletAndProvision from './../components/LeafletAndProvision';
import DiscountInput from './../components/DiscountInput';

export default class FireScreen1 extends FireScreen {
  componentDidMount() {
    this.initialize();
  }

  render() {
    const {
      insuranceProduct: {policy, insuredHome, payment},
      messages,
    } = this.state;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getAQuote}
        />

        {Locale.text.fireInsurance}
        {Locale.text.fireInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.fireInsurance}
            </Heading1>

            <IntroParagraph>
              {Locale.text.yourHomeLongContent}
            </IntroParagraph>

            <ProductEdges>
              {Locale.text.coverBuildingSORB}
              {Locale.text.ageOfBuiling}
            </ProductEdges>

            <LeafletAndProvision
              noPremiumRates
              screen={this}
              provision={provision}
            />

            <SumInsuredAmount
              cozy
              placeholder
              label={Locale.text.fireSumInsuredAmount}
              value={insuredHome.sumInsured}
              onChange={(sumInsured) => this.updateInsuredHome({sumInsured})}
              message={messages.insuredHome && messages.insuredHome.sumInsured}
            />

            <DropDownList
              cozy
              label={Locale.text.ageOfBuilding}
              options={this.buildingAgeOptions}
              value={insuredHome.buildingAge}
              onChange={(buildingAge) =>
                this.updateInsuredHome({buildingAge})}
            />

            {
              insuredHome.buildingAge > 50 &&
                <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
            }

            <DiscountInput
              cozy
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={this.state.initialized &&
                !this.state.agent && !this.state.broker}
              onChange={({promotionCode, businessEmail}) => {
                this.updatePayment({promotionCode});
                this.updatePolicy({businessEmail});
              }}
              messages={this.state.messages.discount}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote('Fire2')}
            >
              {Locale.text.getAQuote}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}

const provision = 'Product%20Provision_Fire%20Insurance%20Policy.pdf';
