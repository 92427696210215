import React from 'react';
import { AsyncStorage } from 'react-native';
import CryptoJS from 'crypto-js';

export default class PromotionCodesStorage {
  setPromotionCodes(promotionCodes, memberId) {
    AsyncStorage.setItem(
      'PROMOTION_CODES',
      CryptoJS.AES.encrypt(JSON.stringify({
        promotionCodes: promotionCodes,
        memberId: memberId,
      }), '37bO1SVEa8qcyrtHvjrV').toString()
    );
  }

  getPromotionCodes(callback) {
    AsyncStorage.getItem('PROMOTION_CODES', (error, result) => {
      if (!error && result) {
        callback(
          JSON.parse(CryptoJS.AES.decrypt(result, '37bO1SVEa8qcyrtHvjrV')
            .toString(CryptoJS.enc.Utf8))
        );
      }
    });
  }
}

export const promotionCodesStorage = new PromotionCodesStorage();
