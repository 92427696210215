import React from 'react';
import { Text, View } from 'react-native';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';

export default class ProductEdges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  render() {
    let productEdges = [];
    const edges =  this.props.productEdges;
    const htmlTagRegex = /<\/?[\w\s="/.':;#-\/\?]+>/gi;
    for (let edge in edges) {
      let productEdgeText = edges[edge]
      if(productEdgeText){
        if (productEdgeText.match(htmlTagRegex)){
          productEdges.push(
            <View style={styles.productEdge} key={edge}>
                <li>
                  <View style={styles.productEdgeText}>
                    <Text dangerouslySetInnerHTML={{__html: productEdgeText}} style={{textAlign: 'left'}}></Text>
                  </View>
                </li>
            </View>
          )
        }else{
          productEdges.push(
            <View style={styles.productEdge} key={edge}>
                <li>
                  <View style={styles.productEdgeText}>
                    <Text style={{textAlign: 'left'}}>{productEdgeText}</Text>
                  </View>
                </li>
            </View>
          );
        }
      }
    }

    return (
      <View style={styles.productEdges}>
        <View style={styles.productEdgeTitle}>
          <Text style={styles.productEdgeTitleText}>{this.state.text.productEdges}</Text>
        </View>
          <ul style={{listStyle: 'outside',marginLeft: -25}}>
            { productEdges }
          </ul>
      </View>
    );
  }
}

const styles = {
  productEdges: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  productEdgeTitle: {
    marginTop: 10,
  },
  productEdgeTitleText: {
    fontSize: 18,
    marginBottom: 10,
  },
  productEdge: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 10,
  },
  productEdgeBullet: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: 10,
  },
  productEdgeText: {

    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 0,
  },
};
