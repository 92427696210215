import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const planTypes = {
  with: {
    label: {
      enUS: enUS.withHomeBuilding,
      zhHK: zhHK.withHomeBuilding,
    },
    color: 'white',
    backgroundColor: '#8E1230',
  },
  without: {
    label: {
      enUS: enUS.withoutHomeBuilding,
      zhHK: zhHK.withoutHomeBuilding,
    },
    color: 'white',
    backgroundColor: '#E9573D',
  },
};
