import React from 'react';
import {
    Text, TextInput,
    View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
  } from 'react-native';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../../lib/LanguageStorage';
import validate from 'validate.js';
import { formStyles } from './../../../config/styles';
import { clearEmptyStrings, hmacSha256, msgCodeDetail} from './../../../lib/utils';
import { host, api24, busiSecKey } from './../../../config/api';
import { planTypes } from './planTypes';
import { platform } from './../../../config/platform';
import ProductEdges from './../../../components/ProductEdges/ProductEdges';
import AttachmentLink from './../../../components/AttachmentLink/AttachmentLink';
import PlanTypePicker from './../../../components/PlanTypePicker/PlanTypePicker';

export default class CentalineLandlordProtector1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planType: 'with',
      propertySize: 1,
      broker: this.props.navigation.getParam('broker'), // BROKER
      messages: {},
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => this._getNetAreas()
    ));
  }

  _findNetAreaById(netAreaId) {
    let match;
    this.state.netAreas.forEach((netArea) => {
      if (netArea.key == netAreaId) {
        match = netArea;
      }
    });
    return match;
  }

  _getNetAreas() {
    const data = JSON.stringify({
      'dt': moment().format('YYYYMMDDhhmmss'),
      'timeStamp': Date.now(),
    });
    const hmac = hmacSha256(data);
    fetch(host + api24, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.msgCode == 0) {
        let netAreas = [];
        let desc = 'descEnUs';
        if (this.state.language == 'zhHK') {
          desc = 'descZhHk';
        }
        responseJson.msgDesc.netAreas.forEach((netArea) => {
          netAreas.push({
            key: netArea.id,
            label: netArea[desc],
          });
        });
        this.setState({
          netAreas: netAreas,
        }, () => {
          this.setState({
            defaultNetAreaLabel:
              this._findNetAreaById(this.state.propertySize).label,
          });
        });
      }
      else {
        alert(msgCodeDetail(responseJson.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
      }
    })
    .catch((error) => {
      alert('Facing error when processing the query!');
    });
  }

  _nextScreen() {
    const state = clearEmptyStrings(this.state);
    let attributes = {
      businessEmailAddress: state.businessEmailAddress,
      promotionCodes: state.promotionCodes,
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      businessEmailAddress: {
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      promotionCodes: {
        format: {
          pattern: 'I Love TW',
          message: this.state.text.invalidPromotionCode ,
        },
      },

    }
    const messages = validate(attributes, constraints, {fullMessages: false});
      if (messages) {
        this.setState({messages: messages});
      }
      else {
        this.props.navigation.push('CentalineLandlordProtector3', {
          broker: this.state.broker, // BROKER
          netAreas: this.state.netAreas,
          planType: this.state.planType,
          propertySize: this.state.propertySize,
          promotionCodes: this.state.promotionCodes,
          businessEmailAddress: this.state.businessEmailAddress,
    });
  }
}

  render() {
    const leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Centaline%20Landlord_Eng_.pdf';
    const provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Centaline%20Landlord%20Protector%20Policy.pdf';

    return (
      <KeyboardAvoidingView style={styles.container} behavior={platform.behavior}>
        <ScrollView style={styles.form}>
          <View style={styles.fieldset}>
            <Text style={styles.h2}>{this.state.text.centalineLandlordProtector}</Text>
          </View>

          <Text>{this.state.text.comprehesiveProtectionOfYourRentalPropertyContent}</Text>
          <ProductEdges
            productEdges={[
              (this.state.text.productEdge1),
              (this.state.text.productEdge2),
              (this.state.text.productEdge3),
              (this.state.text.productEdge4),
              (this.state.text.productEdge5),
            ]}
          />

          <AttachmentLink
            leaflet={leaflet}
            provision={provision}
            navigation={this.props.navigation}
          />

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.PlanType}</Text>
            <View style={styles.planTypes}>
              <PlanTypePicker
                selectedPlanType={this.state.planType}
                onSelect={
                  (selectedPlanType) =>
                    this.setState({planType: selectedPlanType})
                }
                planTypes={planTypes}
              />
            </View>
          </View>

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.sizeOfTheProperty}</Text>
            <Text>{this.state.text.pleaseChooseTheNetSaleable}</Text>
            <Select
              data={this.state.netAreas}
              style={styles.field}
              value={this.state.defaultNetAreaLabel}
              onChange={(event) => this.setState({propertySize: event.target.value})}

            >
            </Select>
            {
              this.state.propertySize == 4 &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
          </View>

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.promotionCodes}</Text>
            <TextInput
              style={styles.input}
              placeholder={this.state.text.optional}
              onChangeText={(text) => this.setState({promotionCodes: text})}
            />
            <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
          </View>

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.businessEmailAddress}</Text>
            <TextInput
              style={styles.input}
              placeholder={this.state.text.optional}
              keyboardType={this.state.text.EmailAddress}
              onChangeText={(text) => this.setState({businessEmailAddress: text})}
            />
            <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
          </View>

          <TouchableOpacity style={styles.submitButton} onPress={() => this._nextScreen()}>
            <Text style={styles.submitButtonText}>{this.state.text.getAQuote}</Text>
          </TouchableOpacity>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: 'center',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  planTypes: {
    marginTop: 10,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
};
