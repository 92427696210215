import md5 from 'md5';
import CryptoJS from 'crypto-js';

import { hmacSha256, msgCodeDetail } from './../lib/utils';
import { host, busiSecKey } from './../config/api';

import moment from 'moment'
import { AsyncStorage } from 'react-native';

export default class Request {

  static get(route, data, onSuccess) {
    fetch(host + route, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
    .then((response) => response.json())
    .then((responseJson) => {
      onSuccess(responseJson);
    })
    .catch((error) => {
      if (error.message != 'Network request failed') {
        alert(error);
      }
    });
  }

  static post(route, data, onSuccess, onFail, options) {
    data = JSON.stringify(data);
    data = data.replace(/[\u007F-\uFFFF]/g, function(chr) {
      return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)
    })
    
    const key = (options && options.busiSecKey) ? options.busiSecKey :
      CryptoJS.AES.decrypt(
        busiSecKey, 'ACTj4a4589rxPEIW2PoE').toString(CryptoJS.enc.Utf8);
    const hmac = data.timeStamp ? hmacSha256(data, key) : md5(data + key);

    const fields = {
      'api': host + route,
      'data': data,
      'hmac': hmac,
      'createdt':moment().format('MMMM Do YYYY, h:mm:ss a'),
      'status':'request'
    };
    this.storelog(fields)


    fetch(((options && options.host) || host) + route, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {


      const handleDefault = () => {
        alert(msgCodeDetail(responseJson.msgCode).desc_en);
      };

      if (responseJson.msgCode === 0) {
        onSuccess(responseJson.msgDesc);
      }
      else if (onFail) {
        const fields = {
          'api': host + route,
          'data': data,
          'hmac': hmac,
          'errorresponseJson': responseJson,
          'createdt':moment().format('MMMM Do YYYY, h:mm:ss a'),
          'status':'error'
        };
        this.storelog(fields)
        onFail(responseJson.msgCode, responseJson.msgDesc, handleDefault);
      }
      else {
        const fields = {
          'api': host + route,
          'data': data,
          'hmac': hmac,
          'errorresponseJson': responseJson,
          'createdt':moment().format('MMMM Do YYYY, h:mm:ss a'),
          'status':'error'
        };
        this.storelog(fields)
        handleDefault();
      }
    })
    .catch((error) => {
      if (error.message != 'Network request failed') {
        alert(error);
      }
    });
  };

  static postsecrect(route, data, onSuccess, onFail, options) {
    //data = JSON.stringify(data);
    const key = (options && options.busiSecKey) ? options.busiSecKey :
      CryptoJS.AES.decrypt(
        busiSecKey, 'ACTj4a4589rxPEIW2PoE').toString(CryptoJS.enc.Utf8);

    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), '3adWfrmAtfpOwvFI').toString();

    const hmac = data.timeStamp ? hmacSha256(data, key) : md5(ciphertext + key);

    const fields = {
      'api': host + route,
      'data': data,
      'hmac': hmac,
      'createdt':moment().format('MMMM Do YYYY, h:mm:ss a'),
      'status':'request'
    };
    this.storelog(fields)

    fetch(host + route, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(ciphertext) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {

      const handleDefault = () => {
        alert(msgCodeDetail(responseJson.msgCode).desc_en);
      };

      if (responseJson.msgCode === 0) {
        onSuccess(responseJson.msgDesc);
      }
      else if (onFail) {
        const fields = {
          'api': host + route,
          'data': data,
          'hmac': hmac,
          'errorresponseJson': responseJson,
          'createdt':moment().format('MMMM Do YYYY, h:mm:ss a'),
          'status':'error'
        };
        this.storelog(fields)
        onFail(responseJson.msgCode, responseJson.msgDesc, handleDefault);
      }
      else {
        const fields = {
          'api': host + route,
          'data': data,
          'hmac': hmac,
          'errorresponseJson': responseJson,
          'createdt':moment().format('MMMM Do YYYY, h:mm:ss a'),
          'status':'error'
        };
        this.storelog(fields)
        handleDefault();
      }
    })
    .catch((error) => {
      if (error.message != 'Network request failed') {
        alert(error);
      }
    });
  };


  static storelog(fields){

    AsyncStorage.getItem('log', (err, result) => {
      var logarray = new Array();
      if(result)
        logarray = JSON.parse(result);
      logarray.push(fields);
      AsyncStorage.setItem('log',JSON.stringify(logarray));
    });
  }

}
