export const enUS = {
  getAQuote: 'Get a Quote',
  bravoTravelProtector: 'Bravo Travel Protector',
  centalineTravelProtector: 'Centaline Travel Protector',
  bravoTravelProtectorIntroParagraph: 'Travel can give you incredible experiences and lifelong memories. When you are overseas, it is reassuring to know that you are always protected. Bravo Travel Protector protects you and your loved ones throughout the entire journey.',

  manualUnderwritingIsNeeded: 'Manual underwriting is needed and we will get back to you within two business days.',

  bravoTravelProtectorProductEdge1: 'Cover Loss of Mobile Phone',
  bravoTravelProtectorProductEdge2: 'Medical Expenses is as high as HK$1,500,000 (including the Illness of COVID-19) \nFor details on COVID-19 coverage, please refer to <a href="http://sitecontent-dev.s3-website.ap-east-1.amazonaws.com/bravo-uat/Bravo%20Travel%20Protector%20%28FAQ%29.pdf" target="_blank" rel="noopener noreferrer">FAQ</a>',
  bravoTravelProtectorProductEdge3: 'Extend the Coverage of Personal Accident as “Scale 2” without additional premium',
  bravoTravelProtectorProductEdge4: 'Enhance benefit of “Travel Delay”',
  bravoTravelProtectorProductEdge5: 'Cover leisure sports including skiing, scuba diving, bungee jumping, etc.',

  forDetailsOfTheProduct:'For details of the product, exclusions and premium rates, please refer to Product Leaflets and Policy Provisions ',
  policyholderIsACompany: 'Policyholder is a company',
  planType: 'Plan Type',
  premier: 'Premier',
  classic: 'Classic',
  standard: 'Standard',

  shortTermTripAnnual: 'Short Term Trip / Annual',
  shortTermTrip: 'Short Term Trip',
  annual: 'Annual',
  cancel: 'cancel',

  individualOrWithOthers: 'Individual or with Others',
  individual:'Individual',
  family: 'Family',
  individualNChildren: 'Individual & Children',
  groupOfIndividuals: 'Group of Individuals',

  addtionalUpgradePersonalAccidentBenefit: 'Additional Upgrade Personal Accident Benefit',
  yes:'Yes',
  no:'No',
  addtionalCruiseBenefit: 'Additional Cruise Benefit',

  periodOfTravel: 'Period of Travel',

  promotionCodes: 'Promotion Codes',
  optional: 'Optional',
  businessEmailAddress: 'Business Email / Membership',
  businessEmailMembership: 'Business Email\n/ Membership',

  iWouldLikeToBuy: 'I would like to buy',
  total: 'Total',
  levyIsNotIncluded: 'Levy is not included',
  buyNow: 'Buy Now',

  theInsuranceIsIssuedByContent:'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',
  theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
  professionalInsurance: '.',
  productType: 'Product Type',

  policyHolder: 'Policyholder',
  name: 'Name',
  title: 'Title',
  mr: 'Mr.',
  ms: 'Ms.',
  mrs: 'Mrs.',
  miss: 'Miss',
  firstName: 'First name',
  lastName: 'Last name',
  dateOfBirth: 'Date of Birth',
  pleasePickADate: 'Please pick a date',
  mobileNumber: 'Mobile Number',
  correspondenceAddress: 'Correspondence Address',
  emailAddress: 'Email Address',
  hkidOrPassportNumber: 'HKID / Passport Number',
  hkid: 'HKID',
  passport: 'Passport',
  pleaseEnterAllCharacters: 'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
  policyholderIs: 'Policyholder is ',

  first: '1st ',
  insured: 'Insured',
  relationshipWithThePolicyholder: 'Relationship with the Policyholder',
  spouse: 'Spouse',
  child: 'Child',
  children: 'Children',
  parents: 'Parents',
  relatives: 'Relatives',
  friends: 'Friends',
  others:'Others',
  haveThisTripInvolvedAnyCompetition: 'Have this trip involved any entertainment performance, reporters‘ duties, tour guide/ tour escort‘s duties, stunt work, construction site or construction site related works?',
  manualUnderwritingIsRequired: '* Manual underwriting is required *',
  specialRequests: 'Special Requests',
  referral: 'Referral Code',
  thisFieldCannotBeBlank: 'This field cannot be blank',
  isBuyagain: 'Over 79 years old',
  invalidPromotionCode: 'Invalid promotion code',
  addInsuranceAdvisor: 'Add Insurance Advisor',
  wouldYouLike: 'Would you like ',
  toBeYourInsuranceAdvisor: ' to be your insurance advisor?',

  iWeDoNotConsentPDto:'I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentPDToCompanyMarketing:'I/ We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
brokerDeclaration: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay ',
brokerDeclarationII:' commission and provide my/our personal data to the said broker/agent for arranging and managing the said policy.',
  numberOfChinaMedicalGuaranteeCards: 'Number of China Medical Guarantee Cards',
  daysdays: 'days',
  thisPromotionCodeIsNotValid: 'Please check your inputs',
  getInsured: 'Get Insured',
  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  pleaseChoose: 'Please choose',
  effectiveDate: 'Effective Date',
  periodOfInsurance: 'Period of Insurance',
  bothDatesInclusive: '(both dates inclusive)',
  destination: 'Destination',
  theTraveler: ' the traveler',
  travelOneOfTheTravelers: ' one of the travelers',
  numberOfChildren: 'Number of Children',
  numberOfIndividuals: 'Number of Individuals',
  chinaMedicalGuaranteeCard: 'China Medical Guarantee Card',
  additionalChinaMedicalGuaranteeCard: 'Additional China Medical Guarantee Card',
  nullnull: 'null',
  sorryThatYourApplication: 'Sorry that your application is declined.',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  policyholderAndInsuredsMust: 'Policyholder and insureds must have valid HKID card and are resident in Hong Kong.',
  theCoveredTripShouldOriginate: 'The covered trip should originate in Hong Kong and not cover within the territory of Hong Kong.',
  noRefundOfPremium: 'No refund of premium is allowed once the policy has been issued.',
  coverWillAutomatically: 'Cover will automatically be extended up to a maximum of 10 calendar days in the event the covered trip is being unavoidably delayed.',
  thisInsuranceIsOnlyValid: 'This insurance is only valid for the purpose of leisure travel or business travel (limited to administrative duty) only and not applicable to tour guide/escort, etc.',
  allPRCCitizensWhoReside: 'All PRC citizens who reside and work in Mainland China will not be covered within the territory of Mainland China.',
  obtainingMedicalTreatment: 'Obtaining medical treatment is not a purpose of this trip.',
  thisInsuranceIsNotApplicable : 'This insurance is not applicable to artist(s) or any entertainment related activities.',
  theInsuredsShallBeInGood: 'The insured(s) shall be in good health and agree that any pre-existing conditions will not be covered under this insurance.',
  allCoveragesAreSubject: 'All coverages are subject to the terms and conditions of the Policy.',
  onlyStandardPlan: 'Only Standard Plan is applicable to the insured person aged 17 or below.',
  assicurazioniGeneraliSpA: 'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  onlyStandardPlanIsApplicable: 'Only Standard Plan is applicable to the insured person aged 17 or below',
  generaliTravelPackageInsurance: 'Generali Travel Package Insurance',
  worldwide: 'Worldwide (Except <Excluded Countries/Territories> referred to in the Territorial Exclusion Clause of the Policy)',
  question: 'Question',
  IWeCertifyThat: 'I/We certify that I/we have read and agreed to the Important Notes of GENERALI Travel Package Insurance.',
  payment: 'Payment',
  youHave: 'You have ',
  eCashNow: 'Generali voucher now. Would you want to use it?',
  pleaseWait: 'Please Wait',
  confirm: 'Confirm',
  pleaseInputInEnglish: '* Please input in English *',
  needsToBeInEnglish: 'Needs to be in English',
  thankYou: 'Thank You',
  youHaveSuccessfully: 'You have successfully submitted your application of this ',
  ccccc: '.',
  manualUnderwriting: ' Manual underwriting is needed and we will get back to you within two business days.',
  inCaseOfAnyQuestions: ' In case of any questions, you can chat with us in the app in section Roar Us.',
  check: 'Please check your inputs again.',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  alert: 'Alert',
  ok: 'OK',
  submissionFailed: 'Submission Failed',
  invalidMobileNumber: 'Invalid insurance advisor mobile number.',
  tryAgain: 'Our system is temporarily not available, please try again later. ',

  age17OrBelow: 'Age 17 or below is Standard Plan only',

  congratulations: 'Congratulations',
  madeAPurchase: 'You have successfully made a purchase. ',
  weWillSend: 'We will send your policy schedule and policy provisions to you via email ',
  anyQuestions: '. If you have any questions, please contact us.\nThank you.',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  travel: 'Travel Package Insurance',
  adventure: 'Enjoy your adventure with peace of mind.',
  mainlandPermit: 'Home return permit number',
  companyName: 'Company Name',
  canOnlyChooseTheStandardPlan: 'Aged 17 or below can only choose the Standard Plan.',
  notAvailableForAgeBelow18: 'Sorry that this plan is not available for age below 18',
  travelPolicyholderIs: 'Policyholder is',
  travelHkidOrPassportNumber: 'HKID /Passport Number',

  forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
  forBetterCustomerExperiencelogin:'Log in',
  forBetterCustomerExperiencregister:'Register',
  forBetterCustomerExperienceCon:'Continue without log in',

  //special case
  paymentStopService1:'Dear Valued Clients,',
  paymentStopService2:'Please be advised that due to service maintenance, purchase attempt via BRAVO during 8 July 23:00 to 9 July 23:00 will be temporarily suspended. We apologize for any inconvenience this may have caused you.',

}
