import React from 'react';
import {
  Text, Switch, Dimensions,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import TextField from '@material-ui/core/TextField';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import './myNewGalleryStyles.css'

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { options } from './options';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api22, api26, busiSecKey } from './../../../config/api';
import { memberStorage } from './../../../lib/MemberStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import PromotionCode from './../../../lib/PromotionCode';
import {
  checkTyphoonMessage, clearEmptyStrings, msgCodeDetail, removeSpaces,
  handleExpiredLogin,
  hmacSha256,
} from './../../../lib/utils';
import TextInput from './../../../components/TextField/TextField';
import Alert  from './../../../components/Alert/Alert';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Calendar  from './../../../components/CalendarPicker/CalendarPicker';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import DatePicker from './../../../components/DatePicker/DatePicker';
import ProductEdges from './../../../components/ProductEdges/ProductEdges';
import AttachmentLink from './../../../components/AttachmentLink/AttachmentLink';
import PlanTypePicker from './../../../components/PlanTypePicker/PlanTypePicker';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';

import Member from './../../../Member';

export default class TravelInsurance1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      broker: this.props.location.state ?
        this.props.location.state.broker : undefined,
      shortTermTripOnly: this.props.location.shortTermTripOnly,
      planType: 'classic',
      period: 'shortTermTrip',
      people: 'individual',
      numberOfChildren: '1',
      numberOfIndividuals: '4',
      paBenefits: 'N',
      medicalGuarantee: 'N',
      numberOfMedicalGuarantee: 1,
      cruiseBenefit: 'N',
      startDate: moment().add(1, 'days').format('MMMM D, YYYY'),
      endDate: moment().add(6, 'days').format('MMMM D, YYYY'),
      maxDate: moment().add(0 + periodMaxDays, 'days'),
      messages: {},
      text: {},
    };

    Member.onReady(() => this.setState(this.state));

    if (!this.state.broker && localStorage.getItem('promotionCodes')) {
      this.state.promotionCodes = localStorage.getItem('promotionCodes');
    }

    if (!this.state.broker && localStorage.getItem('businessEmail')) {
      this.state.businessEmailAddress = localStorage.getItem('businessEmail');
    }

    try{
      const searchParams = new URLSearchParams(this.props.location.search);

      if(searchParams){
        
      }

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }

        this.state.period = 'annual';
          const numTravellersIncludeChinaMedical =
            parseInt(searchParams.get('numTravellersIncludeChinaMedical'));
          if (numTravellersIncludeChinaMedical > 0) {
            this.state.medicalGuarantee = 'Y';
            this.state.numberOfMedicalGuarantee =
              numTravellersIncludeChinaMedical;
          }
      }

      const pn = searchParams.get('pn');
      if (pn) {

        if (pn.endsWith('1')) {
          this.state.planType = 'standard';
        }
        if (pn.endsWith('2')) {
          this.state.planType = 'classic';
        }
        if (pn.endsWith('3')) {
          this.state.planType = 'premier';
        }
        if (pn.startsWith('TRAVELS')) {
          this.state.period = 'shortTermTrip';

          const effectiveDateEnd = searchParams.get('effectiveDateEnd');
          if (effectiveDateEnd) {
            this.state.endDate = moment(effectiveDateEnd).format('MMMM D, YYYY');
          }

          this.state.cruiseBenefit = searchParams.get('includeCruise');
        }
        if (pn.startsWith('TRAVELA')) {
          this.state.period = 'annual';

          const numTravellersIncludeChinaMedical =
            parseInt(searchParams.get('numTravellersIncludeChinaMedical'));
          if (numTravellersIncludeChinaMedical > 0) {
            this.state.medicalGuarantee = 'Y';
            this.state.numberOfMedicalGuarantee =
              numTravellersIncludeChinaMedical;
          }
        }
        if (pn.endsWith('1')) {
          this.state.planType = 'standard';
        }
        if (pn.endsWith('2')) {
          this.state.planType = 'classic';
        }
        if (pn.endsWith('3')) {
          this.state.planType = 'premier';
        }
      }

      const premiumPlan = searchParams.get('premiumPlan');
      if (premiumPlan) {
        switch (premiumPlan) {
          case 'I':
            this.state.people = 'individual';
            break;
          case 'IC':
            this.state.people = 'withChildren';
            break;
          case 'F':
            this.state.people = 'family';
            break;
        }
      }

      const paBenefits = searchParams.get('isUpgradePA');
      if (paBenefits) {
        this.state.paBenefits = paBenefits;
      }

      const numTravellers = searchParams.get('numTravellers');
      if (numTravellers) {
        this.state.numberOfIndividuals = numTravellers;

        if (premiumPlan == 'I') {
          if (numTravellers > 1) {
            this.state.people = 'group';
          }
        }
        else {
          this.state.numberOfIndividuals = 1;

          if (premiumPlan == 'IC') {
            this.state.numberOfChildren = numTravellers - 1;
          }
          if (premiumPlan == 'F') {
            this.state.numberOfChildren = numTravellers - 2;
          }
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        this.state.businessEmailAddress = undefined;
        localStorage.removeItem('businessEmail');

        this.state.promotionCodes = promotionCode;
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        this.state.promotionCodes = undefined;
        localStorage.removeItem('promotionCodes');

        this.state.businessEmailAddress = businessEmail;
        localStorage.setItem('businessEmail', businessEmail);
      }

      const referral = searchParams.get('refcode');
      if (referral) {
        this.state.referral = referral;
      }

      this.state.days = moment(this.state.endDate, 'MMMM D, YYYY').add(1, 'days')
        .diff(moment(this.state.startDate, 'MMMM D, YYYY'), 'days');
    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
    localStorage.setItem('preventGoBack', 'false');
  }


  componentDidMount() {
    stateStorage.getState('gtravel1', (state) => {
      if (state && !this.state.promotionCodes && !this.state.businessEmailAddress) {
        if (state.promotionCodes) {
          delete this.state.businessEmailAddress;
        }
        if (state.businessEmailAddress) {
          delete this.state.promotionCodes;
        }
        state.maxDate = moment(state.maxDate);
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          submitButtonDisabled: member && !this.state.broker,
          member: member,
          token: token,
        }, () => {
          if (!this.state.broker) {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                checkTyphoonMessage(
                  this.state.centaline ? 'CTL' : 'EAPP',
                  'TRAVELS22',
                  this.state.language,
                  this.props.history,
                  (alert) => this.setState({alert: alert}),
                  (noticeAgree) => this.setState({noticeAgree: noticeAgree, alert: undefined})
                );

                if (insuranceAdvisors.TRAVEL) {
                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.TRAVEL.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      this.state.period == 'shortTermTrip' ?
                        'TRAVELS22' : 'TRAVELA22',
                      (promotionCodes) => {
                        this.setState({
                          submitButtonDisabled: false,
                          businessEmailAddress: undefined,
                          promotionCodes: promotionCodes ?
                            promotionCodes : this.state.promotionCodes,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              },
              (alert) => {
                this.setState({alert: alert})
              }
            );
            if (!this.state.member) {
              checkTyphoonMessage(
                this.state.centaline ? 'CTL' : 'EAPP',
                'TRAVELS22',
                this.state.language,
                this.props.history,
                (alert) => this.setState({alert: alert}),
                (noticeAgree) => this.setState({noticeAgree: noticeAgree, alert: undefined})
              );
              this.setState({showBusinessEmailAddress: true});
            }
          }
          else {
            checkTyphoonMessage(
              this.state.centaline ? 'CTL' : 'EAPP',
              'TRAVELS22',
              this.state.language,
              this.props.history,
              (alert) => this.setState({alert: alert}),
              (noticeAgree) => this.setState({noticeAgree: noticeAgree, alert: undefined})
            );
          }
        }));
      })
    );
  }

  _onDateChange(startDate, endDate) {
    this.setState({
      startDate: startDate.format('MMMM D, YYYY'),
      endDate: endDate.format('MMMM D, YYYY'),
      days: endDate.add(1, 'days')
        .diff(moment(startDate, 'MMMM D, YYYY'), 'days'),
    });
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _plannum(plancode) {
      if (plancode == 'premier')
          return 0;
      if (plancode == 'classic')
          return 1;
      if (plancode == 'standard')
          return 2;
  }


  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      businessEmailAddress: state.businessEmailAddress,
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      businessEmailAddress: {
        format: englishFormat,
      },
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let pn = 'EAPP';
      if (this.state.centaline) {
        pn = 'CTL';
      }

      let pl;
      if (this.state.period == 'shortTermTrip') {
        switch (this.state.planType) {
          case 'standard':
            pl = 'TRAVELS21';
            break;
          case 'classic':
            pl = 'TRAVELS22';
            break;
          case 'premier':
            pl = 'TRAVELS23';
            break;
        }
      }
      else {
        switch (this.state.planType) {
          case 'standard':
            pl = 'TRAVELA21';
            break;
          case 'classic':
            pl = 'TRAVELA22';
            break;
          case 'premier':
            pl = 'TRAVELA23';
            break;
        }
      }

      let people = 'I';
      if (this.state.people == 'family') {
        people = 'F';
      }
      if (this.state.people == 'withChildren') {
        people = 'IC';
      }

      let numTravellers = 1;
      switch (this.state.people) {
        case 'family':
          numTravellers += 1;
        case 'withChildren':
          numTravellers += parseInt(this.state.numberOfChildren);
          break;
        case 'group':
          numTravellers = parseInt(this.state.numberOfIndividuals);
          break;
      }

      let fields;
      if (this.state.period == 'shortTermTrip') {
        fields = {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': pn,
          'pl': pl,
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'policy.effectiveDateStart': this._apiDateFormat(this.state.startDate),
          'policy.effectiveDateEnd': this._apiDateFormat(this.state.endDate),
          'travel.premiumPlan': people,
          'travel.isUpgradePA': this.state.paBenefits == 'Y' ? 'Y' : 'N',
          'travel.includeCruise': this.state.cruiseBenefit == 'Y' ? 'Y' : 'N',
          'numTravellers': numTravellers,
        };
      }
      else {
        fields = {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': pn,
          'pl': pl,
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'travel.premiumPlan': people,
          'travel.isUpgradePA': this.state.paBenefits == 'Y' ? 'Y' : 'N',
          'numTravellers': numTravellers,
          'numTravellersIncludeChinaMedical':
            this.state.medicalGuarantee == 'Y' ?
            this.state.numberOfMedicalGuarantee : 0,
        };
      }
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api26, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0 || this.state.nextScreen) {
          let navigationOptions = {
            broker: this.state.broker,
            centaline: this.state.centaline,
            planType: this.state.planType,
            period: this.state.period,
            people: this.state.people,
            numberOfChildren: this.state.numberOfChildren,
            numberOfIndividuals: this.state.numberOfIndividuals,
            paBenefits: this.state.paBenefits,
            maxDate: this.state.maxDate.format(),
            days: this.state.days,
            shortTermTripOnly: this.state.shortTermTripOnly,
            promotionCodes: removeSpaces(this.state.promotionCodes),
            businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
            showBusinessEmailAddress: this.state.showBusinessEmailAddress,
            noticeAgree: this.state.noticeAgree,
            numTravellers: numTravellers,
            premium: responseJson.msgDesc.premium,
            referral: this.state.referral,
          };
          if (this.state.period == 'annual') {
            navigationOptions.medicalGuarantee = this.state.medicalGuarantee;
            if (this.state.medicalGuarantee == 'Y') {
              navigationOptions.numberOfMedicalGuarantee = this.state.numberOfMedicalGuarantee;
            }
          }
          if (this.state.period == 'shortTermTrip') {
            if (this.state.planType == 'premier') {
              navigationOptions.cruiseBenefit = this.state.cruiseBenefit;
            }
            navigationOptions.startDate = this.state.startDate;
            navigationOptions.endDate = this.state.endDate;
          }

          this.setState({submitButtonDisabled: false, alert: undefined}, () => {
            stateStorage.setState('gtravel1', this.state);
            stateStorage.setState('gtravel2', undefined);

            this.props.history.push({
              pathname:'/gtravel2',
              state: navigationOptions,
            });
          });
        }
        else if (responseJson.msgCode == 20) {
          this.setState({
            alert: handleExpiredLogin(this.state.language, this.props.history),
          });
        }
        else if (responseJson.msgCode == 40) {
          if (this.state.broker) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                  style: 'cancel',
                }],
              ),
            });
          }
          else {
            this.setState({alert: Alert.alert(
              this.state.text.addInsuranceAdvisor,
              this.state.text.wouldYouLike
              + responseJson.msgDesc.agent.brokerName
              + this.state.text.toBeYourInsuranceAdvisor,
              [
                {
                  text: this.state.text.ok,
                  onPress: () => {
                    if (!this.state.bindingInsuranceAdvisor) {
                      this.setState({bindingInsuranceAdvisor: true}, () => {
                        insuranceAdvisors.setInsuranceAdvisor(
                          {agentCode: responseJson.msgDesc.agent.agentCode},
                          ['TRAVEL'],
                          this.state.promotionCodes,
                          this.state.language,
                          () => this.setState({bindingInsuranceAdvisor: false},
                            () => this._nextScreen())
                        );
                      });
                    }
                  }
                },
              ],
              { cancelable: false }
            )});
          }
        }
        else if (responseJson.msgCode == 56) {
          this.setState({
            messages: {
              promotionCodes: this.state.text['invalidPromotionCode']
            },
            alert: Alert.alert(
              this.state.text.alert,
              this.state.text.check,
              [{
                text: this.state.text.ok,
                onPress: () => this.setState({alert: null}),
                style: 'cancel',
              }],
            ),
          });
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
        this.setState({submitButtonDisabled: false});
      })
      .catch((error) => {
        alert('Facing error when processing the quotation!' + error);
        this.setState({submitButtonDisabled: false});
      });
    }
  }


  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    let leaflet, provision;
    if (this.state.centaline) {
      provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Centaline%20Travel%20Protector%20Insurance%20Policy.pdf';
    }
    else {
     // leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Travel.pdf';
     // provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Generali%20Travel%20Policy%20Insurance%20Policy.pdf';
      if(this.state.language == 'enUS'){
        leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Travel_new_travel_2.pdf';
        provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Generali%20Travel%20Policy%20Insurance%20Policy.pdf';
        }
        else
        {
        leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Travel_new_travel_2_zh.pdf';
        provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Generali%20Travel%20Policy%20Insurance%20Policy_zh.pdf';
        }
    
    }

    let periodOption = [
      { key: 'shortTermTrip', label: options.period.shortTermTrip.label[this.state.language] },
    ];
    if (!this.state.shortTermTripOnly) {
      periodOption.push(
        { key: 'annual', label: options.period.annual.label[this.state.language] }
      );
    }

    let peopleOptions = [
      { key: 'individual', label: options.people.individual.label[this.state.language] },
      { key: 'family', label: options.people.family.label[this.state.language] },
    ];
    if (this.state.period == 'shortTermTrip') {
      peopleOptions.push(
        { key: 'withChildren', label: options.people.withChildren.label[this.state.language] }
      );
    }
    peopleOptions.push(
      { key: 'group', label: options.people.group.label[this.state.language] }
    );

    let numTravellers = 1;
    switch (this.state.people) {
      case 'family':
        numTravellers += 1;
      case 'withChildren':
        numTravellers += parseInt(this.state.numberOfChildren);
        break;
      case 'group':
        numTravellers = parseInt(this.state.numberOfIndividuals);
        break;
    }
    let medicalGuaranteeOptions = [];
    for (let i = 1; i <= numTravellers; i++) {
      medicalGuaranteeOptions.push({key: i, label: i.toString()});
    }

    return (
      <div className='App'>
        <Helmet>
          <title>Bravo Travel Protector | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />
        <ScrollView>



          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
              <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                  <View style={[styles.greeting,{fontWeight:'bold',}]}>
                    <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.bravoTravelProtector}</Text>
                    <p></p>
                    <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.adventure}</Text>
                    <img
                    //  style={{width: '100%', height: '90%'}}
                    //  src={'./assets/images/travel.png'}
                    >
                    </img>
                  </View>

              </Grid>
              </Hidden>

            <Grid item xs style={{marginTop:30,marginBottom:50}}>
              <View style={formStyles.form,styles.fie}>
              {this.state.alert}
               <View style={styles.fieldset}>
                 <Text style={[styles.h2, {color}]}>
                   {
                     this.state.centaline ?
                     this.state.text.centalineTravelProtector :
                     this.state.text.bravoTravelProtector
                   }
                 </Text>
               </View>

               <View style={{textAlign: 'left'}}>
                <Text>{this.state.text.bravoTravelProtectorIntroParagraph}</Text>
               </View>

               <ProductEdges
                 productEdges={[
                 (this.state.text.bravoTravelProtectorProductEdge1),
                 (this.state.text.bravoTravelProtectorProductEdge2),
                 (this.state.text.bravoTravelProtectorProductEdge3),
                 (this.state.text.bravoTravelProtectorProductEdge4),
                 (this.state.text.bravoTravelProtectorProductEdge5),
                 ]}
               />

               <View style={[styles.fieldset, styles.line,{textAlign: 'left'}]}>
               <AttachmentLink
                 leaflet={leaflet}
                 provision={provision}
                 history={this.props.history}
               />
               </View>

                <Carousel showArrows={true} showStatus={false} showThumbs={false} selectedItem={this._plannum(this.state.planType)}
                         onChange={
                             (index) => {


                                 console.log(this.state.language)
                                if (index == 0) {
                                    this.setState({ planType: 'premier' })
                                }
                                if (index == 1) {
                                    this.setState({ planType: 'classic' })
                                }
                                if (index == 2) {
                                    this.setState({ planType: 'standard' })
                                }
                            }
               }>
                   <div>
                                <img src={'./assets/images/travel_premier_'+this.state.language+'.png'} />
                   </div>
                   <div>
                                <img src={'./assets/images/travel_classic_'+this.state.language+'.png'} />
                   </div>
                   <div>
                                <img src={'./assets/images/travel_standard_' +this.state.language+'.png'} />
                   </div>
               </Carousel> 

               <View style={styles.fieldset}>
                 <Text style={styles.h4}>{this.state.text.planType}</Text>
                 <View style={styles.planTypes}>
                   <PlanTypePicker
                     selectedPlanType={this.state.planType}
                                    onSelect={
                                        (selectedPlanType) => {
                                            console.log(selectedPlanType)
                                            this.setState({ planType: selectedPlanType })
                                        }
                     }
                     planTypes={planTypes}
                   />
                 </View>
               </View>




               <View style={styles.fieldset}>
                 <Text style={styles.h4}>{this.state.text.shortTermTripAnnual}</Text>
                 <ModalSelector
                   data={periodOption}
                   initValue={options.period[this.state.period].label[this.state.language]}
                   onChange={(option) => this.setState({
                     period: option.key,
                     people: option.key == 'annual' &&
                       this.state.people == 'withChildren' ?
                       'individual' : this.state.people
                   })}
                   cancelText={this.state.text.cancel}
                 />
               </View>

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.individualOrWithOthers}</Text>
            <ModalSelector
              data={peopleOptions}
              initValue={options.people[this.state.people].label[this.state.language]}
              onChange={(option) => this.setState({people: option.key})}
              cancelText={this.state.text.cancel}
            />
          </View>
          {
            (this.state.people == 'family' || this.state.people == 'withChildren') &&
              <View style={styles.fieldset}>
                <Text style={styles.h4}>{this.state.text.numberOfChildren}</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={(text) => this.setState({numberOfChildren: text})}
                  value={this.state.numberOfChildren}
                  keyboardType='numeric'
                />
              </View>
          }

          {
            this.state.people == 'group' &&
              <View style={styles.fieldset}>
                <Text style={styles.h4}>{this.state.text.numberOfIndividuals}</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={(text) => this.setState({numberOfIndividuals: text})}
                  value={this.state.numberOfIndividuals}
                  keyboardType='numeric'
                />
                {
                  parseInt(this.state.numberOfIndividuals) > 10 &&
                    <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
                }
              </View>
          }

      {/* <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.addtionalUpgradePersonalAccidentBenefit}
              choice={this.state.paBenefits}
              onChange={(choice) => this.setState({paBenefits: choice})}
            />
          </View> */}

          {
            this.state.period == 'annual' &&
              <View>
                <View style={formStyles.fieldset}>
                  <YesNoQuestion
                    question={this.state.text.additionalChinaMedicalGuaranteeCard}
                    choice={this.state.medicalGuarantee}
                    onChange={(choice) => this.setState({medicalGuarantee: choice})}
                  />
                </View>
                {
                  this.state.medicalGuarantee == 'Y' &&
                    <View style={styles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.numberOfChinaMedicalGuaranteeCards}</Text>
                      <ModalSelector
                        data={medicalGuaranteeOptions}
                        initValue={this.state.numberOfMedicalGuarantee.toString()}
                        onChange={(option) => this.setState({numberOfMedicalGuarantee: option.key})}
                        cancelText={this.state.text.cancel}
                      />
                    </View>
                }
              </View>
          }

          {
            this.state.period == 'shortTermTrip' &&
              <View>
                {
                  this.state.planType == 'premier' &&
                    <View style={formStyles.fieldset}>
                      <YesNoQuestion
                        question={this.state.text.addtionalCruiseBenefit}
                        choice={this.state.cruiseBenefit}
                        onChange={(choice) => this.setState({cruiseBenefit: choice})}
                      />
                    </View>
                }
                <View style={styles.fieldset}>
                  <Text style={styles.h4}>{this.state.text.periodOfTravel}</Text>
                  <TouchableOpacity style={formStyles.calendarPicker} onPress={() => this.setState({showCalendar: !this.state.showCalendar})}>
                    <Text style={formStyles.calendarPickerText}>
                      {
                        this.state.startDate && this.state.endDate ?
                          this.state.startDate + ' - ' +  this.state.endDate :
                          this.state.text.pleasePickADate
                      }
                    </Text>
                    {
                      this.state.days &&
                        <Text style={formStyles.calendarPickerText}>{this.state.days} {this.state.text.daysdays}</Text>
                    }
                  </TouchableOpacity>
                  {
                    this.state.showCalendar &&
                      <Calendar
                        width={Dimensions.get('window').width - 40}
                        minDate={moment()}
                        maxDate={this.state.maxDate}
                        allowRangeSelection={true}
                        minRangeDuration={1}
                        selectedDayColor={color}
                        selectedDayTextColor='white'
                        selectedStartDate={this.state.startDate ? moment(this.state.startDate, 'MMMM D, YYYY') : null}
                        selectedEndDate={this.state.endDate ? moment(this.state.endDate, 'MMMM D, YYYY') : null}
                        onDateChange={(startDate, endDate) => {
                          if (this.state.period == 'shortTermTrip') {
                            this.state.maxDate = moment(startDate, 'MMMM D, YYYY').add(181, 'days');
                          }else{
                            this.state.maxDate = moment().add(1 + periodMaxDays, 'days');
                          }
                          this._onDateChange(startDate, endDate)
                        }}
                      />
                    }
                  </View>
                </View>
            }

            {
              !this.state.businessEmailAddress &&
                <View style={styles.fieldset}>
                  <Text style={styles.h4}>{this.state.text.promotionCodes}</Text>
                  <TextInput
                    style={styles.input}
                    placeholder={this.state.text.optional}
                    placeholderTextColor='lightgrey'
                    value={this.state.promotionCodes}
                    onChangeText={(text) => this.setState({promotionCodes: text})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
                </View>
            }

            {
              !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
                <View style={styles.fieldset}>
                  <Text style={styles.h4}>{this.state.text.businessEmailAddress}</Text>
                  <TextInput
                    style={styles.input}
                    placeholder={this.state.text.optional}
                    placeholderTextColor='lightgrey'
                    keyboardType='email-address'
                    value={this.state.businessEmailAddress}
                    onChangeText={(text) => this.setState({businessEmailAddress: text})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
                </View>
            }

            {
              this.state.submitButtonDisabled ?
                <View style={formStyles.submitButtonDisabled}>
                  <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                </View> :
                <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._nextScreen()}>
                  <Text style={formStyles.submitButtonText}>{this.state.text.getAQuote}</Text>
                </TouchableOpacity>
            }


           </View>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
      </ScrollView>

      <Footer/>
    </div>
    );
  }
}

const periodMaxDays = 181;

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },

  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
      margin: 'auto',
      minWidth: 300,
      maxWidth: 600,
      minHeight: 580,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: 'white',
    },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: 'center',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  // planTypes: {
  //   marginTop: 10,
  //   alignSelf: 'center',
  //    width: '60%',
  // },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
  buton: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  line:{
    justifyContent: 'center',
    alignItems: 'center',
  },
};
