import React from "react";
import {
  Text,
  Image,
  ImageBackground,
  View,
  ScrollView,
  TouchableOpacity,
  //Alert,
} from "react-native";
import Alert from "./../../components/Alert/Alert";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import MaterialButton from "@material-ui/core/Button";
import TextInput from "./../../components/TextField/TextField";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import moment from "moment";
import md5 from "md5";

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { getEcash, hmacSha256, msgCodeDetail } from './../../lib/utils';
import { host, cms, busiSecKey } from './../../config/api';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { formStyles } from './../../config/styles';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import { ControlPointSharp } from "@material-ui/icons";

export default class PrivilegedClubList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      text: {},
      errorText: "",
      voucherCodeInput: "",
      width: 0,
      height: 0,
      alert: "",
      expiryDate: "",
      balance: "",
      //member: {
      //  id: "274",
      //},
      ecash: 0,
      latestTransactionDate: "",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentWillMount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  formatDate(date) {
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  englishDateDisplay(date) {
    let tempDate = new Date(date);
    tempDate.setFullYear(tempDate.getFullYear() + 1);
    return tempDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  chineseDateDisplay(date) {
    let currentDate = new Date(date);
    currentDate.setFullYear(currentDate.getFullYear() + 1);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString();
    let day = currentDate.getDate().toString();
    return `${year}年${month}月${day}日`;
  }

  redeemVoucher(item) {
    const data = JSON.stringify({
      "member.id": this.state.member.id,
      code: this.state.voucherCodeInput,
    });
    //const key = CryptoJS.AES.decrypt(
    //  busiSecKey,
    //  "ACTj4a4589rxPEIW2PoE"
    //).toString(CryptoJS.enc.Utf8);
    //const hmac = md5(data + key);
    fetch(cms + "/api/voucher/redeemVoucher", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      //body:
      //  "data=" +
      //  encodeURIComponent(data) +
      //  "&hmac=" +
      //  hmac,
      body: "data=" + encodeURIComponent(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.msgCode == 0) {
          let data = JSON.stringify({ "member.id": this.state.member.id });
          let key = CryptoJS.AES.decrypt(
            busiSecKey,
            "ACTj4a4589rxPEIW2PoE"
          ).toString(CryptoJS.enc.Utf8);
          let hmac = md5(data + key);
          fetch(
            cms + "/api/voucher/latestTransactionDate",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
            }
          )
            .then((response) => response.json())
            .then((responseJson) => {
              // init ecash
              //console.log(responseJson);
              this.setState({
                latestTransactionDate:
                  responseJson.msgDesc.latestTransactionDate,
              });

              getEcash(this.state.language, this.props.history, (ecash) => {
                this.setState({
                  latestTransactionDate:
                    responseJson.msgDesc.latestTransactionDate,
                  ecash: ecash,
                  loading: false,
                });
                this.setState({
                  alert: Alert.alert(
                    this.state.text.privilegedClub,
                    this.state.text.redeemSuccessfully,
                    //this.state.text.voucherBalanceIsCredited +
                    //  voucher.amount +
                    //  this.state.text
                    //    .youCanCheckYourBalanceAnytime,
                    [
                      {
                        text: this.state.text.ok,
                        onPress: () => this.setState({ alert: "" }),
                      },
                    ]
                  ),
                });
              });
            })
            .catch((error) => {
              alert(
                "Facing error when getting the last transaction date!" + error
              );
            });

          //this.setState({ balance: item.amount });
          //this.setState({ expiryDate: item.expiryDate });
        } else if (responseJson.msgCode == 64) {
          this.setState({
            alert: Alert.alert(
              this.state.text.failedToRedeem,
              this.state.text.canOnlyEnjoyOurOfferOnce,
              [
                {
                  text: this.state.text.ok,
                  onPress: () => this.setState({ alert: "" }),
                },
              ]
            ),
          });
        } else if (responseJson.msgCode == 1001) {
          this.setState({
            alert: Alert.alert(
              this.state.text.failedToRedeem,
              this.state.text.forNewJoiner,
              [
                {
                  text: this.state.text.ok,
                  onPress: () => this.setState({ alert: "" }),
                },
              ]
            ),
          });
        } else {
          this.setState({
            alert: Alert.alert(
              this.state.text.failedToRedeem,
              msgCodeDetail(responseJson.msgCode)[
                this.state.language == "enUS" ? "desc_en" : "desc_zh"
              ],
              [
                {
                  text: this.state.text.ok,
                  onPress: () => this.setState({ alert: "" }),
                },
              ]
            ),
          });
          //alert(
          //  msgCodeDetail(responseJson.msgCode)[
          //    this.state.language == "enUS" ? "desc_en" : "desc_zh"
          //  ]
          //);
        }
        return;
      })
      .catch((error) => {
        alert("Facing error when redeeming the voucher!" + error);
        return;
      });
  }

  fetchVoucherDetail() {
    if (typeof this.state.member === "undefined") {
      this.setState({
        alert: Alert.alert(
          this.state.text.pleaseLogin,
          this.state.text.notLoggedIn,
          [
            {
              text: this.state.text.pleaseLogin,
              onPress: () => this.props.history.push("Login", { from: 'PrivilegedClubList' } ),
            },
          ]
        ),
      });
      return new Promise((resolve, reject) => {
        //reject(this.state.text.pleaseLogin);
        return;
      });
    }

    if (
      typeof this.state.member != "undefined" &&
      this.state.voucherCodeInput != ""
    ) {
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({
          //"member.id": this.state.member.id,
          code: this.state.voucherCodeInput,
        });

        //let key = CryptoJS.AES.decrypt(
        //  busiSecKey,
        //  "ACTj4a4589rxPEIW2PoE"
        //).toString(CryptoJS.enc.Utf8);
        //let hmac = md5(data + key);
        this.setState({ loading: true });
        fetch(cms + "/api/voucher/voucherDetails", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          //body: "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
          body: "data=" + encodeURIComponent(data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log("responseJson===>", responseJson);
            this.setState({ loading: false });

            if (responseJson.msgCode == 0) {
              let voucher = responseJson.msgDesc;
              //console.log(voucher);
              let today = this.formatDate(new Date());

              if (
                new Date(today) > new Date(this.formatDate(voucher.expiryDate))
              ) {
                this.setState({
                  alert: Alert.alert(
                    this.state.text.failedToRedeem,
                    this.state.text.expiredVoucher,
                    [
                      {
                        text: this.state.text.ok,
                        onPress: () => this.setState({ alert: "" }),
                      },
                    ]
                  ),
                });
                //reject(this.state.text.noLongerAvailable);
                return;
              }
              //console.log(voucher)
              if (voucher.number < 1) {
                this.setState({
                  alert: Alert.alert(
                    this.state.text.failedToRedeem,
                    this.state.text.usedVoucher,
                    [
                      {
                        text: this.state.text.ok,
                        onPress: () => this.setState({ alert: "" }),
                      },
                    ]
                  ),
                });
                //reject(this.state.text.usedVoucher);
                return;
              }
              if (voucher.usedBy.indexOf(this.state.member.id.toString()) > -1) {
                this.setState({
                  alert: Alert.alert(
                    this.state.text.failedToRedeem,
                    this.state.text.canOnlyEnjoyOurOfferOnce,
                    [
                      {
                        text: this.state.text.ok,
                        onPress: () => this.setState({ alert: "" }),
                      },
                    ]
                  ),
                });
                //reject(this.state.text.usedVoucher);
                return;
              }
              //console.log("voucher====>",voucher);
              resolve(voucher);
            } else {
              if (responseJson.msgCode == 21) {
                this.setState({
                  alert: Alert.alert(
                    this.state.text.failedToRedeem,
                    this.state.text.invalidVoucher,
                    [
                      {
                        text: this.state.text.ok,
                        onPress: () => this.setState({ alert: "" }),
                      },
                    ]
                  ),
                });
              }
              //reject(this.state.text.invalidVoucher);
              return;
            }
          });
      });
    }

    return new Promise((resolve, reject) => {
      //reject("");
      return;
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    const getText = (language) => {
      let text = enUS;
      if (language == "zhHK") {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState(
        {
          language: language,
          text: getText(language),
        },
        () => {
          memberStorage.getMember((member, token) =>
            
            this.setState(
              {
                //member: {id:"274"},
                member: member,
                token: token,
                loading: !!member,
              },
              () => {
                //console.log(this.state.member)
                // init ecash
                if (!this.state.member) {
                  return;
                }

                let data = JSON.stringify({
                  "member.id": this.state.member.id,
                });
                let key = CryptoJS.AES.decrypt(
                  busiSecKey,
                  "ACTj4a4589rxPEIW2PoE"
                ).toString(CryptoJS.enc.Utf8);
                let hmac = md5(data + key);
                fetch(
                  cms +
                    "/api/voucher/latestTransactionDate",
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
                  }
                )
                  .then((response) => response.json())
                  .then((responseJson) => {
                    // init ecash
                    const voucher = responseJson.msgDesc;
                    this.setState({ loading: false });
                    if (
                      voucher
                        .latestTransactionDate ==
                      "no transaction date"
                    ) {
                      return;
                    }else{
                      this.setState({
                        latestTransactionDate:
                        voucher
                            .latestTransactionDate,
                        loading: false,
                      });
                    }
                    //console.log("logging latestTransactionDate====>", this.state.latestTransactionDate)

                    getEcash(
                      this.state.language,
                      this.props.history,
                      (ecash) => {
                        this.setState({
                          latestTransactionDate:
                            responseJson.msgDesc.latestTransactionDate,
                          ecash: ecash,
                          loading: false,
                        });
                        //console.log('loggging====>',this.state.ecash);
                        //console.log('loggging====>',this.state.latestTransactionDate);
                      }
                    );
                  })
                  .catch((error) => {
                    alert(
                      "Facing error when getting the last transaction date!" +
                        error
                    );
                  });

                // check for and deal with deep link
                const voucherCode = this.props.location.code;
                if (voucherCode && this.state.member) {
                  let data = JSON.stringify({ code: voucherCode, timeStamp: Date.now(), });
                  let key = CryptoJS.AES.decrypt(
                    busiSecKey,
                    "ACTj4a4589rxPEIW2PoE"
                  ).toString(CryptoJS.enc.Utf8);
                  let hmac = md5(data + key);
                  fetch(
                    cms + "/api/voucher/voucherDetails",
                    {
                      method: "POST",                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                      body:
                        "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
                    }
                  )
                    .then((response) => response.json())
                    .then((responseJson) => {
                      const voucher = responseJson.msgDesc;
                      if (
                        voucher
                          .latestTransactionDate ==
                        "no transaction date"
                      ) {
                        return;
                      }else{
                        this.setState({
                          latestTransactionDate:
                          voucher
                              .latestTransactionDate,
                          loading: false,
                        });
                      }
                      //console.log("logging latestTransactionDate====>", this.state.latestTransactionDate)

                      if (responseJson.msgCode == 0) {
                        if (
                          moment().isAfter(
                            moment(voucher.expiryDate, "YYYY-MM-DD")
                          )
                        ) {
                          Alert.alert(
                            this.state.text.failedToRedeem,
                            this.state.text.offerExpired,
                            [{ text: this.state.text.ok }]
                          );
                        } else if (
                          voucher.requirement == "new member" &&
                          moment(this.state.member.registerDate)
                            .add(2, "days")
                            .isBefore(moment())
                        ) {
                          Alert.alert(
                            this.state.text.failedToRedeem,
                            this.state.text.forNewRegisteredMembers,
                            [{ text: this.state.text.ok }]
                          );
                        } else if (voucher.number < 1) {
                          Alert.alert(
                            this.state.text.failedToRedeem,
                            this.state.text.noLongerAvailable,
                            [{ text: this.state.text.ok }]
                          );
                        } else {
                          let data = JSON.stringify({
                            "member.id": this.state.member.id,
                            code: voucherCode,
                          });
                          let key = CryptoJS.AES.decrypt(
                            busiSecKey,
                            "ACTj4a4589rxPEIW2PoE"
                          ).toString(CryptoJS.enc.Utf8);
                          let hmac = md5(data + key);
                          fetch(
                            cms +
                              "/api/voucher/redeemVoucher",
                            {
                              method: "POST",
                              headers: {
                                Accept: "application/json",
                                "Content-Type":
                                  "application/x-www-form-urlencoded",
                              },
                              body:
                                "data=" +
                                encodeURIComponent(data) +
                                "&hmac=" +
                                hmac,
                            }
                          )
                            .then((response) => response.json())
                            .then((responseJson) => {
                              if (responseJson.msgCode == 0) {
                                let data = JSON.stringify({
                                  "member.id": this.state.member.id,
                                  'timeStamp': Date.now(),
                                });
                                let key = CryptoJS.AES.decrypt(
                                  busiSecKey,
                                  "ACTj4a4589rxPEIW2PoE"
                                ).toString(CryptoJS.enc.Utf8);
                                let hmac = hmacSha256(data + key);
                                fetch(
                                  cms +
                                    "/api/voucher/latestTransactionDate",
                                  {
                                    method: "POST",                                    
                                    headers: {
                                      Accept: "application/json",
                                      "Content-Type":
                                        "application/x-www-form-urlencoded",
                                    },
                                    body:
                                      "data=" +
                                      encodeURIComponent(data) +
                                      "&hmac=" +
                                      hmac,
                                  }
                                )
                                  .then((response) => response.json())
                                  .then((responseJson) => {
                                    // init ecash
                                    if (
                                      responseJson.msgDesc
                                        .latestTransactionDate ==
                                      "no transaction date"
                                    ) {
                                      return;
                                    }else{
                                      this.setState({
                                        latestTransactionDate:
                                          responseJson.msgDesc
                                            .latestTransactionDate,
                                        loading: false,
                                      });
                                    }
                                    //console.log("logging latestTransactionDate====>", this.state.latestTransactionDate)
                                    getEcash(
                                      this.state.language,
                                      this.props.history,
                                      (ecash) => {
                                        this.setState({
                                          latestTransactionDate:
                                            responseJson.msgDesc
                                              .latestTransactionDate,
                                          ecash: ecash,
                                          loading: false,
                                        });
                                      }
                                    );
                                    Alert.alert(
                                      this.state.text.redeemSuccessfully,
                                      this.state.text.voucherBalanceIsCredited +
                                        voucher.amount +
                                        this.state.text
                                          .youCanCheckYourBalanceAnytime,
                                      [{ text: this.state.text.ok }]
                                    );
                                  })
                                  .catch((error) => {
                                    alert(
                                      "Facing error when getting the last transaction date!" +
                                        error
                                    );
                                  });
                              } else if (responseJson.msgCode == 64) {
                                Alert.alert(
                                  this.state.text.failedToRedeem,
                                  this.state.text.canOnlyEnjoyOurOfferOnce,
                                  [{ text: this.state.text.ok }]
                                );
                              } else {
                                alert(
                                  msgCodeDetail(responseJson.msgCode)[
                                    this.state.language == "enUS"
                                      ? "desc_en"
                                      : "desc_zh"
                                  ]
                                );
                              }
                            })
                            .catch((error) => {
                              alert(
                                "Facing error when redeeming the voucher!" +
                                  error
                              );
                            });
                        }
                      } else {
                        alert(
                          msgCodeDetail(responseJson.msgCode)[
                            this.state.language == "enUS"
                              ? "desc_en"
                              : "desc_zh"
                          ]
                        );
                      }
                    })
                    .catch((error) => {
                      alert(
                        "Facing error when getting the voucher details!" + error
                      );
                    });
                } else if (voucherCode) {
                  Alert.alert(
                    this.state.text.pleaseLogin,
                    this.state.text.pleaseLoginWithYourAccount,
                    [
                      {
                        text: this.state.text.login,
                        onPress: () => this.props.history.push("Login"),
                      },
                    ]
                  );
                }
              }
            )
          );
        }
      )
    );
  }

  _viewDetail(content) {
    this.props.history.push("/privilegedClubDetail", { content: content });
  }

  TandC = () => {
    return (
      <View
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        {this.state.member && this.state.language == "enUS" && (
          <View>
            <p style={styles.balanceStyle}>
              {/*this.state.text.balanceText + this.state.balance*/}
              {this.state.text.balanceText + this.state.ecash}
            </p>
            {this.state.latestTransactionDate == "" ? null : (
              <p style={styles.balanceStyle}>
                {this.state.text.expiryDateText +
                  this.englishDateDisplay(this.state.latestTransactionDate)}
              </p>
            )}
          </View>
        )}

        {this.state.member && this.state.language != "enUS" && (
          <View>
            <p style={styles.balanceStyle}>
              {/*this.state.text.balanceText + this.state.balance*/}
              {this.state.text.balanceText + this.state.ecash}
            </p>
            {this.state.latestTransactionDate == "" ? null : (
              <p style={styles.balanceStyle}>
                {this.state.text.expiryDateText +
                  this.chineseDateDisplay(this.state.latestTransactionDate)}
              </p>
            )}
          </View>
        )}
        <br />
        <Text
          style={{
            fontSize: "16px",
            color: "rgb(197, 0, 0)",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          {this.state.text.TC}
        </Text>
        <Text style={styles.termsStyle}>{this.state.text.terms1}</Text>
        <Text style={styles.termsStyle}>{this.state.text.terms2}</Text>
        <Text style={styles.termsStyle}>{this.state.text.terms3}</Text>
      </View>
    );
  };

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Privilege Club | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.privilegedClub}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{ backgroundColor: "#f2f2f2" }}>
          {this.state.alert}
          <Hidden xsDown>
            <Grid
              item
              xs
              style={{ marginBottom: 13, marginLeft: "5%", marginRight: "5%" }}
            >
              <View style={[styles.greeting, { fontWeight: "bold" }]}>
                <Text
                  style={[
                    styles.greetingText,
                    { marginTop: "10%", fontSize: 35 },
                  ]}
                >
                  {this.state.text.privilegedClub}
                </Text>
                <p></p>
                <Text style={[styles.greetingText, { fontSize: 20 }]}>
                  {this.state.text.tuned}
                </Text>
              </View>
            </Grid>
          </Hidden>

          <Grid item xs style={{ marginTop: 30, marginBottom: 50 }}>
            <View style={(styles.container, styles.fieldset)}>
              <View style={styles.jumbotron}>
                <ImageBackground
                  source={require("./Privilege-Club.png")}
                  style={styles.jumbotronImage}
                ></ImageBackground>
              </View>
              <View
                style={{
                  flex: 2,
                  background: "white",
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <View
                  style={{
                    flex: "1",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px" }}>
                    {this.state.text.voucherHeader}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 2,
                    background: "white",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: this.state.width <= 500 ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "100%",
                    flex: "1",
                  }}
                >
                  <View style={{ width: "300px" }}>
                    <TextInput
                      textAlign="center"
                      onChangeText={(text) => {
                        this.setState({ voucherCodeInput: text });
                      }}
                      value={this.state.voucherCodeInput}
                      //placeholder={this.state.text.voucherInputPlaceHolder}
                    />

                    {this.state.loading && (
                      <p style={styles.warningMessage}>
                        {this.state.text.loading}
                      </p>
                    )}
                  </View>
                  <MaterialButton
                    variant="contained"
                    style={{ marginRight: 20, background: "rgb(197, 0, 0)" }}
                    color={"secondary"}
                    onClick={() => {
                      //console.log(this.state.member.id)
                      this.fetchVoucherDetail().then((item) => {
                        //console.log(item);
                        if (typeof item != "undefined") {
                          this.redeemVoucher(item);
                        }
                        return;
                      });
                    }}
                  >
                    {this.state.text.confirm}
                  </MaterialButton>
                </View>
                {this.TandC()}
              </View>
              <View style={{ flex: 2, display: "none" }}>
                <ScrollView style={formStyles.form}>
                  <View style={styles.header} />

                  {this.state.loading ? (
                    <Text style={styles.p}>{this.state.text.loading}</Text>
                  ) : (
                    <View>
                      {this.state.ecash == 0 && (
                        <View>
                          <TouchableOpacity
                            style={styles.history}
                            onPress={() => this._viewDetail()}
                          >
                            <View style={styles.historyRead}>
                              <Image
                                style={styles.dot}
                                source={require("./unread.png")}
                              />
                            </View>
                            <View style={styles.historyMessage}>
                              <Text>{this.state.text.voucher}</Text>
                            </View>
                          </TouchableOpacity>

                          <View style={formStyles.hr} />
                        </View>
                      )}
                    </View>
                  )}
                </ScrollView>
              </View>
            </View>
          </Grid>
          <Hidden xsDown>
            <div style={{ marginRight: "5%" }} />
          </Hidden>
        </Grid>

        <Footer />
      </div>
    );
  }
}

const styles = {
  flex: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  jumbotron: {
    flex: 2,
  },
  jumbotronImage: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
    height: "auto",
  },
  jumbotronText: {
    color: "#C50000",
    marginLeft: 10,
    marginBottom: 10,
  },
  header: {
    height: 10,
  },
  history: {
    flex: 1,
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
  },
  historyRead: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  dot: {
    width: 10,
    height: 10,
  },
  historyMessage: {
    flex: 4,
  },
  fieldset: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },
  warningMessage: {
    color: "red",
    fontSize: "12px",
    margin: "0px",
  },

  termsStyle: {
    fontSize: "14px",
    color: "black",
    textAlign: "left",
    marginBottom: "10px",
  },

  balanceStyle: {
    fontSize: "14px",
    color: "black",
    textAlign: "center",
    marginBottom: "5px",
  },
};
