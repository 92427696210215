export const zhHK = {
  centalineLandlordProtector: '中原收租保',
  comprehesiveProtectionOfYourRentalPropertyContent: '每個業主都需要全面保護您的物業，以免造成家居財物損失，業主法律責任和租金損失。為了保障你的物業資產及租金收入，忠意保險提供中原收租保以減低租賃房產的風險。更提供全港獨有中原金融團隊的專業協助收樓 / 追租服務。',

  productEdges: '產品特點',
  productEdge1: '高達HK$500,000的財物損失保障',
  productEdge2: '高達HK$10,000,000的業主法律責任保障',
  productEdge3: '高達HK$200,000的租金損失保障',
  productEdge4: '自選附加樓宇保險',
  productEdge5: '全港專業協助收樓 / 追租服務，全程中原金融團隊專業人士代理',

  forDetails: '產品內容無免責條款跟最高費用，請參照產品徵費與政策條款',
  planType: '計劃類型',
  withHomeBuilding: '包括樓宇保險',
  withoutHomeBuilding: '不包括樓宇保險',

  sizeOfTheProperty: '物業面積',
  pleaseChooseTheNetSaleable: '請選擇實用面積平方呎',

  effectiveDate: '生效日期',
  pleasePickADate: '請選擇日期',
  periodOfInsurance: '保障期限',
  promotionCodes: '優惠代碼',
  businessEmailAddress: '商業電郵地址',
  optional: '非必填',
  getAQuote: '索取報價',

  iWouldLikeToBuy: '我想投保',
  levyIsNotIncluded: '不包括保費徵費及有關費用',
  buyNow: '立即購買',

  executivePersonalAccidentProtector: '中原收租保',
  theInsuranceIsIssuedByContent:'此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發。',
  theInsuranceIsIssued: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 ',
  professionalInsurance: ' 安排。',
  pleaseInputInEnglish: '* 請輸入英文 *',
  productType: '計劃類型',
  thisFieldCannotBeBlank:'必須填寫空格',

  promotionCodes: '優惠代碼',
  policyholder: '保單持有人',
  name: '姓名',
  title:'稱謂',
  firstName:'名字',
  lastName: '姓',
  dateOfBirth:'出生日期',
  hkid:'香港身份證',
  pleaseEnterAllHKID:'請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456（7），請輸入A1234567。',
  mobileNumber:'手機號碼',
  home:'居所',
  address:'地址',
  HKAdressOnly:'地址 (僅限香港地址)',
  size:'面積',
  houseOrApartment:'洋房 或 村屋',
  house:'洋房',
  apartment:'村屋',
  pleaseChoose:'請選擇',
  theHomeBuildingIsMoreThan40years:'上述居所樓齡是否超逾40年',
  yes:'是',
  no:'否',
  manualUnderwritingsIsRequired:'* 您的申請需要核保 *',
  theHomeIsUnoccupiedForLonger30Days:'居所會否連續多於三十日無人居住?',
  anyLossDamageLiabilityOrAccident:'居所曾否於過往十二個月內，發生意外而引致財物損失或損毀、或第三者法律責任？',
  anyLegalActionAgainstTenantToRent:'上述居所曾否因收取欠租或收回物業而對上述居所之租客採取法律行動',
  anyDefeatOfRentalByTenantDuring12Months:'Any defeat of rental by tentant of the insured home during the past 12 months',
  anyInsuranceCompnayDeclined:'上述居所曾否被任何保險公司拒絕投保申請，取消保單或拒絕續保，或須附加特別條款始能被接納',
  specialRequests:'特別要求',
  referral: '轉介',

  importantNotes:'重要聲明',
  thisInsuranceDoesNotCoverCstOrGD:'此保險不包括老化及自然損耗。',
  thisInsuranceIsNotMaintenanceContract:'此保險不是一份維修合約。',
  validCOverProperyGoodOrder:'有效保障的先決條件是採取合理的步驟以有效地保護單位於良好狀態 以避免意外。',
  theInsuredHomeResidentalInHKBuliding:'被保之單位只適用於位於香港的住宅樓宇, 並僅限作住宅用途。',
  theInsuredHomebrickStones:'被保之單位包括屋頂全用磚、石和混凝土所建造。',
  allstructuresOfInsuredHomeBeLegal:'被保之所有單位樓宇結構必須合符法例建造。',
  proposerMustBeHKCitizen:'申請人必須為香港公民或持有有效香港身份證之居民。',
  thisInsurISNotPublicHousingEstate:'本保險不適用於公屋及劏房。',
  tenancyAgreement:'租約–與租客之租約必須已加蓋釐印。',
  section3RentProtection:'項目3 – 租金保險: 受保人被拖欠1個月租金及於14日內必須向租客採取法律行動, 要求租戶立即付款, 並以書面通知忠意保險有限公司(香港分行)。',
  sectione4buildingMax:'項目4 –樓宇保險: 　最高賠償限額可達投保保險金額或樓宇重建所需之費用, 兩者以較低者為準。',
  allCoveragesAreSubject:'所有保障範圍均以保單條款及條文作規限。',
  assicurazioniGenerali:'忠意保險有限公司　(香港分行)　保留接受或拒絕任何申請的權利',

  iWeDoNotConsentPDto:'我/我們不同意提供個人資料被第三方作為行銷用途使用',
  iWeDoNotConsentPDToCompanyMarketing:'我/我們不同意提供個人資料給貴公司作為行銷用途',
  brokerDeclaration: '我理解、承認並同意，傳遞給我的個人資料只會在保單期作安排和管理保單之用。',

  technicalRepresentativeMobileNumber: '技術代理人電話號碼',
  bothDatesInclusive: '(包含開始日及結束日)',
  occupation: '職業',
  emailAddress: '電郵地址',
  anyLegalAction: '上述居所曾否因收取欠租或收回物業而對上述居所之租客採取法律行動',
  anyDefaultOfRental: '十二個月內有無任何房客租金欠收在被投保房屋。',
  invalidPromotionCode: '無效的優惠代碼',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成購買',
  needsToBeInEnglish: '需以英文填寫',
  congratulations: '謝謝！',
  youHaveSuccessfully: '您已成功提交',
  ccccc: '申請。',
  manualUnderwriting: '您的申請需要核保，我們會在兩個工作天內回覆您。',
  youCanAlsoFindYour: '您可以在訂單歷史區域找到您的申請資訊。',
  inCaseOfAnyQuestions: '假如有任何疑問，您可透過此 APP 的「聯絡我們」功能與我們聯絡。',
  check: '請檢查您的輸入一遍！',
  cancel: '取消',

  notValidEmail:'這不是有效的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是有效身份証號碼',

  tryAgain: '我們的系統暫時不可用，請稍後再試',
  alert: '請注意',
  ok: '確認',
  getInsured: '立即投保',
  iCertify: '中文',
  forBetterCustomerExperience: '為了獲得更好的客戶體驗，您想先登入嗎？',
  forBetterCustomerExperiencelogin:'登入',
  forBetterCustomerExperiencregister:'登記',
  forBetterCustomerExperienceCon:'不登入繼續',
  }
