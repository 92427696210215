import Request from './Request';
import { host } from './../config/api';

export default class PayAPI {
  /* 3.10 付款 */
  static pay(txId, locale) {
    return host + '/pay?txId=' + txId + (locale ? '&locale=' + locale : '');
  }

  /* 3.11 通知付款完成 */
  static paid(data, onSuccess, onFail) {
    Request.post('/pay/paid', data, onSuccess, onFail);
  }
}
