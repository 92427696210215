import React from 'react';
import { StyleSheet, Text, Switch, View } from 'react-native';

import Locale from './../../Locale';
import Member from './../../Member';
import { YesNoQuestion } from './../../Form';
import { languageStorage } from './../../lib/LanguageStorage';

export default class DirectMarketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: this.props.choice ? this.props.choice : null,
      text: {},
    };
  }
 

  componentDidMount() {
    languageStorage.getLanguage(
      (language) => this.setState({language: language}));
  }
  
  showPIC() {
    this.props.screen.push('29E');
  }

  render() {
    const {value, onChange, message} = this.props;
    const color = Member.getThemeColor();

    let provisionE =  'https://www.app-generali.com/share_files/Product_29doc/29E_dm_en.pdf';
    if(this.state.language == 'enUS')
    {
       provisionE = 'https://www.app-generali.com/share_files/Product_29doc/29E_dm_en.pdf';
      }
      else
      {
        provisionE = 'https://www.app-generali.com/share_files/Product_29doc/29E_dm_zh.pdf';
      }

    return (
      <YesNoQuestion
        label={
          <Text>
            {Locale.text.agree}

            <Text style={{color}} onPress={() =>{}}>
              <a href={provisionE} target="_blank">{Locale.text.directMarketing}</a>
            </Text>
          </Text>
        }
        value={value}
        onChange={onChange}
        message={message}
      />
    );
  }
}

const styles = StyleSheet.create({
  attachmentLink: {
    marginTop: 10,
  },
  yesNoQuestion: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  yesNoQuestionChoice: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  yesNoQuestionChoiceSwitch: {
    flex: 1,
    alignItems: 'flex-end',
  },
  yesNoQuestionChoiceText: {
    flex: 1,
    alignItems: 'flex-start',
  },
});
