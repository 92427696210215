import { AsyncStorage } from "react-native";
import CryptoJS from "crypto-js";

import { enUS } from "./enUS";
import { zhHK } from "./zhHK";

import moment from "moment";
import Alert from "./components/Alert/Alert";
import config from "./config";

export default class Locale {
  static onReady(event) {
    Locale._onReady = event;
  }

  static getLocale() {
    return Locale._locale ? Locale._locale : Locale.enUS;
  }

  static getPaymentStatus() {
    var getToday = new Date();
    var startTime = moment(getToday, "DD-MM-YYYY hh:mm:ss");
    //var startTime = moment("08-07-2022 23:00:00", "DD-MM-YYYY hh:mm:ss"); //start 1380m
    var endTime = moment("09-07-2022 23:00:00", "DD-MM-YYYY hh:mm:ss");
    var minutesDiff = endTime.diff(startTime, "minutes");
    console.log("Minutes:" + minutesDiff);
    if (minutesDiff > 1440 || minutesDiff < 1) {
      return true;
    } else {
      return false;
    }
  }

  static setLocale(locale) {
    Locale._locale = locale;
    Locale.text = Locale.getText(locale);

    const encrypt = (data) => CryptoJS.AES.encrypt(data, config.secretKey).toString();
    AsyncStorage.setItem("LOCALE", encrypt(Locale.getLocale()));
  }

  static getText(locale) {
    switch (locale) {
      case Locale.enUS:
        return enUS;
      case Locale.zhHK:
        return zhHK;
    }
  }

  static getOrdinalNumber(number) {
    if (number == 0) {
      return "";
    } else if (number == 1) {
      return Locale.text.first;
    } else if (number == 2) {
      return Locale.text.second;
    } else if (number == 3) {
      return Locale.text.third;
    } else {
      return Locale.text.ordinal + number + Locale.text.th;
    }
  }
}

Locale.enUS = "enUS";
Locale.zhHK = "zhHK";
Locale.text = {};

AsyncStorage.getItem("LOCALE", (error, result) => {
  if (result) {
    const decrypt = (data) =>
      CryptoJS.AES.decrypt(data, config.secretKey).toString(CryptoJS.enc.Utf8);
    Locale._locale = decrypt(result);
  }

  Locale.text = Locale.getText(Locale.getLocale());
  Locale._onReady && Locale._onReady();
});
