import Locale from './../../Locale';
import ProductAPI from './../../api/ProductAPI';

export default class GrossArea {
  constructor({id, descEnUs, descZhHk}) {
    this.id = id;
    this.descEnUs = descEnUs;
    this.descZhHk = descZhHk;
  }

  toString() {
    switch (Locale.getLocale()) {
      case Locale.enUS:
        return this.descEnUs;
      case Locale.zhHK:
        return this.descZhHk;
    }
  }

  static initialize() {
    return new Promise((resolve, reject) => {
      if (GrossArea.grossAreas) {
        resolve();
      }
      else {
        GrossArea.grossAreas = [];

        ProductAPI.getGrossAreasHome(
          {pn: 'EAPP'},
          (msgDesc) => {
            if (msgDesc.grossAreas) {
              msgDesc.grossAreas.forEach((grossArea) => {
                const newGrossArea = new GrossArea(grossArea);
                GrossArea.grossAreas.push(newGrossArea);
              });
            }
            resolve();
          }
        );
      }
    });
  }

  static getDesc(id) {
    let desc;
    if (GrossArea.grossAreas) {
    GrossArea.grossAreas.forEach((grossArea) => {
      if (id == grossArea.id) {
        desc = grossArea.toString();
      }
    });
  }
    return desc;
  }
}
