import React from 'react';

import Locale from './../Locale';
import {languageStorage} from './../lib/LanguageStorage';
import Member from './../Member';
import {Screen} from './../Screen';
import NetPromoterScore from './NetPromoterScore';
import MemberAPI from './../api/MemberAPI';
import ProductAPI from './../api/ProductAPI';
import PromoteAPI from './../api/PromoteAPI';
import PayAPI from './../api/PayAPI';
import AgentAPI from './../api/AgentAPI';


export default class InsuranceProductScreen extends Screen {
  constructor(props, planType) {
    super(props);
   
    this.planType = planType;

    const defaultData = {
      pn: 'EAPP',
      agent: {},
      policy: {},
      policyHolder: {},
      payment: {},
    };

    this.state = {
      agent: this.getParam('agent'),
      broker: this.getParam('broker'),
      defaultBroker: this.getParam('defaultBroker'),
      insuranceProduct: this.getParam('insuranceProduct') || defaultData,
      messages: {},
      policyholderIsInsured: 'Y',
    };

    this.buyAgain();

    let prefilling;

    try {
      const {policy, payment} = this.state.insuranceProduct;
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            Locale.setLocale(Locale.enUS);
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            Locale.setLocale(Locale.zhHK);
            languageStorage.setLanguage('zhHK');
            break;
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        delete policy.businessEmail;
        localStorage.removeItem('businessEmail');

        payment.promotionCode = promotionCode;
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        delete payment.promotionCode;
        localStorage.removeItem('promotionCodes');

        policy.businessEmail = businessEmail;
        localStorage.setItem('businessEmail', businessEmail);
      }

      const pathname = window.location.pathname;
      if (!payment.promotionCode && !policy.businessEmail && pathname.slice(-1)=='1') {
        const promotionCode = localStorage.getItem('promotionCodes');
        const businessEmail = localStorage.getItem('businessEmail');
        console.log('local-promotionCode'+promotionCode)
        console.log('local-businessEmail'+businessEmail)
        if (promotionCode && !this.state.broker) {
          payment.promotionCode = promotionCode;
        }
        else if (businessEmail) {
          policy.businessEmail = businessEmail;
        }
      }

      if(this.state.broker){
        this.updatePolicy({businessEmail: "broker"});
      }

    

      const outpatient = searchParams.get('outpatient');
      if (outpatient) {
        localStorage.setItem('outpatient', outpatient);
      }

      

      const referralCode = searchParams.get('refcode');
      if (referralCode) {
        policy.referralCode = referralCode;
      }

      prefilling = !promotionCode && !businessEmail && !referralCode;
    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
    finally {
      const state = localStorage.getItem('state');

      if (prefilling && state) {
        const insuranceProduct = JSON.parse(state);

        if (insuranceProduct[planType]) {
          this.state.insuranceProduct = insuranceProduct[planType];
        }
      }
    }
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
    }
  }

  saveState() {
    const savedState = {};
    savedState[this.planType] = this.state.insuranceProduct;
    localStorage.setItem('state', JSON.stringify(savedState));
  }

  componentWillUnmount() {
    this.saveState();
  }

  push(routeName, params, action) {
    if (!params) {
      params = {};
    }

    params.agent = this.state.agent;
    params.broker = this.state.broker;
    params.defaultBroker = this.state.defaultBroker;
    params.insuranceProduct = this.state.insuranceProduct;

    if(this.state.broker){
      this.updatePolicy({businessEmail: null});
    }

    this.saveState();

    super.push(routeName, params, action);
  }

  refresh() {
    this.setState(this.state);
  }

  updatePartnerCode(pn) {
    this.state.insuranceProduct.pn = pn;
    this.refresh();
  }

  updatePlanCode(pl) {
    this.state.insuranceProduct.pl = pl;
    this.refresh();
  }

  updateInsuranceProduct(prop, data) {
    Object.assign(this.state.insuranceProduct[prop], data);
    this.refresh();
  }

  updateAgent(data) {
    this.updateInsuranceProduct('agent', data);
  }

  updatePolicy(data) {
    console.log('updatePolicy:'+JSON.stringify(data))
    this.updateInsuranceProduct('policy', data);
  }

  updatePolicyHolder(data) {
    this.updateInsuranceProduct('policyHolder', data);
  }

  updatePayment(data) {
    if (!Member.getMember()) {
      const {promotionCode} = data;

      if (promotionCode) {
        const {pn, pl} = this.state.insuranceProduct;

        PromoteAPI.checkPromotionCode(
          {pn, pl, promotionCode},
          (agent) => {
            this.setState({agent});
          },
          (msgCode, msgDesc, handleDefault) => {
            if (msgCode == 56) {
              this.setState({agent: null});
            }
            else {
              handleDefault();
            }
          }
        );
      }else{
        setTimeout(()=>{
          this.setState({agent: null});
        },200)
      }
    }

    this.updateInsuranceProduct('payment', data);
  }

  readAdvisor() {
    return new Promise((resolve, reject) => {
      this.setState({waiting: true});
      if (Member.getMember()) {
        MemberAPI.checkAdvisor(
          {
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'planType': this.planType,
          },
          ({plans}) => {
            if (plans[this.planType]) {
              this.setState(
                {agent: plans[this.planType].agent,
                  waiting: false},
                resolve
              );
            }
            else {
              resolve();
            }
          },
          (msgCode, msgDesc, handleDefault) => {
            if (msgCode == 12) {
              this.handleExpiredLogin();
            }
            else {
              handleDefault();
            }
          }
        );
      }
      else {
        resolve();
      }
    });
  }

  readDiscount() {
    return new Promise((resolve, reject) => {
      if (Member.getMember()) {
        MemberAPI.checkDiscount(
          {
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'planType': this.planType,
          },
          (msgDesc) => {
            if (msgDesc) {
              const {policy, payment} = this.state.insuranceProduct;
              const {promotionCode, businessEmail, membershipCode} = msgDesc;

              if (promotionCode) {
                delete policy.businessEmail;
                this.updatePayment({promotionCode});
              }
              else if (this.state.agent) {
                delete policy.businessEmail;
                this.refresh();
              }
              else {
                if (businessEmail) {
                  delete payment.promotionCode;
                  this.updatePolicy({businessEmail});
                }
                else if (membershipCode) {
                  delete payment.promotionCode;
                  this.updatePolicy({businessEmail: membershipCode});
                }
              }
            }
            resolve();
          },
          (msgCode, msgDesc, handleDefault) => {
            if (msgCode == 12) {
              this.handleExpiredLogin();
            }
            else {
              handleDefault();
            }
          }
        );
      }
      else {
        resolve();
      }
    });
  }

  readDetails() {
    const {insuranceProduct} = this.state;

    return new Promise((resolve, reject) => {
      ProductAPI.details(
        {
          'pn': insuranceProduct.pn,
          'pl': insuranceProduct.pl,
        },
        ({plan}) => {
          if (plan.notices && plan.notices.length > 0) {
            const notice = plan.notices[0];

            let content;
            switch (Locale.getLocale()) {
              case Locale.enUS:
                content = notice.contentEnUs;
                break;
              case Locale.zhHK:
                content = notice.contentZhHk;
                break;
            }

            const noticeAgree = {noticeId: notice.id, agree: 'Y'};

            this.alert(
              undefined,
              content,
              [{
                text: Locale.text.ok,
                onPress: () => insuranceProduct.noticeAgree = noticeAgree,
              }],
              {cancelable: false}
            );
            resolve();
          }
          else {
            resolve();
          }
        }
      );
    });
  }

  async initialize() {
    localStorage.removeItem('preventGoBack');

    this.setState({waiting: true}, async () => {
      if (!this.state.broker) {
        await this.readAdvisor();
        await this.readDiscount();
      }
      await this.readDetails();

      if (!Member.getMember()) {
        const {pn, pl, payment: {promotionCode}} = this.state.insuranceProduct;

        if (promotionCode) {
          PromoteAPI.checkPromotionCode(
            {pn, pl, promotionCode},
            (agent) => {
              this.setState({agent});
            },
            (msgCode, msgDesc, handleDefault) => {
              if (msgCode != 56) {
                handleDefault();
              }
            }
          );
        }
      }

      this.setState({
        waiting: false,
        initialized: true,
      });
    });
  }

  readPremium(data) {
    const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;
    this.setState({waiting: true});
    return new Promise((resolve, reject) => {
      
      premium(
        data,
        (msgDesc) => {
          this.setState({waiting: false}, () => resolve(msgDesc));
        },
        (msgCode, msgDesc, handleDefault) => {
          const messages = {
            discount: {promotionCode: Locale.text.invalidPromotionCode},
          };

          if (msgCode == 12) {
            this.handleExpiredLogin();
          }
          else if (msgCode == 40) {
            if (this.state.broker) {
              this.alert(
                Locale.text.alert,
                Locale.text.check,
                [{text: Locale.text.ok, onPress: () => this.dismissAlert()}],
              );

              this.setState({messages});
            }
            else {
              const {payment} = this.state.insuranceProduct;

              this.alert(
                Locale.text.addInsuranceAdvisor,
                Locale.text.wouldYouLike + msgDesc.agent.brokerName +
                  Locale.text.toBeYourInsuranceAdvisor,
                [{
                    text: Locale.text.ok,
                    onPress: () => {

                      this.dismissAlert();
                      this.setState({waiting: true});

                      MemberAPI.referAdvisor(
                      {
                        'member.id': Member.getMemberId(),
                        'member.token': Member.getMemberToken(),
                        'planTypes': [this.planType],
                        'agentCode': msgDesc.agent.agentCode,
                        'promotionCode': payment.promotionCode,
                      },
                      ({theme}) => {
                        
                        Member.setTheme(theme);
                        this.readAdvisor().then(() =>
                          this.readPremium(data).then((premium) =>
                            resolve(premium)
                          )
                        );
                      }
                    )
                    }
                }],
                {cancelable: false}
              );
            }
          }
          else if (msgCode == 56) {
            this.alert(
              Locale.text.alert,
              Locale.text.check,
              [{text: Locale.text.ok, onPress: () => this.dismissAlert()}],
            );

            this.setState({messages});
          }
          else {
            handleDefault();
          }
          this.setState({waiting: false});
        }
      );
    });
  }

  askCustomerToLogin() {
    return new Promise((resolve, reject) => {
      if (!Member.getMember()) {
        resolve();
        // this.alert(
        //   Locale.text.logIn,
        //   Locale.text.forBetterCustomerExperience,
        //   [
        //     {
        //       text: Locale.text.forBetterCustomerExperiencelogin,
        //       onPress: () => this.push('Login'),
        //     },
        //     {
        //       text: Locale.text.forBetterCustomerExperiencregister,
        //       onPress: () => this.push('Register'),
        //     },
        //     {
        //       text: Locale.text.forBetterCustomerExperienceCon,
        //       onPress: () => resolve(),
        //     },
        //   ],
        //   {cancelable: false},
        // );
      }
      else {
        resolve();
      }
    });
  }

  readPlanBroker() {
    return new Promise((resolve, reject) => {
      const {pn, pl, payment} = this.state.insuranceProduct;
      const details = this.state.broker ?
        AgentAPI.details : ProductAPI.details;

      details(
        {
          'pn': pn,
          'pl': pl,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'payment.promotionCode': payment.promotionCode,
        },
        ({plan}) => {
          resolve(plan.planBroker);
        }
      );
    });
  }

  buyAgain() {
    const policy = this.getParam('policy');

    if (policy) {
      const {insuranceProduct} = this.state;

      insuranceProduct.pl = policy.planKey.planCode;
      insuranceProduct.policyHolder = policy.policyHolder;

      return true;
    }
    else {
      return false;
    }
  }

  decline(message) {
    this.alert(
      Locale.text.applicationDeclined,
      message,
      [
        {text: Locale.text.reInput, onPress: () => this.dismissAlert()},
        {text: Locale.text.backHome, onPress: () => this.navigate('/')},
      ],
      {cancelable: false},
    );
  }


  apply(product, api, data) {
    const {policyHolder} = this.state.insuranceProduct;
    const {broker} = this.state;
    api(
      data,
      ({policyNo, isManualWriting, txId}) =>  {
        if (this.state.broker && !isManualWriting) {
          this.alert(
            Locale.text.thankYou,
            Locale.text.youHaveSuccessfully +
            product +
            Locale.text.ccccc,
            [{
              text: Locale.text.ok,
              onPress: () => {
                NetPromoterScore.collectFeedback(policyNo);
                this.push('/');
              },
            }],
            {cancelable: false}
          );
        }
        else if (isManualWriting) {
          this.alert(
            Locale.text.claimThankYou,
            Locale.text.youHaveSuccessfully +
            product +
            Locale.text.ccccc +
            Locale.text.manualUnderwritingIsNeeded +
            Locale.text.inCaseOfAnyQuestions,
            [{text: Locale.text.ok, onPress: () => this.push('/')}],
            {cancelable: false}
          );
        }
        else if (!txId) {
          this.alert(
            Locale.text.congratulations,
            Locale.text.madeAPurchase +
            Locale.text.weWillSend +
            policyHolder.email +
            Locale.text.anyQuestions,
            [{text: Locale.text.ok, onPress: () => this.push('/')}],
            {cancelable: false}
          );
        }
        else {
          this.push('Payment', {
            uri: PayAPI.pay(txId,
              Locale.getLocale() == Locale.zhHK ? 'zh-HK' : 'en-US'),
            email: this.state.insuranceProduct.policyHolder.email,
            recaptcha: !Member.getMember(),
            policyNumber: policyNo,
            product,
          });
        }
        this.setState({waiting: false});
      },
      (msgCode, msgDesc, handleDefault) =>  {
        if (msgCode == 12) {
          this.handleExpiredLogin();
        }
        else if (msgCode == 53) {
          this.alert(
            Locale.text.submissionFailed,
            Locale.text.invalidMobileNumber,
            [{
              text: Locale.text.ok,
              onPress: () => this.dismissAlert(),
            }]
          );
        }else if(msgCode == 180){
          this.alert(
            Locale.text.submissionFailed,
            Locale.text.tryAgain,
            [{
              text: Locale.text.ok,
              onPress: () => this.dismissAlert(),
            }]
          );
        }
        else {
          handleDefault();
        }
        this.setState({waiting: false});
      }
    );
  }
}
