import React from 'react';
export const zhHK = {
  personCollection: <a href='https://www.generali.com.hk/ZH_HK/personal-information-collection-statement' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'個人資料收集聲明'}</a>,
 legalInformation: <a href='https://www.generali.com.hk/ZH_HK/legal-information' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'法律信息'}</a>,
 Privacy: <a href='https://www.generali.com.hk/ZH_HK/privacy' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'隱私，安全和暫存紀錄'}</a>,
 email: '信箱: bravo@generali.com.hk',
 phone: '電話: +852 6682 1112',
 copy: '版權 © 2019 忠意人壽(香港)有限公司 及 忠意保險有限公司 香港分行',
 reserved: '版權所有',
 group: <a href='http://www.generali.com/' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'忠意集團成員'}</a>,
}
