import React from 'react';
import {
  Text, TextInput, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import validate from 'validate.js';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { getEcash, formatName, msgCodeDetail, removeSpaces, hmacSha256 } from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { historyStorage } from './../../../lib/HistoryStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { host, api26, api32, api310, busiSecKey } from './../../../config/api';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { stateStorage } from './../../../lib/StateStorage';
import Member from './../../../Member';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink.js';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import PaymentBreakdown from './../../../components/PaymentBreakdown/PaymentBreakdown';
import DirectMarketingTickBox from './../../../components/DirectMarketingTickBox/DirectMarketingTickBox';

export default class DomesticHelperInsurance4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state.broker,
      insuranceAdvisor: this.props.location.state.insuranceAdvisor,
      insuranceAdvisorMobile: this.props.location.state.insuranceAdvisorMobile,
      centaline: this.props.location.state.centaline,
      planType: this.props.location.state.planType,
      effectiveDateStart: this.props.location.state.effectiveDateStart,
      effectiveDateEnd: this.props.location.state.effectiveDateEnd,
      periodOfInsurance: this.props.location.state.periodOfInsurance,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      specialRequests: this.props.location.state.specialRequests,
      referral: this.props.location.state.referral,
      policyholderTitle: this.props.location.state.policyholderTitle,
      policyholderFirstName: this.props.location.state.policyholderFirstName,
      policyholderLastName: this.props.location.state.policyholderLastName,
      policyholderPhone: this.props.location.state.policyholderPhone,
      policyholderEmail: this.props.location.state.policyholderEmail,
      policyholderAddress: this.props.location.state.policyholderAddress,
      policyholderHKID: this.props.location.state.policyholderHKID,
      policyholderDOB: this.props.location.state.policyholderDOB,
      insuredTitle: this.props.location.state.insuredTitle,
      insuredFirstName: this.props.location.state.insuredFirstName,
      insuredLastName: this.props.location.state.insuredLastName,
      insuredSex: this.props.location.state.insuredSex,
      insuredDOB: this.props.location.state.insuredDOB,
      insuredIdType: this.props.location.state.insuredIdType,
      insuredIdNumber: this.props.location.state.insuredIdNumber,
      insuredCountry: this.props.location.state.insuredCountry,
      insuredDeclaration1: this.props.location.state.insuredDeclaration1,
      insuredDeclaration2: this.props.location.state.insuredDeclaration2,
      insuredDeclaration3: this.props.location.state.insuredDeclaration3,
      insuredDeclaration4: this.props.location.state.insuredDeclaration4,
      rawRequest: this.props.location.state.rawRequest,
      premium: this.props.location.state.premium,
      ecLevy: this.props.location.state.ecLevy,
      terrorCharge: this.props.location.state.terrorCharge,
      levy: this.props.location.state.levy,
      total: this.props.location.state.total,
      text: {},
      messages: {},
    };
    this.state.manualUnderwriting =
      this.state.insuredDeclaration2 =='Y'
      || this.state.insuredDeclaration3 =='Y'
      || this.state.specialRequests;

    const details = this.state.broker ?
      AgentAPI.details : ProductAPI.details;

    details(
      {
        'pn': 'EAPP',
        'pl': 'DH',
        'member.id': Member.getMemberId(),
        'member.token': Member.getMemberToken(),
        'payment.promotionCode': this.state.promotionCodes,
      },
      ({plan}) => {
        this.setState({planBroker: plan.planBroker});
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('domestic3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0)
    // init member and token
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init ecash
        getEcash(
          this.state.language,
          this.props.history,
          (ecash) => this.setState({ecash: ecash})
        );
      })
    );
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _confirm() {
    if (this.state.submitButtonDisabled) {
      return;
    }
    this.setState({submitButtonDisabled: true});

    let attributes = {
      declaration1: this.state.declaration1,
      declaration3: this.state.declaration3,
      declaration4: this.state.declaration4,
    }

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    }

    let constraints = {
      declaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
    }

    if (
      this.state.insuranceAdvisor ||
      this.state.broker ||
      this.state.promotionCodes
    ) {
      attributes.brokerDeclaration = this.state.brokerDeclaration;
      constraints.brokerDeclaration = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      };
    }

    if (
      !this.state.broker &&
      !this.state.manualUnderwriting &&
      this.state.ecash > 0
    ) {
      attributes.usingEcash = this.state.usingEcash;
      constraints.usingEcash = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
      return;
    }
    else {
      let rawRequest = this.state.rawRequest;
      rawRequest.declaration1 = this.state.declaration1;
      rawRequest.declaration3 = this.state.declaration3;
      rawRequest.declaration4 = this.state.declaration4;
      rawRequest.brokerDeclaration = this.state.brokerDeclaration;
      rawRequest.usingEcash = this.state.usingEcash;

      const fields = {
        'pn': 'EAPP',
        'pl': 'DH',
        'agent.mobile': this.state.insuranceAdvisorMobile,
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.effectiveDateEnd': this._apiDateFormat(this.state.effectiveDateEnd),
        'policy.allowPrivacyPromote3P': this.state.declaration4,
        'policy.allowPrivacyPromote': this.state.declaration4,
        'policy.businessEmail': this.state.centaline ?
          undefined : removeSpaces(this.state.businessEmailAddress),
        'policy.specialRequest': this.state.specialRequests,
        'policyHolder.salutation': this.state.policyholderTitle,
        'policyHolder.firstName': this.state.policyholderFirstName,
        'policyHolder.lastName': this.state.policyholderLastName,
        'policyHolder.mobile': this.state.policyholderPhone,
        'policyHolder.email': this.state.policyholderEmail,
        'policyHolder.address1': this.state.policyholderAddress,
        'policyHolder.address2': this.state.policyholderAddress,
        'policyHolder.preferredContact': 'em',
        'policyHolder.documentType': 'HKID',
        'policyHolder.documentNo': this.state.policyholderHKID,
        'policyHolder.dateOfBirth': this._apiDateFormat(this.state.policyholderDOB),
        'domesticHelper.address1': this.state.policyholderAddress,
        'domesticHelper.addressArea': 'HK',
        'domesticHelper.salutation': this.state.insuredTitle,
        'domesticHelper.firstName': this.state.insuredFirstName,
        'domesticHelper.lastName': this.state.insuredLastName,
        'domesticHelper.name': this.state.insuredFirstName,
        'domesticHelper.gender': this.state.insuredSex,
        'domesticHelper.dateOfBirth': this._apiDateFormat(this.state.insuredDOB),
        'domesticHelper.documentType': this.state.insuredIdType,
        'domesticHelper.documentNo': this.state.insuredIdNumber,
        'domesticHelper.originCountry': enUS[this.state.insuredCountry] ? enUS[this.state.insuredCountry] : this.state.insuredCountry,
        'domesticHelper.includeOutpatient': this.state.planType == 'with' ? 'Y' : 'N',
        'domesticHelper.isRefused': this.state.insuredDeclaration2,
        'domesticHelper.isMedAttn': this.state.insuredDeclaration3,
        'domesticHelper.isEligible': this.state.insuredDeclaration4,
        'rawRequest': encodeURIComponent(JSON.stringify(rawRequest)),
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      if (this.state.usingEcash == 'Y') {
        fields['payment.ecash'] =
          this.state.ecash > this.state.total ?
          this.state.total : this.state.ecash;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api32, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.msgCode == 0) {
          if (this.state.broker) {
            this.setState({alert:
              Alert.alert(
                this.state.text.thankYou,
                this.state.text.youHaveSuccessfully +
                this.state.text.domesticHelperInsurance +
                this.state.text.ccccc,
                [{
                  text: this.state.text.ok,
                  style: 'cancel',
                  onPress: () => this.props.history.push('/'),
                }]
              )
            });
          }
          else if (this.state.manualUnderwriting) {
            this.setState({alert:
              Alert.alert(
                this.state.text.thankYou,
                this.state.text.youHaveSuccessfully +
                this.state.text.domesticHelperInsurance +
                this.state.text.ccccc +
                this.state.text.manualUnderwriting +
                this.state.text.inCaseOfAnyQuestions,
                [{
                  text: this.state.text.ok,
                  style: 'cancel',
                  onPress: () => this.props.history.push('/'),
                }]
              )
            });
          }
          else if (
            this.state.usingEcash == 'Y' &&
            this.state.total - this.state.ecash <= 0
          ) {
            this.setState({alert:
              Alert.alert(
                this.state.text.congratulations,
                this.state.text.madeAPurchase +
                this.state.text.weWillSend +
                this.state.policyholderEmail +
                this.state.text.anyQuestions,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.props.history.push('/'),
                }]
              )
            });
          }
          else {

            stateStorage.setState('domestic4', this.state);

            this.props.history.push('Payment', {
              uri: host + api310 +
                '?txId=' + responseJson.msgDesc.txId + '&' +
                'locale=' + (this.state.language == 'zhHK' ? 'zh-HK' : 'en-US'),
              policyNumber: responseJson.msgDesc.policyNo,
              email: this.state.policyholderEmail,
              recaptcha: !this.state.member,
            });
          }
        }
        else if (this.state.broker && responseJson.msgCode == 21) {
          this.setState({alert:
          Alert.alert(this.state.text.submissionFailed,
            this.state.text.invalidMobileNumber,
            [{text: this.state.text.ok}]
          )
        });
        }else if(responseJson.msgCode==180){
          this.setState({alert: Alert.alert(this.state.text.submissionFailed,
            this.state.text.tryAgain,
            [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}]
          )});
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
        this.setState({submitButtonDisabled: false});
      })
      .catch((error) => {
        alert('Facing error when purchasing this Domestic Helper Insurance!'
          + 'Error message: ' + error);
        this.setState({submitButtonDisabled: false});
      });
    }
  }

  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Domestic Helper Insurance | Bravo Generali</title>
        </Helmet>

        <Navbar
            title={this.state.text.getInsured}
            backButton
            history={this.props.history}
          />

          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
                <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                    <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                      <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.domestic}</Text>
                        <p></p>
                        {
                          this.state.language == 'zhHK' ?
                          <View>
                          <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.beingand}</Text>
                          </View>:
                          <View>
                          <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.beingand}</Text>
                          </View>

                        }
                    </View>

                </Grid>
            </Hidden>
          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>

        <ScrollView style={formStyles.form}>
        {this.state.alert}
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h2}>
            {
              this.state.centaline ?
              this.state.text.centalineDomesticHelperProtector :
              this.state.text.domesticHelperInsurance
            }
          </Text>
        </View>

        <View>
          {
            this.state.broker &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.bold}>{this.state.text.insuranceAdvisorMobile}</Text>
                <Text>{this.state.insuranceAdvisorMobile}</Text>
              </View>
          }

          <View style={styles.font}>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.productType}</Text>
            <Text>
            {
              this.state.planType == 'with' ?
              this.state.text.withOutpatientBenefit : this.state.text.withoutOutpatientBenefit
            }
            </Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.effectiveDate}</Text>
            <Text>{this.state.effectiveDateStart} - {this.state.effectiveDateEnd}</Text>
          </View>

          {
            this.state.centaline &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.bold}>{this.state.text.periodOfInsurance}</Text>
                <Text>{this.state.text[this.state.periodOfInsurance]}</Text>
              </View>
          }

          {
            this.state.promotionCodes &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.promotionCodes}</Text>
            <Text>{this.state.promotionCodes}</Text>
          </View>
          }

          {
            this.state.businessEmailAddress &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.businessEmailAddress}</Text>
            <Text>{this.state.businessEmailAddress}</Text>
          </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.policyholder}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.name}</Text>
            <Text>
            {formatName(
              this.state.policyholderTitle,
              this.state.policyholderFirstName,
              this.state.policyholderLastName
            )}
            </Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.hkid}</Text>
            <Text>{this.state.policyholderHKID}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
            <Text>{this.state.policyholderDOB}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.mobileNumber}</Text>
          <Text>{this.state.policyholderPhone}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.emailAddress}</Text>
            <Text>{this.state.policyholderEmail}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.residentialAddress}</Text>
            <Text>{this.state.policyholderAddress}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.domesticHelper}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.name}</Text>
            <Text>
            {formatName(
              this.state.insuredTitle,
              this.state.insuredFirstName,
              this.state.insuredLastName
            )}
            </Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.hkidPassportNumber}</Text>
            <Text>{this.state.insuredIdNumber}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
            <Text>{this.state.insuredDOB}</Text>
          </View>

          {
            this.state.insuredSex &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.bold}>{this.state.text.sex}</Text>
                <Text>{this.state.insuredSex  == 'M' ? this.state.text.male : this.state.text.female }</Text>
              </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.countryOfOrigin}</Text>
            <Text>
              {
                this.state.text[this.state.insuredCountry] ?
                  this.state.text[this.state.insuredCountry] :
                  this.state.insuredCountry
              }
            </Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.thePlaceOfEmploymentIsInHK}</Text>
            <Text>{this.state.text.yes}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.theHelperHasEverBeenRufusedForEnrollment}</Text>
            <Text>{this.state.insuredDeclaration2 == 'Y' ? this.state.text.yes : this.state.text.no}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.theHelperIsReceivingOrContemplatingAnyMdeical}</Text>
            <Text>{this.state.insuredDeclaration3 == 'Y' ? this.state.text.yes : this.state.text.no}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.theHelperIsFullTimeOverseasHelper}</Text>
            <Text>{this.state.text.yes}</Text>
          </View>
          {
            this.state.specialRequests &&
           <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
            <Text>{this.state.specialRequests}</Text>
           </View>
          }
          {
            false && this.state.referral &&
           <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.referral}</Text>
            <Text>{this.state.referral}</Text>
           </View>
          }
          </View>

          <View style={formStyles.fieldset}>
            <ImportantNotes
              color='#C50000'
              backgroundColor='#F5F5F5'
              importantNotes={[
                {
                  number: 1,
                  text: this.state.text.thisInsuranceIsAvailableForFullTimeOverseas
                },
                {
                  number: 2,
                  text: this.state.text.assicurazioniGeneraliSpAReservesTheRightTo
                },

              ]}
            />
          </View>

          <View style={formStyles.fieldset, styles.fie}>
          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.iHerebyDeclareThatTheBasicBenefitPayableUnderSection2}
              choice={this.state.declaration1}
              onChange={(choice) => this.setState({declaration1: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration1}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <LegalDocumentLink
              product={this.state.text.domesticHelperInsurance}
              planBroker={this.state.planBroker}
              history={this.props.history}
              choice={this.state.declaration3}
              onChange={(choice) => this.setState({declaration3: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <DirectMarketingTickBox
              history={this.props.history}
              choice={this.state.declaration4}
              onChange={(choice) => this.setState({declaration4: choice})}

            />
            <Text style={formStyles.message}>{this.state.messages.declaration4}</Text>
          </View>

          {
            (this.state.insuranceAdvisor || this.state.broker || this.state.promotionCodes) &&
              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.brokerDeclaration}
                  choice={this.state.brokerDeclaration}
                  onChange={(choice) => this.setState({brokerDeclaration: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.brokerDeclaration}</Text>
              </View>
          }

          {
            this.state.manualUnderwriting ?
              <PaymentBreakdown manualUnderwriting /> :
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.payment}</Text>
                </View>

                {
                  !this.state.broker && this.state.ecash > 0 &&
                    <View style={formStyles.fieldset}>
                      <YesNoQuestion
                        question={this.state.text.youHave + this.state.text.eCashNow}
                        choice={this.state.usingEcash}
                        onChange={(choice) => this.setState({usingEcash: choice})}
                      />
                      <Text style={formStyles.message}>{this.state.messages.usingEcash}</Text>
                    </View>
                }

                <PaymentBreakdown
                  premium={this.state.premium}
                  ecLevy={this.state.ecLevy}
                  terrorCharge={this.state.terrorCharge}
                  levy={this.state.levy}
                  total={this.state.total}
                />
              </View>
          }

          {
            this.state.submitButtonDisabled ?
              <View style={formStyles.submitButtonDisabled}>
                <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
              </View> :
              <TouchableOpacity style={formStyles.submitButton} onPress={() => this._confirm()}>
                <Text style={formStyles.submitButtonText}>{this.state.text.submit}</Text>
              </TouchableOpacity>
          }
          </View>
        </View>
      </ScrollView>

      </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
     </Grid>

      <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
  },
  font:{
    margin: 'auto',
    minWidth: 300,
    maxWidth: 300,
    textAlign: 'left'
  },
};
