import React from 'react';
import {
  StyleSheet, AsyncStorage, Alert, View, Image, TouchableOpacity,
} from 'react-native';
// import Dialog from 'react-native-dialog';
import moment from 'moment';

import Locale from './../Locale';
import ApplyAPI from './../api/ApplyAPI';

export default class NetPromoterScore {

  static onRenderDialog(renderDialog) {
    this.renderDialog = renderDialog;
  }

  static collectFeedback(policyNo) {
    return;
    // AsyncStorage.getItem('NPS', (error, result) => {
    //   let allowToCollect = false;
    //
    //   if (result) {
    //     const nps = JSON.parse(result);
    //
    //     if (nps.lastCollectionDate) {
    //       const now = moment();
    //       const lastCollectionDate = moment(nps.lastCollectionDate);
    //
    //       if (now.diff(lastCollectionDate, 'months') > 0) {
    //         allowToCollect = true;
    //       }
    //     }
    //   }
    //   else {
    //     allowToCollect = true;
    //   }
    //
    //   if (allowToCollect) {
    //     const nps = {
    //       lastCollectionDate: moment().format(),
    //     };
    //
    //     AsyncStorage.setItem('NPS', JSON.stringify(nps));
    //
    //     const feedback = (policyNo, happiness) => {
    //       ApplyAPI.feedback(
    //         {policyNo, 'score.happiness': happiness},
    //         (msgDesc) => {
    //           Alert.alert(
    //             Locale.text.feedbackSubmitted,
    //             Locale.text.thankYouForYourFeedback,
    //             [{
    //               text: Locale.text.ok,
    //               onPress: () => this.renderDialog(null),
    //             }]
    //           );
    //         }
    //       );
    //     };
    //
    //     this.renderDialog(
    //       <Dialog.Container visible={true}>
    //         <Dialog.Title>
    //           {Locale.text.doYouLikeThisApplicationPlatform}
    //         </Dialog.Title>
    //
    //         <View style={styles.faces}>
    //           <TouchableOpacity
    //             style={styles.face}
    //             onPress={ () => feedback(policyNo, 2)}
    //           >
    //             <Image source={require('./images/smile-50.png')} />
    //           </TouchableOpacity>
    //
    //           <TouchableOpacity
    //             style={styles.face}
    //             onPress={ () => feedback(policyNo, 1)}
    //           >
    //             <Image source={require('./images/ordinary-50.png')} />
    //           </TouchableOpacity>
    //
    //           <TouchableOpacity
    //             style={styles.face}
    //             onPress={ () => feedback(policyNo, 0)}
    //           >
    //             <Image source={require('./images/cry-50.png')} />
    //           </TouchableOpacity>
    //         </View>
    //
    //         <Dialog.Button
    //           style={styles.cancelButton}
    //           label={Locale.text.skip}
    //           onPress={() => this.renderDialog(null)}
    //         />
    //       </Dialog.Container>
    //     );
    //   }
    // });
  }
}

const styles = StyleSheet.create({
  faces: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  face: {
    flex: 1,
    alignItems: 'center',
  },
  cancelButton: {
    marginTop:0,
  },
});
