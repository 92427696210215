import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Locale from './../../Locale';
import Member from './../../Member';
import Format from './../../Format';
import {Heading2, YesNoQuestion} from './../../Form';
import MemberAPI from './../../api/MemberAPI';

export default class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ecash: 0,
    };
  }

  componentDidMount() {
    if (Member.getMember()) {
      MemberAPI.checkEcash(
        {
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
        },
        ({ecash}) => {
          this.setState({ecash});
        }
      );
    }
  }

  onChange(usingEcash) {
    const {onChange} = this.props;
    const {ecash} = this.state;

    this.setState({usingEcash});

    if (usingEcash == 'Y') {
      onChange(ecash);
    }
    else {
      onChange();
    }
  }

  getDiscountPercentage(original, discount) {
    return Math.round((1000 - Math.round(discount / original * 1000)) / 10);
  }

  round(number) {
    return Math.round(number * 100) / 100;
  }

  render() {
    const {manualUnderwriting, premium} = this.props;
    const {formatNumber} = Format;

    if (!manualUnderwriting && !premium) {
      return (
        <View />
      );
    }

    if (manualUnderwriting) {
      return (
        <View style={[styles.payment, {marginTop: 20}]}>
          <View style={styles.paymentItem}>
            <View style={styles.paymentLabelCentered}>
              <Text style={styles.paymentLabelText}>
                {Locale.text.manualUnderwriting}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    const {original, discount, redeemed} = premium;
    const {groupDiscount, ecLevy, terrorCharge, levy} = this.props;
    const total = formatNumber(
      this.round((discount + (ecLevy ? ecLevy : 0) +
      (terrorCharge ? terrorCharge : 0) + levy) -
      (redeemed ? discount - redeemed : 0))
    );

    return (
      <View>
        <Heading2>{Locale.text.payment}</Heading2>

        {
          !this.props.broker && this.state.ecash > 0 &&
            <YesNoQuestion
              label={Locale.text.youHaveGeneraliVoucherNow}
              value={this.state.usingEcash}
              onChange={(usingEcash) => this.onChange(usingEcash)}
            />
        }

        {
          original > discount || redeemed ?
            <View style={styles.paymentItem}>
              <View style={styles.paymentNumber}>
                <Text style={styles.paymentNumberText}>
                  {
                    original > discount ?
                      this.getDiscountPercentage(original, discount) +
                        '% Off' + (groupDiscount ? ' *' : '') :
                      Locale.text.less + ' $' + (original - redeemed)
                  }
                </Text>
              </View>
            </View> :
            <View style={{marginTop: 20}} />
        }

        <View style={styles.payment}>
          <View style={styles.paymentItem}>
            <View style={styles.paymentLabel}>
              <Text style={styles.paymentLabelText}>
                {Locale.text.premium}
              </Text>
            </View>

            {
              (original > discount || redeemed) &&
                <View style={styles.premiumNumber}>
                  <Text style={styles.originalNumberText}>
                    ${formatNumber(this.round(original))}
                  </Text>
                </View>
            }

            <View style={styles.premiumNumber}>
              <Text style={styles.paymentNumberText}>
                ${formatNumber(this.round(
                  redeemed && !groupDiscount ? redeemed : discount))}
              </Text>
            </View>
          </View>

          {
            ecLevy &&
              <View style={styles.paymentItem}>
                <View style={styles.paymentLabel}>
                  <Text style={styles.paymentLabelText}>
                    {Locale.text.ecLevy}
                  </Text>
                </View>

                <View style={styles.paymentNumber}>
                  <Text style={styles.paymentNumberText}>
                    ${formatNumber(this.round(ecLevy))}
                  </Text>
                </View>
              </View>
          }

          {
            terrorCharge &&
              <View style={styles.paymentItem}>
                <View style={styles.paymentLabel}>
                  <Text style={styles.paymentLabelText}>
                    {Locale.text.terrorCharge}
                  </Text>
                </View>

                <View style={styles.paymentNumber}>
                  <Text style={styles.paymentNumberText}>
                    ${formatNumber(this.round(terrorCharge))}
                  </Text>
                </View>
              </View>
          }

          {
            groupDiscount && redeemed &&
              <View style={styles.paymentItem}>
                <View style={styles.paymentLabel}>
                  <Text style={styles.paymentLabelText}>
                    {Locale.text.flatDiscount}
                  </Text>
                </View>

                <View style={styles.paymentNumber}>
                  <Text style={styles.paymentNumberText}>
                    -${formatNumber(this.round(discount - redeemed))}
                  </Text>
                </View>
              </View>
          }

          <View style={styles.paymentItem}>
            <View style={styles.paymentLabel}>
              <Text style={styles.paymentLabelText}>
                {Locale.text.levy}
              </Text>
            </View>

            <View style={styles.paymentNumber}>
              <Text style={styles.paymentNumberText}>
                ${formatNumber(this.round(levy))}
              </Text>
            </View>
          </View>

          <View style={styles.paymentItem}>
            <View style={styles.paymentLabel}>
              <Text style={styles.paymentLabelText}>
                {Locale.text.total}
              </Text>
            </View>

            <View style={styles.paymentNumber}>
              <Text style={styles.paymentNumberText}>
                ${total}
              </Text>
            </View>
          </View>
        </View>

        {
          groupDiscount &&
            <View style={styles.groupDiscount}>
              <Text style={styles.paymentNumberText}>
                *{Locale.text.roundedToTheNearestPercentage}
              </Text>
            </View>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  payment: {
    flex: 1,
    minHeight: 40,
    marginBottom: 20,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#C50000',
  },
  paymentItem: {
    flexDirection: 'row',
    height: 40,
  },
  paymentLabel: {
    flex: 3,
    justifyContent: 'center',
    marginLeft: 20,
  },
  paymentLabelCentered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentLabelText: {
    fontSize: 16,
    color: '#C50000',
  },
  paymentNumber: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  paymentNumberText: {
    fontSize: 16,
    color: '#C50000',
  },
  premiumNumber: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  originalNumberText: {
    fontSize: 16,
    textDecorationLine:  'line-through',
    color: '#C50000',
  },
  levy: {
    alignItems: 'flex-end',
    marginBottom: 10,
  },
  groupDiscount: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: -3,
  },
});
