import React from 'react';
export const enUS = {
  personCollection: <a href='https://www.generali.com.hk/personal-information-collection-statement' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'Personal Information Collection Statement'}</a>,
  legalInformation: <a href='https://www.generali.com.hk/legal-information' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'Legal Information'}</a>,
  Privacy: <a href='https://www.generali.com.hk/privacy' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'Privacy, Security & Cookies'}</a>,
  email: 'Email: bravo@generali.com.hk',
  phone: 'Tel: +852 6682 1112',
  copy: 'Copyright © 2019 GENERALI LIFE (HONG KONG) LIMITED & ASSICURAZIONI GENERALI S.p.A. – HONG KONG BRANCH',
  reserved: 'All rights reserved',
  group:  <a href='http://www.generali.com/' target="_blank" style={{textDecoration: 'none',color:'#FFFFFF'}}>{'PART OF GENERALI GROUP'}</a>,

}
