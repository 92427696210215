import React from 'react';
import {
  Text, Image, Switch, Dimensions,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import validate from 'validate.js';
import CryptoJS from 'crypto-js';
import validid from 'validid';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US';
import { zhHK } from './zh-HK';
import { languageStorage } from'./../../../lib/LanguageStorage';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api42, busiSecKey } from './../../../config/api';
import { clearEmptyStrings, hmacSha256, msgCodeDetail } from './../../../lib/utils';
import { stateStorage } from './../../../lib/StateStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import DatePicker from './../../../components/DatePicker/DatePicker';
import Calendar  from './../../../components/CalendarPicker/CalendarPicker';
import NameInput from './../../../components/NameInput/NameInput';
import AmountInput from './../../../components/AmountInput/AmountInput';
import IdentityInput from './../../../components/IdentityInput/IdentityInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import DocumentAttached from './../../../components/DocumentAttached/DocumentAttached';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink';

export default class TravelInsurance extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    if (policy) {
      this.state = {
        lostItems: [{}],
        reportedPlaces: [{}],
        emergencyEssentialItems: [{}],
        documentsAttached: [],
        messages: {},
        policyNumber: policy.policyNo,
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        idType: policy.policyHolder.documentType,
        idNumber: policy.policyHolder.documentNo,
        mobileNumber: policy.policyHolder.mobile,
        emailAddress: policy.policyHolder.email,
        text: {},
      };
    }
    else {
      this.state = {
          broker: this.props.location.state.broker,
          lostItems: [{}],
          reportedPlaces: [{}],
          emergencyEssentialItems: [{}],
          documentsAttached: [],
          messages: {},
          text:{},
      };
    }
  }

  componentDidMount() {

    stateStorage.setState('travel', this.state);

    window.scrollTo(0, 0)
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value)) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };
      })
    );
  }

  _setPolicyNumber(policyNumber) {
    this.setState({policyNumber: policyNumber});
    if (policyNumber.startsWith('City U')) {
      this.setState({
        schoolPolicyNumber: true,
        policyholderName: this.state.text.CityUniversityOfHongKong ,
      });
    }
    else if (policyNumber.startsWith('UOW')) {
      this.setState({
        schoolPolicyNumber: true,
        policyholderName: this.state.text.uowCollegeHongKong ,
      });
    }
    else {
      let policyholderName = this.state.policyholderName;
      if (this.state.policyholderName == this.state.text.CityUniversityOfHongKong ||
          this.state.policyholderName == this.state.text.uowCollegeHongKong) {
        policyholderName = '';
      }
      this.setState({
        schoolPolicyNumber: false,
        policyholderName: policyholderName
      });
    }
  }

  _onHospitalStayDateChange(date, type) {
    if (type === 'END_DATE') {
      this.setState({
        hospitalStayEndDate: date.format('MMMM D, YYYY'),
      });
    }
    else {
      this.setState({
        hospitalStayStartDate: date.format('MMMM D, YYYY'),
        hospitalStayEndDate: null,
      });
    }
  }

  _onVisitDurationDateChange(date, type) {
    if (type === 'END_DATE') {
      this.setState({
        visitDurationEndDate: date.format('MMMM D, YYYY'),
      });
    }
    else {
      this.setState({
        visitDurationStartDate: date.format('MMMM D, YYYY'),
        visitDurationEndDate: null,
      });
    }
  }

  _addReportedPlace() {
    const reportedPlaces = this.state.reportedPlaces;
    reportedPlaces.push({});
    this.setState({reportedPlaces: reportedPlaces});
  }

  _onReportedPlaceChange(reportedPlace, place, contactInformation) {
    this.state.reportedPlaces[reportedPlace] = {
      place: place,
      contactInformation: contactInformation,
    };
    this.setState({reportedPlaces: this.state.reportedPlaces});
  }

  _addLostItem() {
    const lostItems = this.state.lostItems;
    lostItems.push({
      lostDate:
        this.state.lostItems[this.state.lostItems.length - 1].lostDate,
      purchaseValueCurrency:
        this.state.lostItems[this.state.lostItems.length - 1].purchaseValueCurrency,
      replacementCostCurrency:
        this.state.lostItems[this.state.lostItems.length - 1].replacementCostCurrency,
    });
    this.setState({lostItems: lostItems});
  }

  _onLostItemChange(lostItem, item, lostDate, purchaseValueCurrency,
    purchaseValue, replacementCostCurrency, replacementCost) {
    this.state.lostItems[lostItem] = {
      item: item,
      lostDate: lostDate,
      purchaseValueCurrency: purchaseValueCurrency,
      purchaseValue: purchaseValue,
      replacementCostCurrency: replacementCostCurrency,
      replacementCost: replacementCost,
    };
    this.setState({lostItems: this.state.lostItems});
  }

  _addEmergencyEssentialItem() {
    const emergencyEssentialItems = this.state.emergencyEssentialItems;
    emergencyEssentialItems.push({});
    this.setState({emergencyEssentialItems: emergencyEssentialItems});
  }

  _onEmergencyEssentialItemChange(emergencyEssentialItem, item) {
    this.state.emergencyEssentialItems[emergencyEssentialItem] = {
      item: item,
    };
    this.setState({emergencyEssentialItems: this.state.emergencyEssentialItems});
  }

  _submit() {
    if (this.state.submitting) {
      return;
    }
    else {
      this.setState({submitting: true});
    }

    const state = clearEmptyStrings(this.state);
    let attributes = {
      policyNumber: state.policyNumber,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      insuredFirstName: state.insuredFirstName,
      insuredLastName: state.insuredLastName,
      idType: state.idType,
      idNumber: state.idNumber,
      mobileNumber: state.mobileNumber,
      emailAddress: state.emailAddress,
      otherInsurance: state.otherInsurance,
      declaration1: state.declaration1,
      declaration2: state.declaration2,
      declaration3: state.declaration3,
      declaration4: state.declaration4,
      declaration5: state.declaration5,
      declaration6: state.declaration6,
    };

    const format = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish,
    };

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    };

    let constraints = {
      policyNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      insuredFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: format,
      },
      insuredLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: format,
      },
      idType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      idNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: this.state.idType == 'HKID',
        format: format,
      },
      mobileNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        numericality: {message: this.state.text.notANumber},
      },
      emailAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        email: {message: this.state.text.notValidEmail},
        format: format,
      },
      otherInsurance: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      declaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration5: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      declaration6: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
    }

    let messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitting: false,
      });
    }
    else {

      const submit = () => {
        const claimDetails = Object.assign({}, this.state);
        delete claimDetails.documentsAttached;
        delete claimDetails.messages;
        delete claimDetails.text;
        const data = JSON.stringify({
          'policy.policyNo': this.state.policyNumber,
          'policyHolder.salutation': this.state.policyholderTitle,
          'policyHolder.firstName': this.state.policyholderFirstName,
          'policyHolder.lastName': this.state.policyholderLastName,
          'policyHolder.mobile': this.state.mobileNumber,
          'policyHolder.email': this.state.emailAddress,
          'policyHolder.documentType': this.state.idType,
          'policyHolder.documentNo': this.state.idNumber,
          'claim.details': JSON.stringify(claimDetails),
          'timeStamp': Date.now(),
        });
        const hmac = hmacSha256(data);
        let formData = new FormData();
        formData.append('data', data);
        formData.append('hmac', hmac);
        if (this.state.documentsAttached.length > 0) {
          let supportDocs = [];
          let index = 1;
          this.state.documentsAttached.forEach((documentAttached) => {
            formData.append('supportDocs', {
              uri: documentAttached.uri,
              name: `image${index++}.jpg`,
              type: 'image/jpeg',
            });
          });
        }
        if (this.state.member) {
const superagent = require('superagent');
superagent
  .post(host + api42)
  .withCredentials()
  .send(formData)
  .end((err, res) => {
    if (err) {
      console.log(err);
      alert('Facing error when submitting your claim!' + err);
      this.setState({submitting: false});
  } else {
      if(res.body.msgCode == 0) {
        this.setState({alert:
          Alert.alert(
            this.state.text.thankYou,
            this.state.text.submitted +
              this.state.text.claimReferenceNumber +
              res.body.msgDesc.claimNo,
            [{
              text: this.state.text.ok,
              onPress: () => this.props.history.push('/')
            }]
          )
        });
      }
      else {
        alert(msgCodeDetail(res.body.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
      }
  }
  });
          // fetch(host + api42, {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //   },
          //   body: formData,
          // })
          // .then((response) => response.json())
          // .then((responseJson) => {
          //   if(responseJson.msgCode == 0) {
          //     this.setState({alert:
          //     Alert.alert(this.state.text.thankYou,
          //       this.state.text.submitted
          //       + this.state.text.claimReferenceNumber
          //       + responseJson.msgDesc.claimNo)
          //     });
          //     this.props.history.push('/');
          //   }
          //   else {
          //     alert(msgCodeDetail(responseJson.msgCode)
          //       [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
          //   }
          //   this.setState({submitting: false});
          // })
          // .catch((error) => {
            // alert('Facing error when submitting your claim!' + error);
            // this.setState({submitting: false});
          // });
        }
        else {

          stateStorage.getState('travel', (state) => {
            if (state) {
              this.setState(state);
            }
          });

          this.props.history.push('Captcha', {formData: formData});
          this.setState({submitting: false});
        }
      };

      if (messages) {
        this.setState(
          {
            messages: messages,
            alert:
        Alert.alert(
          this.state.text.fieldsMissing,
          this.state.text.someBlankFields,
          [
            {text: this.state.text.resumeFilling, style: 'cancel',
              onPress: () => this.setState({submitting: false})},
            {text: this.state.text.submit, onPress: () => submit()},
          ],
          { cancelable: false }
        )
      });
      }
      else {
        submit();
      }
    }
  }

  render() {
    let reportedPlaces = [];
    let reportedPlace;
    for (reportedPlace in this.state.reportedPlaces) {
      reportedPlaces.push(
        <ReportedPlace
          key={reportedPlace}
          number={parseInt(reportedPlace) + 1}
          place={this.state.reportedPlaces[reportedPlace].place}
          contactInformation=
            {this.state.reportedPlaces[reportedPlace].contactInformation}
          onChange={(place, contactInformation) =>
            this._onReportedPlaceChange(
              reportedPlace, place, contactInformation)
          }
          text={this.state.text}
        />
      );
    }

    let lostItems = [];
    let lostItem;
    for (lostItem in this.state.lostItems) {
      lostItems.push(
        <LostItem
          key={lostItem}
          number={parseInt(lostItem) + 1}
          item={this.state.lostItems[lostItem].item}
          lostDate={this.state.lostItems[lostItem].lostDate}
          purchaseValueCurrency={this.state.lostItems[lostItem].purchaseValueCurrency}
          purchaseValue={this.state.lostItems[lostItem].purchaseValue}
          replacementCostCurrency={this.state.lostItems[lostItem].replacementCostCurrency}
          replacementCost={this.state.lostItems[lostItem].replacementCost}
          onChange={(item, lostDate, purchaseValueCurrency, purchaseValue,
            replacementCostCurrency, replacementCost) =>
            this._onLostItemChange(lostItem, item, lostDate,
              purchaseValueCurrency, purchaseValue,
              replacementCostCurrency, replacementCost)
          }
          text={this.state.text}
        />
      );
    }

    let emergencyEssentialItems = [];
    let emergencyEssentialItem;
    for (emergencyEssentialItem in this.state.emergencyEssentialItems) {
      emergencyEssentialItems.push(
        <EmergencyEssentialItem
          key={emergencyEssentialItem}
          number={parseInt(emergencyEssentialItem) + 1}
          item={this.state.emergencyEssentialItems[emergencyEssentialItem].item}
          onChange={(item) => this._onEmergencyEssentialItemChange(
              emergencyEssentialItem, item)
          }
          text={this.state.text}
        />
      );
    }

    return(
    <div className='App'>
      <Navbar
        title={this.state.text.instantClaim}
        backButton
        history={this.props.history}
      />

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>Claim Submission</Text>
                <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>Easy digital claims submission at your fingertips.</Text>

            </View>

          </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
        <ScrollView style={formStyles.form,styles.fie}>
        {this.state.alert}
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h2}>{this.state.text.travelInsurance}</Text>
          </View>

          {

            this.state.broker &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                  value={this.state.insuranceAdvisorMobile}
                  maxLength={8}
                />
              </View>
          }
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>
              {this.state.schoolPolicyNumber ? this.state.text.policyholderThe : ''}{this.state.text.insuredDetails}
            </Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>
              {this.state.text.policyNumber}
            </Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this._setPolicyNumber(text)}
              value={this.state.policyNumber}
            />
            <Text style={formStyles.message}>{this.state.messages.policyNumber}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>
              {this.state.text.policyholderName} {this.state.schoolPolicyNumber ? this.state.text.employerName : ''}
            </Text>
            <NameInput
              title={this.state.policyholderTitle}
              firstName={this.state.policyholderFirstName}
              lastName={this.state.policyholderLastName}
              onChange={(title, firstName, lastName) => this.setState({
                policyholderTitle: title,
                policyholderFirstName: firstName,
                policyholderLastName: lastName,
              })}
            />
            <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
            <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.insuredName}</Text>
            <NameInput
              title={this.state.insuredTitle}
              firstName={this.state.insuredFirstName}
              lastName={this.state.insuredLastName}
              onChange={(title, firstName, lastName) => this.setState({
                insuredTitle: title,
                insuredFirstName: firstName,
                insuredLastName: lastName,
              })}
            />
            <Text style={formStyles.message}>{this.state.messages.insuredFirstName}</Text>
            <Text style={formStyles.message}>{this.state.messages.insuredLastName}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.hkidNumberPassNumber}</Text>
            <IdentityInput
              type={this.state.idType}
              number={this.state.idNumber}
              onChange={(type, number) => this.setState({
                idType: type,
                idNumber: number,
              })}
            />
            <Text style={formStyles.message}>{this.state.messages.idType}</Text>
            <Text style={formStyles.message}>{this.state.messages.idNumber}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({mobileNumber: text})}
              value={this.state.mobileNumber}
              keyboardType='numeric'
              maxLength={8}
            />
            <Text style={formStyles.message}>{this.state.messages.mobileNumber}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({emailAddress: text})}
              value={this.state.emailAddress}
              keyboardType='email-address'
            />
            <Text style={formStyles.message}>{this.state.messages.emailAddress}</Text>
          </View>
          {
            this.state.schoolPolicyNumber &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.staffStudentNumber}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({staffNumber: text})}
                    value={this.state.staffNumber}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.occupationPosition}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({occupation: text})}
                    value={this.state.occupation}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.stationedCityCountry}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({stationedCity: text})}
                    value={this.state.stationedCity}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.homeResidingCountry}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({homeCountry: text})}
                    value={this.state.homeCountry}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfSecondment}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.secondmentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({secondmentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.purposeOfTrip}</Text>
                  <ModalSelector
                    data={[
                      { key: 'business', label: this.state.text.business },
                      { key: 'others', label: this.state.text.smallOthers},
                    ]}
                    initValue={this.state.text.pleaseChoose}
                    onChange={(option) => this.setState({tripPurpose: option.key })}
                    cancelText={this.state.text.cancel}
                  />
                </View>
              </View>
          }
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.typeOfClaim}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.medicalExpenses}
                  onValueChange={(value) => this.setState({medicalExpenses: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.medicalExpense}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.loss}
                  onValueChange={(value) => this.setState({loss: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.loss}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.journeyCancellation}
                  onValueChange={(value) => this.setState({journeyCancellation: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.journeyCancellation}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.travelDelay}
                  onValueChange={(value) => this.setState({travelDelay: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.travelBaggageDelay}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.personalAccident}
                  onValueChange={(value) => this.setState({personalAccident: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.personalAccident}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.careVisit}
                  onValueChange={(value) => this.setState({careVisit: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.careVisit}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.personalLiability}
                  onValueChange={(value) => this.setState({personalLiability: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.personalLiability}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.others}
                  onValueChange={(value) => this.setState({others: value})}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.others}</Text>
              </View>
            </View>
          </View>
          {
            this.state.medicalExpenses &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.medicalExpenseReClaimDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.injuryOrSickness}</Text>
                  <ModalSelector
                    data={[
                      { key: 'injury', label: this.state.text.injury },
                      { key: 'sickness', label: this.state.text.sickness },
                    ]}
                    initValue={this.state.text.pleaseChoose}
                    onChange={(option) => this.setState({medicalType: option.key })}
                    cancelText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.medicalType}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfInjuryOrSickness}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.injuryDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({injuryDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.injuryDate}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.ifInjury}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({howInjuryHappen: text})}
                    value={this.state.howInjuryHappen}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.howInjuryHappen}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.ifSickness}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({whenSicknessAppear: text})}
                    value={this.state.whenSicknessAppear}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.whenSicknessAppear}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.natureOfInjury}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({injuryNature: text})}
                    value={this.state.injuryNature}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.injuryNature}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.claimAmount}</Text>
                  <AmountInput
                    currency={this.state.medicalClaimedAmountCurrency}
                    number={this.state.medicalClaimedAmount}
                    onChange={(currency, number) =>
                      this.setState({
                        medicalClaimedAmountCurrency: currency,
                        medicalClaimedAmount: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.medicalClaimedAmountCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.medicalClaimedAmount}</Text>
                </View>
                {
                  this.state.medicalType == 'injury' &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.accidentHappen}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({howAccidentHappen: text})}
                        value={this.state.howAccidentHappen}
                        multiline
                      />
                      <Text style={formStyles.message}>{this.state.messages.howAccidentHappen}</Text>
                    </View>
                }
                {
                  this.state.medicalType == 'sickness' &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.symptomsFirstAppear}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({whenSymptomFirstAppear: text})}
                        value={this.state.whenSymptomFirstAppear}
                        multiline
                      />
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameAndAddressOfDoctorOrHospital}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({doctorName: text})}
                    value={this.state.doctorName}
                    placeholder={this.state.text.name}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.doctorName}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({doctorAddress: text})}
                    value={this.state.doctorAddress}
                    placeholder={this.state.text.address}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.doctorAddress}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.ifItWasAHospitalPeriod}</Text>
                  <TouchableOpacity style={formStyles.calendarPicker} onPress={() =>
                    this.setState({showHospitalStayCalendar: !this.state.showHospitalStayCalendar})}
                  >
                  <Text style={formStyles.message}>{this.state.messages.showHospitalStayCalendar}</Text>
                    <Text style={formStyles.calendarPickerText}>
                      {
                        this.state.hospitalStayStartDate && this.state.hospitalStayEndDate ?
                          this.state.hospitalStayStartDate + ' - ' +  this.state.hospitalStayEndDate :
                          this.state.text.pleasePickADate
                      }
                    </Text>
                  </TouchableOpacity>
                  {
                    this.state.showHospitalStayCalendar &&
                      <Calendar
                        width={Dimensions.get('window').width - 40}
                        maxDate={moment()}
                        allowRangeSelection={true}
                        minRangeDuration={1}
                        selectedDayColor='#C50000'
                        selectedDayTextColor='white'
                        selectedStartDate={this.state.hospitalStayStartDate ?
                          moment(this.state.hospitalStayStartDate, 'MMMM D, YYYY') : null}
                        selectedEndDate={this.state.hospitalStayEndDate ?
                          moment(this.state.hospitalStayEndDate, 'MMMM D, YYYY') : null}
                        onDateChange={(date, type) => this._onHospitalStayDateChange(date, type)}
                      />
                  }
                </View>
              </View>
          }
          {
            this.state.loss &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.lossClaimDetail}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfLossDamage}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.lossDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({lossDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.lossDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfLossDamage}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({lossTime: text})}
                    value={this.state.lossTime}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.lossTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfLossDamage}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({lossPlace: text})}
                    value={this.state.lossPlace}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.lossPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.reportedPoliceStation}</Text>
                  { reportedPlaces }
                  <TouchableOpacity style={formStyles.addButton} onPress={() => this._addReportedPlace()}>
                    <Text style={formStyles.addButtonText}>{this.state.text.addAReportedPlace}</Text>
                  </TouchableOpacity>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.howTheLossDamageOccurredDiscovered}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({howLossOccurred: text})}
                    value={this.state.howLossOccurred}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.howLossOccurred}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.lostDamagedItems}</Text>
                </View>
                { lostItems }
                <TouchableOpacity style={formStyles.addButton} onPress={() => this._addLostItem()}>
                  <Text style={formStyles.addButtonText}>{this.state.text.addALostDamagedItem}</Text>
                </TouchableOpacity>
              </View>
          }
          {
            this.state.journeyCancellation &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.journeyCancellationClaimDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfOriginalSchedule}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.originalScheduleDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({originalScheduleDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.originalScheduleDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.locationOfOriginalSchedule}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({originalScheduleLocation: text})}
                    value={this.state.originalScheduleLocation}
                  />
                  <Text style={formStyles.message}>{this.state.messages.originalScheduleLocation}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfCCRJourney}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.curtailedJourneyDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({curtailedJourneyDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.curtailedJourneyDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.locationOfCCRJourney}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({curtailedJourneyLocation: text})}
                    value={this.state.curtailedJourneyLocation}
                  />
                  <Text style={formStyles.message}>{this.state.messages.curtailedJourneyLocation}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.reasonForJourneyCCR}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({journeyCancellationReason: text})}
                    value={this.state.journeyCancellationReason}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.journeyCancellationReason}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.claimAmount}</Text>
                  <AmountInput
                    currency={this.state.journeyCancellationClaimedAmountCurrency}
                    number={this.state.journeyCancellationClaimedAmount}
                    onChange={(currency, number) =>
                      this.setState({
                        journeyCancellationClaimedAmountCurrency: currency,
                        journeyCancellationClaimedAmount: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.journeyCancellationClaimedAmountCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.journeyCancellationClaimedAmount}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h4,{textAlign: 'left',}]}>{this.state.text.ifTheJourneyCurtailmentIsDueTo}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({sickPersonFullName: text})}
                    value={this.state.sickPersonFullName}
                    placeholder={this.state.text.fullNameOfTheSickPerson}
                    placeholderTextColor='lightgrey'
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.sickPersonFullName}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({sickPersonRelationship: text})}
                    value={this.state.sickPersonRelationship}
                    placeholder={this.state.text.relationshipToTheInsuredPerson}
                    placeholderTextColor='lightgrey'
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.sickPersonRelationship}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({sickPersonDiagnosis: text})}
                    value={this.state.sickPersonDiagnosis}
                    placeholder={this.state.text.diagnosis}
                    placeholderTextColor='lightgrey'
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.sickPersonDiagnosis}</Text>
                </View>
              </View>
          }
          {
            this.state.travelDelay &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.travelBaggageDelayDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.reasonForDelay}</Text>
                  <ModalSelector
                    data={[
                      { key: 'Flight Delayed', label: this.state.text.flightDelayed },
                      { key: 'Missed Connection', label: this.state.text.missedConnection },
                      { key: 'Baggage Delayed', label: this.state.text.baggageDelayed },
                      { key: 'others', label: this.state.text.smallOthers },
                    ]}
                    initValue={this.state.text.pleaseChoose}
                    onChange={(option) => this.setState({delayReason: option.key })}
                    cancelText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.delayReason}</Text>
                  {
                    this.state.delayReason == 'others' ?
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({customDelayReason: text})}
                        value={this.state.customDelayReason}
                        placeholder={this.state.text.pleaseFillInDetails}
                        placeholderTextColor='lightgrey'
                        multiline
                      /> : null
                  }
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.whichAirport}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({airport: text})}
                    value={this.state.airport}
                  />
                  <Text style={formStyles.message}>{this.state.messages.airport}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.delayedFlightNo}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({delayedFlightNumber: text})}
                    value={this.state.delayedFlightNumber}
                  />
                  <Text style={formStyles.message}>{this.state.messages.delayedFlightNumber}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateNTime}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.delayDatetime}
                    mode='datetime'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY h:mm a'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({delayDatetime: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.delayDatetime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.emergencyEssentialItemsPurchased}</Text>
                  { emergencyEssentialItems }
                  <TouchableOpacity style={formStyles.addButton} onPress={() => this._addEmergencyEssentialItem()}>
                    <Text style={formStyles.addButtonText}>{this.state.text.addAPurchasedItem}</Text>
                  </TouchableOpacity>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.datePurchased}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.datePurchased}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({datePurchased: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.datePurchased}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.pricePaid}</Text>
                  <AmountInput
                    currency={this.state.pricePaidCurrency}
                    number={this.state.pricePaid}
                    onChange={(currency, number) =>
                      this.setState({
                        pricePaidCurrency: currency,
                        pricePaid: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.pricePaidCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.pricePaid}</Text>
                </View>
              </View>
          }
          {
            this.state.personalAccident &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.personalAccidentClaimDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfAccident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.accidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({accidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentTime: text})}
                      value={this.state.accidentTime}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentPlace: text})}
                    value={this.state.accidentPlace}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.describeHowTheAccidentOccurred}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentDescription: text})}
                    value={this.state.accidentDescription}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDescription}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameAndAddressOfTheAttendingDoctor}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({doctorName: text})}
                    value={this.state.doctorName}
                    placeholder={this.state.text.name}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.doctorName}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({doctorAddress: text})}
                    value={this.state.doctorAddress}
                    placeholder={this.state.text.address}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.doctorAddress}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.permanentDisabilityDegreeNExtent}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({permanentDisability: text})}
                    value={this.state.permanentDisability}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.permanentDisability}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.causeOfDeath}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({causeOfDeath: text})}
                    value={this.state.causeOfDeath}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.causeOfDeath}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameAndContactNumberOfWitness}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({witnessName: text})}
                    value={this.state.witnessName}
                    placeholder={this.state.text.name}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.witnessName}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({witnessContactNumber: text})}
                    value={this.state.witnessContactNumber}
                    placeholder={this.state.text.contactNumber}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.witnessContactNumber}</Text>
                </View>
              </View>
          }
          {
            this.state.careVisit &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.areVisitClaimDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameOfVisitingFamilyMember}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({familyMemberName: text})}
                    value={this.state.familyMemberName}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.familyMemberName}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.relationshipToInsuredPerson}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({relationshipToInsured: text})}
                    value={this.state.relationshipToInsured}
                  />
                  <Text style={formStyles.message}>{this.state.messages.relationshipToInsured}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.durationOfVisit}</Text>
                  <TouchableOpacity style={formStyles.calendarPicker} onPress={() =>
                    this.setState({showVisitDurationCalendar: !this.state.showVisitDurationCalendar})}
                  >
                    <Text style={formStyles.calendarPickerText}>
                      {
                        this.state.visitDurationStartDate && this.state.visitDurationEndDate ?
                          this.state.visitDurationStartDate + ' - ' +  this.state.visitDurationEndDate :
                          this.state.text.pleasePickADate
                      }
                    </Text>
                  </TouchableOpacity>
                  {
                    this.state.showVisitDurationCalendar &&
                      <Calendar
                        width={Dimensions.get('window').width - 40}
                        maxDate={moment()}
                        allowRangeSelection={true}
                        minRangeDuration={1}
                        selectedDayColor='#C50000'
                        selectedDayTextColor='white'
                        selectedStartDate={this.state.visitDurationStartDate ?
                          moment(this.state.visitDurationStartDate, 'MMMM D, YYYY') : null}
                        selectedEndDate={this.state.visitDurationEndDate ?
                          moment(this.state.visitDurationEndDate, 'MMMM D, YYYY') : null}
                        onDateChange={(date, type) => this._onVisitDurationDateChange(date, type)}
                      />
                  }
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.claimAmount}</Text>
                  <AmountInput
                    currency={this.state.pricePaidCurrency}
                    number={this.state.pricePaid}
                    onChange={(currency, number) =>
                      this.setState({
                        pricePaidCurrency: currency,
                        pricePaid: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.pricePaidCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.pricePaid}</Text>
                </View>
              </View>
          }
          {
            this.state.personalLiability &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.personalLiabilityClaimDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfTheIncident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.incidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({incidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfTheIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentTime: text})}
                      value={this.state.incidentTime}
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfTheIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentPlace: text})}
                    value={this.state.incidentPlace}
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.fullNameOfTheThirdPartyClaimant}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({thirdPartyFullName: text})}
                    value={this.state.thirdPartyFullName}
                  />
                  <Text style={formStyles.message}>{this.state.messages.thirdPartyFullName}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.contactOfTheThirdPartyClaimant}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({thirdPartyContact: text})}
                    value={this.state.thirdPartyContact}
                  />
                  <Text style={formStyles.message}>{this.state.messages.thirdPartyContact}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.fullNameOfWitness}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({witnessFullName: text})}
                    value={this.state.witnessFullName}
                  />
                  <Text style={formStyles.message}>{this.state.messages.witnessFullName}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.contactOfWitness}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({witnessContact: text})}
                    value={this.state.witnessContact}
                  />
                  <Text style={formStyles.message}>{this.state.messages.witnessContact}</Text>
                </View>
              </View>
          }
          {
            this.state.others &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.othersClaimDetails}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfAccident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.accidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({accidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentTime: text})}
                      value={this.state.accidentTime}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentPlace: text})}
                    value={this.state.accidentPlace}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.descriptionOfTheAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentDescription: text})}
                    value={this.state.accidentDescription}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDescription}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.claimAmount}</Text>
                  <AmountInput
                    currency={this.state.othersClaimedAmountCurrency}
                    number={this.state.othersClaimedAmount}
                    onChange={(currency, number) =>
                      this.setState({
                        othersClaimedAmountCurrency: currency,
                        othersClaimedAmount: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.othersClaimedAmountCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.othersClaimedAmount}</Text>
                </View>
              </View>
          }
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
          </View>
          <View style={[formStyles.fieldset,{textAlign: 'left',}]}>
            <Text>{this.state.text.reservesTheRight}</Text>
          </View>
          <View>
            {
              !!this.state.policyNumber &&
                this.state.policyNumber.startsWith('TO') &&
                <DocumentAttached
                  text={this.state.text.documentShowingTheStudentIdentity}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
            }
            <DocumentAttached
              text={this.state.text.travelItinerary}
              onSelect={(selectedDocument) =>
                this.state.documentsAttached.push(selectedDocument)}
            />
          </View>
          {
            this.state.medicalExpenses &&
              <View>
                <DocumentAttached
                  text={this.state.text.hospitalMedicalBillsReceiptReport}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <Text style={{textAlign: 'left',}}>
                  {this.state.text.pleaseBeReminded}
                </Text>
              </View>
          }
          {
            this.state.loss &&
              <View>
                <DocumentAttached
                  text={this.state.text.lossDamageReportIssuedByTheRelevantAuthorities}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.photosShowingTheExtent}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.purchaseReceiptRepairQuotation}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.compensationBreakdownFromOtherInsurers}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View>
          }
          {
            this.state.journeyCancellation &&
              <View>
                <DocumentAttached
                  text={this.state.text.receiptShowingAnyPrepaidCosts}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.documentationIssuedByTravelAgent}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                {
                  !this.state.sickPersonFullName || this.state.sickPersonFullName.length > 0 &&
                    <View>
                      <DocumentAttached
                        text={this.state.text.medicalCertificateIndicatingDiagnosis}
                        onSelect={(selectedDocument) =>
                          this.state.documentsAttached.push(selectedDocument)}
                      />
                      <DocumentAttached
                        text={this.state.text.deathCertificate}
                        onSelect={(selectedDocument) =>
                          this.state.documentsAttached.push(selectedDocument)}
                      />
                      <DocumentAttached
                        text={this.state.text.proofOfRelationship}
                        onSelect={(selectedDocument) =>
                          this.state.documentsAttached.push(selectedDocument)}
                      />
                    </View>
                }
              </View>
          }
          {
            this.state.travelDelay &&
              <View>
                <DocumentAttached
                  text={this.state.text.documentationIndicatingTheReasonForDelay}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                {
                  !((!this.state.emergencyEssentialItems[0].item ||
                    this.state.emergencyEssentialItems[0].item == '') &&
                    (!this.state.pricePaid || this.state.pricePaid == '')) &&
                    <DocumentAttached
                      text={this.state.text.receiptsForEmergencyPurchase}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                }
              </View>
          }
          {
            this.state.personalAccident &&
              <View>
                <DocumentAttached
                  text={this.state.text.accidentReportIssuedByRelevantAuthorities}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.medicalReportStatingTheDateOfAttendance}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.copyOfDeathCertificate}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View>
          }
          {
            this.state.careVisit &&
              <View>
                <DocumentAttached
                  text={this.state.text.proofOfRelationship}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.receiptsOfTravelingAccommodationExpensesIncurred}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View>
          }
          {
            this.state.personalLiability &&
              <View>
                <DocumentAttached
                  text={this.state.text.copyOfIncidentReportFromRelevantParties}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.claimLetterAndInvoice}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.otherDocuments}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View>
          }
          <DocumentAttached
            text={this.state.text.others}
            onSelect={(selectedDocument) =>
              this.state.documentsAttached.push(selectedDocument)}
          />
          {
            this.state.personalLiability ?
              <View style={styles.importantNotes}>
                <ImportantNotes
                  color='#C50000'
                  backgroundColor='#F5F5F5'
                  importantNotes={
                    [
                      {
                        number: 1,
                        text: this.state.text.anyLawsuit
                      },
                      {
                        number: 2,
                        text: this.state.text.noLiabilityShould
                      },
                    ]
                  }
                />
              </View> : null
          }
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.otherInsurance}</Text>
          </View>

                 <View style={formStyles.fieldset}>
                   <YesNoQuestion
                     question={this.state.text.otherInsuranceCoverage}
                     choice={this.state.otherInsurance}
                     onChange={(choice) => this.setState({otherInsurance: choice})}
                   />
                   <Text style={formStyles.message}>{this.state.messages.otherInsurance}</Text>
                 </View>
          {
            this.state.otherInsurance =='Y' &&
              <View style={styles.indented}>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameOfInsurer}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({otherInsuranceInsurerName: text})}
                    value={this.state.otherInsuranceInsurerName}
                  />
                  <Text style={formStyles.message}>{this.state.messages.otherInsuranceInsurerName}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.typeOfCoverage}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({otherInsuranceCoverageType: text})}
                    value={this.state.otherInsuranceCoverageType}
                  />
                  <Text style={formStyles.message}>{this.state.messages.otherInsuranceCoverageType}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.policyNumber}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({otherInsurancePolicyNumber: text})}
                    value={this.state.otherInsurancePolicyNumber}
                  />
                  <Text style={formStyles.message}>{this.state.messages.otherInsurancePolicyNumber}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.policyEffectiveDate}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.otherInsuranceEffectiveDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='DD/MM/YY'
                    minDate='01/01/90'
                    maxDate={moment().format('DD/MM/YY')}
                    onDateChange={(date) => {this.setState({otherInsuranceEffectiveDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.otherInsuranceEffectiveDate}</Text>
                </View>
              </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({specialRequests: text})}
              value={this.state.specialRequests}
            />
            <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
          </View>

          <View style={formStyles.hr} />

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.declaration}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration1}
              choice={this.state.declaration1}
              onChange={(choice) => this.setState({declaration1: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration1}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration2}
              choice={this.state.declaration2}
              onChange={(choice) => this.setState({declaration2: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration3}
              choice={this.state.declaration3}
              onChange={(choice) => this.setState({declaration3: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <LegalDocumentLink
              navigation={this.props.navigation}
              choice={this.state.declaration4}
              noDisclaimer
              onChange={(choice) => this.setState({declaration4: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration4}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration5}
              choice={this.state.declaration5}
              onChange={(choice) => this.setState({declaration5: choice})}
            />
          <Text style={formStyles.message}>{this.state.messages.declaration5}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration6}
              choice={this.state.declaration6}
              onChange={(choice) => this.setState({declaration6: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration6}</Text>
          </View>
          {
            this.state.submitting ?
              <View style={formStyles.submitButtonDisabled}>
                <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
              </View> :
              <TouchableOpacity style={formStyles.submitButton} onPress={() => this._submit()}>
                <Text style={formStyles.submitButtonText}>{this.state.text.submit}</Text>
              </TouchableOpacity>
          }

        </ScrollView>
        </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
      </Grid>
      <Footer/>
   </div>
    );
  }
  }

  class ReportedPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      place: this.props.place ? this.props.place : '',
      contactInformation:
        this.props.contactInformation ? this.props.contactInformation : '',
      text: {},
    };
  }

  _onPlaceChange(place) {
    this.setState({place: place});
    this.props.onChange(place, this.state.contactInformation);
  }

  _onContactInformationChange(contactInformation) {
    this.setState({contactInformation: contactInformation});
    this.props.onChange(this.state.place, contactInformation);
  }

  render() {
    return (
      <View style={formStyles.fieldset}>
        <Text>{getOrdinalNumber(this.props.number)} {this.props.text.reportedPlace}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this._onPlaceChange(text)}
          value={this.state.place}
          placeholder={this.props.text.reportedPlace}
          placeholderTextColor='lightgrey'
        />
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this._onContactInformationChange(text)}
          value={this.state.contactInformation}
          placeholder={this.props.text.contactInformation}
          placeholderTextColor='lightgrey'
        />
      </View>
    );
  }
  }

  class LostItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item ? this.props.item : '',
      lostDate: this.props.lostDate ? this.props.lostDate : '',
      purchaseValueCurrency: this.props.purchaseValueCurrency ? this.props.purchaseValueCurrency : '',
      purchaseValue: this.props.purchaseValue ? this.props.purchaseValue : '',
      replacementCostCurrency: this.props.replacementCostCurrency ? this.props.replacementCostCurrency : '',
      replacementCost: this.props.replacementCost ? this.props.replacementCost : '',
    };
  }

  _onItemChange(item) {
    this.setState({item: item});
    this.props.onChange(item, this.state.lostDate,
      this.state.purchaseValueCurrency, this.state.purchaseValue,
      this.state.replacementCostCurrency, this.state.replacementCost);
  }

  _onLostDateChange(lostDate) {
    this.setState({lostDate: lostDate});
    this.props.onChange(this.state.item, lostDate,
      this.state.purchaseValueCurrency, this.state.purchaseValue,
      this.state.replacementCostCurrency, this.state.replacementCost);
  }

  _onPurchaseValueChange(currency, number) {
    this.setState({purchaseValueCurrency: currency, purchaseValue: number});
    this.props.onChange(this.state.item, this.state.lostDate, currency, number,
      this.state.replacementCostCurrency, this.state.replacementCost);
  }

  _onReplacementCostChange(currency, number) {
    this.setState({replacementCostCurrency: currency, replacementCost: number});
    this.props.onChange(this.state.item, this.state.lostDate,
      this.state.purchaseValueCurrency, this.state.purchaseValue, currency, number,);
  }

  render() {
    return (
      <View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>
            {getOrdinalNumber(this.props.number)} {this.props.text.lostDamagedItem}
          </Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this._onItemChange(text)}
            value={this.state.item}
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.props.text.dateWhenLostDamagedItems}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.state.lostDate}
            mode='date'
            placeholder={this.props.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate='January 1, 1990'
            maxDate={moment().format('MMMM D, YYYY')}
            onDateChange={(date) => {this._onLostDateChange(date)}}
            confirmBtnText={this.props.text.ok}
            cancelBtnText={this.props.text.cancel}
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.props.text.OriginalPurchaseValue}</Text>
          <AmountInput
            currency={this.state.purchaseValueCurrency}
            number={this.state.purchaseValue}
            onChange={(currency, number) =>
              this._onPurchaseValueChange(currency, number)}
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.props.text.replacementRepairCost}</Text>
          <AmountInput
            currency={this.state.replacementCostCurrency}
            number={this.state.replacementCost}
            onChange={(currency, number) =>
              this._onReplacementCostChange(currency, number)}
          />
        </View>
      </View>
    );
  }
  }

  class EmergencyEssentialItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item ? this.props.item : '',
    };
  }

  _onItemChange(item) {
    this.setState({item: item});
    this.props.onChange(item);
  }

  render() {
    return (
      <View>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this._onItemChange(text)}
          value={this.state.item}
          placeholder={getOrdinalNumber(this.props.number) +  this.props.text.item }
          placeholderTextColor='lightgrey'
        />
      </View>
    );
  }
  }

  const getOrdinalNumber = function (number) {
  if (number == 1 || (number > 20 && number % 10 == 1)) {
    return number + 'st';
  }
  else if (number == 2 || (number > 20 && number % 10 == 2)) {
    return number + 'nd';
  }
  else if (number == 3 || (number > 20 && number % 10 == 3)) {
    return number + 'rd';
  }
  else {
    return number + 'th';
  }
  }

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  importantNotes: {
    marginTop: 10,
    marginBottom: 10,
  },
  indented: {
    marginLeft: 20,
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    paddingLeft: 20,
   paddingRight: 20,
  },
};
