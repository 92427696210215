import React from "react";
import {
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import CryptoJS from "crypto-js";
import validate from "validate.js";
import moment from "moment";
import md5 from "md5";

import ProductAPI from "./../../../api/ProductAPI";
import { enUS } from "./en-US.js";
import { zhHK } from "./zh-HK.js";
import { languageStorage } from "./../../../lib/LanguageStorage";
import { planTypes } from "./planTypes";
import { instituteLocations } from "./instituteLocations";
import { platform } from "./../../../config/platform";
import { formStyles } from "./../../../config/styles";
import PromotionCode from "./../../../lib/PromotionCode";
import { host, api26, busiSecKey } from "./../../../config/api";
import { memberStorage } from "./../../../lib/MemberStorage";
import { stateStorage } from "./../../../lib/StateStorage";
import {
  clearEmptyStrings,
  msgCodeDetail,
  removeSpaces,
  formatName,
  hmacSha256,
} from "./../../../lib/utils";
import { insuranceAdvisors } from "./../../../lib/InsuranceAdvisors";
import HKID from "./../../../lib/HKID";
import TextInput from "./../../../components/TextField/TextField";
import Alert from "./../../../components/Alert/Alert";
import Footer from "./../../../components/Footer/Footer";
import PromoteAPI from "./../../../api/PromoteAPI";
import Navbar from "./../../../components/Navbar/Navbar";
import DatePicker from "./../../../components/DatePicker/DatePicker";
import ModalSelector from "./../../../components/ModalSelector/ModalSelector";
import NameInput from "./../../../components/NameInput/NameInput";
import ImportantNotes from "./../../../components/ImportantNotes/ImportantNotes";
import YesNoQuestion from "./../../../components/YesNoQuestion/YesNoQuestion";

import Member from "./../../../Member";
import validid from 'validid';

export default class USSP3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    const USSP = this.props.location.state.USSP;
    const insuredPeople = this.props.location.state.insuredPeople;
    const institution = this.props.location.state.institution;
    
    if (policy) {
      this.state = {
        effectiveDateStart: moment(
          policy.effectiveDateStart,
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMMM D, YYYY"),
        effectiveDateEnd: moment(
          policy.effectiveDateEnd,
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMMM D, YYYY"),
        policyholderTitle: policy.policyHolder.salutation.descEnUs.replace(
          /\./g,
          ""
        ),
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderEmail: policy.policyHolder.email,
        policyholderAddress: policy.policyHolder.address1,
        policyholderHKID: policy.policyHolder.documentNo,
        policyholderDOB: moment(
          policy.policyHolder.dateOfBirth,
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMMM D, YYYY"),
        planType: USSP.includeGuardian == "Y" ? "with" : "without",
        insuredTitle: insuredPeople[0].salutation
          ? insuredPeople[0].salutation.descEnUs.replace(/\./g, "")
          : undefined,
        insuredFirstName: insuredPeople[0].firstName,
        insuredLastName: insuredPeople[0].lastName,
        insuredHKID: insuredPeople[0].documentNo,
        specialRequests: policy.specialRequest,
        statesList: [],
        universitiesList: [],
        visaStatusesList: [],
        messages: {},
        insuredCitizenship: "Hong Kong",
        text: {},
      };
    } else {
      console.log(this.props.location.state.effectiveDateStart)
      this.state = {
        broker: this.props.location.state.broker,
        planType: this.props.location.state.planType,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress:
          this.props.location.state.showBusinessEmailAddress,
        referral: this.props.location.state.referral,
        statesList: [],
        universitiesList: [],
        visaStatusesList: [],
        deductible:this.props.location.state.deductible,
        policyholderDOB:this.props.location.state.studentage,
        insuredDOB:this.props.location.state.studentage,
        effectiveDateStart:this.props.location.state.effectiveDateStart,
        effectiveDateEnd:this.props.location.state.effectiveDateEnd,
        messages: {},
        insuredCitizenship: "Hong Kong",
        text: {},
      };
    }
  }

  componentDidMount() {
    if (localStorage.getItem("preventGoBack") == "true") {
      window.location = "/";
      return;
    }

    stateStorage.getState("USSP3", (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == "zhHK") {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState(
        {
          language: language,
          text: getText(language),
        },
        () => {
          // init HKID validation
          const text = this.state.text;
          validate.validators.hkid = function (
            value,
            options,
            key,
            attributes
          ) {
            if (!value || validid.hkid(value.toUpperCase())) {
              return null;
            } else {
              return text.notValidHKID;
            }
          };

          // init member and token
          memberStorage.getMember((member, token) =>
            this.setState(
              {
                member: member,
                token: token,
              },
              () => {
                if (this.state.broker) {
                  // init broker-specific input field
                  this.setState({
                    insuranceAdvisorMobile:
                      this.state.member && this.state.member.agent.isAdmin
                        ? undefined
                        : this.state.broker.mobile,
                  });
                } else {
                  // init bound insurance advisor
                  insuranceAdvisors.getInsuranceAdvisors(
                    this.state.language,
                    this.props.navigation,
                    (insuranceAdvisors) => {
                      const insuranceAdvisor = insuranceAdvisors.USSP;
                      if (insuranceAdvisor) {
                        this.setState({ insuranceAdvisor: insuranceAdvisor });

                        if (this.state.member) {
                          PromotionCode.validatePromotionCodes(
                            insuranceAdvisors.USSP.promotionCode,
                            this.state.member.id,
                            this.state.token.tokenStr,
                            "EAPP",
                            "USSP",
                            (promotionCodes) => {
                              this.setState({
                                showBusinessEmailAddress: false,
                                businessEmailAddress: undefined,
                                submitButtonDisabled: false,
                              });
                            }
                          );
                        }
                      } else {
                        this.setState({
                          showBusinessEmailAddress: true,
                          submitButtonDisabled: false,
                        });
                      }
                    }
                  );
                  if (!this.state.member && this.state.promotionCodes) {
                    PromoteAPI.checkPromotionCode(
                      {
                        pn: "EAPP",
                        pl: "USSP",
                        promotionCode: this.state.promotionCodes,
                      },
                      (msgDesc) => {
                        this.setState({ insuranceAdvisor: { agent: msgDesc } });
                      }
                    );
                  }

                  if (!this.state.member) {
                    this.setState({ showBusinessEmailAddress: true });
                  }
                }
              }
            )
          );

          ProductAPI.getStates({}, (msgDesc) => {
            this.setState({ statesList:[]})
            for (let i = 0; i < msgDesc.states.length; i++) {
              this.state.statesList.push({
                key: msgDesc.states[i].stateCode,
                label:
                  language == "zhHK"
                    ? msgDesc.states[i].stateNameZhHk
                    : msgDesc.states[i].stateNameEnUs,
              });
            }
            this.setState({ statesList: this.state.statesList, stateCode:msgDesc.states[0].key });
          });

          ProductAPI.getUniversities({
            
          }, (msgDesc) => {
            this.setState({ universitiesList:[]})
            for (let i = 0; i < msgDesc.universities.length; i++) {
              this.state.universitiesList.push({
                key: msgDesc.universities[i].universityCode,
                label:
                  language == "zhHK"
                    ? msgDesc.universities[i].universityNameZhHk
                    : msgDesc.universities[i].universityNameEnUs,
              });
            }
            this.setState({ universitiesList: this.state.universitiesList });
            this.setState({ universityCode:msgDesc.universities[0].universityCode });
            
          });

          ProductAPI.getVisaStatuses({}, (msgDesc) => {
            this.setState({ visaStatusesList:[]})
            for (let i = 0; i < msgDesc.visaStatuses.length; i++) {
              this.state.visaStatusesList.push({
                key: msgDesc.visaStatuses[i].statusCode,
                label: msgDesc.visaStatuses[i].statusName,
              });
            }
            // this.setState({ visaStatusesList: this.state.visaStatusesList,insuredVisaStatus:msgDesc.visaStatuses[0].statusCode });
            this.setState({ visaStatusesList: this.state.visaStatusesList });
          });
        }
      )
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState("USSP3", rawRequest);
    stateStorage.setState("USSP4", undefined);
  }

  _apiDateFormat(date) {
    return moment(date, "MMMM D, YYYY").format("YYYY-MM-DD");
  }

  _getRelationshipLabel() {
    switch (this.props.relationship) {
      case "insured":
        return this.state.text.policyHolder;
        break;
      case "spouse":
        return this.state.text.spouse;
        break;
      case "guardian":
        return this.state.text.guardian;
        break;
      case "parents":
        return this.state.text.parents;
        break;
      case "relative":
        return this.state.text.relatives;
        break;
      case "friend":
        return this.state.text.friends;
        break;
      case "others":
        return this.state.text.others;
    }
  }

  _nextScreen() {
    this.setState({ submitButtonDisabled: true });

    const state = clearEmptyStrings(this.state);
    let attributes = {
      planType: state.planType,
      deductible: state.deductible,
      stateCode: state.stateCode,
      universityCode: state.universityCode,
      hasMedicalCare: state.hasMedicalCare,
      takeMedications: state.takeMedications,
      isPregnant: state.isPregnant,
      effectiveDate: state.effectiveDateStart,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderHKID: state.policyholderHKID,
      policyholderDOB: state.policyholderDOB,
      policyholderPhone: state.policyholderPhone,
      policyholderAddress: state.policyholderAddress,
      policyholderEmail: state.policyholderEmail,
      insuredFirstName: state.insuredFirstName,
      insuredLastName: state.insuredLastName,
      insuredHKID: state.insuredHKID,
      insuredDOB: state.insuredDOB,
      insuredEmail: state.insuredEmail,
      insuredPhone: state.insuredPhone,
      insuredAddress: state.insuredAddress,
      insuredVisaStatus: state.insuredVisaStatus,
      insuredCitizenship: state.insuredCitizenship,
      emergencyFirstName: state.emergencyFirstName,
      emergencyLastName: state.emergencyLastName,
      emergencyPhone: state.emergencyPhone,
      emergencyRelationship: state.emergencyRelationship,
      businessEmailAddress: state.businessEmailAddress,
      specialRequests: state.specialRequests,
      referral: state.referral,
    };

    console.log("attributes:" + JSON.stringify(attributes));
 
    const englishFormat = {
      pattern: "[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+",
      message: this.state.text.needsToBeInEnglish,
    };

    let constraints = {
      planType: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      effectiveDate: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderFirstName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderLastName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderHKID: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        hkid: { message: this.state.text.notValidHKID },
        format: englishFormat,
      },
      policyholderPhone: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        numericality: { message: this.state.text.notANumber },
      },
      policyholderDOB: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderAddress: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderEmail: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        email: { message: this.state.text.notValidEmail },
        format: englishFormat,
      },
      insuredFirstName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      insuredLastName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      insuredHKID: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        hkid: { message: this.state.text.notValidHKID },
        format: englishFormat,
      },
      insuredEmail: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        email: { message: this.state.text.notValidEmail },
        format: englishFormat,
      },
      insuredDOB: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      insuredVisaStatus: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      insuredAddress: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      insuredPhone: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        numericality: { message: this.state.text.notANumber },
      },
      emergencyFirstName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      emergencyLastName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      emergencyPhone: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      emergencyRelationship: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      businessEmailAddress: {
        format: englishFormat,
      },
      specialRequests: {
        format: englishFormat,
      },
      referral: {
        format: englishFormat,
      },
    };
    // if (this.state.planType == "with") {
    //   constraints.optionalBenefit = {
    //     presence: { message: this.state.text.thisFieldCannotBeBlank },
    //   };
    //   constraints.optionalBenefitRelationship = {
    //     presence: { message: this.state.text.thisFieldCannotBeBlank },
    //   };
    //   if (this.state.optionalBenefit == "other") {
    //     (constraints.optionalBenefitFirstName = {
    //       presence: { message: this.state.text.thisFieldCannotBeBlank },
    //       format: englishFormat,
    //     }),
    //       (constraints.optionalBenefitLastName = {
    //         presence: { message: this.state.text.thisFieldCannotBeBlank },
    //         format: englishFormat,
    //       }),
    //       (constraints.optionalBenefitHKID = {
    //         presence: { message: this.state.text.thisFieldCannotBeBlank },
    //         format: englishFormat,
    //       }),
    //       (constraints.optionalBenefitDOB = {
    //         presence: { message: this.state.text.thisFieldCannotBeBlank },
    //       });
    //   }
    // }

    const messages = validate(attributes, constraints, { fullMessages: false });
    if (messages) {
      this.setState({
        alert: Alert.alert(this.state.text.alert, this.state.text.check, [
          {
            text: this.state.text.ok,
            onPress: () => this.setState({ alert: null }),
            style: "cancel",
          },
        ]),
        messages: messages,
        submitButtonDisabled: false,
      });
    } else {
      let fields = {
        dt: moment().format("YYYYMMDDhhmmss"),
        pn: "EAPP",
        pl: "USSP",
        "payment.promotionCode": removeSpaces(this.state.promotionCodes),
        "policy.effectiveDateStart": this._apiDateFormat(
          this.state.effectiveDateStart
        ),
        "policy.businessEmail": removeSpaces(this.state.businessEmailAddress),
        "ussp.premiumPlan":this.state.planType,
        "ussp.deductible": this.state.deductible,
        "student.age": parseInt(moment(this.state.effectiveDateStart).diff(this.state.insuredDOB, "years", true))
      };
      if (this.state.member) {
        fields["member.id"] = this.state.member.id;
      }
      if (this.state.token) {
        fields["member.token"] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? "/agent" : "") + api26, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.msgCode == 0) {
            let rawRequest = Object.assign({}, state);
            delete rawRequest.messages;
            delete rawRequest.text;
            delete rawRequest.alert;

            const premium = responseJson.msgDesc.premium;
            const levy = responseJson.msgDesc.levy;
            const total = premium.discount + levy;

            stateStorage.setState("USSP3", rawRequest);
            stateStorage.setState("USSP4", undefined);

            this.props.history.push("/USSP4", {
              broker: this.state.broker,
              insuranceAdvisor: state.insuranceAdvisor,
              insuranceAdvisorMobile: state.insuranceAdvisorMobile,
              product: state.product,
              effectiveDateStart: state.effectiveDateStart,
              effectiveDateEnd: state.effectiveDateEnd,
              promotionCodes: removeSpaces(state.promotionCodes),
              businessEmailAddress: removeSpaces(state.businessEmailAddress),
              specialRequests: state.specialRequests,
              referral: state.referral,
              planType: this.state.planType,
              deductible: state.deductible,
              stateCode: state.stateCode,
              universityCode: state.universityCode,
              stateName: state.stateName,
              universityName: state.universityName,
              hasMedicalCare: state.hasMedicalCare,
              takeMedications: state.takeMedications,
              isPregnant: state.isPregnant,
              effectiveDate: state.effectiveDateStart,
              policyholderFirstName: state.policyholderFirstName,
              policyholderLastName: state.policyholderLastName,
              policyholderHKID: state.policyholderHKID,
              policyholderDOB: state.policyholderDOB,
              policyholderPhone: state.policyholderPhone,
              policyholderAddress: state.policyholderAddress,
              policyholderEmail: state.policyholderEmail,
              insuredFirstName: state.insuredFirstName,
              insuredLastName: state.insuredLastName,
              insuredHKID: state.insuredHKID,
              insuredDOB: state.insuredDOB,
              insuredEmail: state.insuredEmail,
              insuredPhone: state.insuredPhone,
              insuredAddress: state.insuredAddress,
              insuredVisaStatus: state.insuredVisaStatus,
              insuredCitizenship: state.insuredCitizenship,
              emergencyFirstName: state.emergencyFirstName,
              emergencyLastName: state.emergencyLastName,
              emergencyPhone: state.emergencyPhone,
              emergencyRelationship: state.emergencyRelationship,
              rawRequest: rawRequest,
              premium: premium,
              levy: levy,
              total: total,
            });
          } else if (responseJson.msgCode == 40) {
            if (this.state.broker) {
              this.setState({
                messages: {
                  promotionCodes: this.state.text["invalidPromotionCode"],
                },
                alert: Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => this.setState({ alert: null }),
                      style: "cancel",
                    },
                  ]
                ),
              });
            } else {
              this.setState({
                alert: Alert.alert(
                  this.state.text.addInsuranceAdvisor,
                  this.state.text.wouldYouLike +
                    responseJson.msgDesc.agent.brokerName +
                    this.state.text.toBeYourInsuranceAdvisor,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => {
                        if (!this.state.bindingInsuranceAdvisor) {
                          this.setState(
                            { bindingInsuranceAdvisor: true },
                            () => {
                              insuranceAdvisors.setInsuranceAdvisor(
                                {
                                  agentCode:
                                    responseJson.msgDesc.agent.agentCode,
                                },
                                ["USSP"],
                                this.state.promotionCodes,
                                this.state.language,
                                () =>
                                  this.setState(
                                    { bindingInsuranceAdvisor: false },
                                    () => this._nextScreen()
                                  )
                              );
                            }
                          );
                        }
                      },
                    },
                  ],
                  { cancelable: false }
                ),
              });
            }
          } else if (responseJson.msgCode == 56) {
            this.setState({
              messages: {
                promotionCodes: this.state.text["invalidPromotionCode"],
              },
              alert: Alert.alert(this.state.text.alert, this.state.text.check, [
                {
                  text: this.state.text.ok,
                  onPress: () => this.setState({ alert: null }),
                  style: "cancel",
                },
              ]),
            });
          } else {
            alert(
              msgCodeDetail(responseJson.msgCode)[
                this.state.language == "enUS" ? "desc_en" : "desc_zh"
              ]
            );
          }
          this.setState({ submitButtonDisabled: false });
        })
        .catch((error) => {
          alert("Facing error when processing the quotation!");
          this.setState({ submitButtonDisabled: false });
        });
    }
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    return (
      <div className="App">
        <Helmet>
          <title>US Study Plan | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getInsured}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{ backgroundColor: "#f2f2f2" }}>
          <Hidden xsDown>
            <Grid
              item
              xs
              style={{ marginBottom: 13, marginLeft: "5%", marginRight: "5%" }}
            >
              <View style={[styles.greeting, { fontWeight: "bold" }]}>
                <Text
                  style={[
                    styles.greetingText,
                    { marginTop: "10%", fontSize: 35 },
                  ]}
                >
                  {this.state.text.ussp}
                </Text>
                <p></p>
                <Text style={[styles.greetingText, { fontSize: 20 }]}>
                  {this.state.text.ensure}
                </Text>
              </View>
            </Grid>
          </Hidden>

          <Grid
            item
            xs
            style={{
              marginTop: 30,
              marginBottom: 50,
              marginRight: "5%",
              backgroundColor: "white",
            }}
          >
            <ScrollView style={(formStyles.form, styles.fie)}>
              {this.state.alert}
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h2, { color }]}>
                  {this.state.text.annualStudyAbroadProtector}
                </Text>
              </View>
              {this.state.insuranceAdvisor || this.state.broker ? (
                <View style={formStyles.fieldset}>
                  <Text style={{ textAlign: "left" }}>
                    {this.state.text.theInsuranceIsIssued}
                    {this.state.broker
                      ? this.state.broker.name
                      : this.state.insuranceAdvisor.agent.broker.nameEnUs}
                    {this.state.text.professionalInsurance}
                  </Text>
                </View>
              ) : (
                <View style={{ textAlign: "left" }}>
                  <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
                </View>
              )}

              <Text>{this.state.text.pleaseInputInEnglish}</Text>

              {this.state.broker && (
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>
                    {this.state.text.insuranceAdvisorMobile}
                  </Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) =>
                      this.setState({ insuranceAdvisorMobile: text })
                    }
                    value={this.state.insuranceAdvisorMobile}
                    editable={
                      this.state.member && this.state.member.agent.isAdmin
                    }
                    keyboardType="phone-pad"
                    maxLength={8}
                  />
                </View>
              )}

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.productType}</Text>
                <ModalSelector
                  data={[
                    {
                      key: "basic",
                      label: this.state.text.withAccompanying,
                    },
                    {
                      key: "plus",
                      label: this.state.text.withoutAccompanying,
                    },
                  ]}
                  initValue={this.state.planType.charAt(0).toUpperCase() + this.state.planType.slice(1)}
                  onChange={(option) => this.setState({ planType: option.key })}
                  cancelText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.planType}
                </Text>
              </View>

              <View style={styles.fieldset}>
                <Text style={styles.h4}>{this.state.text.deductible}</Text>
                <ModalSelector
                  data={[
                    { key: "100", label: "100" },
                    { key: "500", label: "500" },
                  ]}
                  initValue={this.state.deductible}
                  onChange={(option) =>
                    this.setState({ deductible: option.key })
                  }
                  cancelText={this.state.text.cancel}
                />
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.stateCode}</Text>
                <ModalSelector
                  data={this.state.statesList}
                  initValue={this.state.stateCode}
                  onChange={(option) =>{
                    this.setState({ stateCode: option.key })
                    this.setState({ universitiesList: [] });
                    this.setState({ universityCode: null })
                    this.setState({ universityName: null });

                    const filtered = this.state.statesList.filter(obj => {
                      return obj.key === option.key;
                    });
                    this.setState({ stateName: filtered[0].label})


                    ProductAPI.getUniversities({
                      'stateCode': option.key,
                    }, (msgDesc) => {
                      for (let i = 0; i < msgDesc.universities.length; i++) {
                        this.state.universitiesList.push({
                          key: msgDesc.universities[i].universityCode,
                          label:
                          this.state.language == "zhHK"
                              ? msgDesc.universities[i].universityNameZhHk
                              : msgDesc.universities[i].universityNameEnUs,
                        });
                      }
                      this.setState({ universitiesList: this.state.universitiesList });
                      this.setState({ universityCode:msgDesc.universities[0].universityCode });
                    });
                  }
                  }
                  cancelText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.stateCode}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.universityCode}
                </Text>
                <ModalSelector
                  data={this.state.universitiesList}
                  initValue={this.state.universityCode}
                  onChange={(option) =>{
                    const filtered = this.state.universitiesList.filter(obj => {
                      return obj.key === option.key;
                    });
                    this.setState({ universityCode: option.key })
                    this.setState({ universityName: filtered[0].label})
                  }
                  }
                  cancelText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.universityCode}
                </Text>
              </View>

              {/* <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.hasMedicalCare}
                  choice={this.state.hasMedicalCare}
                  onChange={(choice) =>
                    this.setState({ hasMedicalCare: choice })
                  }
                />
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.takeMedications}
                  choice={this.state.takeMedications}
                  onChange={(choice) =>
                    this.setState({ takeMedications: choice })
                  }
                />
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.isPregnant}
                  choice={this.state.isPregnant}
                  onChange={(choice) => this.setState({ isPregnant: choice })}
                />
              </View> */}

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.effectiveDate}
                </Text>
                <DatePicker
                  style={formStyles.datepicker}
                  date={
                    this.state.effectiveDateStart
                  }
                  disabled={true}
                  mode="date"
                  placeholder={this.state.text.pleasePickADate}
                  format="MMMM D, YYYY"
                  minDate={moment().add(1, "days").format("MMMM D, YYYY")}
                  maxDate={moment()
                    .add(1, "days")
                    .add(6, "months")
                    .format("MMMM D, YYYY")}
                  onDateChange={(date) => {
                    this.setState({
                      effectiveDateStart: date,
                      effectiveDateEnd: moment(date, "MMMM D, YYYY")
                        .add(1, "years")
                        .subtract(1, "days")
                        .format("MMMM D, YYYY"),
                    });
                  }}
                  confirmBtnText={this.state.text.ok}
                  cancelBtnText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.effectiveDate}
                </Text>
              </View>
              {this.state.effectiveDateStart && (
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>
                    {this.state.text.periodOfInsurance}
                  </Text>
                  <View style={styles.periodOfInsurance}>
                    <Text>
                      {this.state.effectiveDateStart} -{" "}
                      {this.state.effectiveDateEnd}
                    </Text>
                    <Text>{this.state.text.bothDatesInclusive}</Text>
                  </View>
                </View>
              )}
              {!this.state.businessEmailAddress && (
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>
                    {this.state.text.promotionCodes}
                  </Text>
                  <TextInput
                    style={formStyles.input}
                    placeholder={this.state.text.optional}
                    placeholderTextColor="lightgrey"
                    onChangeText={(text) =>
                      this.setState({ promotionCodes: text })
                    }
                    value={this.state.promotionCodes}
                  />
                  <Text style={formStyles.message}>
                    {this.state.messages.promotionCodes}
                  </Text>
                </View>
              )}
              {!this.state.promotionCodes &&
                this.state.showBusinessEmailAddress && (
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>
                      {this.state.text.businessEmailAddress}
                    </Text>
                    <TextInput
                      style={formStyles.input}
                      placeholder={this.state.text.optional}
                      placeholderTextColor="lightgrey"
                      keyboardType="email-address"
                      onChangeText={(text) =>
                        this.setState({ businessEmailAddress: text })
                      }
                      value={this.state.businessEmailAddress}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.businessEmailAddress}
                    </Text>
                  </View>
                )}
              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, { color }]}>
                  {this.state.text.policyHolder}
                </Text>
                <Text>{this.state.text.thePolicyHolderMustBeThe}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.name}</Text>
                <NameInput
                  title={this.state.policyholderTitle}
                  firstName={this.state.policyholderFirstName}
                  lastName={this.state.policyholderLastName}
                  onChange={(title, firstName, lastName) =>{
                    this.setState({
                      policyholderTitle: title,
                      policyholderFirstName: firstName,
                      policyholderLastName: lastName,
                    })
                    this.setState({
                      insuredTitle: title,
                      insuredFirstName: firstName,
                      insuredLastName: lastName,
                    })
                  }
                  }
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderFirstName}
                </Text>
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderLastName}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    {
                      this.setState({ policyholderHKID: text })
                      this.setState({ insuredHKID: text })
                    }
                  }
                  value={this.state.policyholderHKID}
                  placeholder="A1234567"
                  placeholderTextColor="lightgrey"
                />
                <Text style={{ textAlign: "left" }}>
                  {this.state.text.pleaseEnterAllCharacters}
                </Text>
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderHKID}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                <DatePicker
                  disabled={true}
                  style={formStyles.datepicker}
                  date={this.state.policyholderDOB}
                  fullDate={true}
                  mode="date"
                  placeholder={this.state.text.pleasePickADate}
                  format="MMMM D, YYYY"
                  minDate={moment()
                    .subtract(30, "years")
                    .add(0, "days")
                    .format("MMMM D, YYYY")}
                  maxDate={moment()
                    .subtract(18, "years")
                    .format("MMMM D, YYYY")}
                  onDateChange={(date) => {
                    this.setState({ policyholderDOB: date });
                    this.setState({ insuredDOB: date })
                  }}
                  confirmBtnText={this.state.text.ok}
                  cancelBtnText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderDOB}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.mobileNumber}
                </Text>

                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    {
                      this.setState({ policyholderPhone: text })
                      this.setState({ insuredPhone: text })
                    }
                  }
                  value={this.state.policyholderPhone}
                  keyboardType="phone-pad"
                  maxLength={8}
                />

                <Text style={formStyles.message}>
                  {this.state.messages.policyholderPhone}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.correspondenceAddress}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    {
                      this.setState({ policyholderAddress: text })
                      this.setState({ insuredAddress: text })
                    }
                  }
                  value={this.state.policyholderAddress}
                  //placeholder={this.state.text.optional}
                  placeholderTextColor="lightgrey"
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderAddress}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.emailAddress}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>{
                    this.setState({ policyholderEmail: text })
                    this.setState({ insuredEmail: text })
                  }
                  }
                  value={this.state.policyholderEmail}
                  keyboardType="email-address"
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderEmail}
                </Text>
              </View>

              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, { color }]}>
                  {this.state.text.insuredStudent}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.name}</Text>
                <TextInput
                          style={formStyles.input}
                          value={formatName(
                            this.state.policyholderTitle,
                            this.state.policyholderFirstName,
                            this.state.policyholderLastName
                          )}
                          editable={false}
                        />
                <Text style={formStyles.message}>
                  {this.state.messages.insuredFirstName}
                </Text>
                <Text style={formStyles.message}>
                  {this.state.messages.insuredLastName}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ insuredHKID: text })}
                  value={this.state.insuredHKID}
                  placeholder="A1234567"
                  placeholderTextColor="lightgrey"
                  maxLength={8}
                  editable={false}
                />
                <Text style={{ textAlign: "left" }}>
                  {this.state.text.pleaseEnterAllCharacters}
                </Text>
                <Text style={formStyles.message}>
                  {this.state.messages.insuredHKID}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                <DatePicker
                  disabled={true}
                  style={formStyles.datepicker}
                  date={this.state.insuredDOB}
                  fullDate={true}
                  mode="date"
                  placeholder={this.state.text.pleasePickADate}
                  format="MMMM D, YYYY"
                  minDate={moment()
                    .subtract(30, "years")
                    .add(0, "days")
                    .format("MMMM D, YYYY")}
                  maxDate={moment()
                    .subtract(18, "years")
                    .format("MMMM D, YYYY")}
                  onDateChange={(date) => {
                    this.setState({ insuredDOB: date });
                  }}
                  confirmBtnText={this.state.text.ok}
                  cancelBtnText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.insuredDOB}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.emailAddress}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ insuredEmail: text })}
                  value={this.state.insuredEmail}
                  keyboardType="email-address"
                  editable={false}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.insuredEmail}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.insuredPhone}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ insuredPhone: text })}
                  value={this.state.insuredPhone}
                  editable={false}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.insuredPhone}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.insuredAddress}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ insuredAddress: text })}
                  value={this.state.insuredAddress}
                  editable={false}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.insuredAddress}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.citizenship}</Text>
                <TextInput
                  style={formStyles.input}
                  // onChangeText={(text) => this.setState({ insuredCitizenship: text })}
                  value={this.state.insuredCitizenship}
                  keyboardType=""
                  editable={false}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.citizenship}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.visaStatus}</Text>
                <ModalSelector
                  data={this.state.visaStatusesList}
                  initValue={this.state.insuredVisaStatus}
                  onChange={(option) =>
                    this.setState({ insuredVisaStatus: option.key })
                  }
                  cancelText={this.state.text.cancel}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.insuredVisaStatus}
                </Text>
              </View>
              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, { color }]}>
                  {this.state.text.overseasStudyingInstitution}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.name}</Text>
                <NameInput
                  title={this.state.emergencyTitle}
                  firstName={this.state.emergencyFirstName}
                  lastName={this.state.emergencyLastName}
                  onChange={(title, firstName, lastName) =>
                    this.setState({
                      emergencyTitle: title,
                      emergencyFirstName: firstName,
                      emergencyLastName: lastName,
                    })
                  }
                />
                <Text style={formStyles.message}>
                  {this.state.messages.emergencyFirstName}
                </Text>
                <Text style={formStyles.message}>
                  {this.state.messages.emergencyLastName}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.emergencyPhone}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ emergencyPhone: text })
                  }
                  value={this.state.emergencyPhone}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.emergencyPhone}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.relationshipWithThePolicyholder}
                </Text>
                <ModalSelector
                  selectStyle={{ borderColor: "lightgrey" }}
                  initValueTextStyle={{ color: "black" }}
                  data={[
                    { key: "spouse", label: this.state.text.spouse },
                    { key: "guardian", label: this.state.text.guardian },
                    { key: "parents", label: this.state.text.parents },
                    { key: "relative", label: this.state.text.relatives },
                    { key: "friend", label: this.state.text.friends },
                    { key: "others", label: this.state.text.others },
                  ]}
                  initValue={this._getRelationshipLabel()}
                  onChange={(option) =>
                    this.setState({ emergencyRelationship: option.key })
                  }
                  cancelText={this.state.text.cancel}
                />
              </View>

              <View style={formStyles.hr} />
              {/* <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, { color }]}>
                  {this.state.text.specialRequests}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ specialRequests: text })
                  }
                  value={this.state.specialRequests}
                  placeholder={this.state.text.optional}
                  placeholderTextColor="lightgrey"
                />
                <Text style={formStyles.message}>
                  {this.state.messages.specialRequests}
                </Text>
                {!!this.state.specialRequests && (
                  <Text>{this.state.text.manualUnderwritingIsRequired}</Text>
                )}
              </View>
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, { color }]}>
                  {this.state.text.referral}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ referral: text })}
                  value={this.state.referral}
                  placeholder={this.state.text.optional}
                  placeholderTextColor="lightgrey"
                />
                <Text style={formStyles.message}>
                  {this.state.messages.referral}
                </Text>
              </View> */}
              {this.state.submitButtonDisabled ? (
                <View style={formStyles.submitButtonDisabled}>
                  <Text style={formStyles.submitButtonText}>
                    {this.state.text.pleaseWait}
                  </Text>
                </View>
              ) : (
                <TouchableOpacity
                  style={[formStyles.submitButton, { backgroundColor }]}
                  onPress={() => this._nextScreen()}
                >
                  <Text style={formStyles.submitButtonText}>
                    {this.state.text.buyNow}
                  </Text>
                </TouchableOpacity>
              )}
            </ScrollView>
          </Grid>
          <Hidden xsDown>
            <div style={{ marginRight: "5%" }} />
          </Hidden>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fie: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
