import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const RelationshipForm = ({data, disabled, initValue, onChange}) => {
    const [value, setValue] = useState("");
    
    useEffect(() => {
        setValue(initValue)
    }, [initValue])
    
    return (
      <FormControl disabled={disabled}>
        <Select
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
            onChange({ key: event.target.value })
          }}
        >
            { 
                data.map((datum, i) => {
                    return <MenuItem key={datum.key} value={datum.key}>{datum.label}</MenuItem>
                })
            }

        </Select>
      </FormControl>
    );
}


export default RelationshipForm;