import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Locale from './../../Locale';
import Member from './../../Member';

export default class LegalStatement extends React.Component {
  render() {
    const {broker, agent} = this.props;
    const color = Member.getThemeColor();

    return (
      <View style={styles.legalStatement}>
        {
          broker || agent ?
            <Text>
              {Locale.text.theInsuranceIsIssued}
              {broker ? broker.nameEnUs || broker.name : agent.broker.nameEnUs}
              {Locale.text.professionalInsurance}
            </Text> :
            <Text>
              {Locale.text.theInsuranceIsIssuedByContent}
            </Text>
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  legalStatement: {
    marginTop: 5,
    marginBottom: 5,
  },
});
