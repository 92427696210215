import React from 'react';
import {View} from 'react-native';

import Locale from './../../Locale';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, Button} from './../../Form';
import CYBERScreen from './CYBERScreen';
import IntroParagraph from './../components/IntroParagraph';
import ProductEdges from './../components/ProductEdges';
import PlanType from './../components/PlanType';
import DiscountInput from './../components/DiscountInput';
import LeafletAndProvision from './../components/LeafletAndProvision';

export default class CYBERScreen1 extends CYBERScreen {
  componentDidMount() {
    this.initialize();
  }

  render() {
    const {policy, cyber, payment} = this.state.insuranceProduct;

    let leaflet;
    switch (Locale.getLocale()) {
      case Locale.enUS:
        leaflet = 'Product_leaflet_Bravogo_eng.pdf';
        break;
      case Locale.zhHK:
        leaflet = 'Product_leaflet_Bravogo_chi.pdf';
        break;
    }

    const provision = 'Product%20Provision_Bravogo.pdf';

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getAQuote}
        />

        {Locale.text.cyberInsurance}
        {''}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.cyberInsurance}
            </Heading1>

            <PlanType
              options={this.premiumPlanOptions}
              value={cyber.premiumPlan}
              onChange={(premiumPlan) =>
                this.updateCYBER({premiumPlan})}
            />

            <DiscountInput
              cozy
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={this.state.initialized &&
                !this.state.agent && !this.state.broker}
              onChange={({promotionCode, businessEmail}) => {
                this.updatePayment({promotionCode});
                this.updatePolicy({businessEmail});
              }}
              messages={this.state.messages.discount}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote('Cyber2')}
            >
              {Locale.text.getAQuote}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
