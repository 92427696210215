import Request from './Request';
import {host} from './../config/api';

export default class ProductAPI {
  /* 2.1 檢視產品列表 */
  static list(data, onSuccess, onFail) {
    Request.post('/product/list', data, onSuccess, onFail);
  }

  /* 2.2 檢視單一產品 */
  static details(data, onSuccess, onFail) {
    Request.post('/product/details', data, onSuccess, onFail);
  }

  /* 2.3 取得建築面積選項列表 */
  static getGrossAreasHome(data, onSuccess, onFail) {
    Request.post('/product/home/getGrossAreas', data, onSuccess, onFail);
  }

  /* 2.4 取得實用面積選項列表 */
  static getNetAreasLandlord(data, onSuccess, onFail) {
    Request.post('/product/landlord/getNetAreas', data, onSuccess, onFail);
  }

  /* 2.5 取得國家選項列表 */
  static getCountriesAsap(data, onSuccess, onFail) {
    Request.post('/product/asap/getCountries', data, onSuccess, onFail);
  }

  /* 2.6 取得產品保費 */
  static premium(data, onSuccess, onFail) {
    Request.post('/product/premium', data, onSuccess, onFail);
  }

  /* 2.7 取得親友關係選項列表 */
  static getRelationships(data, onSuccess, onFail) {
    Request.post('/product/getRelationships', data, onSuccess, onFail);
  }

  /* 2.8 取得年齡群選項列表 */
  static getPersonAgeBands(data, onSuccess, onFail) {
    Request.post('/product/person/getAgeBands', data, onSuccess, onFail);
  }

  /* 2.10 取得職業選項列表 */
  static getOccupations(data, onSuccess, onFail) {
    Request.post('/product/getOccupations', data, onSuccess, onFail);
  }

  /* 2.11 取得美國州份選項列表 */
  static getStates(data, onSuccess, onFail) {
    Request.post('/product/ussp/getStates', data, onSuccess, onFail);
  }

  /* 2.12 取得美國大學選項列表 */
  static getUniversities(data, onSuccess, onFail) {
    Request.post('/product/ussp/getUniversities', data, onSuccess, onFail);
  }

  /* 2.13 取得美國簽證狀態選項列表 */
  static getVisaStatuses(data, onSuccess, onFail) {
    Request.post('/product/ussp/getVisaStatuses', data, onSuccess, onFail);
  }

  /* 3.17a "快蓄保2"報價 */
  static quoteEndowment(data, onSuccess, onFail) {
    Request.post('/product/endowment/quote', data, onSuccess, onFail);
  }

  /* 3.17b 建立"快蓄保2"建議書 */
  static endowmentProposal(data, onSuccess, onFail) {
    Request.post('/product/endowment/proposal', data, onSuccess, onFail);
  }

  /* 3.17c 下載"快蓄保2"建議書 */
  static downloadEndowmentProposal(token) {
    return host + '/product/endowment/proposal/download?token=' + token;
  }

  /* 3.17d 簽署"快蓄保2"建議書 */
  static signEndowmentProposal(data, onSuccess, onFail) {
    Request.post('/product/endowment/proposal/sign', data, onSuccess, onFail);
  }
}
