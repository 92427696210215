import React from 'react';
import {
  Text, TextInput, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import {Helmet} from 'react-helmet';
import validate from 'validate.js';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { options } from './options';
import { getEcash, formatName, msgCodeDetail, removeSpaces, checkTyphoonMessage } from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';


import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api26, api36, api37, api310, busiSecKey } from './../../../config/api';
import { stateStorage } from './../../../lib/StateStorage';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Member from './../../../Member';
import ProductAPI from './../../../api/ProductAPI';
import ApplyAPI from './../../../api/ApplyAPI';
import AgentAPI from './../../../api/AgentAPI';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink';
import Alert  from './../../../components/Alert/Alert';
import PaymentBreakdown from './../../../components/PaymentBreakdown/PaymentBreakdown';
import DirectMarketingTickBox from './../../../components/DirectMarketingTickBox/DirectMarketingTickBox';

export default class TravelInsurance4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state.broker,
      insuranceAdvisor: this.props.location.state.insuranceAdvisor,
      insuranceAdvisorMobile: this.props.location.state.insuranceAdvisorMobile,
      centaline: this.props.location.state.centaline,
      planType: this.props.location.state.planType,
      period: this.props.location.state.period,
      people: this.props.location.state.people,
      numberOfChildren: this.props.location.state.numberOfChildren,
      numberOfIndividuals: this.props.location.state.numberOfIndividuals,
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      days: this.props.location.state.days,
      effectiveDateStart: this.props.location.state.effectiveDateStart,
      effectiveDateEnd: this.props.location.state.effectiveDateEnd,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      specialRequests: this.props.location.state.specialRequests,
      referral: this.props.location.state.referral,
      policyholderTitle: this.props.location.state.policyholderTitle,
      policyholderFirstName: this.props.location.state.policyholderFirstName,
      policyholderLastName: this.props.location.state.policyholderLastName,
      policyholderDOB: this.props.location.state.policyholderDOB,
      policyholderPhone: this.props.location.state.policyholderPhone,
      policyholderAddress: this.props.location.state.policyholderAddress,
      policyholderEmail: this.props.location.state.policyholderEmail,
      policyholderIdType: this.props.location.state.policyholderIdType,
      policyholderIdNumber: this.props.location.state.policyholderIdNumber,
      paBenefits: this.props.location.state.paBenefits,
      cruiseBenefit: this.props.location.state.cruiseBenefit,
      medicalGuarantee: this.props.location.state.medicalGuarantee,
      travellers: this.props.location.state.travellers,
      noticeAgree: this.props.location.state.noticeAgree,
      rawRequest: this.props.location.state.rawRequest,
      premium: this.props.location.state.premium,
      levy: this.props.location.state.levy,
      total: this.props.location.state.total,
      messages: {},
      text: {},
    };
    console.log(this.state.policyholderIdNumber)
    this.state.manualUnderwriting =
      parseInt(this.state.numberOfIndividuals) > 10
      || this.state.specialRequests

    const details = this.state.broker ?
      AgentAPI.details : ProductAPI.details;

    details(
      {
        'pn': 'EAPP',
        'pl': 'TRAVELS22',
        'member.id': Member.getMemberId(),
        'member.token': Member.getMemberToken(),
        'payment.promotionCode': this.state.promotionCodes,
      },
      ({plan}) => {
        this.setState({planBroker: plan.planBroker});
      }
    );
  }


  handleLeavePage(e) {
    const confirmationMessage = 'Some message';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }
  

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleLeavePage);
 }

  componentDidMount() {
    // window.addEventListener('beforeunload', this.handleLeavePage);
    // if (localStorage.getItem('preventGoBack') == 'true') {
    //   window.location = '/';
    //   return;
    // }

    stateStorage.getState('gtravel3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init member and token
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init ecash
        getEcash(
          this.state.language,
          this.props.navigation,
          (ecash) => this.setState({ecash: ecash})
        );
      })
    );
   
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _confirm() {
    if (this.state.submitButtonDisabled) {
      return;
    }
    this.setState({submitButtonDisabled: true});

    let attributes = {
      declaration2: this.state.declaration2,
      declaration3: this.state.declaration3,
    }

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    }

    let constraints = {
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
    }

    if (
      !this.state.broker &&
      !this.state.manualUnderwriting &&
      this.state.ecash > 0
    ) {
      attributes.usingEcash = this.state.usingEcash;
      constraints.usingEcash = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      };
    }

    if (
      this.state.insuranceAdvisor ||
      this.state.broker ||
      this.state.promotionCodes
    ) {
      attributes.brokerDeclaration = this.state.brokerDeclaration;
      constraints.brokerDeclaration = {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      };
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
      return;
    }
    else {
      checkTyphoonMessage(
        this.state.centaline ? 'CTL' : 'EAPP',
        'TRAVELS22',
        this.state.language,
        this.props.history,
        (alert) => this.setState({alert}),
        (noticeAgree) => {

          this.setState({alert: null})

          let pn = 'EAPP';
          if (this.state.centaline) {
            pn = 'CTL';
          }

          let pl;
          if (this.state.period == 'shortTermTrip') {
            switch (this.state.planType) {
              case 'standard':
                pl = 'TRAVELS21';
                break;
              case 'classic':
                pl = 'TRAVELS22';
                break;
              case 'premier':
                pl = 'TRAVELS23';
                break;
            }
          }
          else {
            switch (this.state.planType) {
              case 'standard':
                pl = 'TRAVELA21';
                break;
              case 'classic':
                pl = 'TRAVELA22';
                break;
              case 'premier':
                pl = 'TRAVELA23';
                break;
            }
          }

          let people = 'I';
          if (this.state.people == 'family') {
            people = 'F';
          }
          if (this.state.people == 'withChildren') {
            people = 'IC';
          }

          let rawRequest = this.state.rawRequest;
          rawRequest.declaration2 = this.state.declaration2;
          rawRequest.declaration3 = this.state.declaration3;
          rawRequest.brokerDeclaration = this.state.brokerDeclaration;
          rawRequest.usingEcash = this.state.usingEcash;
          delete rawRequest.planBroker;

          stateStorage.setState('gtravel4', rawRequest);

          let travel;
          if (this.state.period == 'shortTermTrip') {
            travel = this.state.broker ? AgentAPI.singleTravel : ApplyAPI.singleTravel;
          }
          if (this.state.period == 'annual') {
            travel = this.state.broker ? AgentAPI.annualTravel : ApplyAPI.annualTravel;
          }

          let fields;
          if (this.state.period == 'shortTermTrip') {
            fields = {
              'pn': pn,
              'pl': pl,
              'agent.mobile': this.state.insuranceAdvisorMobile,
              'payment.promotionCode': removeSpaces(this.state.promotionCodes),
              'policy.effectiveDateStart': this._apiDateFormat(this.state.startDate),
              'policy.effectiveDateEnd': this._apiDateFormat(this.state.endDate),
              'policy.allowPrivacyPromote3P': this.state.declaration3,
              'policy.allowPrivacyPromote': this.state.declaration3,
              'policy.businessEmail': this.state.centaline ?
                undefined : removeSpaces(this.state.businessEmailAddress),
              'policy.specialRequest': this.state.specialRequests,
              'policyHolder.mobile': this.state.policyholderPhone,
              'policyHolder.email': this.state.policyholderEmail,
              'policyHolder.address1': this.state.policyholderAddress,
              'noticeAgree': this.state.noticeAgree,
              'travel.premiumPlan': people,
              'travel.isUpgradePA': this.state.paBenefits,
              'travel.includeCruise': this.state.planType == 'premier' ? this.state.cruiseBenefit : 'N',
              'travellers': this.state.travellers,
              'rawRequest': JSON.stringify(rawRequest),
            };
          }
          else {
            fields = {
              'pn': pn,
              'pl': pl,
              'agent.mobile': this.state.insuranceAdvisorMobile,
              'payment.promotionCode': removeSpaces(this.state.promotionCodes),
              'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
              'policy.effectiveDateEnd': this._apiDateFormat(this.state.effectiveDateEnd),
              'policy.allowPrivacyPromote3P': this.state.declaration3,
              'policy.allowPrivacyPromote': this.state.declaration3,
              'policy.businessEmail': this.state.centaline ?
                undefined : removeSpaces(this.state.businessEmailAddress),
              'policy.specialRequest': this.state.specialRequests,
              'policyHolder.mobile': this.state.policyholderPhone,
              'policyHolder.email': this.state.policyholderEmail,
              'policyHolder.address1': this.state.policyholderAddress,
              'noticeAgree': this.state.noticeAgree,
              'travel.premiumPlan': people,
              'travel.isUpgradePA': this.state.paBenefits,
              'travellers': this.state.travellers,
              'rawRequest': JSON.stringify(rawRequest),
            };
          }
          if (this.state.member) {
            fields['member.id'] = this.state.member.id;
          }
          if (this.state.token) {
            fields['member.token'] = this.state.token.tokenStr;
          }
          if (this.state.usingEcash == 'Y') {
            fields['payment.ecash'] =
              this.state.ecash > this.state.total ?
              this.state.total : this.state.ecash;
          }
          if (this.state.isCompany) {
            fields['policyHolder.organization'] = this.state.policyHolderOrganization;
          }
          else {
            fields['policyHolder.documentType'] = this.state.policyholderIdType;
            fields['policyHolder.documentNo'] = this.state.policyholderIdNumber;
            fields['policyHolder.dateOfBirth'] = this._apiDateFormat(this.state.policyholderDOB);
            fields['policyHolder.salutation'] = this.state.policyholderTitle;
            fields['policyHolder.firstName'] = this.state.policyholderFirstName;
            fields['policyHolder.lastName'] = this.state.policyholderLastName;
          }

          travel(
            fields,
            (msgDesc) => {
              if (this.state.broker && !msgDesc.isManualWriting) {
                this.setState({alert: Alert.alert(
                  this.state.text.thankYou,
                  this.state.text.youHaveSuccessfully +
                  this.state.text.bravoTravelProtector +
                  this.state.text.ccccc,
                  [{
                    text: this.state.text.ok,
                    onPress: () => this.props.history.push('/'),
                  }]
                )});
              }
              else if (msgDesc.isManualWriting) {
                this.setState({alert: Alert.alert(
                  this.state.text.claimThankYou,
                  this.state.text.youHaveSuccessfully +
                  this.state.text.bravoTravelProtector +
                  this.state.text.ccccc +
                  this.state.text.manualUnderwritingIsNeeded +
                  this.state.text.inCaseOfAnyQuestions,
                  [{text: this.state.text.ok, onPress: () => this.props.history.push('/')}]
                )});
              }
              else if (
                this.state.usingEcash == 'Y' &&
                this.state.total - this.state.ecash <= 0
              ) {
                this.setState({alert: Alert.alert(
                  this.state.text.congratulations,
                  this.state.text.madeAPurchase +
                  this.state.text.weWillSend +
                  this.state.policyholderEmail +
                  this.state.text.anyQuestions,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => this.props.history.push('/'),
                    },
                  ]
                )});
              }
              else {
                this.props.history.push('Payment', {
                  uri: host + api310 +
                    '?txId=' + msgDesc.txId + '&locale=' +
                    (this.state.language == 'zhHK' ? 'zh-HK' : 'en-US'),
                  policyNumber: msgDesc.policyNo,
                  email: this.state.policyholderEmail,
                  recaptcha: !this.state.member,
                  broker: this.state.broker,
                  product: this.state.text.bravoTravelProtector,
                });
              }
              this.setState({submitButtonDisabled: false});
            },
            (msgCode, msgDesc, handleDefault) => {
              if (this.state.broker && msgCode == 53) {
                this.setState({alert: Alert.alert(this.state.text.submissionFailed,
                  this.state.text.invalidMobileNumber,
                  [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}]
                )});
              }else if(msgCode==180){
                this.setState({alert: Alert.alert(this.state.text.submissionFailed,
                  this.state.text.tryAgain,
                  [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}]
                )});
              }else {
                handleDefault();
              }
              this.setState({submitButtonDisabled: false});
            },
          )
        }
      );
    }
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    let insureds = [];
    this.state.travellers.forEach((traveller) => {
      let relationship;
      switch (traveller.relationship) {
        case 'spouse':
          relationship = this.state.text.spouse;
          break;
        case 'child':
          relationship = this.state.text.child;
          break;
        default:
          relationship = this.state.text.insured;
      }

      insureds.push(
        <View key={traveller.documentNo}>
          <View style={formStyles.fieldset}>
            <Text style={[formStyles.h3, {color}]}>{relationship}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.name}</Text>
            <Text>
              {formatName(
                traveller.salutation,
                traveller.firstName,
                traveller.lastName
              )}
            </Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
            <Text>{moment(traveller.dateOfBirth, 'YYYY-MM-DD').format('MMMM D, YYYY')}</Text>
          </View>

          <View style={formStyles.fieldset}>
            {
              traveller.documentType == 'HKID' ?
                <Text style={formStyles.bold}>{this.state.text.hkid}</Text> :
                <Text style={formStyles.bold}>{this.state.text.passport}</Text>
            }
            <Text>{traveller.documentNo}</Text>
          </View>

          {
            this.state.period == 'annual' && traveller.includeChinaMedical &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.bold}>{this.state.text.chinaMedicalGuaranteeCard}</Text>
                <Text>{traveller.includeChinaMedical == 'Y' ? this.state.text.yes : this.state.text.no}</Text>
              </View>
          }

          {
            this.state.period == 'annual' && traveller.includeChinaMedical =='Y' &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.bold}>{this.state.text.mainlandPermit}</Text>
                <Text>{traveller.mainlandPermit}</Text>
              </View>
          }
        </View>
      );
    });

    let importantNotes = [];
    if (this.state.period == 'shortTermTrip') {
      importantNotes = [
        {
          number: 1,
          text: this.state.text.policyholderAndInsuredsMust
        },
        {
          number: 2,
          text: this.state.text.theCoveredTripShouldOriginate
        },
        {
          number: 3,
          text: this.state.text.noRefundOfPremium
        },
        {
          number: 4,
          text: this.state.text.assicurazioniGeneraliSpA
        },
      ];
    }
    if (this.state.period == 'annual') {
      importantNotes = [
        {
          number: 1,
          text: this.state.text.policyholderAndInsuredsMust
        },
        {
          number: 2,
          text: this.state.text.theCoveredTripShouldOriginate
        },
        {
          number: 3,
          text: this.state.text.noRefundOfPremium
        },
        {
          number: 4,
          text: this.state.text.assicurazioniGeneraliSpA
        },
      ];
    }

    var name = '';
    if(this.state.planBroker)
       name = this.state.planBroker.nameEnUs


    return (
      <div className='App'>
        <Helmet>
          <title>Bravo Travel Protector | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getInsured}
          backButton
          history={this.props.history}
        />

        <ScrollView>
          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
              <Grid
                item xs
                style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}
              >
                <View style={[styles.greeting,{fontWeight:'bold',}]}>
                  <Text
                    style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}
                  >
                    {this.state.text.bravoTravelProtector}
                  </Text>
                  <p></p>
                  <Text style={[styles.greetingText,{fontSize: 20,}]}>
                    {this.state.text.adventure}
                  </Text>
                  <img
                  //  style={{width: '100%', height: '90%'}}
                  //  src={'./assets/images/travel.png'}
                  >
                  </img>
                </View>
              </Grid>
            </Hidden>

            <Grid
              item xs
              style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}
            >
              <View style={formStyles.form}>
                {this.state.alert}
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h2, {color}]}>
                    {
                      this.state.centaline ?
                      this.state.text.centalineTravelProtector :
                      this.state.text.bravoTravelProtector
                    }
                  </Text>
                </View>

                {
                  this.state.broker &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.insuranceAdvisorMobile}</Text>
                      <Text>{this.state.insuranceAdvisorMobile}</Text>
                    </View>
                }
                <View style={styles.font}>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.productType}</Text>
                  <Text>{planTypes[this.state.planType].label[this.state.language]}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.shortTermTripAnnual}</Text>
                  <Text>{options.period[this.state.period].label[this.state.language]}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.individualOrWithOthers}</Text>
                  <Text>{options.people[this.state.people].label[this.state.language]}</Text>
                </View>

                {/* <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.addtionalUpgradePersonalAccidentBenefit}</Text>
                  <Text>{this.state.paBenefits == 'Y' ?
                    this.state.text.yes : this.state.text.no}</Text>
                </View> */}

                {
                  this.state.period == 'annual' &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.additionalChinaMedicalGuaranteeCard}</Text>
                      <Text>{this.state.medicalGuarantee == 'Y' ?
                        this.state.text.yes : this.state.text.no}</Text>
                    </View>
                }

                {
                  this.state.planType == 'premier' && this.state.period == 'shortTermTrip' &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.addtionalCruiseBenefit}</Text>
                      <Text>{this.state.cruiseBenefit == 'Y' ?
                        this.state.text.yes : this.state.text.no}</Text>
                    </View>
                }

                {
                  this.state.period == 'shortTermTrip' ?
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.periodOfTravel}</Text>
                      <Text>{this.state.startDate} - {this.state.endDate} ({this.state.days} {this.state.text.daysdays})</Text>
                    </View> :
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.effectiveDate}</Text>
                      <Text>{this.state.effectiveDateStart} - {this.state.effectiveDateEnd}</Text>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.destination}</Text>
                  <Text>{this.state.text.worldwide}</Text>
                </View>

                {
                  this.state.promotionCodes &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.promotionCodes}</Text>
                      <Text>{this.state.promotionCodes}</Text>
                    </View>
                }

                {
                  this.state.businessEmailAddress &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.businessEmailAddress}</Text>
                      <Text>{this.state.businessEmailAddress}</Text>
                    </View>
                }

                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h3, {color}]}>{this.state.text.policyHolder}</Text>
                  <Text></Text>
                </View>

                {
                  this.state.policyHolderOrganization ?
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.bold}>{this.state.text.companyName}</Text>
                    <Text>{this.state.policyHolderOrganization}</Text>
                  </View>
                  :
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.name}</Text>
                      <Text>
                        {formatName(
                          this.state.policyholderTitle,
                          this.state.policyholderFirstName,
                          this.state.policyholderLastName
                        )}
                      </Text>
                    </View>

                    <View style={formStyles.fieldset}>
                      {
                        this.state.policyholderIdType == 'HKID' ?
                          <Text style={formStyles.bold}>{this.state.text.hkid}</Text> :
                          <Text style={formStyles.bold}>{this.state.text.passport}</Text>
                      }
                      <Text>{this.state.policyholderIdNumber}</Text>
                    </View>

                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.dateOfBirth}</Text>
                      <Text>{this.state.policyholderDOB}</Text>
                    </View>
                  </View>
                }



                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.mobileNumber}</Text>
                  <Text>{this.state.policyholderPhone}</Text>
                </View>

                {
                  this.state.policyholderAddress &&
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.bold}>{this.state.text.correspondenceAddress}</Text>
                      <Text>{this.state.policyholderAddress}</Text>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.emailAddress}</Text>
                  <Text>{this.state.policyholderEmail}</Text>
                </View>

                { insureds }
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h3, {color}]}>{this.state.text.question}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.bold}>{this.state.text.haveThisTripInvolvedAnyCompetition}</Text>
                  <Text>{this.state.text.no}</Text>
                </View>

                {
                  this.state.specialRequests &&
                    <View style={formStyles.fieldset}>
                      <Text style={[formStyles.h3, {color}]}>{this.state.text.specialRequests}</Text>
                      <Text>{this.state.specialRequests}</Text>
                    </View>
                }

                <View style={formStyles.fieldset}>
                  <ImportantNotes
                    color='#C50000'
                    backgroundColor='#F5F5F5'
                    importantNotes={importantNotes}
                  />
                </View>

                <View style={formStyles.fieldset}>
                  <LegalDocumentLink
                    product={this.state.text.bravoTravelProtector}
                    planBroker={this.state.planBroker}
                    history={this.props.history}
                    choice={this.state.declaration2}
                    onChange={(choice) => this.setState({declaration2: choice})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
                </View>

                <View style={formStyles.fieldset}>
                  <DirectMarketingTickBox
                    history={this.props.history}
                    choice={this.state.declaration3}
                    onChange={(choice) => this.setState({declaration3: choice})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
                </View>

                {
                  (this.state.insuranceAdvisor || this.state.broker || this.state.promotionCodes) &&
                  <View style={formStyles.fieldset}>

                <YesNoQuestion
                  question={this.state.text.brokerDeclaration+ 
                    name
                    +this.state.text.brokerDeclarationII}
                  choice={this.state.brokerDeclaration}
                  onChange={(choice) => this.setState({brokerDeclaration: choice})}
                />
                  
                <Text style={formStyles.message}>{this.state.messages.brokerDeclaration}</Text>
              </View>
             }

                {
                  this.state.manualUnderwriting ?
                  <PaymentBreakdown manualUnderwriting /> :
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={[formStyles.h3, {color}]}>{this.state.text.payment}</Text>
                    </View>

                    {
                      !this.state.broker && this.state.ecash > 0 &&
                        <View style={formStyles.fieldset}>
                          <YesNoQuestion
                            question={this.state.text.youHave + this.state.text.eCashNow}
                            choice={this.state.usingEcash}
                            onChange={(choice) => this.setState({usingEcash: choice})}
                          />
                          <Text style={formStyles.message}>{this.state.messages.usingEcash}</Text>
                        </View>
                    }

                    <PaymentBreakdown
                      premium={this.state.premium}
                      levy={this.state.levy}
                      total={this.state.total}
                      groupDiscount={
                        this.state.period == 'shortTermTrip' &&
                        this.state.people == 'group' &&
                        this.state.travellers.length >= 7
                      }
                    />
                  </View>
                }

                {
                  this.state.submitButtonDisabled ?
                    <View style={formStyles.submitButtonDisabled}>
                      <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                    </View> :
                    <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._confirm()}>
                      <Text style={formStyles.submitButtonText}>{this.state.text.confirm}</Text>
                    </TouchableOpacity>
                }

              </View>
            </Grid>
            <Hidden xsDown>
              <div style={{marginRight: '5%'}} />
            </Hidden>
          </Grid>
        </ScrollView>
        <Footer/>
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    backgroundColor: 'white',
  },
  font:{
    margin: 'auto',
    minWidth: 300,
    maxWidth: 300,
    textAlign: 'left'
  },
};
