import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const planTypes = {
  premier: {
    label: {
      enUS: enUS.premier,
      zhHK: zhHK.premier,
    },
    color: 'white',
    backgroundColor: '#8E1230',
  },
  classic: {
    label: {
      enUS: enUS.classic,
      zhHK: zhHK.classic,
    },
    color: 'white',
    backgroundColor: '#E9573D',
  },
  standard: {
    label: {
      enUS: enUS.standard,
      zhHK: zhHK.standard,
    },
    color: 'white',
    backgroundColor: '#F09273',
  },
};
